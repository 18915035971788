@import "../../../scss/globals/global";
@import '../../../scss/utilities/mixins';

.creditsWrapper {
   width: fit-content;
   display: inline-block;
   font-size: 14px;
   margin: 0 auto;

   img {
      transform: skew(10deg, 0deg) !important;
      margin: 0 8px;
   }

   span {
      color: #17EA85;
      font-size: 14px;
      transform: skew(10deg, 0deg) !important;
   }

   label {
      text-transform: capitalize;
   }

   .credits {
      padding: 6px 22px;
      border: none;
      outline: none;
      position: relative;
      z-index: 1;
      border-radius: 2px;
      background: linear-gradient(103.05deg, #F9FF01 7.22%, #2FFF9E 93.09%);
      //    cursor: pointer;
      transform: skew(-9deg, 0deg);
      text-transform: uppercase;
      margin-right: 10px;
   }

   .credits::before {
      content: "";
      position: absolute;
      left: 1px;
      right: 1px;
      top: 1px;
      bottom: 1px;
      border-radius: 4px;
      background-color: #0E2029;
      z-index: -1;
      transition: 200ms
   }

   .credits::after {
      content: attr(data);
      font-size: 16px;
      background: linear-gradient(to left, #00FFA3, #DC1FFF);
      -webkit-background-clip: text;
      color: #fff;
      transition: 200ms
   }
}

.modalContentContainer {
   box-shadow: 0px 0px 65px 0px #80ff5f6e;
}

.PaymentConfirmmodal {
   display: block;
   background: rgba(0, 0, 0, 0.2588235294);
   backdrop-filter: blur(5px);
   -webkit-backdrop-filter: blur(5px);

   .CreditPurchaseModal {
      width: 100%;
      max-width: 580px;

      .closeButton {
         position: absolute;
         z-index: 1;
         top: 10px;
         right: 15px;
      }

      .ModalHeader {
         background: rgba(17, 30, 51, 1);
         margin-bottom: 40px;

         h4 {
            font-size: 20px;
            font-family: Montserrat;
            padding: 20px;

            img {
               margin: -6px 10px 0 0;
            }
         }
      }
   }

   .CreditRate {
      width: fit-content;
      margin: 0 auto;

      img {
         width: 24px;
         height: 24px;
      }

      * {
         padding: 3px;
      }

      p {
         text-align: center;

         a {
            color: #17ea85;
            text-decoration: underline;
            display: unset;
         }
      }
   }

   .OmniCardWrapper {
      margin-top: 15px;

      .OmniCard {
         display: block;
         position: relative;
         width: calc(50% - 13px);
         float: left;
         padding: 5px 10px;
         background-color: #062024;
         border: solid 1px #102531;
         margin: 6px;
         border-radius: 4px;
         margin-top: 20px;

         * {
            padding: 4px;
         }

         img {
            width: 32px;
            height: 32px;
         }

         > div {
            display: flex;
            justify-content: center;
         }
      }

      .OmniCardClicked {
         border-color: rgba(23, 234, 133, 1);
         cursor: pointer;
      }

      label, span {
         font-size: 14px;
      }
   }

   fieldset {
      border-top: solid 1px #1b3341;
      margin: 30px 0px;

      legend {
         all: unset;
         margin: 0 auto;
         color: rgba(149, 166, 183, 1);
         font-size: 16px;
         padding-left: 10px;
         padding-right: 10px;
      }

      .OmniCal {
         margin-top: 30px;
         display: grid;
         grid-template-columns: 1fr 30px 1fr;
         gap: 5px;

         input {
            width: 100%;
            padding: 10px;
            border: solid 1px rgb(28 45 74);
            background-color: rgba(17, 30, 51, 1);
            border-radius: 4px;
         }

         input:focus-visible {
            border: solid 1px rgb(50 68 100) !important;
            outline: none;
         }

         span {
            display: block;
            text-align: center;
            color: rgba(149, 166, 183, 1);
            padding-top: 42px;
         }

         label {
            color: rgba(149, 166, 183, 1);
         }
      }
   }

   .photoModifyModelBtnRow {
      display: block !important;
      width: 90% !important;
      margin-left: auto;
      margin-right: auto;

      .BlackSkBtn {
         float: left;
         background: linear-gradient(103.05deg, #F9FF01 7.22%, #2FFF9E 93.09%);
         border: 0 !important;

         span {
            // background-image: linear-gradient(0deg, #FAFF01, #39FF96);
            // font-size: 16px;
            // line-height: 20px;
            // -webkit-background-clip: text;
            // background-clip: text;
            // color: transparent;
            // background-image: linear-gradient(90deg, #FAFF01, #39FF96);

            font-size: 16px;
            line-height: 20px;
            background-color: #000 !important;
            padding: 15px 27px;
            border-radius: 4px;
            // -webkit-background-clip: text;
            // background-clip: text;
            // color: transparent;
         }
      }

      .LoginButton {
         float: left;
      }

      .LoginButton.ProceedButton {
         float: right;
      }

   }
}

.Radial1 {
   @include gradientGeneralLeft(900px, 900px, -550px, 550px, $Blue03, 32.65%, 32.65%, $GradPosCenter);
   mix-blend-mode: normal;
   opacity: 0.14;
   z-index: -1;
}

.Radial2 {
   @include gradientGeneralRight(500px, 500px, 0, 1050px, $Blue03, 32.65%, 32.65%, $GradPosRight);
   mix-blend-mode: normal;
   opacity: 0.14;
   z-index: -1;
}


// p {
//    text-align: center;
//    font-size: 18px;
//    font-family: 'ObjectSans-Regular';
// }


.OverviewBox {
   @include overview-box;
   padding-left: 5px;

   img {
      width: auto;
   }
}

.leaderBoardTitle {
   position: relative;

   .shareBtnWrapper {
      position: absolute;
      top: -4px;
      right: 0;
   }
}

.borderTop1 {
   @include yellow-gradient;
}

.borderTop2 {
   @include green-gradient;
}

.borderTop3 {
   @include purple-gradient;
}


.SplashBox {
   height: 320px;
   background-image: url(https://storage.googleapis.com/meta11/serviceplatform/splash-bg.png);
   background-position: center 0%;
}

.marks {
   width: 56px;
   height: 28px;
   background: #172842;
   border: 1px solid #253342;
   border-radius: 4px;
   margin-left: auto;
   margin-right: auto;
}


.RadioBox {
   width: 20%;
   float: left;
   text-align: center;
   padding-top: 21px;
}

.radioFill:checked {
   background-color: #04151d;
   border-color: #17EA85;
   background-image: url(https://storage.googleapis.com/meta11/serviceplatform/radioCheckGreen.png) !important;
   background-repeat: no-repeat;
   background-size: 60%;
}

.radioFill {
   border: 1px solid #17EA85 !important;
   width: 1.3em;
   height: 1.3em;
   background-color: #04151d;
   cursor: pointer;
}


.BlackSkBtn text {

   background-image: linear-gradient(0deg, #FAFF01, #39FF96);
   background-clip: text;
   color: transparent;
   font-family: "ObjectSans-Heavy";
   font-size: 16px;
   line-height: 18px;
}

.GrayButton {
   @include GrayButtonAtributes($grayButtonColor1, $grayButtonColor2, $buttonColor1, $buttonColor2, $BtnBorderRadius, $Btntransform, $BtntextTransform, $BtnPaddingGray);
   @include css3Anim();
   border: 1px solid $buttonColor1;
   background-clip: text;
   color: transparent;
   padding: 12px 20px;
   border-radius: 5px;

   text {
      @include GrayButtonText($buttonColor1, $buttonColor2, $BtnfontSize, $BtnlineHeight);
      -webkit-background-clip: text;
      background-clip: text;
      color: transparent;
      font-family: $buttonTextFont;
   }
}

.GrayButton:hover {
   background: $grayButtonHover;
}

.BlackSkBtn {
   background-color: linear-gradient(0deg, #0E2029, #0E2029) padding-box2029;
   box-shadow: 0px 2px 0px rgba(0, 0, 0, 0.51);
   border-radius: 4px;
   transform: skew(-10deg, 0deg);
   border: 1px solid rgba(0, 255, 194, 0.09);
   /* identical to box height, or 129% */
   text-align: center;
   letter-spacing: -0.01em;
   text-transform: uppercase;
   padding: 1px;
   font-weight: 700;
   height: 54px;

   .BlackSkBtn text {
      color: linear-gradient(0deg, #FAFF01, #39FF96) 5A6B7;
   }

   :visited {
      box-shadow: 0px 2px 0px rgba(0, 0, 0, 0.51);
      cursor: not-allowed;
   }

   :disabled,
   .BlackSkBtn[disabled] {
      background: #0E2029;
      box-shadow: 0px 2px 0px rgba(0, 0, 0, 0.51);
      backdrop-filter: blur(45px);
   }
}

.BlackSkBtn:hover {
   background: #203455;
}

.LoginButton {
   background: linear-gradient(95.34deg, #FAFF00 6.28%, #00FFC2 111.13%);
   box-shadow: 0px 2px 0px rgba(0, 0, 0, 0.51);
   border-radius: 4px;
   transform: skew(-10deg, 0deg);
   border: 1px solid #2FFF9E;
   /*border-image-source: linear-gradient(103.05deg, #2FFF9E 7.22%, #F9FF01 93.09%);*/
   text-align: center;
   letter-spacing: -0.01em;
   padding: 10px 27px;
   /*font-weight: 700;
   width: 100%;*/
   height: 48px;
   margin-top: 1px;
   font-family: "ObjectSans-Heavy", Helvetica, Arial, sans-serif;
   font-size: 16px;
   line-height: 18px;
   text-transform: uppercase;
   margin-left: 10px;
   // :hover {
   //   background: linear-gradient(95.34deg, #00FFC2 6.28%, #FAFF00 111.13%);
   // }

   :visited {
      background: linear-gradient(95.34deg, #B0FFCA 6.28%, #F2FF7E 111.13%);
   }
}

.LoginButton:hover {
   background: linear-gradient(95.34deg, #00FFC2 6.28%, #FAFF00 111.13%);
}

.modalBlur {
   background-color: #00000063;
}

//  .closeButton {
//     border: 0px;
//     background-color: #00000000;
//  }

.CreditPurchaseModal {
   .modalContainer {
      .modalTitle {
         font-family: 'SolinaLight';
         font-size: 20px;
         color: #ffffff;
         text-transform: uppercase;
         line-height: 40px;
      }
   }

   .photoModifyModelBtnRow {
      display: flex;
      width: fit-content;
      margin: 0 auto;

      > a {
         float: right;
         margin-right: -25px;

         > button:hover {
            -webkit-text-fill-color: unset !important;
            cursor: pointer;
         }
      }

      > a:hover {
         -webkit-text-fill-color: unset !important;
         color: #000;

      }
   }
}


.DeleteAccountWrapper {
   margin: 40px 0;
   padding: 0;

   h3 {
      font-size: 32px;
      font-family: "SolinaLight", Helvetica, Arial, sans-serif;
      text-align: left;
      line-height: 35px;
      color: #ffffff;
      text-transform: uppercase;
   }

   .DeleteAccountBtnWrapper {
      .DeleteAccountBtn {
         padding: 14px 23px;
         border: none;
         outline: none;
         position: relative;
         z-index: 1;
         border-radius: 5px;
         background: linear-gradient(103.05deg, #F9FF01 7.22%, #2FFF9E 93.09%);
         cursor: pointer;
         transform: skew(-10deg, 0deg);
         text-transform: uppercase;
      }

      .DeleteAccountBtn::before {
         content: "";
         position: absolute;
         left: 1px;
         right: 1px;
         top: 1px;
         bottom: 1px;
         border-radius: 4px;
         background-color: #0E2029;
         z-index: -1;
         transition: 200ms
      }

      .DeleteAccountBtn::after {
         content: attr(data);
         font-size: 16px;
         background: linear-gradient(to left, #00FFA3, #DC1FFF);
         -webkit-background-clip: text;
         color: #fff;
         transition: 200ms
      }

      .DeleteAccountBtn:hover::before {
         opacity: 50%;
         top: 0px;
         right: 0px;
         bottom: 0px;
         left: 0px;
      }

      .DeleteAccountBtn:hover::after {
         color: white;
      }
   }

}

.UserProfileSplashBox {

   .SplashBox {
      min-height: 320px;
      padding-bottom: 40px !important;
      background-image: url(../../../assets/images/leader-board-splash.webp);
      background-repeat: no-repeat;
      background-position: center 0%;
   }

   .upChevron {
      width: 24px;
      height: 24px;
      margin: 0 auto;
      margin-bottom: 10px;
      background-image: url(../../../assets/images/up-chevron.webp);
      background-repeat: no-repeat;
   }

   .downChevron {
      width: 24px;
      height: 24px;
      margin: 0 auto;
      margin-bottom: 10px;
      background-image: url(../../../assets/images/down-chevron.webp);
      background-repeat: no-repeat;
   }

   .outCurcleWrapper {
      width: 144px;
      height: 144px;
      position: relative;
      margin: 0 auto;
   }

   .trophyTextW {
      font-size: 18px;
      color: #ffffff;
      font-family: "ObjectSans-Regular";
      text-transform: uppercase;
      letter-spacing: -0.01em;
      line-height: 24px;
      font-style: italic;
      font-weight: 700;
      min-height: 60px;
   }

   .curcleOut1 {
      margin-top: 27px;
   }

}

.CreditPurchaseModal {
   position: relative;

   .closeButton {
      position: absolute;
      top: 15px;
      right: 16px;
      z-index: 2;

      img {
         display: block;
         width: 14px;
         height: 14px;
         position: absolute;
         z-index: 4;
      }
   }

   .circle1 {
      z-index: 0;
      width: 195px;
      height: 220px;
      position: absolute;
      background-image: url('../../../assets/images/circle1.webp');
      background-size: 195px 220px;
      background-repeat: no-repeat;
      background-position: top right;
      top: -24px;
      right: -24px;
   }

   .circle2 {
      z-index: 0;
      width: 98px;
      height: 200px;
      position: absolute;
      background-image: url('../../../assets/images/circle2.webp');
      background-size: 98px 200px;
      background-repeat: no-repeat;
      background-position: top right;
      top: 73px;
      left: -24px;
   }

   .circle3 {
      z-index: 0;
      width: 88px;
      height: 88px;
      position: absolute;
      background-image: url('../../../assets/images/circle3.webp');
      background-size: 88px 88px;
      background-repeat: no-repeat;
      background-position: bottom left;
      bottom: 32px;
      left: 24px;
   }

   .UserProfileModal {
      img {
         width: fit-content;
         max-width: 240px;
         margin: 0 auto;
         display: block;
      }

      h4 {
         text-transform: uppercase;
         font-family: Montserrat;
         font-size: 28px;
         font-style: italic;
         font-weight: 800;
         line-height: 32px;
         letter-spacing: 0.02em;
         text-align: center;
         text-transform: uppercase;
         background: linear-gradient(89.7deg, #17EA85 2.27%, #14D0E6 23.6%, #17EA85 44.41%, #14D0E6 64.17%, #17EA85 82.99%, #14D0E6 99.74%);
         -webkit-background-clip: text;
         -webkit-text-fill-color: transparent;
         background-clip: text;
         text-fill-color: transparent;
         text-shadow: 0px 0px 8px rgba(20, 208, 229, 0.5607843137);
         padding-top: 30px !important;
      }

   }

   .SaveTag {
      color: rgba(222, 74, 74, 1);
      background-color: #291421;
      width: fit-content;
      padding-left: 10px !important;
      padding-right: 10px !important;
      position: absolute;
      top: -14px;
      left: 50%;
      transform: translate(-50%, 0);
      font-size: 12px;
   }
}

.ScheduledMatchPopUp {
   .FaqLink {
      a {
         color: #17EA85;
         text-decoration: underline;
      }
   }

   .eMulticolorFull {
      scale: 2;
      bottom: 126px !important;
      z-index: 0 !important;
   }

   .ModalBody {
      > div {
         position: relative;
      }
   }

   .BtnRow {
      justify-content: end;

      button {
         max-width: fit-content;
         margin-right: 20px;
         margin-bottom: 16px;
         color: #fff;
         padding: 5px 20px;
         background: linear-gradient(0deg, #0E2029, #0E2029) padding-box !important;
      }
   }

   .VideoRapper {
      position: relative;
      width: 402px;
      height: 255px;

      > video {
         position: absolute;
         left: 0;
         top: 0;
         z-index: 1;
      }
   }

   .HowToEngageTxt {
      border-top: 1.5px solid transparent;
      -webkit-border-top-width: 1px;
      border-style: solid;
      border-image: linear-gradient(to right, rgba(38, 200, 103, 0), rgb(38, 200, 103), rgba(38, 200, 103, 0)) 0.8;
      -webkit-mask-image: linear-gradient(to right, rgba(38, 200, 103, 0), rgb(38, 200, 103), rgba(38, 200, 103, 0)) 0.8;
      border-bottom: 0px;
      padding-top: 18px;
   }

   .redManEllipsRed {
      position: absolute;
      width: 282px;
      height: 400px;
      left: 0;
      bottom: -102px;
      mix-blend-mode: screen;
      opacity: 0.3;
      background: radial-gradient(32.65% 32.65% at 100% 50%, #e61479 0%, rgba(230, 20, 20, 0) 100%);
      z-index: 1;
      pointer-events: none;
      transform: rotate(-180deg);
   }

   .eMulticolorHalf {
      background-size: 100% 100%;
      position: absolute;
      z-index: 1;
      left: 0;
      top: 173px;
      width: 80px;
      height: 157px;
      transform: rotate(180deg);
      opacity: 0.4;
   }
}

@media (max-width: 992px) {
   .ProfilePaneWrapper {
      .subTitle {
         padding-top: 6px !important;
      }
   }
}

@media (max-width: 768px) {
   .photoModifyModelBtnRow {
      display: grid !important;
      grid-template-columns: 1fr !important;
      gap: 15px !important;

      .LoginButton {
         margin-left: -13px;
      }
   }
   .CreditPurchaseModal {
      > .modalContentContainer {
         width: 95vw;

         .OmniCardWrapper {
            position: relative;

            .OmniCard {
               position: relative;
               width: 98%;
               margin: 0 auto;
               margin-bottom: 15px;

               > div {
                  width: fit-content;
                  margin: 0 auto;
                  margin-top: 4px;
               }
            }
         }

         fieldset {
            .OmniCal {
               display: block;

               > span {
                  display: none;
               }

               > div {
                  display: block;
                  margin-bottom: 10px;
               }
            }
         }
      }
   }
   .ModalBody {
      > div {
         position: relative;

         > div > div > div > div:nth-child(1) {

            .timerWrapper {
               top: unset !important;
            }
         }

         > div > div > div > div:nth-child(3) {
            top: -48px !important;
         }

      }
   }

}

@media (max-width: 468px) {
   .VideoRapper {
      position: relative;
      width: 100% !important;

      > video {
         position: absolute;
         left: 0;
         top: 0;
         z-index: 1;
      }
   }
}

.FantasyTeamSelector {
   gap: 20px;
   display: grid;
   grid-template-columns: 390px 1fr;
}

.FantasyTeamPage {
   .TrashIcon {
      width: 24px;
      height: 24px;

      img {
         width: 24px;
         height: 24px;
      }
   }

   .PlayerRoleIconSelectedPlayers {
      position: absolute;
      top: 5px;
      left: -5px;
      width: 18px;
   }

   .container {
      h1 {
         font-size: 35px;
         line-height: 58px;
         text-align: left;
         margin-top: 10px;
         font-family: "Montserrat";
         font-weight: 800;
         font-style: italic;
         letter-spacing: 0.02em;
         text-transform: uppercase;
         background: linear-gradient(89.7deg, #17EA85 2.27%, #14D0E6 23.6%, #17EA85 44.41%, #14D0E6 64.17%, #17EA85 82.99%, #14D0E6 99.74%);
         -webkit-background-clip: text;
         -webkit-text-fill-color: transparent;
         background-clip: text;
         text-fill-color: transparent;
      }
   }
}


@mixin FantacyCard {

   position: relative;
   background: linear-gradient(0deg, #224755, #068F7A, #224755);
   border-radius: 8px;

   width: 160px;
   max-width: 160px;
   padding: 2px;
   .CardBackground {
      background-color: #0A1627;
      border-radius: 8px;
      padding: 10px;
      position: relative;
      min-height: 206px;

      > div {
         justify-content: center;
      }
   }
   .ProfilePic {
      width: 78px;
      height: 78px;
      border-radius: 50%;
      background-size: cover;
      background-repeat: no-repeat;
      background-color: #010E14;
      background-image: url('../../assets/images/PlayerProfilePic.webp');
      background-repeat: no-repeat;
   }
   .EmptyProfilePicBorder {
      background: linear-gradient(180deg, #F9FF01, #2FFF9E);
      padding: 2px;
      border-radius: 5px;
      width: 80px;
      height: 80px;
      border-radius: 50%;
   }
   .EmptyProfilePic {
      // background-image: url('../../assets/images/PlayerProfilePic.webp');
      background-image: url('../../assets/images/FantasyProfilePic.webp');
      background-repeat: no-repeat;
      background-position: right -3px bottom -7px;
      margin-top: -1px;
      margin-left: -1px;
   }
   .TrashIcon {
      position: absolute;
      top: 5px;
      right: 20px;
   }
   .BtnRow {
      display: flex;
      justify-content: center;

      button {
         font-family: 'ObjectSans-Regular';
         border: solid 1px #17EA85;
         background-color: #152D39;
         color: #17EA85;
         border-radius: 4px;
         margin-bottom: 4px;
         font-size: 14px;
      }

      button:disabled {
         filter: grayscale(1);
      }
   }

   .TitleRow {
      justify-content: center;

      span {
         color: #17EA85;
         font-size: 14px;
         text-align: center;
         margin-top: -4px;
         margin-bottom: 4px;
      }
   }
   .PlayerNameWrapper {
      padding: 0;
      display: flex;
      justify-content: center;

      h4 {
         font-family: 'ObjectSans-Regular';
         font-size: 12px;
         font-weight: 700;
         font-style: italic;
         text-transform: uppercase;
         text-align: center;
         padding: 0;
         margin: 0;
         // margin-bottom: 5px;
         margin-top: 5px;

         max-width: 110px;
         white-space: nowrap;
         overflow: hidden;
         text-overflow: ellipsis;
         padding-right: 3px;
      }

      a {
         img {
            margin-top: -6px;
         }
      }
   }
   h4 {
      font-family: 'ObjectSans-Regular';
      font-size: 12px;
      font-weight: 700;
      font-style: italic;
      text-transform: uppercase;
      text-align: center;
      padding: 0;
      margin: 0;
      // margin-bottom: 5px;
      margin-top: 5px;
   }

}

.PlayerListCol {
   border: solid 1px #355A69;
   border-radius: 4px;
   padding-top: 18px;
   background-color: #010B14;

   .SearchInputWrapper {
      position: relative;

      input {
         padding-top: 0px;
         padding-bottom: 0;
         height: 52px;
         background-color: #111E33 !important;
      }

      img {
         position: absolute;
         right: 10px;
         top: 17px;
      }
   }

   .PlayerListWrapper {
      padding-right: 0;

      .PlayerList {
         margin-top: 15px;
         margin-bottom: 18px;
         overflow-y: auto;
         max-height: 800px;
         padding-right: 6px;

         .Player {
            border: solid 1px #355A69;
            background-color: #111E33;
            border-radius: 4px;
            margin: 6px 0;

            .Wrapper {
               display: flex;
               gap: 7px;
               padding: 12px;

               .PlayerProfilePic {
                  width: 98px;
                  background-color: #010E14;
                  position: relative;

                  img {
                     width: 100%;
                  }

                  .PlayerLogo {
                     position: absolute;
                     top: 5px;
                     left: 5px;
                     width: 26px;
                     height: 26px;
                     background-size: contain;
                  }

                  .PlayerRoleIconAllPlayers {
                     position: absolute;
                     top: 5px;
                     right: 5px;
                     width: 18px;
                     background-size: contain;
                  }
               }

               .ProfileInfo {
                  width: calc(100% - 98px);

                  .PlayerNameRow {
                     padding-top: 5px;
                     position: relative;
                     display: block;
                     width: 100%;
                     // grid-template-columns: 1fr 57px 60px;
                     h4 {
                        all: unset;
                        font-size: 12px;
                        margin: 0;
                        padding: 0;
                        // width:100%;
                        max-width: 118px;
                        white-space: nowrap;
                        overflow: hidden;
                        text-overflow: ellipsis;
                        float: left;
                     }

                     a {
                        width: 20px;
                        height: 20px;
                        display: block;
                        float: left;
                        margin-top: -4px;
                     }

                     .AddBtnWrapper {
                        background-color: #010E14;
                        border-radius: 4px;
                        height: 32px;
                        min-width: 60px;
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        position: relative;
                        color: #fff;
                        border: none;
                        font-size: 12px;
                        float: right;

                        > span {
                           font-size: 12px;
                        }

                        img {
                           position: absolute;
                           top: 4px;
                           right: 0;
                        }

                        button {
                           border-radius: 4px;
                           border: none;
                           color: #fff !important;
                        }
                     }
                  }

                  .OtherInfo {
                     margin-top: 5px;
                     clear: both;

                     ul {
                        padding: 0;
                        margin: 0;

                        li {
                           list-style: none;
                           width: 100%;
                           font-size: 12px;
                           color: #95A6B7;
                           line-height: 14px;

                           label {
                              display: inline-block;
                           }

                           span:not(.ProgressGroove) {
                              display: inline-block;
                              text-transform: uppercase;
                              color: #fff;
                              max-width: 150px;
                              white-space: nowrap;
                              overflow: hidden;
                              text-overflow: ellipsis;
                              padding-left: 4px;
                              margin-bottom: -4px;
                              font-style: italic;
                           }
                        }

                        li:nth-child(4) {
                           margin-top: 8px;
                        }

                        li:last-child {
                           .progressBarWrapper {
                              padding: 0;
                           }
                        }
                     }
                  }

                  .DropdownWrapper {
                     * {
                        font-size: 12px !important;
                        color: #fff !important;
                     }

                     button {
                        width: 60px;
                        padding: 0;
                        background-color: #010E14;
                        padding: 6px;
                        text-align: center;
                     }

                     > div > div {
                        background-color: #000 !important;
                        padding: 8px !important;
                        // padding-left: 20px !important;
                        a {
                           background-color: #000 !important;
                           padding: 4px !important;
                           padding-left: 15px !important;
                        }

                        a:hover {
                           background-position: 0% 10px !important;
                           background-size: 10px !important;
                        }
                     }
                  }
               }
            }
         }

      }


      // .PlayerList::-webkit-scrollbar {
      //     // width: 10px;
      //     height: 8px;
      // }

      // /* Track */
      // .PlayerList::-webkit-scrollbar-track {
      //     background: #0D1A27;
      //     margin-left: 10px;
      // }

      // /* Handle */
      // .PlayerList::-webkit-scrollbar-thumb {
      //     background: linear-gradient(180deg, #224755 -19.6%, #878787 48.63%, #646464 110.8%);
      //     border-radius: 5px;
      // }

      // /* Handle on hover */
      // .PlayerList::-webkit-scrollbar-thumb:hover {
      //     background: #555;
      // }

   }

}


.SelectedPlayersCol {
   border: solid 1px #355A69;
   border-radius: 4px;
   padding-top: 18px;
   background-color: #010B14;

   .CreateTeamName {
      max-width: 480px;
      margin: 0 auto;
      // margin-top: 10px;
      position: relative;
      background-color: #111E33 !important;
      border: solid 1px #3E5168;
      transform: skew(-10deg, 0deg);
      border-radius: 4px;

      img {
         position: absolute;
         right: 12px;
         top: 16px;
         transform: skew(10deg, 0deg);
      }

      img:hover {
         cursor: pointer;
         opacity: .8;
      }

      input {
         padding-top: 0px;
         padding-bottom: 0;
         height: 52px;
         background-color: transparent !important;
         border: none !important;
         background-image: none;
      }
   }

   .CaptainRow {
      margin-top: 40px;
      display: flex;
      gap: 16px;
      justify-content: center;

      .Card {
         @include FantacyCard;

         h4 {
            margin-bottom: 2px
         }
      }
   }

   .OtherPlayers {
      .Card {
         @include FantacyCard;

         .CardBackground {
            min-height: 187px;
         }

         h4 {
            margin-bottom: 4px;
            max-width: 132px;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
         }
      }

      .Wrapper {
         margin-top: 16px;
         display: flex;
         gap: 16px;
         flex-flow: row wrap;
         justify-content: center;
      }
   }

   .CounterRow {
      width: fit-content;
      margin: 0 auto;
      margin-bottom: 30px;
      margin-top: 30px;

      .TeamPlayerCounter {
         width: fit-content;
         display: flex;
         margin: 0 auto;
         margin-top: 30px;
         margin-bottom: 30px;
         gap: 7px;
         background: linear-gradient(45deg, #262E35, #1e2429);

         > div {
            width: 26px;
            height: 16px;
            border-radius: 2px;
            border: solid 1px #326668;
            transform: skew(12deg, 0deg);
         }

         > div.Filled {
            background: linear-gradient(45deg, #00FFC2, #FAFF00);
         }
      }
   }

   .FooterBtnRow {
      margin-bottom: 40px;

      .BtnRow {
         button {
            float: right;
            padding: 0;
            background: linear-gradient(90deg, #F9FF01, #2FFF9E);
            padding: 2px;
            border-radius: 5px;
            transform: skew(-10deg, 0deg);
            margin: 2px;

            > div {
               background-color: #0E2029;
               border-radius: 4px;
               color: #fff;
               text-transform: uppercase;
               font-size: 16px;
               font-weight: 600;
               // font-style: italic;
               padding: 10px 18px;
            }
         }

         button.ContinueBtn {
            > div {
               background: linear-gradient(90deg, #F9FF01, #2FFF9E);
               color: #03161F;
               border-radius: 4px;
               text-transform: uppercase;
               font-size: 16px;
               font-weight: 600;
               font-style: italic;
               padding: 10px 18px;
            }
         }

         button.HowFantasyWorksBtn {
            float: left;

         }
      }
   }
}

.sellectionWrapper {
   background-color: #111E33 !important;
   border: solid 1px #3E5168;
   border-radius: 4px;
   margin: auto;
   margin-top: 15px;
   margin-bottom: -5px;
   height: 50px;
   width: 94%;
   display: flex;
   justify-content: center;
   align-items: center;
   gap: 8px;
   font-size: 14px;

   > div:nth-child(1), div:nth-child(2) {
      padding-top: 7px;
   }

   input[type="radio"] {
      margin-right: 6px;
      -webkit-appearance: none;
      appearance: none;
      background-color: #3E5168;
      border-radius: 50%;
      width: 10px;
      height: 10px;

   }

   input[type="radio"]::before {
      background-color: #3E5168;
   }

   input[type="radio"]:checked {
      outline: max(2px, 0.15em) solid #00FFC2;
      background-color: #00FFC2;
      outline-offset: max(2px, 0.15em);
   }

   select {
      background-color: #36424d;
      color: #fff;
      border-radius: 4px;
      padding: 4px 8px;
      margin-left: 5px;
      opacity: 1;
      max-width: 120px;
      min-width: 120px;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
   }

   select[disabled] {
      opacity: .5;
   }
}

@media only screen and (min-width: 1200px) {
   .FooterBtnRow {
      .BtnRow {
         display: flex;

         .Col2 {
            order: 2;
            margin-left: auto;
            display: flex;

            button {
               order: 1;
            }

            button.ContinueBtn {
               order: 2;
            }
         }

         .Col1 {
            order: 1;
         }
      }
   }
}

@media only screen and (max-width: 1200px) {
   .FooterBtnRow {
      .BtnRow {
         display: grid;
         grid-template-columns: 1fr;

         .Col1 {
            display: flex;
            justify-content: center;
         }

         .Col2 {
            display: flex;
            justify-content: center;
            margin-top: 15px;
         }
      }
   }
}

@media only screen and (min-width: 992px) {
   .FantasyTeamSelector {
      display: grid;
      grid-template-columns: 390px 1fr;
   }
}

@media only screen and (max-width: 992px) {
   .FantasyTeamSelector {
      display: grid;
      grid-template-columns: 345px 1fr;
   }
   .PlayerListWrapper {
      .PlayerList {
         .Player {
            .Wrapper {
               .PlayerProfilePic {
                  width: 90px;
               }
            }
         }
      }
   }
   .FantasyTeamPage {
      .contentWrapper {
         .container {
            padding: 0 !important;

            .FantasyTeamSelector {
               gap: 10px;
            }
         }
      }
   }
   .sellectionWrapper {
      font-size: 13px;

      select {
         max-width: 115px;
         min-width: 116px;
      }
   }
}

@media only screen and (max-width: 846px) {
   .FantasyTeamSelector {
      .Card {
         width: 144px !important;

         .BtnRow {
            button {
               font-size: 12px !important;
               padding: 4px 8px;
            }
         }
      }

   }
   .FantasyTeamPage {
      .contentWrapper {
         margin-top: 5px !important;

         .container {
            padding: 0 !important;
         }
      }
   }
}

@media only screen and (min-width: 768px) {
   .SearchInputWrapper {
      .ShowTeamBtn {
         display: none;
      }
   }
   .CreateTeamNameWrapper {
      .SearchAthlete {
         display: none;
      }
   }

}

@media only screen and (max-width: 768px) {
   .FantasyTeamPage {
      h1 {
         font-size: 28px !important;
         padding-top: 0;
         margin-top: -26px !important
      }
   }
   .FantasyTeamSelector {
      gap: 10px;
      grid-template-columns:1fr;

      .Card {
         width: 144px !important;

         .BtnRow {
            button {
               font-size: 12px !important;
               padding: 4px 8px;
            }
         }
      }

      .PlayerListCol {
         padding: 16px 3px;
      }

      .contentWrapper {
         .container {
            padding: 0 !important;
         }
      }
   }
   .SearchInputWrapper {
      display: flex;
      // grid-template-columns: 1fr 96px;
      gap: 8px;

      > img {
         display: none;
      }

      .ShowTeamBtn {
         display: flex;
         width: 140px;
         color: #8c9cab;
         font-weight: 600;
         background-color: #111d30;
         justify-content: center;
         align-items: center;
         border-radius: 6px;
         border: solid 1px #3E5168;
      }

      .ShowTeamBtn:hover {
         cursor: pointer;
         text-decoration: none;
         background-color: #0e1725;
         color: #fff;
      }
   }
   .CreateTeamNameWrapper {
      display: flex;
      gap: 8px;

      .SearchAthlete {
         display: flex;
         width: 140px;
         color: #8c9cab;
         font-weight: 600;
         background-color: #111d30;
         justify-content: center;
         align-items: center;
         border-radius: 6px;
         border: solid 1px #3E5168;
      }
   }
   .CreateTeamName {
      transform: skew(0deg, 0deg) !important;

      img {
         transform: skew(0deg, 0deg) !important;
      }
   }
   .SelectedPlayersCol {
      .TeamCaptains, .OtherPlayers {
         padding: 0 !important;

         > div {
            padding: 0 !important;
         }
      }
   }
   .FantasyTeamSelector {
      grid-template-columns:unset !important;
      justify-content: center;
   }
}

@media only screen and (max-width: 576px) {
   .FantasyTeamPage {
      h1 {
         font-size: 20px !important;
      }

      .PlayerListCol {
         .SearchInputWrapper {
            font-size: 14px !important;

            input {
               padding-left: 8px;
               padding-right: 8px;
               font-size: 14px;
               margin-left: 8px;
            }

            a {
               margin-right: 8px;
            }

            input::placeholder {
               font-size: 14px !important;
            }
         }

         > div > div {
            padding-left: 0 !important;
            padding-right: 0 !important;
         }
      }

      .SelectedPlayersCol {
         .CreateTeamNameWrapper {
            font-size: 14px !important;

            input::placeholder {
               font-size: 14px !important;
            }
         }

         > div > div {
            padding: 0 !important;
         }
      }
   }
}

@media only screen and (max-width: 476px) {
   .FooterBtnRow {
      .BtnRow {
         button > div {
            font-size: 14px !important;
         }
      }
   }
}

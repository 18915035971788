@import '../../../scss/globals/global';
@import '../../../scss/utilities/mixins';

@mixin eMulticolorFull {
   position: absolute;
   background-image: url("../../../assets/images/backgrounds/MulticolorFullEllipse.webp");
   background-repeat: no-repeat;
   background-size: 100% 100%;
}

@mixin eMulticolorHalf {
   position: absolute;
   background-image: url("../../../assets/images/Ellipse-half.webp");
   background-repeat: no-repeat;
   background-size: 100% 100%;
}

.PlayerInfoHistoryModal {
   .TabSetWrapper {
      .TabContent1 {
         .PlayerActionImage {
            position: absolute;
            width: 340px;
            height: 480px;
            bottom: 15px;
            right: 0;
            z-index: 0;
         }

         .ModalButtonRowWrapper {
            // position: relative;
            position: absolute;
            width: 100%;
            bottom: 0;

            .ModalButtonRow {
               width: fit-content;
               display: flex;
               justify-content: center;

               button {
                  position: absolute;
                  z-index: 2;
                  width: fit-content;

                  bottom: 22px;
               }
            }
         }

         .TeamBackgroundLogo {
            // background-image: url(http://localhost:3000/static/media/BackgroundLogoDragon.eb2f5ae….webp);
            background-repeat: no-repeat;
            background-position: top -15px right 30px;
            background-size: 430px;
            position: absolute;
            width: 100%;
            height: 446px;
            z-index: 1;
            opacity: 0.1;
         }

         .ModalButtonRowWrapper {
            // position: relative;
            position: absolute;
            width: 100%;
            bottom: 0;

            .ModalButtonRow {
               width: fit-content;
               display: flex;
               justify-content: center;

               button {
                  position: absolute;
                  z-index: 2;
                  width: fit-content;
                  // left: 50%;
                  // transform: translateX(-50%), skew(-10deg, 0deg);
                  // transform: skew(-10deg, 0deg);
                  bottom: 22px;
               }
            }
         }

         .GradientRow {
            position: absolute;
            width: 100%;
            height: 1px;
            bottom: 0;

            .FooterGradient {
               position: absolute;
               z-index: 3;
               bottom: 0;
               left: -13px;
               width: 100%;
               height: 200px;
               background: rgb(0, 0, 0);
               background: linear-gradient(0deg, rgba(0, 0, 0, 1) 0%, rgba(0, 0, 0, 0.01444) 100%);
            }
         }

         .ModalButtonRow {
            button {
               padding: 10px 15px;
            }
         }


         .TopRightConner {
            width: 200px;
            height: 200px;
            right: 0px;
            top: 0px;
            background: radial-gradient(32.65% 32.65% at 20% 20%, #103a5f 0%, rgba(20, 208, 230, 0) 100%);
            position: absolute;
            mix-blend-mode: screen;
            opacity: 0.4;
            z-index: 2;
            transform: rotate(90deg);
         }

         .eMulticolorFull1 {
            @include eMulticolorFull;

            width: 75px;
            height: 75px;
            z-index: 0 !important;
            left: 5px;
            scale: 1;
            top: 150px;
            bottom: unset !important;
            filter: blur(0.3rem);
         }

         .eMulticolorFull2 {
            @include eMulticolorFull;

            width: 100px;
            height: 100px;
            z-index: 0 !important;
            left: 165px;
            scale: 1.5;
            bottom: 98px !important;
            top: unset !important;
            filter: unset;
            opacity: 1;
         }

      }

      .TabContent2 {
         .Radial2 {
            left: 0;
         }

         .ProfilePicWrapper {
            width: 494px;
            background-image: url("../../../assets/images/backgrounds/splashbg.webp");
            background-repeat: no-repeat;
            display: flex;
            margin: 0 auto;
            padding: 32px 32px 40px 32px;
            position: relative;
            justify-content: center;

            .ProfilePicBorder {
               background: linear-gradient(180deg, #FAFF01 0.03%, #39FF96 86.64%);
               width: 140px;
               height: 140px;
               border-radius: 50%;
               display: flex;
               justify-content: center;
               align-items: center;

               .ProfilePic {
                  background-image: url("https://storage.googleapis.com/meta11/players/LeiWei.png");
                  background-color: #010e14;
                  width: 138px;
                  height: 138px;
                  border-radius: 50%;
                  background-size: contain;
                  background-repeat: no-repeat;
                  background-position: center;
               }
            }
         }

         .PlayerNameWrapper {
            h4 {
               font-family: 'ObjectSans-Regular';
               text-transform: uppercase;
               font-size: 24px;
               font-weight: 700;
               font-style: italic;
            }
         }


         .FantasyHistoryTable::-webkit-scrollbar {
            height: 10px;
         }

         /* Track */
         .FantasyHistoryTable::-webkit-scrollbar-track {
            background: #f1f1f1;
         }

         /* Handle */
         .FantasyHistoryTable::-webkit-scrollbar-thumb {
            background: linear-gradient(180deg, #224755 -19.6%, #878787 48.63%, #646464 110.8%);
            border-radius: 5px;
         }

         /* Handle on hover */
         .FantasyHistoryTable::-webkit-scrollbar-thumb:hover {
            background: #555;
         }


         .FantasyHistoryTable {
            width: 680px;
            height: 400px;
            display: block;
            margin: 0 auto;
            overflow-y: auto;
            overflow-x: auto;
            position: relative;

            .NoFantasyHistoryData {
               width: 100%;
               text-align: center;
               padding-top: 10%;

            }

            table {
               position: absolute;
               z-index: 1;
               width: 600px;
               max-height: 400px;
               margin: 0 auto;
               border: solid 1px #1f2a36;
               margin-bottom: 32px;

               thead {
                  background: linear-gradient(180deg, #FAFF01 0.03%, #39FF96 86.64%);
                  color: #03161F;
                  font-style: italic;
                  border: solid 1px #1f2a36;
                  top: 0;
                  position: sticky;
                  width: 100%;

                  tr {
                     border: solid 1px #1f2a36;

                     th {
                        padding: 6px;
                        font-size: 14px;
                     }
                  }
               }

               tbody {
                  tr {
                     background-color: #0b1a28;
                     border: solid 1px #1f2a36;
                     height: 28px;

                     td {
                        padding: 10px 5px;

                        button {
                           color: #17EA85;
                           border: solid 1px #17EA85;
                           background-color: #152d39;
                           font-size: 12px;
                           border-radius: 6px;
                           padding: 4px 6px;
                        }
                     }

                     td:nth-child(1) {
                        padding-left: 15px;
                        max-width: 168px;
                        white-space: nowrap;
                        overflow: hidden;
                        text-overflow: ellipsis;
                     }
                  }

                  tr:nth-child(odd) {
                     background-color: #131f30;
                  }
               }
            }
         }

         .ModalButtonRow {
            margin-top: 35px;
            margin-bottom: 35px;

            button {
               padding: 14px;
               font-size: 16px;
               width: 165px;
               margin: 0 auto;
            }
         }

      }
   }

}

@media (max-width: 576px) {
   .PlayerInfoHistoryModal {
      .TabContent1 {
         .PlayerActionImage {
            opacity: .6;
            z-index: 0 !important;
         }
      }

      .TabContent2 {
         .FantasyHistoryTable {
            height: 280px;
         }

         .ProfilePicWrapper {
            scale: .7;
            padding: 10px !important;

            h4 {
               font-size: 18px !important;
            }
         }

         table {
            th, td {
               font-size: 12px;
               padding: 4px 5px;
            }
         }

         .EdgeRadial1 {
            width: 126px;
            height: 93px;
            opacity: .3;
         }

         .Radial1 {
            opacity: .3;
         }

         .Radial2 {
            opacity: .3;
         }
      }

   }
}

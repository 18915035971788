.LoginButton {
   background: linear-gradient(95.34deg, #FAFF00 6.28%, #00FFC2 111.13%);
   box-shadow: 0px 2px 0px rgba(0, 0, 0, 0.51);
   border-radius: 4px;
   transform: skew(-10deg, 0deg);
   border: 1px solid #2FFF9E;
   /*border-image-source: linear-gradient(103.05deg, #2FFF9E 7.22%, #F9FF01 93.09%);*/
   text-align: center;
   letter-spacing: -0.01em;
   padding: 14px 27px;
   /*font-weight: 700;*/
   width: 100%;
   height: 54px;
   font-family: "ObjectSans-Heavy", Helvetica, Arial, sans-serif;
   font-size: 14px;
   line-height: 18px;
   text-transform: uppercase;
}

.LoginButton:hover {
   background: linear-gradient(95.34deg, #00FFC2 6.28%, #FAFF00 111.13%);
}

.BlackSkBtn text {

   background-image: linear-gradient(0deg, #FAFF01, #39FF96);
   background-clip: text;
   color: transparent;
   font-family: "ObjectSans-Heavy";
   font-size: 16px;
   line-height: 18px;
}

.BlackSkBtn {
   background-color: #0E2029;
   box-shadow: 0px 2px 0px rgba(0, 0, 0, 0.51);
   border-radius: 4px;
   transform: skew(-10deg, 0deg);
   border: 1px solid rgba(0, 255, 194, 0.09);

   /* identical to box height, or 129% */

   text-align: center;
   letter-spacing: -0.01em;
   text-transform: uppercase;
   padding: 14px 27px;
   font-weight: 700;
   height: 54px;
}

.BlackSkBtn:hover {
   background: #1A4152;
}

.BlackSkBtn:visited {
   box-shadow: 0px 2px 0px rgba(0, 0, 0, 0.51);
   cursor: not-allowed;
}

.BlackSkBtn:disabled,
.BlackSkBtn[disabled] {
   background: #0E2029;
   box-shadow: 0px 2px 0px rgba(0, 0, 0, 0.51);
   backdrop-filter: blur(45px);
}

.controls {
   position: absolute;
   bottom: 20px;
   left: 50%;
   width: 50%;
   transform: translateX(-50%);
   height: 40px;
   display: flex;
   align-items: center;
}

.container {
   height: 223px;
   width: 100%;
   background: linear-gradient(180deg, rgba(26, 38, 57, 0.5) 0%, rgba(11, 28, 53, 0.5) 100%);
   border: 1px dashed rgba(255, 255, 255, 0.06);
   box-shadow: 10px 22px 47px rgba(0, 0, 0, 0.13);
   backdrop-filter: blur(20px);
}

.containerCropper {
   height: 100%;
   padding: 10px;
}

.cropper {
   height: 100%;
   position: relative;
}

.slider {
   height: 10%;
   display: flex;
   align-items: center;
   margin: auto;
   width: 60%;
}

.containerButtons {
   border: 1px solid #f5f5f5;
   height: 10%;
   display: flex;
   align-items: center;
   justify-content: center;
}

.modal-content {
   padding: 10px !important;
   background-color: #063145 !important;
}

.smallBtn {
   background-color: #000000;
   font-family: 'Rubik-Medium', Helvetica, Arial, sans-serif;
   padding-top: 10px;
   padding-right: 20px;
   padding-bottom: 10px;
   padding-left: 20px;
   color: #000000;
   font-size: 15px;
   border-radius: 20px;
   background-image: -webkit-linear-gradient(0deg, rgba(25, 234, 23, 1.00) 0%, rgba(20, 208, 230, 1.00) 100%);
   background-image: -moz-linear-gradient(0deg, rgba(25, 234, 23, 1.00) 0%, rgba(20, 208, 230, 1.00) 100%);
   background-image: -o-linear-gradient(0deg, rgba(25, 234, 23, 1.00) 0%, rgba(20, 208, 230, 1.00) 100%);
   background-image: linear-gradient(90deg, rgba(25, 234, 23, 1.00) 0%, rgba(20, 208, 230, 1.00) 100%);
   border: 0px;
   height: 38px;
   line-height: 19px;
}

.updatingBtn {
   background-color: #000000;
   font-family: 'Rubik-Medium', Helvetica, Arial, sans-serif;
   padding-top: 5px;
   padding-right: 20px;
   padding-bottom: 10px;
   padding-left: 20px;
   color: #000000;
   font-size: 15px;
   border-radius: 20px;
   background-image: -webkit-linear-gradient(0deg, rgba(25, 234, 23, 1.00) 0%, rgba(20, 208, 230, 1.00) 100%);
   background-image: -moz-linear-gradient(0deg, rgba(25, 234, 23, 1.00) 0%, rgba(20, 208, 230, 1.00) 100%);
   background-image: -o-linear-gradient(0deg, rgba(25, 234, 23, 1.00) 0%, rgba(20, 208, 230, 1.00) 100%);
   background-image: linear-gradient(90deg, rgba(25, 234, 23, 1.00) 0%, rgba(20, 208, 230, 1.00) 100%);
   border: 0px;
   height: 38px;
   line-height: 19px;
   width: 440px;
}

.smallBtn:hover {
   background-color: #000000;
   font-family: 'Rubik-Medium', Helvetica, Arial, sans-serif;
   padding-top: 10px;
   padding-right: 20px;
   padding-bottom: 10px;
   padding-left: 20px;
   color: white;
   font-size: 15px;
   border-radius: 20px;
   background-image: -webkit-linear-gradient(0deg, rgba(25, 234, 23, 1.00) 0%, rgba(20, 208, 230, 1.00) 100%);
   background-image: -moz-linear-gradient(0deg, rgba(25, 234, 23, 1.00) 0%, rgba(20, 208, 230, 1.00) 100%);
   background-image: -o-linear-gradient(0deg, rgba(25, 234, 23, 1.00) 0%, rgba(20, 208, 230, 1.00) 100%);
   background-image: linear-gradient(90deg, rgba(25, 234, 23, 1.00) 0%, rgba(20, 208, 230, 1.00) 100%);
   border: 0px;
   height: 38px;
   line-height: 19px;
}

@import '../../../scss/globals/global';
@import '../../../scss/utilities/mixins';

.WinnerWrapper {
   width: 208px;
   height: 155px;
   background-image: url("../../../assets/images/winner_background.webp");
   background-repeat: no-repeat;
   padding: 15px;
   margin: 0 auto;
   margin-bottom: 26px;

   h4 {
      font-family: 'ObjectSans-Regular';
      font-size: 12px;
      text-transform: uppercase;
      text-align: center;
      font-style: italic;
      margin-top: 8px;
      padding-bottom: 0;
      border: none;
   }

   .WinnerProfilePic {
      width: 56px;
      height: 56px;
      border-radius: 50%;
      border: 1px solid;
      margin: 0 auto !important;
      background-image: linear-gradient(to bottom, #FAFF01 0%, #39FF96 100%);
      box-shadow: 0px 0px 10px 0px rgba(86, 255, 128, 0.5);
      position: relative;

      img {
         position: absolute;
         left: 0;
         top: 0;
         width: 55px;
         height: 55px;
         border-radius: 50%;
         padding: 2px;
      }

      .EmptyProfilePic {
         background-image: url('../.././../assets/images/FantasyProfilePic.webp');
         background-color: #000;
         width: 52px;
         height: 52px;
         border-radius: 50%;
         background-repeat: no-repeat;
         background-position: right -3px bottom -7px;
         background-size: cover;
         // left: 1px;
         // top: 1px;
         margin-top: 1px !important;
         // position: absolute;
      }
   }

   .WinnerProfilePic.noProfilePic {
      .proTextImg {
         width: 55px;
         height: 55px;
         margin-left: -12px !important;
         margin-top: -.5px !important;
      }
   }

   .WinnerPointsWrapper {
      display: flex;
      gap: 5px;
      width: 100%;

      > div:nth-child(1), div:nth-child(3) {
         flex: 1;
      }

      > div:nth-child(1) {
         .IconWithValues {
            font-weight: bold;
            color: #17EA85;
         }
      }
   }

   .WinnerPlace {
      width: 30px;
      background-image: url("../../../assets/images/place_background.webp");
      background-repeat: no-repeat;

      span {
         color: #000;
         font-weight: bold;
         font-size: 17px;
         padding-top: 3px;
         display: block;
         width: 100%;
         text-align: center;
      }
   }

   .IconWithValues {
      display: flex;

      img {
         width: 16px;
         height: 16px;
         margin-top: 4px;
      }
   }
}

.WinnerWrapper:last-child {
   margin-bottom: 0;
}

.WinnerWrapperCol {
   .noProfilePic {
      > div.EmptyProfilePic {
         width: 52px;
         height: 52px;
         margin-left: -11px !important;
         margin-top: 1px !important;

         > div {
            width: 52px;
            height: 52px;
            margin-left: -11px !important;
            margin-top: 1px !important;
         }
      }
   }

   .WinnerProfilePic {
      > div:not(.EmptyProfilePic) {
         width: 54px;
         height: 54px;
         margin-left: -12px !important;
         margin-top: -.5px !important;
      }
   }
}

@import '../../scss/utilities/variables';
@import '../../scss/utilities/mixins';

.noMatchText {
   font-size: 12px;
   line-height: 16px;
   letter-spacing: -0.01em;
   color: $textGray2;
   margin-bottom: 5px;
}

.emptyMatchStatus {
   height: 28px;
   flex-wrap: wrap;
   align-items: center;
   border-radius: 90px;
   margin: 10px 4px;
   color: $textGray2;
}

.puffWrapper {
   position: absolute;
   top: 30px;
   right: 20px;
}

.loaderContainer {
   margin: 0 auto;
   transform: translateY(400%);
   text-align: center;
}

.matchCardInnerWrapper {
   -webkit-backdrop-filter: blur(20px);
   backdrop-filter: blur(20px);
   background: linear-gradient(163.1deg, rgba(18, 34, 73, .5) .85%, rgba(8, 56, 130, 0) 111.21%);
   border: 1px solid rgba(255, 255, 255, 0.06);
   border-radius: 4px;
   display: inline-block;
   -webkit-filter: drop-shadow(10px 22px 47px rgba(0, 0, 0, .13));
   filter: drop-shadow(10px 22px 47px rgba(0, 0, 0, .13));
   margin-bottom: 25px;
   position: relative;
   width: 100%;
   -webkit-transition-property: all;
   -webkit-transition-duration: 0.4s;
   -webkit-transition-timing-function: ease;
   transition-property: all;
   transition-duration: 0.4s;
   transition-timing-function: ease;
   -moz-transition-property: all;
   -moz-transition-duration: 0.4s;
   -moz-transition-timing-function: ease;
   -o-transition-property: all;
   -o-transition-duration: 0.4s;
   -o-transition-timing-function: ease;
   -webkit-animation-name: icons-anim;
   -webkit-animation-timing-function: ease;
   -webkit-animation-iteration-count: 1;
   -webkit-animation-duration: 0.8s;
   -webkit-transform-style: preserve-3d;
   margin-right: 15px;
}

.matchCardInnerWrapper:hover {
   background: linear-gradient(163.1deg, rgb(18 34 73 / 78%) .85%, rgba(8, 56, 130, 0) 111.21%);
}

.ResponceCont {
   max-width: 1240px;
   margin-left: auto;
   margin-right: auto;
}

.shield {
   height: 40px;
   width: 100px;
   border: 3px solid var(--shield-border-color);
   border-radius: 20px;
   clip-path: polygon(0 70%, 0 0, 100% 0, 100% 70%, 50% 100%);
   background-repeat: no-repeat;
   background-position: center center;
}

@import "../../scss/globals/global";

/* Countdown */
.nextTournamentCol {
   .nextTournamentWrapper {
      position: relative;
      left: 0;
      //right: 10vw;
      margin-left: -18px;
      margin-right: auto;

      bottom: 0;
      //width: 480px;
      // height: 182px;
      border-radius: 3px;
      //box-shadow: 0px 0px 55.573062896728516px 0px rgba(0, 255, 194, 0.7);
      //background: #00000042;
      //backdrop-filter: blur(5px);
      //-webkit-backdrop-filter: blur(5px);
      //border: solid 1px;
      //border-image: linear-gradient(45deg, #F9FF01, #2FFF9E) 1;
      //border-width: 2px;
      padding-bottom: 10px;

      h2 {
         font-family: $countdown-bottom-font;
         font-size: 24px;
         font-weight: 400;
         line-height: 27px;
         letter-spacing: 0.28em;
         text-align: center;
         text-transform: uppercase;
         background: -webkit-linear-gradient(45deg, #FAFF00, #00FFC2 80%);
         -webkit-background-clip: text;
         -webkit-text-fill-color: transparent;
         margin-top: 20px;
      }

      .countdown {
         width: fit-content;
         margin: 0 auto;
         margin-top: 10px;
         display: grid;
         grid-template-columns: repeat(3, 78px);
         gap: 0px;
         //margin-left: 18px;
         margin-bottom: -10px;
         margin-top: 21px;

         > div {
            .cercalBack {
               width: 56px;
               height: 56px;
               // background-image: url('../images/backgrounds/countdown_circle-01.svg');
               background-image: url('../../assets/images/backgrounds/countdown_circle-01.svg');
               background-position: top left;
               background-repeat: no-repeat;
               position: relative;
               margin-left: 13px;
            }

            .cercal {
               width: 56PX;
               height: 56px;
               border-radius: 50%;
               /* border: 1.69px solid; */
               border-color: linear-gradient(143.92deg, #FAFF01 13.27%, #39FF96 61.67%);

               > span {
                  position: absolute;
                  width: 50px;
                  top: 19%;
                  left: 5%;
                  margin-left: auto;
                  margin-right: auto;
                  font-family: $countdown-count-font;
                  // font-size: $countdown-count;
                  font-size: 21px;
                  letter-spacing: 2px;
                  background: -webkit-linear-gradient(45deg, #FAFF00, #00FFC2 80%);
                  -webkit-background-clip: text;
                  -webkit-text-fill-color: transparent;
                  text-align: center;
               }

            }

            > span {
               text-transform: uppercase;
               font-weight: 400;
               display: block;
               text-align: center;
               font-family: $countdown-bottom-font;
               font-size: $countdown-bottom-title;
               margin-top: 6px;
               background: -webkit-linear-gradient(45deg, #FAFF00, #00FFC2 80%);
               -webkit-background-clip: text;
               -webkit-text-fill-color: transparent;
               font-style: normal;
               line-height: 12px;
            }

         }

         .timerWrapper {
            width: fit-content;
            position: absolute;
            top: -62px;
            margin-left: 1px;

            div {
               max-width: 54px;

               > svg {
                  max-width: 54px;
                  margin-left: 13px;

                  > :nth-child(1) {
                     stroke: unset !important;
                  }
               }
            }

         }
      }
   }
}

@import '../../../scss/globals/global';
@import '../../../scss/utilities/mixins';

.MatchDetails {
   h2 {
      text-align: center;
      font-size: 24px;
      text-transform: uppercase;
   }

   .WinPrizes {
      position: relative;
      margin-bottom: 20px;
      border: solid 2px #112c4dcc;
      border-radius: 6px;
      background-color: #112338;
      // box-shadow: 0 8px 6px -6px #091627;

      -webkit-box-shadow: inset 0px 0px 25px 15px rgb(14 30 51);
      -moz-box-shadow: inset 0px 0px 25px 15px rgb(14 30 51);
      box-shadow: inset 0px 0px 25px 15px rgb(14 30 51);

   }

   .TournamentView {
      text-transform: uppercase;

      .tournamentContainer {
         max-width: 600px;
         margin: 0 auto;
      }

      .tournamentContainer::-webkit-scrollbar {
         // width: 10px;
         height: 10px;
      }

      /* Track */
      .tournamentContainer::-webkit-scrollbar-track {
         background: #f1f1f1;
      }

      /* Handle */
      .tournamentContainer::-webkit-scrollbar-thumb {
         background: linear-gradient(180deg, #224755 -19.6%, #8bfe7b 48.63%, #1E4F56 110.8%);
      }

      /* Handle on hover */
      .tournamentContainer::-webkit-scrollbar-thumb:hover {
         background: #ccc;
      }

      ul {
         li.TeamItem {
            > div {
               position: relative;
               transform: skew(-12deg, 0deg);
               width: 142px;
               border: 1px solid;
               border-image-source: linear-gradient(99.98deg, rgba(213, 42, 177, 0.2) -2.72%, rgba(68, 91, 204, 0.2) 128.13%);
               background: radial-gradient(87.12% 337.52% at 31.82% 50%, rgba(213, 42, 177, 0.2) 0%, rgba(68, 91, 204, 0.45) 100%);
               border-radius: 4px;

               span {
                  position: absolute;
                  top: -5px;
                  left: -8px;
                  display: block;
                  width: 30px;
                  height: 36px;
                  background-image: url("../../../assets/images/match_tree_img_node_back.webp");
                  background-repeat: no-repeat;
                  -webkit-box-shadow: 0px 0px 7px 0px rgba(0, 0, 0, 0.83);
                  -moz-box-shadow: 0px 0px 7px 0px rgba(0, 0, 0, 0.83);
                  box-shadow: 0px 0px 7px 0px rgba(0, 0, 0, 0.83);
                  border-radius: 4px;

                  img {
                     width: 32px;
                     height: 36px;
                  }
               }

               label {
                  font-size: 12px;
                  font-family: $sectionContentFont;
                  padding-left: 14px;
               }
            }
         }

         li.EmptyTeamItem {
            > div {
               > span {
                  position: absolute;
                  top: -5px;
                  left: -8px;
                  display: block;
                  width: 30px;
                  height: 36px;
                  background: rgb(213, 42, 177);
                  background: linear-gradient(121deg, rgba(213, 42, 177, 1) 36%, rgba(68, 91, 204, 1) 100%);
                  border-radius: 4px;
                  -webkit-box-shadow: 0px 0px 7px 0px rgba(0, 0, 0, 0.83);
                  -moz-box-shadow: 0px 0px 7px 0px rgba(0, 0, 0, 0.83);
                  box-shadow: 0px 0px 7px 0px rgba(0, 0, 0, 0.83);
                  border-radius: 4px;

                  label {
                     font-size: 24px;
                     font-weight: bolder;
                     position: absolute;
                     left: -6px;
                     top: -4px;
                  }
               }

            }
         }


      }

      .tournamentBrackets {
         display: flex;
         flex-direction: row;
         list-style-type: none;
         //background: #fdfdfd;
         margin-bottom: 50px;
         overflow-x: auto;
      }

      .tournamentBrackets::-webkit-scrollbar {
         // width: 10px;
         height: 10px;
      }

      /* Track */
      .tournamentBrackets::-webkit-scrollbar-track {
         background: #f1f1f1;
      }

      /* Handle */
      .tournamentBrackets::-webkit-scrollbar-thumb {
         background: linear-gradient(180deg, #224755 -19.6%, #8bfe7b 48.63%, #1E4F56 110.8%);
      }

      /* Handle on hover */
      .tournamentBrackets::-webkit-scrollbar-thumb:hover {
         background: #ccc;
      }

      .bracket {
         padding-left: 0;
         display: flex;
         margin: 0;
         padding: 30px 0;
         flex-grow: 1;
         flex-direction: column;
         justify-content: space-around;
         list-style-type: none;
         // border-right: 1px dashed #ccc;
         flex: 1;
      }

      .bracket:nth-child(2) {
         padding-left: 30px;
      }

      .bracket:nth-child(3) {
         padding-left: 30px;

         .TeamItem:nth-of-type(odd) {
            // height: 140px !important;
         }
      }

      .TeamItem {
         // background-color: #555;
         padding: .5rem;
         display: block;
         margin: 0.05rem 10px;
         position: relative;
         vertical-align: middle;
         line-height: 2;
         text-align: center;
      }

      .TeamItem:after {
         content: '';
         border-color: #445BCC80;
         border-width: 2px;
         position: absolute;
         display: block;
         width: 30px;
         right: -20px;
      }

      .TeamItem:nth-of-type(odd):after {
         border-right-style: solid;
         border-top-style: solid;
         height: 100%;
         top: 50%;
      }

      .TeamItem:nth-of-type(even):after {
         border-right-style: solid;
         border-bottom-style: solid;
         height: 100%;
         top: -50%;
      }

      .TeamItem:before {
         content: '';
         border-top: 2px solid #445BCC80;;
         position: absolute;
         height: 2px;
         width: 39px;
         left: -31px;
         top: 50%;
      }

      .bracket2 {
         .TeamItem:nth-of-type(odd):after {
            height: 200%;
            top: 50%;
         }

         .TeamItem:nth-of-type(even):after {
            height: 200%;
            top: -150%;
         }
      }

      .bracket3 {
         .TeamItem:nth-of-type(odd):after {
            height: 350%;
            top: 50%;
         }

         .TeamItem:nth-of-type(even):after {
            height: 350%;
            top: -300%;
         }
      }

      .bracket4 {
         .TeamItem:nth-of-type(odd):after {
            height: 700%;
            top: 50%;
         }

         .TeamItem:nth-of-type(even):after {
            height: 700%;
            top: -650%;
         }
      }

      .bracket:first-of-type {
         .TeamItem:before {
            display: none;
         }
      }

      .bracket4 {
         .TeamItem:after {
            display: none;
         }
      }

      .bracket:last-of-type {
         .TeamItem:before,
         .TeamItem:after {
            display: none;
         }
      }

      .TeamItem time {
         display: inline-block;
         background-color: #dbdbdb;
         font-size: .8rem;
         padding: 0 .6rem;
      }

      .bracketFInal {
         li.TeamItem {
            div {
               span {
                  background: rgb(250, 255, 0);
                  background: linear-gradient(121deg, rgba(250, 255, 0, 1) 36%, rgba(0, 255, 194, 1) 100%);

                  label {
                     color: #000;
                  }

                  img {
                     position: absolute;
                     width: 20px;
                     height: 15px;
                     top: -17px;
                     left: 3px;
                     transform: skew(11deg, 0deg);
                  }
               }
            }

            h4 {
               font-size: 14px;
               font-family: 'ObjectSans-Regular';
               margin-top: 4px;
            }
         }

         > li::before {
            content: '';
            border-top: 2px solid #445BCC80;
            position: absolute;
            height: 2px;
            width: 39px;
            left: -31px;
            top: 50%;
         }
      }
   }

}


.WinPrizes {
   .WinPrizesWrapper {
      .LineWrapper {
         height: 38px;
         width: fit-content;
         margin: 0 auto;

         img {
            display: block;
            margin: 0 auto;
            height: 46px;
         }
      }

      .FirstPlace,
      .SecondPlace,
      .ThirdPlace {
         > img {
            display: block;
            margin: 0 auto;
            height: 55px;
         }
      }
   }

   .WinnerWrapper {
      width: 208px;
      height: 155px;
      background-image: url("../../../assets/images/winner_background.webp");
      background-repeat: no-repeat;
      padding: 15px;
      margin: 0 auto;
      margin-bottom: 20px;

      h4 {
         font-family: 'ObjectSans-Regular';
         font-size: 12px;
         text-transform: uppercase;
         text-align: center;
         font-style: italic;
         margin-top: 8px;
         padding-bottom: 0;
         border: none;
      }

      .WinnerProfilePic {
         width: 56px;
         height: 56px;
         border-radius: 50%;
         border: 1px solid;
         margin: 0 auto !important;
         background-image: linear-gradient(to bottom, #FAFF01 0%, #39FF96 100%);
         box-shadow: 0px 0px 10px 0px rgba(86, 255, 128, 0.5);
         position: relative;

         img {
            position: absolute;
            left: 0;
            top: 0;
            width: 55px;
            height: 55px;
            border-radius: 50%;
            padding: 2px;
         }

      }

      .WinnerPointsWrapper {
         display: flex;
         gap: 5px;
         width: 100%;

         > div:nth-child(1), div:nth-child(3) {
            flex: 1;
         }

         > div:nth-child(1) {
            .IconWithValues {
               font-weight: bold;
               color: #17EA85;
            }
         }
      }

      .WinnerPlace {
         width: 30px;
         background-image: url("../../../assets/images/place_background.webp");
         background-repeat: no-repeat;

         span {
            color: #000;
            font-weight: bold;
            font-size: 17px;
            padding-top: 3px;
            display: block;
            width: 100%;
            text-align: center;
         }
      }

      .IconWithValues {
         display: flex;

         img {
            width: 16px;
            height: 16px;
            margin-top: 4px;
         }
      }
   }

   .WinnerWrapper:last-child {
      margin-bottom: 0;
   }
}

.PlayerContainerWrapper {
   background: linear-gradient(180deg, #224755 -19.6%, #07A88F 48.63%, #1E4F56 110.8%);

   margin: 20px 30px !important;
   padding: 5px;
   margin: 0 auto;
   border-radius: 6px;

   .PlayerContainer {
      background-color: #020d15;
      border-radius: 6px;
      padding: 5px;

      table {
         width: 100%;
         min-width: 600px;

         thead {
            background-color: #020d15;

            th {
               padding: 8px 10px;
               font-family: "ObjectSans-Regular";
               font-weight: normal;
               font-size: 14px;
               font-weight: bold;
               color: #95A6B7;
               font-style: italic;
            }

            th:nth-child(1) {
               width: 40%;
            }
         }

         tbody {
            tr:nth-child(odd) {
               background-color: #0c1728;
            }

            td {
               padding: 8px 10px;
               font-family: "ObjectSans-Regular";
               font-weight: normal;
               font-size: 14px;

               a {
                  text-decoration: none;
                  color: #FFFFFF;
               }

               img {
                  //width: 36px;
                  max-width: 36px;
                  height: 36px;
                  border-radius: 50%;
               }

               img.PointsIcon {
                  width: 28px;
                  height: 28px;
                  padding: 2px;
               }
            }

            td:nth-child(1) {
               width: 10%;
               min-width: 80px;
               padding-right: 0;
            }

            td:nth-child(2) {
               min-width: 180px;

               > span {
                  display: block;
                  width: 180px;
                  white-space: nowrap;
                  overflow: hidden;
                  text-overflow: ellipsis;
               }
            }

            td:nth-child(3) {
               min-width: 212px;
            }
         }
      }
   }

   .PlayerContainer {
      overflow-x: auto;
   }

   .PlayerContainer::-webkit-scrollbar {
      // width: 10px;
      height: 10px;
   }

   /* Track */
   .PlayerContainer::-webkit-scrollbar-track {
      background: #f1f1f1;
   }

   /* Handle */
   .PlayerContainer::-webkit-scrollbar-thumb {
      background: linear-gradient(180deg, #224755 -19.6%, #8bfe7b 48.63%, #1E4F56 110.8%);
   }

   /* Handle on hover */
   .PlayerContainer::-webkit-scrollbar-thumb:hover {
      background: #555;
   }
}

@media (min-width: 1200px) {
   .MatchDetails {
      padding-right: 24px !important;
   }
}

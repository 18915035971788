@import '../../scss/utilities/variables';

$color_1: #000000;
$color_2: #ffffff;
$color_3: white;
$background-color_1: #000000;

.floatingArrow {
   color: $color_1;
   font-size: 30px;

   &:hover {
      color: $color_2;
      font-size: 30px;
   }
}

#preview {
   display: none;
}

.ChatWrappper {
   position: fixed;
   bottom: 15px;
   right: 18px;
   z-index: 99;

   .floatingButton {
      background-color: #000000;
      background-image: linear-gradient(95.34deg, #FAFF00 6.28%, #00FFC2 111.13%);
      border-radius: 40px;
      width: max-content;
      height: 56px;
      font-size: 20px;
      border: none;
      z-index: 1000;
      opacity: 0.85;
      padding: 0 15px;
      color: #000;

      svg {
         font-size: 32px;
      }

      .unreadNotify {
         position: absolute;
         top: -0.5em;
         right: 0;
         color: #FFFFFF;
         background-color: #000;
         width: 32px;
         height: 32px;
         border-radius: 50%;
         border: solid 1.5px #15d4d4;
         box-shadow: 0px 0px 3px #000;
      }
   }

   .chatInnerWrapper {
      width: 340px;
      position: absolute;
      right: 0;
      bottom: 65px;
      padding: 0;

      .chatHeader {
         background: linear-gradient(180deg, #FAFF01 0.03%, #39FF96 86.64%);
         padding: 8px 20px;
         position: relative;
         border-radius: 6px 6px 0 0;

         img {
            width: 32px;
            margin-right: 10px;
         }

         .closeBtn {
            position: absolute;
            border-radius: 90px;
            right: 10px;
            top: 8px;
            width: 32px;
            height: 32px;
            display: flex;
            justify-content: center;
            align-items: center;
            color: #fff;
            background: linear-gradient(0deg, #1F2A36, #1F2A36), linear-gradient(180deg, rgba(26, 38, 57, 0.5) 0%, rgba(11, 28, 53, 0.5) 100%);
            border: 1px solid rgba(149, 166, 183, 0.5);
         }
      }

      .chatContent {
         padding: 15px 20px;
         margin-bottom: 0;
         height: 50vh;
         overflow-y: auto;
         overflow-x: hidden;
         background: #1F2A36;

         .msgDate {
            text-align: center;
            width: 100%;
            position: relative;
            height: 20px;
            display: inline-block;

            span {
               background: rgb(12, 25, 39);
               position: relative;
               z-index: 2;
               padding: 5px 10px;
            }
         }

         .msgDate:before {
            content: "";
            border-bottom: 1px solid rgba(149, 166, 183, 0.5);
            position: absolute;
            top: 5.5px;
            width: 100%;
            left: 0;
            z-index: 1;
         }

         .chatList {
            list-style: none;
            padding: 0;
            margin: 0;

            .downArrow {
               z-index: 1000;
               position: absolute;
               background-image: linear-gradient(90deg, rgba(4, 21, 29, 1.00) 0%, rgba(11, 28, 53, 0.00) 100%);
               font-size: 25px;
               bottom: 74px;
               right: 17px;
               border-radius: 50%;
               width: 30px;
               height: 30px;
               padding: 2px;
               background-color: #000;

               > svg {
                  width: 20px;
                  height: 18px;
                  display: block;
                  margin: 0 auto;
                  margin-top: 6px;
               }
            }

            .downArrow:hover {
               cursor: pointer;
               background-color: #e01654;
            }

            li {
               margin-bottom: 10px;
               margin-top: 10px;

               .wrapper {
                  padding: 5px 0;
                  display: grid;
                  grid-template-columns: 50px 1fr;
                  position: relative;

                  .avatarName {
                     margin-bottom: 5px;
                     margin-top: 3px;

                     a {
                        > span:nth-child(1) {
                           display: block;
                           width: calc(100% - 90px) !important;
                           white-space: nowrap;
                           overflow: hidden;
                           text-overflow: ellipsis;
                        }

                        span {
                           svg {
                              color: #15dcba;
                              margin-left: 1px;
                           }
                        }
                     }
                  }

                  .avatarNameWrapper {
                     span {
                        cursor: pointer;
                     }

                     .avatarName {
                        > span > span {
                           color: #15dcba;
                           margin-left: 2px;
                           margin-right: 2px;
                        }
                     }
                  }

                  .avatarImgWrapper img {
                     width: 36px;
                     height: 36px;
                     border-radius: 50px
                  }

                  .chattime {
                     position: absolute;
                     right: 0;
                     top: 4px;
                  }
               }

               .outgoing {
                  background-color: #ccc;

                  > div:nth-child(1) {
                     order: 2;
                  }
               }
            }
         }

      }

      textarea {
         // background-color: #091725 !important;


      }
   }

   .chatFooter {
      border-top: 1px solid rgba(149, 166, 183, 0.5);
      padding: 7px 8px 7px 10px;
      margin-bottom: 0;

      .group {
         position: relative;
         border: solid 1px rgb(85 85 85 / 80%);
         border-radius: 1px;
         height: 45px;
         background-color: #071621;

         aside {
            width: 30vh !important;
            right: 35vh !important;
            position: absolute !important;
            z-index: 1500 !important;
            bottom: 8vh !important;
         }

         em-emoji-picker {
            width: 30vw;
            // right: 35vw;
            right: 0;
            position: absolute;
            z-index: 1500;
            bottom: 8vh;
            min-width: 352px !important;

            > section {
               min-width: 352px !important;
            }

            #preview {
               display: none !important;
            }
         }

         em-emoji-picker[id="preview"] {
            display: none;
         }

         div {
            // background-color: #04151DFF;
            color: #FFFFFF;

            > div {
               > div {
                  display: none;
               }

               textarea {
                  overflow-y: auto !important;
                  border: none;
                  width: 76% !important;
                  left: 30px !important;

                  top: 7px;
                  padding: 0 !important;
                  margin-left: 7px !important;
                  margin-top: 8px !important;
                  line-height: normal;
                  height: 31px !important;
               }
            }
         }

         .pickerBtn {
            cursor: pointer;
            position: absolute;
            border-radius: 90px;
            left: 8px;
            top: 10px;
            width: 24px;
            height: 24px;
            display: flex;
            justify-content: center;
            align-items: center;
            color: #fff;
            z-index: 1000;

            svg {
               font-size: 30px;
            }
         }

         .sendBtn {
            cursor: pointer;
            position: absolute;
            border-radius: 90px;
            right: 9px;
            top: 11px;
            width: 24px;
            height: 24px;
            display: flex;
            justify-content: center;
            align-items: center;
            color: #fff;
            background: $textturquiose;

            svg {
               font-size: 12px;
            }
         }
      }
   }
}

.chatFooter {
   input[type=text] {
      display: block;
      background-image: linear-gradient(90deg, rgba(4, 21, 29, 1.00) 0%, rgba(11, 28, 53, 0.00) 100%);
      max-width: 100%;
      color: #ffffff;
      padding-left: 20px;
      padding-right: 50px;
      font-size: 16px;
      width: 100%;
      box-sizing: border-box;
      border: solid 1px #3E5168;
      height: 56px;
      border-radius: 6px;
      background-color: #04151D;
      padding-bottom: 22px;
      padding-top: 20px;
      line-height: 10px;
      background-repeat: no-repeat;
      background-position: 96% center;
   }
}

.msgIconWrapper {
   position: relative;

   > span {
      display: block;
      width: 30px;
      float: left;

      > span {
         position: absolute;
         font-size: 11px;
         margin: 7px 0 0 4px;
      }
   }

   > span:nth-child(2) {
      > a {
         svg {
            margin-bottom: 3px;
         }
      }
   }

   .heartIconBtn {
      color: red;
   }
}

.heartIconBtn {
   color: red;
}

@media (min-width: 768px) {
   .chatFooter {
      .group {
         em-emoji-picker {
            right: unset;
            zoom: 1;
         }
      }
   }
}

@media (max-width: 768px) {
   .floatingButton {
      position: fixed;
      bottom: 30%;
      right: 15px;
      background-color: $background-color_1;
      background-image: -webkit-linear-gradient(95.34deg, #FAFF00 6.28%, #00FFC2 111.13%);
      background-image: -moz-linear-gradient(95.34deg, #FAFF00 6.28%, #00FFC2 111.13%);
      background-image: -o-linear-gradient(95.34deg, #FAFF00 6.28%, #00FFC2 111.13%);
      background-image: linear-gradient(95.34deg, #FAFF00 6.28%, #00FFC2 111.13%);
      color: $color_3;
      border-radius: 50%;
      width: 38px;
      height: 38px;
      font-size: 20px;
      border: none;
      z-index: 20000;
      opacity: 0.85;
   }
   .ChatWrappper {
      position: fixed;
      bottom: 16px;
      right: 10px;

      > button {
         position: relative;

         .floatingButtonText {
            display: none;
         }
      }
   }
   .chatFooter {
      .group {
         em-emoji-picker {
            right: 5% !important;
            zoom: .9;
         }
      }
   }
}

.unseen {
   animation: pulse 2s infinite;
}

@keyframes pulse {
   0% {
      box-shadow: 0 0 5px #2FFF9E;
   }
   70% {
      box-shadow: 0 0 25px #2FFF9E, 0 0 5px #2FFF9E;
   }
   100% {
      box-shadow: 0 0 5px #2FFF9E;
   }
}

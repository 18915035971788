.KnockoutMatchDraw {
   // background-color: red;
   img.noProfPic {
      width: 40px !important;
      height: 31px !important;
      transform: skew(16deg, 0deg);
      margin-top: -7px;
      margin-left: -5px;
   }
}

.TournamentSubText {
   font-weight: 400;
   font-family: 'Montserrat', sans-serif;
   font-size: 32px;
   line-height: 42px;
   text-transform: uppercase;
}

@media only screen and (min-width: 1199px) {
   .KnockoutMatchDraw {
      padding-right: 24px !important;
   }
}

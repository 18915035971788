@import "../../../scss/globals/global";

.section1EndGradient {
   background: linear-gradient(
         178.27deg,
         rgba(3, 21, 30, 0) 5.01%,
         #010e14 78.82%
   );
   height: 40px;
   top: -123px;
   z-index: -1;
   filter: blur(20px);
}

.loaderContainer {
   position: fixed;
   top: 50%;
   left: 50%;
   transform: translate(-50%, -50%);
}

.marginTop42 {
   margin-top: 42px;
}

.relative-pos {
   position: relative;
}

.profileTextTle {
   font-size: 24px;
   line-height: 32px;
   color: rgb(255, 255, 255);
   letter-spacing: 1px;
}

.profileText {
   font-size: 22px;
   line-height: 28px;
   color: rgba(203, 207, 210, 1);
   letter-spacing: -0.1px;
}

.profileTextSm {
   font-size: 18px;
   line-height: 24px;
   color: rgba(203, 207, 210, 1);
   letter-spacing: -0.1px;
}

.bigLogo {
   width: 352px;
   margin-bottom: 35px;
}

.mainHeaderH1 {
   font-family: "Montserrat-ExtraBoldItalic";
   font-size: 60px;
   line-height: 70px;
   letter-spacing: 2%;
   text-transform: uppercase;
   background: linear-gradient(
         89.7deg,
         #17ea85 2.27%,
         #14d0e6 23.6%,
         #17ea85 44.41%,
         #14d0e6 64.17%,
         #17ea85 82.99%,
         #14d0e6 99.74%
   );
   -webkit-background-clip: text;
   -webkit-text-fill-color: transparent;
   background-clip: text;
   text-fill-color: transparent;
   text-shadow: 0px 0px 8px rgba(20, 208, 229, 0.56);
   margin-top: 50px;
   text-transform: uppercase;

   .white86 {
      background: #fff;
      color: #fff;
      width: 100%;
      float: left;
      display: block;
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
      text-shadow: 0px 0px 8px rgb(255 255 255 / 56%);
      font-size: 92px;
      line-height: 92px;
   }

   .text16Heading {
      font-family: "Montserrat-Regular";
      background: #fff;
      color: #fff;
      text-shadow: 0px 0px 8px rgb(255 255 255 / 100%);
      width: 100%;
      float: left;
      font-weight: 500;
      font-style: normal;
      display: flex;
      flex-direction: column;
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
      text-shadow: none;
      font-size: 16px;
      line-height: 30px;
      height: 55px;
      margin: 10px 0;
   }

}

.mainHeaderH1.text20 {
   font-size: 20px;
   line-height: 24px;
}

.mainHeaderH1.Sub {
   font-size: 52px;
   line-height: 58px;
   width: 100%;
}

.mainHeaderH1.Sub1 {
   font-size: 52px;
   line-height: 58px;
}

.gray-text {
   color: #95a6b7;
}

.blueFlash2 {
   width: 234px;
   height: 224px;
   margin-bottom: -120px;
   margin-left: 100px;
   background-image: url("https://storage.googleapis.com/meta11/serviceplatform/Blue-Light-Effect-Transparent.webp");
   /*mix-blend-mode: overlay;*/
   pointer-events: none;
}

.blueFlash {
   position: absolute;
   width: 234px;
   height: 224px;
   left: -110px;
   top: -76px;
   background-image: url(https://storage.googleapis.com/meta11/serviceplatform/Blue-Light-Effect-Transparent.webp);
   background-repeat: no-repeat;
   pointer-events: none;
   z-index: -1;
}

.ellipse {
   position: absolute;
   opacity: 0.29;
   pointer-events: none;
   z-index: -1;
}

.ellipse.e26 {
   @include gradientGeneralLeft(
         984px,
         984px,
         0px,
         -145px,
         $Blue01,
         32.65%,
         32.65%,
         $GradPosLeft
   );
   mix-blend-mode: screen;
   opacity: 0.29;
   z-index: 2;
}

.ellipse.e27 {
   @include gradientGeneralLeft(
         579px,
         579px,
         -355px,
         198px,
         $Blue02,
         32.65%,
         32.65%,
         $GradPosLeft
   );
   mix-blend-mode: screen;
   z-index: 2;
}

.ellipse.e28 {
   @include gradientGeneralLeft(
         45vw,
         106vh,
         0px,
         65px,
         $Pink01,
         32.65%,
         32.65%,
         $GradPosLeft
   );
   mix-blend-mode: screen;
   opacity: 0.24;
   z-index: 2;
}

.ellipse.e29 {
   @include gradientGeneralLeft(
         579px,
         579px,
         336px,
         256px,
         $Blue02,
         32.65%,
         32.65%,
         $GradPosCenter
   );
   mix-blend-mode: normal;
   z-index: 2;
}

.ellipse.e37 {
   @include gradientGeneralLeft(
         34vw,
         42vh,
         0,
         -352px,
         $Blue03,
         32.65%,
         32.65%,
         $GradPosLeft
   );
   mix-blend-mode: screen;
   opacity: 0.29;
}

.ellipse.e38 {
   @include gradientGeneralLeft(
         45vw,
         40vh,
         31%,
         17%,
         $Blue03,
         32.65%,
         32.65%,
         $GradPosCenter
   );
   mix-blend-mode: screen;
   opacity: 0.29;
   z-index: -1;
}

.ellipse.e39 {
   @include gradientGeneralRight(
         36vw,
         42vh,
         0,
         25%,
         $Blue05,
         32.65%,
         32.65%,
         $GradPosCenter
   );
   mix-blend-mode: screen;
   opacity: 0.4;
   z-index: 2;
}

.ellipse.e40 {
   width: 315px;
   height: 420px;
   left: 0;
   mix-blend-mode: screen;
   opacity: 0.8;
   background-image: url("https://storage.googleapis.com/meta11/serviceplatform/Ellipse19.webp");
   background-position: 0 0;
   background-repeat: no-repeat;
   z-index: -2;
   margin-bottom: -310px;
   position: relative;
}

.ellipse.e41 {
   @include gradientGeneralLeft(
         45vw,
         40vh,
         0,
         5%,
         $Blue05,
         53.25%,
         53.25%,
         $GradPosLeft
   );
   mix-blend-mode: screen;
   opacity: 1;
   z-index: 2;
}

.yellowBatBlur {
   position: absolute;
   background-image: url(https://storage.googleapis.com/meta11/serviceplatform/yellowbatBlur.webp);
   background-position: center right;
   background-repeat: no-repeat;
   background-size: 80% 100%;
   top: 40px;
   left: 10px;
   width: 100%;
   height: 100%;
   z-index: -1;
   pointer-events: none;
}

.yellowMainBack {
   position: absolute;
   background-image: url("https://storage.googleapis.com/meta11/serviceplatform/bg3About.webp");
   background-position: 0 0;
   background-repeat: no-repeat;
   background-size: 100%;
   top: 0;
   left: 0;
   width: 100%;
   height: 100%;
   z-index: -3;
   opacity: 0.3;
   pointer-events: none;
}

.yellowGradientBot {
   background: linear-gradient(
         180deg,
         rgba(3, 21, 30, 0) 17.39%,
         #010e14 90.22%
   );
   bottom: 0px;
   position: absolute;
   height: 138px;
   width: 100%;
   z-index: -2;
   pointer-events: none;
}

.yellowGradient01 {
   background: radial-gradient(
         32.65% 32.65% at 50% 50%,
         #e61479 0%,
         rgba(230, 20, 20, 0) 100%
   );
   position: absolute;
   width: 700px;
   height: 700px;
   right: 300px;
   top: 300px;
   mix-blend-mode: screen;
   opacity: 0.5;
   z-index: -1;
   pointer-events: none;
   opacity: 20%;
}

.yellowGradient02 {
   background: radial-gradient(
         32.65% 32.65% at 35% 50%,
         #04f0ff 0%,
         rgba(20, 208, 230, 0) 100%
   );
   position: absolute;
   width: 500px;
   height: 500px;
   left: 10px;
   top: 550px;
   mix-blend-mode: screen;
   opacity: 0.3;
   z-index: -1;
   pointer-events: none;
   opacity: 20%;
}

.yellowGradient03 {
   background: radial-gradient(
         32.65% 32.65% at 35% 50%,
         #103a5f 0%,
         rgba(3, 22, 31, 0) 100%
   );
   position: absolute;
   width: 30vw;
   height: 60vw;
   left: -55px;
   top: 30%;
   mix-blend-mode: screen;
   opacity: 0.5;
   z-index: -1;
   pointer-events: none;
}

.yellowRightSphere {
   position: absolute;
   width: 16vw;
   height: 34vw;
   right: 0;
   top: 5%;
   mix-blend-mode: screen;
   opacity: 1;
   background-image: url("https://storage.googleapis.com/meta11/serviceplatform/yellowRightSphere.webp");
   background-position: 0 0;
   background-repeat: no-repeat;
   background-size: 100%;
   z-index: -1;
   pointer-events: none;
}

.starryback {
   width: 100%;
   height: 100%;
   background-image: url("https://storage.googleapis.com/meta11/serviceplatform/starryback.webp");
   background-position: 0 -100px;
   background-repeat: no-repeat;
   background-size: 100%;
}

.leftRay {
   position: absolute;
   opacity: 0.3;
   left: -12px;

   img {
      width: 70%;
   }
}

.rightRay {
   position: absolute;
   opacity: 0.3;
   right: 0;
   text-align: right;

   img {
      width: 70%;
   }
}

.ladyBack {
   position: absolute;
   width: 100%;
   height: 100%;
   right: 0;
   top: 5%;
   opacity: 1;
   background-image: url("https://storage.googleapis.com/meta11/serviceplatform/BG-blue-ladyBack.webp");
   background-position: -1px -179px;
   background-repeat: no-repeat;
   background-size: 93%;
   z-index: -1;
   pointer-events: none;
}

.redManEllips {
   position: absolute;
   width: 30vw;
   height: 30vw;
   left: 0;
   mix-blend-mode: screen;
   opacity: 1;
   z-index: 0;
   pointer-events: none;
}

.redManEllipsRed {
   position: absolute;
   width: 28vw;
   height: 35vw;
   right: 0;
   bottom: 330px;
   mix-blend-mode: screen;
   opacity: 0.5;
   background: radial-gradient(
         32.65% 32.65% at 100% 50%,
         #e61479 0%,
         rgba(230, 20, 20, 0) 100%
   );
   z-index: -2;
   pointer-events: none;
}

.redMansphere {
   position: absolute;
   width: 16vw;
   height: 26vw;
   right: 0;
   bottom: -230px;
   mix-blend-mode: screen;
   opacity: 0.8;
   background-image: url("https://storage.googleapis.com/meta11/serviceplatform/redMansphere.webp");
   background-position: 0 0;
   background-repeat: no-repeat;
   background-size: 100%;
   z-index: -1;
   pointer-events: none;
}

.sectionPadding {
   padding: 80px 0 0 0;
}

.rightLightning {
   position: absolute;
   right: 0;
   top: 0;
   opacity: 0.6;
   mix-blend-mode: overlay;
}

.lineWaveImgWrapper {
   position: absolute;
   top: 469px;
   left: 0;
   overflow: hidden;

   img {
      width: 100%;
   }
}

.section1Container {
   height: auto;
}

.FaqFonts {
   font-size: 24px;
   font-family: "Montserrat", sans-serif;
   cursor: pointer;
   font-weight: 500;

   font {
      width: 80%;
      float: left;
   }
}

.FaqFontsP {
   font-size: 14px;
   font-family: "OSRegular", Helvetica, Arial, sans-serif;
   font-weight: 100;
   color: #95a6b7;
   line-height: 18px;
   text-align: justify;
   text-justify: inter-word;
   margin-top: 8px;
}

.FaqFontsOl {
   font-size: 14px;
   font-family: "OSRegular", Helvetica, Arial, sans-serif;
   font-weight: 100;
   color: #95a6b7;
   line-height: 18px;
   text-align: justify;
   text-justify: inter-word;

   li {
      margin-top: 8px;
   }
}


.joinComunitySection {
   background: rgb(57, 45, 252);
   background: linear-gradient(
         90deg,
         rgba(57, 45, 252, 0.2) 0%,
         rgba(255, 122, 0, 0.2) 100%
   );
   width: 100%;
   float: left;
   padding: 20px;
   display: flex;
   flex-direction: column;

   .leftShock {
      position: absolute;
      top: 0;
      left: 0;
      width: 25%;
      height: 100%;
      background-image: url("https://storage.googleapis.com/meta11/serviceplatform/leftShock.webp");
      background-size: 100% 100%;
      background-position: 0 0;
      z-index: -2;
      opacity: 0.7;
   }

   .rightShock {
      position: absolute;
      top: 0;
      right: 0;
      width: 25%;
      height: 100%;
      background-image: url("https://storage.googleapis.com/meta11/serviceplatform/rightShock.webp");
      background-size: 100% 100%;
      background-position: 0 0;
      z-index: -2;
      opacity: 0.7;
   }

   img {
      margin-top: -76px;
      width: 100%;
   }
}


.section3Background {
   position: absolute;
   top: 0;
   left: 0;
   width: 100%;
   height: 100%;
   background-image: url("https://storage.googleapis.com/meta11/serviceplatform/BG-blue.webp");
   background-size: 100% 100%;
   background-position: 0 0;
   z-index: -2;
}

.ResponceCont {
   max-width: 1240px;
   margin-left: auto;
   margin-right: auto;
}

.ResponceCont2 {
   max-width: 1440px;
   margin-left: auto;
   margin-right: auto;
}

.topline {
   background-image: url("https://storage.googleapis.com/meta11/serviceplatform/GradientTopBorder.webp");
   background-repeat: no-repeat;
   height: 2px;
   background-position: center 0%;
   background-size: 100%;
}

.inputHome[type="text"] {
   display: block;
   background-image: -webkit-linear-gradient(
         0deg,
         rgba(4, 21, 29, 1) 0%,
         rgba(11, 28, 53, 0) 100%
   );
   background-image: -moz-linear-gradient(
         0deg,
         rgba(4, 21, 29, 1) 0%,
         rgba(11, 28, 53, 0) 100%
   );
   background-image: -o-linear-gradient(
         0deg,
         rgba(4, 21, 29, 1) 0%,
         rgba(11, 28, 53, 0) 100%
   );
   background-image: linear-gradient(
         90deg,
         rgba(4, 21, 29, 1) 0%,
         rgba(11, 28, 53, 0) 100%
   );
   max-width: 100%;
   color: #ffffff;
   padding-left: 20px;
   padding-right: 50px;
   font-size: 16px;
   /*font-family: 'SolinaLight';*/
   width: 100%;
   box-sizing: border-box;
   border: solid 1px #3e5168;
   height: 54px;
   border-radius: 3px;
   background-color: #04151d;
   padding-bottom: 22px;
   padding-top: 20px;
   line-height: 10px;
   /*background-image: url("https://storage.googleapis.com/meta11/serviceplatform/pw-eye.png");*/
   background-repeat: no-repeat;
   background-position: 96% center;
   transform: skew(-10deg, 0deg);
   text-transform: skew(10deg, 0deg);
}

.inputHome[type="text"]:focus {
   border-style: solid;
   border-width: 1px;
   border-radius: 2px;
   outline: none;
}

/* active state */
.inputHome[type="text"]:focus ~ label,
.inputHome[type="text"]:valid ~ label {
   top: 10px;
   font-size: 12px;
   color: #95a6b7;
   padding-left: 6px;
}

.mainImgBg {
   background-image: url("https://storage.googleapis.com/meta11/serviceplatform/home3-1.webp");
   background-repeat: no-repeat;
   background-position: right center;
   background-size: auto 100%;
   background-blend-mode: normal;
   padding-bottom: 100px !important;
}

video {
   width: 100%;

   source {
      width: 100% !important;
   }
}

.startButtonWrapper {
   a, button {
      display: block;
      float: left;
      height: 53px;
   }

   a {
      padding: 16px 20px;
   }
}

/* Countdown */
.nextTournamentCol {
   .nextTournamentWrapper {
      //position: absolute;
      // left: 0;
      //right: 10vw;
      //margin-left: auto;
      //margin-right: auto;

      //bottom: 0;
      //width: 480px;
      // height: 182px;
      //border-radius: 3px;
      //box-shadow: 0px 0px 55.573062896728516px 0px rgba(0, 255, 194, 0.7);
      //background: #00000042;
      //backdrop-filter: blur(5px);
      //-webkit-backdrop-filter: blur(5px);
      //border: solid 1px;
      //border-image: linear-gradient(45deg, #F9FF01, #2FFF9E) 1;
      //border-width: 2px;
      //padding-bottom:10px;

      h2 {
         font-family: $countdown-bottom-font;
         font-size: 27px;
         font-weight: 400;
         line-height: 27px;
         letter-spacing: 0.28em;
         text-align: center;
         text-transform: uppercase;
         background: -webkit-linear-gradient(45deg, #FAFF00, #00FFC2 80%);
         -webkit-background-clip: text;
         -webkit-text-fill-color: transparent;
         margin-top: 20px;
      }

      .countdown {
         width: fit-content;
         margin: 0 auto;
         margin-top: 10px;
         display: grid;
         grid-template-columns: repeat(3, 84px);
         gap: 30px;

         > div {
            .cercalBack {
               width: 82px;
               height: 82px;
               // background-image: url('../images/backgrounds/countdown_circle-01.svg');
               background-image: url('../../../assets/images/backgrounds/countdown_circle-01.svg');
               background-position: top left;
               background-repeat: no-repeat;
               position: relative;
            }

            .cercal {
               width: 82PX;
               height: 82px;
               border-radius: 50%;
               /* border: 1.69px solid; */
               border-color: linear-gradient(143.92deg, #FAFF01 13.27%, #39FF96 61.67%);

               > span {
                  position: absolute;
                  width: 50px;
                  top: 29%;
                  left: 20%;
                  margin-left: auto;
                  margin-right: auto;
                  font-family: $countdown-count-font;
                  // font-size: $countdown-count;
                  font-size: 21px;
                  font-weight: 400;
                  line-height: 36px;
                  letter-spacing: 2px;
                  background: -webkit-linear-gradient(45deg, #FAFF00, #00FFC2 80%);
                  -webkit-background-clip: text;
                  -webkit-text-fill-color: transparent;
                  text-align: center;
               }

            }

            > span {
               text-transform: uppercase;
               font-size: 14.82px;
               font-weight: 400;
               display: block;
               text-align: center;
               font-family: $countdown-bottom-font;
               font-size: $countdown-bottom-title;
               margin-top: 8px;
               background: -webkit-linear-gradient(45deg, #FAFF00, #00FFC2 80%);
               -webkit-background-clip: text;
               -webkit-text-fill-color: transparent;
            }

         }

         .timerWrapper {
            width: fit-content;
            position: absolute;
            top: -50px;
            margin-left: 1px;

            div {
               max-width: 80px;

               > svg {
                  max-width: 80px;

                  > :nth-child(1) {
                     stroke: unset !important;
                  }
               }
            }

         }
      }
   }
}

.faqListWrapper {
   float: left;
   width: 100%;
   border-bottom-width: 1px;
   border-bottom: 1px solid transparent;
   -webkit-border-bottom-width: 1px;
   border-style: solid;
   border-image: linear-gradient(to right, rgba(38, 200, 103, 0), rgba(38, 200, 103, 1), rgba(38, 200, 103, 0)) 0.8;
   -webkit-mask-image: linear-gradient(to right, rgba(38, 200, 103, 0), rgba(38, 200, 103, 1), rgba(38, 200, 103, 0)) 0.8;
   border-top: 0px;
}

.kumasContent {
   h2 {
      text-align: left;
      font-family: "Montserrat-Regular", ObjectSans-Regular;
      font-size: 32px;
      font-weight: 600;
      line-height: 42px;
      letter-spacing: 0.02em;
      margin-bottom: 14px;
   }

   p {
      text-align: left;
      font-size: 22px;
      font-weight: 400;
      line-height: 28px;
      letter-spacing: -0.01em;
      margin-bottom: 20px;
      font-family: "ObjectSans-Regular";
   }

   > div {
      background: linear-gradient(91deg, rgba(57, 45, 252, 0.2) 1.34%, rgba(255, 122, 0, 0.2) 100%) !important;
      border: 1px solid #0c2b3e;
      display: flex;
      align-items: center;

      > div:nth-child(1) {
         padding-top: 20px !important;
         padding-bottom: 20px !important;
         padding-right: 20px !important;
      }
   }

   img {
      width: unset;
      width: 100%;
      display: block;
      margin: 0 auto;
      max-width: 440px;
   }
}

.homeContainer {
   .mainH1Wrapper {
      h1 {
         font-size: 54px !important;

         > div.timeNontText {
            font-size: 48px !important;
            line-height: normal !important;

            > label {
               font-size: 32px !important;
               font-weight: 600;
               text-transform: uppercase;
               background: linear-gradient(89.7deg, #17EA85 2.27%, #14D0E6 23.6%, #17EA85 44.41%, #14D0E6 64.17%, #17EA85 82.99%, #14D0E6 99.74%);
               -webkit-background-clip: text;
               -webkit-text-fill-color: transparent;
               background-clip: text;
               text-fill-color: transparent;
               text-shadow: 0px 0px 8px rgba(20, 208, 229, 0.5607843137);
            }
         }

         > span {
            display: block;
            margin-top: 20px;
            font-size: 48px;
            -webkit-text-size-adjust: 50%;
            line-height: normal;
         }
      }
   }

   .timeNontText {
      text-transform: uppercase;
      background: linear-gradient(89.7deg, #17EA85 2.27%, #14D0E6 23.6%, #17EA85 44.41%, #14D0E6 64.17%, #17EA85 82.99%, #14D0E6 99.74%);
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
      background-clip: text;
      text-fill-color: transparent;
      text-shadow: 0px 0px 8px rgba(20, 208, 229, 0.5607843137);
   }
}

.homeContainer {
   .startButtonWrapper {
      > button {
         background: linear-gradient(0deg, #0E2029, #0E2029) padding-box !important;
      }
   }
}

@media screen and (max-width: 1400px) {
   .container {
      max-width: 100%;
   }
}

@media screen and (min-width: 1400px) {
   .nextTournamentLeftCol {
      display: none;
   }
   .nextTournamentCol {
      position: relative;
      width: 100%;
      max-width: 1400px;
      margin: 0 auto;

      .nextTournamentWrapper {
         right: 0;
      }
   }
}

@media screen and (max-width: 1244px) {
   .mainImgBg {
      h1.mainH1 {
         zoom: 0.75;
      }
   }
}

@media screen and (max-width: 1200px) {
   .faqIMG {
      width: 80% !important;
   }

   .mainImgBg {
      background-size: auto 80%;
      padding-bottom: 50px !important;
   }

   .blueFlash2 {
      display: none;
   }
}

@media screen and (max-width: 1200px) and (min-width: 992px) {
   .mainHeaderH1 {
      font-size: 50px;
      line-height: 50px;
      text-align: left;
      margin-top: 10px;
   }

   .section1Container {
      height: auto;
   }

   .ellipse29Img,
   .ellipse.e33,
   .ellipse.e29,
   .ellipse.e25,
   .ellipse.e26,
   .ellipse.e27,
   .ellipse.e28 {
      display: none;
   }
   .transCard {
      margin-bottom: 25px;
   }
   .marginTop42 {
      margin-top: 20px;
   }
}

@media screen and (max-width: 1024px) {
   .faqListWrapper:last-child {
      margin-bottom: 70px;
   }
}

@media screen and (max-width: 992px) and (min-width: 768px) {
   .mainHeaderH1 {
      font-size: 50px;
      line-height: 50px;
      text-align: left;
      margin-top: 10px;
   }

   .section1Container {
      height: auto;
   }

   .ellipse.e31,
   .ellipse29Img,
   .ellipse.e33,
   .ellipse.e29,
   .ellipse.e25,
   .ellipse.e26,
   .ellipse.e27,
   .ellipse.e28,
   .ellipse.e30 {
      display: none;
   }
   .transCard {
      margin-bottom: 25px;
   }
   .marginTop42 {
      margin-top: 20px;
   }
   .profileTextTle {
      font-size: 22px;
      line-height: 26px;
      color: rgb(255, 255, 255);
      letter-spacing: 1px;
   }

   .profileText {
      font-size: 20px;
      line-height: 24px;
   }

   .profileTextSm {
      font-size: 16px;
      line-height: 20px;
      color: rgba(203, 207, 210, 1);
      letter-spacing: -0.1px;
   }

   .bigLogo {
      width: 300px;
      margin-bottom: 25px;
   }

   .mainHeaderH1 {
      font-size: 50px;
      line-height: 60px;
   }

   .mainHeaderH1 .white86 {
      font-size: 76px;
      line-height: 86px;
   }

   .mainHeaderH1Sub {
      font-size: 40px;
      line-height: 50px;
   }

   .mainHeaderH1Sub1 {
      font-size: 30px;
      line-height: 35px;
   }

   .mainImgBg {
      h1.mainH1 {
         font-size: 6vw;
      }
   }
   .nextTournamentWrapper {
      right: 3vw !important;
      zoom: .8;

      .countdown > div > span {
         font-size: 20px !important;
      }
   }

}

@media screen and (max-width: 768px) {

   .topline {
      background-image: url("https://storage.googleapis.com/meta11/serviceplatform/GradientTopBorder.webp");
      background-repeat: no-repeat;
      height: 2px;
      background-position: left 0%;
      background-size: auto;
   }
   .yellowBatBlur {
      left: 0px;
   }

   .FaqFontsP {
      font-size: 18px;
      font-family: "OSRegular", Helvetica, Arial, sans-serif;
      font-weight: 100;
      color: #95a6b7;
      line-height: 24px;
      text-align: left;
      text-justify: inter-word;
      margin-top: 12px;
   }

   .FaqFontsOl {
      font-size: 18px;
      font-family: "OSRegular", Helvetica, Arial, sans-serif;
      font-weight: 100;
      color: #95a6b7;
      line-height: 24px;
      text-align: justify;
      text-justify: inter-word;

      li {
         margin-top: 12px;
      }

   }

   .joinComunitySection {
      padding: 75px 50px 75px 50px;
   }

   .profileTextTle {
      font-size: 20px;
      line-height: 28px;
      color: rgb(255, 255, 255);
      letter-spacing: 1px;
   }

   .profileText {
      font-size: 18px;
      line-height: 22px;
   }

   .profileTextSm {
      font-size: 14px;
      line-height: 20px;
      color: rgba(203, 207, 210, 1);
      letter-spacing: -0.1px;
   }

   .bigLogo {
      width: 250px;
      margin-bottom: 25px;
   }

   .mainHeaderH1 {
      font-size: 40px;
      line-height: 50px;
   }

   .mainHeaderH1 .white86 {
      font-size: 56px;
      line-height: 56px;
   }

   .mainHeaderH1Sub {
      font-size: 26px;
      line-height: 30px;
   }

   .mainHeaderH1Sub1 {
      font-size: 25px;
      line-height: 30px;
   }

   .mainHeaderH1 {
      font-size: 50px;
      line-height: 50px;
      text-align: left;
      margin-top: 10px;
   }

   .section1Container {
      height: auto;
   }

   .ellipse.e31,
   .ellipse29Img,
   .ellipse.e33,
   .ellipse.e29,
   .ellipse.e25,
   .ellipse.e27,
   .ellipse.e28,
   .ellipse.e30 {
      display: none;
   }

   .ellipse.e26 {
      width: 100%;
      display: inline-block;
   }
   .transCard {
      margin-bottom: 25px;
   }
   .marginTop42 {
      margin-top: 20px;
   }
   .ellipse34 {
      position: absolute;
      width: 100%;
      height: 600px;
      background: radial-gradient(
            51.65% 28.65% at 50% 47%,
            #14d0e6 0%,
            rgba(20, 208, 230, 0) 100%
      );
   }

   .ellipse35 {
      display: none;
   }

   .faqIMG {
      width: 100% !important;
   }

   .mainImgBg {
      background-image: none;
      padding-bottom: 0px !important;
   }

   .mainHeaderH1.text20 {
      font-size: 14px;
      line-height: 18px;
   }
   .yellowGradient02 {
      .yellowGradient02 {
         width: 100%;
         height: 500px;
      }

   }
   .mainImgBg {
      h1.mainH1 {
         font-size: 6vw;
      }
   }
   .nextTournamentWrapper {
      left: 0 !important;
      right: 0 !important;
   }
   .kumasContent {
      h2 {
         font-size: 28px;
      }

      p {
         font-size: 18px;
      }
   }

   .homeContainer {
      .mainH1Wrapper {
         h1 {
            font-size: 60px !important;
            line-height: 60px;
            max-width: 80%;

            > div.timeNontText {
               font-size: 45px !important;
               line-height: normal !important;
               display: block;
               width: 67%;

               > label {
                  font-size: 32px !important;
                  font-weight: 600;
               }
            }
         }
      }

      .timeNontText {

      }
   }
   .blueFlash {
      left: -108px !important;
      top: -77px !important;
   }

   .nextTournamentCol {
      .nextTournamentWrapper {
         //position: absolute;
         // left: 0;
         //right: 10vw;
         //margin-left: auto;
         //margin-right: auto;

         //bottom: 0;
         //width: 480px;
         // height: 182px;
         //border-radius: 3px;
         //box-shadow: 0px 0px 55.573062896728516px 0px rgba(0, 255, 194, 0.7);
         //background: #00000042;
         //backdrop-filter: blur(5px);
         //-webkit-backdrop-filter: blur(5px);
         //border: solid 1px;
         //border-image: linear-gradient(45deg, #F9FF01, #2FFF9E) 1;
         //border-width: 2px;
         //padding-bottom:10px;

         h2 {
            font-family: $countdown-bottom-font;
            font-size: 20px;
            font-weight: 400;
            line-height: 27px;
            letter-spacing: 0.28em;
            text-align: center;
            text-transform: uppercase;
            background: -webkit-linear-gradient(45deg, #FAFF00, #00FFC2 80%);
            -webkit-background-clip: text;
            -webkit-text-fill-color: transparent;
            margin-top: 20px;
         }

         .countdown {
            width: fit-content;
            margin: 0 auto;
            margin-top: 10px;
            display: grid;
            grid-template-columns: repeat(3, 84px);
            gap: 30px;

            > div {
               .cercalBack {
                  width: 82px;
                  height: 82px;
                  // background-image: url('../images/backgrounds/countdown_circle-01.svg');
                  background-image: url('../../../assets/images/backgrounds/countdown_circle-01.svg');
                  background-position: top left;
                  background-repeat: no-repeat;
                  position: relative;
               }

               .cercal {
                  width: 82PX;
                  height: 82px;
                  border-radius: 50%;
                  /* border: 1.69px solid; */
                  border-color: linear-gradient(143.92deg, #FAFF01 13.27%, #39FF96 61.67%);

                  > span {
                     position: absolute;
                     width: 50px;
                     top: 29%;
                     left: 20%;
                     margin-left: auto;
                     margin-right: auto;
                     font-family: $countdown-count-font;
                     // font-size: $countdown-count;
                     font-size: 21px;
                     letter-spacing: 2px;
                     background: -webkit-linear-gradient(45deg, #FAFF00, #00FFC2 80%);
                     -webkit-background-clip: text;
                     -webkit-text-fill-color: transparent;
                     text-align: center;
                  }

               }

               > span {
                  text-transform: uppercase;
                  font-size: 14.82px;
                  font-weight: 400;
                  display: block;
                  text-align: center;
                  font-family: $countdown-bottom-font;
                  font-size: $countdown-bottom-title;
                  margin-top: 8px;
                  background: -webkit-linear-gradient(45deg, #FAFF00, #00FFC2 80%);
                  -webkit-background-clip: text;
                  -webkit-text-fill-color: transparent;
               }

            }

            .timerWrapper {
               width: fit-content;
               position: absolute;
               top: 8px;
               margin-left: 1px;

               div {
                  max-width: 80px;

                  > svg {
                     max-width: 80px;

                     > :nth-child(1) {
                        stroke: unset !important;
                     }
                  }
               }

            }
         }
      }
   }


}

@media only screen and (max-width: 576px) {
   .container {
      max-width: 100%;
   }

   p {
      font-size: 16px;
      line-height: 20px;
   }
   .mainHeaderH1 {
      font-size: 26px;
      line-height: 30px;
      text-align: left;
      margin-top: 10px;
   }

   .blueFlash {
      display: block;
      left: -112px;
      top: -53px !important;
      background-size: 60%;
      background-position: center;
      z-index: 0;
   }

   .section1Container {
      height: auto;
   }

   .ellipse.e31,
   .ellipse29Img,
   .ellipse.e33,
   .ellipse.e29,
   .ellipse.e25,
   .ellipse.e27,
   .ellipse.e28,
   .ellipse.e30 {
      display: none;
   }
   .ellipse.e26 {
      display: inline-block;
      width: 100%;
   }

   .transCard {
      margin-bottom: 25px;
   }
   .marginTop42 {
      margin-top: 20px;
   }
   .textCenter {
      text-align: center;
   }

   .faqHeading {
      margin-top: 15px;
      position: relative;
   }

   .FaqFonts {
      font-size: 16px;
      line-height: 20px;
      letter-spacing: 0.02em;
      text-transform: uppercase;
      /*padding-right: 50px;*/
   }

   .openedFaq {
      position: absolute;
      right: 4px;
      top: 0;
   }

   .navBarLoginButtonShow {
      right: 0;
   }

   .LoginButton {
      height: 47px;
      padding: 14px 20px;
   }
   h1.mainH1 {
      zoom: 1;
      font-size: 9vw !important;
      padding-right: 78px;
   }
   .nextTournamentWrapper {
      left: 0 !important;
      right: 0 !important;
      zoom: .8;
      width: 80% !important;

      //   background-color: red;
      //    > div > .cercal > span{
      //     font-size: 21px !important;
      //    }
      //  }
   }
   .kumasContent {
      margin-bottom: 50px;

      h2 {
         font-size: 22px;
      }

      img {
         padding-bottom: 20px;
      }

      > div {
         padding: 10px !important;
      }

      > div > div:nth-child(1) {
         padding: 0 !important;
      }
   }

   .homeContainer {
      .section1Container {
         .mainH1Wrapper {
            h1 {
               font-size: 54px !important;
               line-height: 60px;
               max-width: 100%;
               zoom: 0.5 !important;

               > div.timeNontText {
                  font-size: 45px !important;
                  -webkit-text-size-adjust: 50%;
                  line-height: normal !important;
                  display: block;
                  width: 100%;

                  > label {
                     font-size: 32px !important;
                     font-weight: 600;
                  }
               }
            }
         }

         .timeNontText {

         }
      }
   }
   .nextTournamentWrapper {
      position: unset !important;
   }
}

/*********  iPad layouts *********/
@media only screen and (device-width: 768px) {
   /* For general iPad layouts */
   .homeContainer {
      .aiAthletesRow {
         .heading2.gradient-color {
            text-shadow: 0 0 0 rgba(20, 208, 229, 1);
         }
      }

      .faqTitleWrapper {
         .heading2.gradient-color {
            text-shadow: 0 0 0 rgba(20, 208, 229, 1);
         }
      }
   }
   .navItem .active {
      color: #17ea85 !important;
      background: unset;
      -webkit-text-fill-color: #17ea85 !important;
   }
   .navbar-expand-lg .navbar-collapse {
      justify-content: flex-end;
   }
}

/*********  iphone 15 *********/
@media only screen and (device-width: 414px) and (device-height: 896px) and (-webkit-device-pixel-ratio: 3) {
   .faqListContainer {
      .faqListWrapper {
         border: none;

         background: linear-gradient(to right, rgba(38, 200, 103, 0) 0%, rgba(38, 200, 103, 1) 49%, rgba(38, 200, 103, 0) 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */

         padding-top: 0;
         padding-bottom: 0;
         margin-top: 0px;
         margin-bottom: 10px;
         padding-bottom: 1px;

         > div {
            background-color: #010d14;
            padding: 10px;
         }

         > div:nth-child(1) {
            margin-top: 0px;
            padding: 20px 10px;
         }
      }
   }
}

@media only screen and (min-device-width: 481px) and (max-device-width: 1024px) and (orientation: portrait) {
   /* For portrait layouts only */
   .navItem .active {
      color: #17ea85 !important;
      background: unset;
      -webkit-text-fill-color: #17ea85 !important;
   }
   .navbar-expand-lg .navbar-collapse {
      justify-content: flex-end;
   }
}

@media only screen and (min-device-width: 481px) and (max-device-width: 1024px) and (orientation: landscape) {
   /* For landscape layouts only */
   .navItem .active {
      color: #17ea85 !important;
      background: unset;
      -webkit-text-fill-color: #17ea85 !important;
   }
   .navbar-expand-lg .navbar-collapse {
      justify-content: flex-end;
   }
}

.centerLoader {
   position: absolute;
   top: 50%;
   left: 50%;
   //transform: translate(-50%, -50%);
}

.qrSpace {
   height: 80px !important;
   float: left;
   width: 100%;
}

.loadingContainer {
   position: fixed;
   top: 50%;
   left: 50%;
   transform: translate(-50%, -50%);
}

.tournamentsPage {
   .loaderContainer {
      margin: 0 auto;
      transform: translateY(400%);
      text-align: center;
   }

   .ResponceCont {
      max-width: 1240px;
      margin-left: auto;
      margin-right: auto;
   }

   h1 {
      //margin: 40px 0;
      font-size: 42px;
      line-height: 44px;
      text-align: left;
      margin-top: 10px;
      font-family: "Montserrat";
      font-weight: 800;
      font-style: italic;
      letter-spacing: 0.02em;
      text-transform: uppercase;
      /* text-shadow: 0px 0px 8px rgba(20, 208, 229, 0.56); */
      background: linear-gradient(89.7deg, #17EA85 2.27%, #14D0E6 23.6%, #17EA85 44.41%, #14D0E6 64.17%, #17EA85 82.99%, #14D0E6 99.74%);
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
      background-clip: text;
      text-fill-color: transparent;
   }

   @mixin btn {
      background: linear-gradient(95.34deg, #FAFF00 6.28%, #00FFC2 111.13%);
      box-shadow: 0px 2px 0px rgba(0, 0, 0, 0.51);
      border-radius: 2px;
      transform: skew(-10deg, 0deg);
      border: 1px solid #2FFF9E;
      /* border-image-source: linear-gradient(103.05deg, #2FFF9E 7.22%, #F9FF01 93.09%); */
      text-align: center;
      letter-spacing: -0.01em;
      padding: 12px 25px;
      /* font-weight: 700; */
      width: -moz-fit-content;
      width: fit-content;
      height: 46px;
      color: #03161F;
      font-family: "ObjSans-HeavySlanted";
      font-size: 14px;
      text-transform: uppercase;
      transform: skew(-10deg, 0deg);
   }

   a.playNowLink {
      display: block;
      @include btn;

      img {
         margin-top: -7px;
      }
   }

   button.playNow {
      @include btn;

      img {
         margin-top: -7px;
      }

      img.LockIcon {
         margin-right: 10px;
      }
   }

   .pageWrapper {
      min-height: 380px;

      button:hover {
         background: linear-gradient(95.34deg, #00FFC2 6.28%, #FAFF00 111.13%);
      }
   }

   .upcomingTag {
      display: block;
      width: fit-content;
      font-family: "ObjectSans-Regular";
      letter-spacing: 2px;
      font-size: 12px;
      background: linear-gradient(90.87deg, #19A62F 0.87%, #DBB908 43.25%),
      linear-gradient(92.52deg, #D0B80B 0.84%, #1EA72F 41.93%);
      border: 1px solid;
      border-image-source: linear-gradient(92.52deg, #D0B80B 0.84%, #1EA72F 41.93%);
      transform: skew(-10deg, 0deg);
      padding: 2px 14px;
      text-transform: uppercase;
      margin: 0;
   }

   .PlayNowliveTag {
      display: block;
      width: fit-content;
      background: linear-gradient(96.32deg, #B10B8F 1.99%, #E01655 99.35%),
      linear-gradient(92.2deg, #E11653 2.13%, #AD0A93 106.58%);
      transform: skew(-10deg, 0deg);
      bottom: 2px;
      font-family: "ObjectSans-Regular";
      letter-spacing: 2px;
      font-size: 12px;
      padding: 2px 10px;
      line-height: 17px;
      color: rgb(255, 255, 255);
      -webkit-text-fill-color: #FFFFFF !important;
      text-transform: uppercase;

      > .liveTagWrapper {
         display: flex;
         animation: blink 2s infinite;

         > span {
            display: block;
            background-color: #fff;
            display: inline-block;
            margin-right: 4px;
            margin-bottom: 2px;
            margin-top: 8px;
            width: 4px;
            height: 4px;
            border-radius: 50%;
            // animation: blink 2s infinite;
         }
      }


      @keyframes blink {
         0% {
            opacity: 0
         }
         49% {
            opacity: 1
         }
         50% {
            opacity: 0
         }
      }

   }


   @keyframes BgGradient {
      0% {
         scale: 1;
         opacity: 1;
      }
      50% {
         scale: 1.1;
         opacity: .8;
      }
      90% {
         scale: 1.2;
         opacity: .3;
      }
      100% {
         scale: 1;
         opacity: .0;
      }
   }

   .PlayNowLiveTagContainer {
      position: relative;

      .PlayNowLiveTag {
         width: -moz-fit-content;
         width: fit-content;
         // background: linear-gradient(96.32deg, #B10B8F 1.99%, #E01655 99.35%), linear-gradient(92.2deg, #E11653 2.13%, #AD0A93 106.58%);
         background: linear-gradient(-45deg, #ee7752, #e73c7e, #931cb1, #e01655);
         transform: skew(-10deg, 0deg);
         bottom: 2px;
         font-family: "ObjectSans-Regular";
         letter-spacing: 2px;
         font-size: 12px;
         padding: 2px 10px;
         height: 24px;
         color: rgb(255, 255, 255);
         -webkit-text-fill-color: #FFFFFF !important;
         text-transform: uppercase;
         display: flex;
         font-size: 10px;
         background-size: 300% 200%;
         // animation: BgGradient 1s ease infinite;
         .LiveTag {
            padding-top: 1px;
            font-size: 10px;
            display: block;
            margin-top: -6px;
         }

         .BlinkingDot {
            display: block;
            margin-top: 8px;
            margin-right: 4px;
         }
      }

      .PlayNowLiveTagWrapper {
         background: linear-gradient(-45deg, #ee7752, #e73c7e, #931cb1, #e01655);
         transform: skew(-10deg, 0deg);
         width: 102px;
         height: 27px;
         border-radius: 2px;
         position: absolute;
         top: -2px;
         animation: BgGradient 1s ease infinite;
      }
   }


   .TwoPlayers {
      margin-top: 52px;
      display: flex;
      gap: 10px;
      margin-left: 15px;
      padding-top: 4px;

      > label {
         display: block;
         padding-right: 2px;

         > img {
            display: block;
            margin: 0 auto;
         }
      }

      .PlayerWrapper {
         .Player {
            width: fit-content;
            padding: 2px 20px;
            transform: skew(-10deg, 0deg);
            border-width: 2px;
            border-image-source: linear-gradient(99.98deg, rgba(213, 42, 177, 0.4) -2.72%, rgba(68, 91, 204, 0.4) 128.13%);
            background: radial-gradient(106.3% 411.84% at 12.63% 50%, rgba(213, 42, 177, 0.3) 0%, rgba(68, 91, 204, 0.6) 100%);
            border: 1px solid #5a66bb8f !important;
            border-radius: 2px;

            img {
               width: 37px;
               height: 42px;
            }

            .PlayerThumbWrapper {
               width: 37px;
               height: 42px;
               position: absolute;
               top: -12px;
               left: -8px;
               background-image: url(../../assets/images/PlayerBackground.webp);
               background-repeat: no-repeat;
               //transform: skew(-10deg, 0deg);
               border-radius: 4px;
            }

            span {
               font-family: "ObjectSans-Regular";
               font-size: 14px;
               font-weight: 700;
               margin-left: 20px;
            }
         }
      }
   }

   .RightColWrapper {
      display: grid;
      grid-template-columns: 1fr;

      > div {

      }

      .UserInforLabel {
         text-align: right;
         margin-top: 20px;
         color: #95A6B7;
         font-family: "ObjectSans-Regular";
         font-size: 14px;
      }

      .QRCotainer {
         display: flex;
         //width: 50px;
         // display: grid;
         // grid-template-columns: 1fr 1fr;
         gap: 10px;

         .QRCodeWrapper {
            width: 45px;
            height: 45px;
            display: flex;
            background: #0E2029;
            border-radius: 90px;
            justify-content: center;

            img {
               opacity: .8;
               cursor: pointer;
            }

            img:hover {
               opacity: 1;
            }
         }
      }
   }

   .creditsWrapper {
      width: fit-content;
      display: inline-block;
      font-size: 14px;
      margin: 0 auto;

      img {
         transform: skew(10deg, 0deg) !important;
         margin: 0 8px;
      }

      span {
         color: #17EA85;
         font-size: 14px !important;
         transform: skew(10deg, 0deg) !important;
      }

      label {
         text-transform: capitalize;
      }

      .credits {
         padding: 6px 22px;
         border: none;
         outline: none;
         position: relative;
         z-index: 1;
         border-radius: 2px;
         background: linear-gradient(103.05deg, #F9FF01 7.22%, #2FFF9E 93.09%);
         //    cursor: pointer;
         transform: skew(-9deg, 0deg);
         text-transform: uppercase;
         margin-right: 10px;
      }

      .credits::before {
         content: "";
         position: absolute;
         left: 1px;
         right: 1px;
         top: 1px;
         bottom: 1px;
         border-radius: 4px;
         background-color: #0E2029;
         z-index: -1;
         transition: 200ms
      }

      .credits::after {
         content: attr(data);
         font-size: 16px;
         background: linear-gradient(to left, #00FFA3, #DC1FFF);
         -webkit-background-clip: text;
         color: #fff;
         transition: 200ms
      }
   }

   .noCreditsWrapper {
      margin-top: 2px;

      > .credits {
         padding-top: 2px !important;

         label {
            display: block;
            width: 100%;
            text-align: center;
            padding-top: 4px !important;
         }
      }
   }

   .KnockoutTag {
      font-family: "ObjectSans-Regular";
      font-weight: 600;
      color: #17EA85;
      text-transform: uppercase;
      font-style: italic;
      margin: 0;
      padding-top: 2px;
   }

   .completedTag {
      width: fit-content;
      font-size: 12px;
      background: linear-gradient(92.07deg, #3421AA 2.01%, #0F7A89 100.44%) padding-box, linear-gradient(to right, #0F7A89, #3421AA) border-box;
      align-items: center;
      border-radius: 2px;
      transform: skew(-5deg, 0deg);
      height: 24px;
      padding: 10px;
      padding-left: 10px;
      padding-right: 11px;
      display: flex;
      text-transform: uppercase;
      font-family: 'ObjectSans-Regular';
      letter-spacing: 0.05em;
   }

   .MatchWrapper {
      margin-bottom: 20px;
      background-repeat: no-repeat;
      background-size: cover;
      min-height: 248px;
      padding: 24px;
      position: relative;
      border-radius: 4px;

      h2 {
         font-family: "Montserrat";
         text-transform: uppercase;
         font-size: 32px;
         font-style: italic;
         font-weight: 800;
         line-height: 46px;
         letter-spacing: 0.02em;
         text-align: left;
         margin: 0;
         padding-top: 20px;
      }

      span {
         font-family: "Montserrat";
         text-transform: uppercase;
         font-size: 24px;
         font-style: italic;
         font-weight: 600;
         line-height: 32px;
         letter-spacing: 0.02em;
         text-align: left;

      }

      button {
         float: right;
         padding: 5px 15px;
         line-height: 15px;
      }

      .buttonRow {
         //position: absolute;
         //bottom: 44px;
         // right: 24px;
         //    width: 100%;
         float: right;
         margin-top: 10px;
         gap: 10px;

         .creditsWrapper {
            margin-top: 10px;
            width: fit-content;
            min-width: 130px;
            // display: inline-block;
            font-size: 14px;
            // float: right;
            img {
               transform: skew(10deg, 0deg) !important;
               margin: 0 8px;
               width: 24px;
               height: 24px;
            }

            span {
               color: #17EA85;
               font-size: 14px;
               transform: skew(10deg, 0deg) !important;
            }

            label {
               text-transform: capitalize;
            }

            b {
               height: 26px;
            }

            .credits {
               padding: 6px 16px !important;
               border: none;
               outline: none;
               position: relative;
               //    z-index: 1;
               border-radius: 2px;
               background: linear-gradient(103.05deg, #F9FF01 7.22%, #2FFF9E 93.09%);
               //    cursor: pointer;
               transform: skew(-9deg, 0deg);
               text-transform: uppercase;
               margin-right: 10px;
               display: flex;
               gap: 2px;
               padding: 0;
               margin: 0;
               justify-content: center;
               align-items: center;
            }

            .credits::before {
               content: "";
               position: absolute;
               left: 1px;
               right: 1px;
               top: 1px;
               bottom: 1px;
               border-radius: 4px;
               background-color: #0E2029;
               z-index: -1;
               transition: 200ms
            }

            .credits::after {
               content: attr(data);
               font-size: 16px;
               background: linear-gradient(to left, #00FFA3, #DC1FFF);
               -webkit-background-clip: text;
               color: #fff;
               transition: 200ms
            }

         }

      }
   }

   .CountDownWrapper {
      width: fit-content;
      //margin-left: -15px;
      .countdown {
         width: fit-content;
         margin: 0 auto;
         margin-top: 10px;
         display: grid;
         grid-template-columns: repeat(3, 68px);
         gap: 2px;

         > div {
            .cercalBack {
               width: 82px;
               height: 82px;
               background-image: url('../../assets/images/backgrounds/countdown_circle-01.svg');
               background-position: top left;
               background-repeat: no-repeat;
               position: relative;
               scale: .7;
            }

            .cercal {
               width: 82PX;
               height: 82px;
               border-radius: 50%;
               /* border: 1.69px solid; */
               border-color: linear-gradient(143.92deg, #FAFF01 13.27%, #39FF96 61.67%);

               > span {
                  position: absolute;
                  width: 50px;
                  top: 29%;
                  left: 20%;
                  margin-left: auto;
                  margin-right: auto;
                  //   font-family: $countdown-count-font;
                  font-family: "Audiowide-Regular";
                  // font-size: $countdown-count;
                  font-size: 21px;
                  letter-spacing: 2px;
                  background: -webkit-linear-gradient(45deg, #FAFF00, #00FFC2 80%);
                  -webkit-background-clip: text;
                  -webkit-text-fill-color: transparent;
                  text-align: center;
                  transform: skew(12deg, 0deg);
               }

            }

            > span {
               margin-top: -12px;
               text-transform: uppercase;
               font-size: 14.82px;
               font-weight: 400;
               display: block;
               text-align: center;
               font-family: "OdibeeSans-Regular";
               font-size: 16px;
               background: -webkit-linear-gradient(45deg, #FAFF00, #00FFC2 80%);
               -webkit-background-clip: text;
               -webkit-text-fill-color: transparent;
               transform: skew(12deg, 0deg);
            }

         }

         .timerWrapper {
            width: fit-content;
            position: absolute;
            top: 8px;
            margin-left: 1px;

            div {
               max-width: 80px;

               > svg {
                  max-width: 80px;

                  > :nth-child(1) {
                     stroke: unset !important;
                  }
               }
            }

         }
      }
   }

   .WinnerWrapper {
      margin-top: 40px;

      .Winner {
         position: relative;
         width: fit-content;
         height: 36px;
         background: linear-gradient(95.34deg, rgba(249, 255, 1, .3) 20%, rgba(47, 255, 158, .3) 45%);
         box-shadow: 0px 2px 0px rgba(0, 0, 0, 0.51);
         border-radius: 2px;
         transform: skew(-10deg, 0deg);
         border: 1px solid #2FFF9E;
         padding: 0 20px;
         display: inline-block;
         width: fit-content;
         margin-left: 13px;

         span {
            display: block;
            width: fit-content;
            font-size: 16px;
            font-weight: 700;
            text-transform: uppercase;
            padding-left: 16px;
         }

         img.WinnerBack {
            position: absolute;
            top: -34px;
            left: -12px;
         }

         img.WinnerTeamLogo {
            position: absolute;
            transform: skew(7deg, 0deg);
            width: 27px;
            left: -3px;
            top: -9px;
         }
      }
   }

   .CompletedMatchesWrapper {
      .buttonRow {
         bottom: 0;
         right: 0;
         text-align: right;
         // margin-bottom : 20px;
      }

      .row2 {
         // padding-top: 40px;
      }

      .UserInforLabel {
         text-align: right !important;
         margin-top: 20px;
         color: #95A6B7;
         font-family: "ObjectSans-Regular";
         font-size: 14px;

      }
   }

   .matchTypeWrapper {
      //margin-top: 13px;
      justify-content: left;
      margin-left: 0px !important;

      .SearchWrapper {
         width: 220px;
         position: relative;
         order: 5;

         input {
            height: 50px;
            //margin-top: 2px;
            border-radius: 3px;
            background-color: #111E33;
            color: #fff;
            border-color: #111E33;
         }

         input::placeholder {
            color: #95A6B7;
         }

         span {
            background-color: transparent;
            padding: 0;
            width: 30px;
            height: 30px !important;
            border: none;
            position: absolute;
            top: 12px;
            right: 0px;
         }
      }
   }

   .FooterRow {
      all: unset;
      display: flex;
      width: 100%;
      justify-content: space-between;

      .FooterCol2 {
         // display: flex;
         > .buttonRow {
            display: flex;
            justify-content: flex-end;
         }
      }

      .UserInforLabel {
         text-align: right;
         margin-bottom: 10px;
         margin-top: 0;
         color: #95A6B7;
         font-family: "ObjectSans-Regular";
         font-size: 14px;
      }
   }

}

.UpcomingMatchWrapper {
   // filter: grayscale(75%);

   .HeaderRow {
      display: flex;
      justify-content: space-between;
   }

   .LeftColWrapper {

   }

   .RightColWrapper {

   }

   .BackgroundImageFilter {
      width: 100%;
      height: 100%;
      position: absolute;
      left: 0;
      top: 0;
      z-index: -1;
      background-repeat: no-repeat;
      background-position: center;
      background-size: cover;
      filter: grayscale(75%);
   }

}

.LiveTournamentCard {
   .QRCotainer {
      position: relative;

      .QRCodeWrapperFloat {
         position: absolute;
         right: 0;
         z-index: 1;
         display: flex;
         gap: 10px;

         .QRCodeWrapper {
            width: 45px;
            height: 45px;
            display: flex;
            background: #0E2029;
            border-radius: 90px;
            justify-content: center;
         }
      }
   }

   h2 {
      line-height: 32px !important;
   }
}

.CompletedTournamentCard {
   .QRCotainer {
      position: relative;
      height: 1px;

      .QRCodeWrapper {
         position: absolute;
         right: 0;
         top: 14px;
         z-index: 1;
      }
   }

   h2 {
      line-height: 32px !important;
   }
}

.NoTournamentsFound {
   display: flex;
   justify-content: center;
   vertical-align: middle;

}

@media screen and (max-width: 1200px) {
   .tournamentsPage {
      h1 {
         // text-align: center;
         font-size: 45px;
         line-height: normal;
         margin: 0;
         line-height: 45px;
         text-align: left;
         margin-top: 0px;
      }

      .MatchWrapper {
         h2 {
            font-size: 28px;
         }
      }
   }
   .matchTypeWrapper {
      margin-top: 10px;
      justify-content: left;
      margin-left: 0px !important;
      gap: 5px;

      > * {
         justify-content: space-between;
      }

      .SearchWrapper {
         order: 1 !important;
      }
   }
   // .NoTournamentsFound{
   //     display: table-cell;
   //     vertical-align: middle;
   //     width: 100%;
   //     text-align: center;
   //     min-height: 200px;

   // }
}

@media screen and (max-width: 992px) {
   .tournamentsPage {
      h1 {
         // text-align: center;
         font-size: 40px;
         line-height: normal;
      }
   }
   .LiveTournamentCard {
      .Player {
         span {
            max-width: 120px;
            display: block;
            max-width: 120px !important;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
         }
      }
   }
   .pageWrapper {
      min-height: 450px !important;
   }
}

@media only screen and (max-width: 768px) {
   .tournamentsPage {
      .MatchWrapper:nth-child(1),
      .MatchWrapper:nth-child(2) {
         background-position: -93px;
      }
   }
   .matchTypeWrapper {
      padding: 0 !important;
      gap: 5px;
      margin: 0 auto !important;
      margin-top: 10px !important;

      > * {
         flex-grow: 1;
         width: fit-content;
      }

      > .SearchWrapper {
         width: 100% !important;
         padding: 0;
         margin-left: 1px !important;
         margin-bottom: 5px;
         // flex-grow: 2;
      }
   }
   .FooterRow {
      display: block !important;
      margin-top: 10px;

      .UserInforLabel {
         text-align: left !important;
         margin-top: 10px;
      }

      .buttonRow {
         justify-content: flex-start !important;
      }
   }
   .UpcomingMatchWrapper {
      .completedTag, .KnockoutTag, h2 {
         margin-left: 0px;
      }
   }

   .PlayerWrapper {
      .Player > span {
         display: block;
         max-width: 120px !important;
         white-space: nowrap;
         overflow: hidden;
         text-overflow: ellipsis;
      }

      .TwoPlayer > label {
         width: 48px !important;
      }
   }
   .FooterCol2 {
      .buttonRow {
         .creditsWrapper {
            margin: unset;
         }
      }
   }
   .UpcomingMatchWrapper {
      .buttonRow {
         float: left;
         width: fit-content !important;
      }
   }
   .buttonRow {
      float: left !important;
      width: fit-content !important;
   }
   .CompletedMatchesWrapper .UserInforLabel {
      padding-top: 8px;

   }

   .UserInforLabel {
      padding-top: 8px;
      text-align: left !important;
   }
   .CompletedTournamentCard {
      .FooterRow .UserInforLabel {
         padding-top: 8px;
         text-align: left !important;
      }
   }
}

@media only screen and (max-width: 576px) {
   .tournamentsPage {
      h1 {
         // text-align: center;
         font-size: 32px;
         line-height: normal;
         padding-left: 10px;
      }

      .MatchWrapper {
         padding-left: 20px;

         .buttonRow {
            left: 16px;
         }

         h2 {
            font-size: 22px;
            line-height: normal;
            margin-bottom: 0;
         }

         span {
            font-size: 16px;
         }

         // .completedTag, .KnockoutTag, h2{
         //     margin-left: -24px;
         // }
      }

      .FooterRow {
         display: block;
         margin-top: 10px;

         .UserInforLabel {
            text-align: left !important;
            margin-top: 10px;
         }

         .buttonRow {
            justify-content: flex-start !important;
         }
      }

      .UpcomingMatchWrapper {
         .completedTag, .KnockoutTag, h2 {
            margin-left: 0px;
         }
      }
   }
   .UserInforLabel {
      text-align: left !important;

   }
   .tournamentsPage {
      .WinnerWrapper {
         .Winner {
            span {
               font-size: 14px;
            }
         }
      }
   }
   .matchTypeWrapper {
      > label {
         width: calc(98% / 2) !important;
         margin-bottom: 4px !important;
      }

      > label:nth-child(2) {
         padding: 0 !important;
         margin: 0 !important;
      }
   }
   .PlayerWrapper {
      .Player > span {
         max-width: 90px !important;
         font-size: 12px !important;
      }
   }
   .MatchWrapper {
      .MobileHeaderRow1 {
         padding-left: 0 !important;
      }
   }
   .CompletedMatchesWrapper {
      .MobileHeaderRow1 {
         padding-left: 0 !important;
         margin-left: -8px !important;
      }
   }
   .tournamentsPage .CompletedMatchesWrapper .UserInforLabel {
      text-align: left !important;
   }
   .CountDownWrapper {
      margin: 0 auto;
      zoom: 1.5;
      // > div.row{
      //     zoom: 1.5;
      //     width: fit-content;
      //     margin: 0 auto;
      // }
   }
}

.radioButtonlbl span {
   padding-right: 27px !important;
}

@media only screen and (max-width: 476px) {
   .PlayerWrapper {
      .Player > span {
         max-width: 60px !important;
         font-size: 11px !important;
      }
   }
}

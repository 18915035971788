.OmniCreditsNavBar {
   width: fit-content;

   ul {
      background-color: #0A1724;
      margin: 13px 15px 0 15px;
      padding: 10px 13px;
      display: flex;
      border: solid 1px #1F2A36;
      border-radius: 6px;

      li {
         list-style: none;
         display: inline-block;
         margin-right: 8px;

         span {
            margin-left: 5px;
         }

         a {
            color: #fff;
            text-decoration: none;
            display: flex;
            padding: 0;
         }

         a.omniNavItem {
            position: relative;
         }

         a.omniNavItem:hover {
            cursor: pointer;
         }
      }
   }
}

@media only screen and (max-width: 1200px) {
   .OmniCreditsNavBar {
      width: fit-content;

      ul {
         background-color: #0A1724;
         margin: 13px 15px 0 15px;
      }
   }
}

@media only screen and (max-width: 768px) {
   .OmniCreditsNavBar {
      width: fit-content !important;
      margin: 0 auto;
   }
}

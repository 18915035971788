body {
   background-color: #010E14;
}

@media (min-width: 1440px) {
   .midContent {
      width: 100%;
      margin-top: 40px;
      margin-left: auto;
      margin-right: auto;
      padding-right: 0px;
      padding-left: 0px;
   }
}

.loginLogo {
   width: 197px;
   height: 54px;
}

.LoginFont {
   font-family: 'Rubik-medium', Helvetica, Arial, sans-serif;
   font-size: 30px;
   color: #FFFFFF;
}

@media (max-width: 1200px) {
   .loginLogo {
      width: 150px;
   }

   .LoginFont {
      font-family: 'Rubik-medium', Helvetica, Arial, sans-serif;
      font-size: 25px;
      color: #FFFFFF;
   }
}

@media (max-width: 768px) {
   .loginLogo {
      width: 120px;
   }

   .LoginFont {
      font-family: 'Rubik-medium', Helvetica, Arial, sans-serif;
      font-size: 20px;
      color: #FFFFFF;
   }
}

.midContent {
   width: 100%;
   height: auto;
   max-width: 1440px;
   /*padding-top: 30px;
   padding-right: 20px;
   padding-left: 20px;
   padding-bottom: 30px;*/
   margin-left: auto;
   margin-right: 0px;
   margin-bottom: 150px;
}

.midContentTitle {
   font-family: 'Rubik-medium', Helvetica, Arial, sans-serif;
   font-size: 48px;
   text-decoration: none;
   text-align: center;
   margin-left: auto;
   font-weight: bold;
   color: hsla(0, 0%, 100%, 1.00);
}

.midContent2 {
   width: 100%;
   max-width: 1440px;
   padding-top: 10px;
   padding-right: 20px;
   padding-left: 20px;
   padding-bottom: 10px;
   margin-right: auto;
   margin-left: auto;
}

.midContent2Title {
   font-family: 'Rubik-medium', Helvetica, Arial, sans-serif;
   font-size: 20px;
   text-align: left;
   font-weight: 600;
   padding-top: 0px;
   width: 0%;
}

.bold {
   font-weight: 600;
   color: #FFFFFF;
}

.smallBtn2 {
   background-color: #000000;
   padding-top: 10px;
   padding-right: 20px;
   padding-bottom: 10px;
   padding-left: 20px;
   color: #fff;
   font-size: 15px;
   border-radius: 20px;
   border: 4px solid hsla(0, 0%, 100%, 1.00);
   line-height: 14px;
}

.left-menu li .active {
   /*background-color: #AB47BC!important;*/
   color: #fff !important;
}

.customBox {
   margin-top: 1%;
}

.font30Bold {
   font-size: 30px;
   line-height: 21px;
   font-family: 'Rubik-Medium', Helvetica, Arial, sans-serif;
}

.textf {
   border: 1px solid #0f2e46;
   background-color: #0b1e2c !important;
   height: 40px;
   padding: 10px;
   font-size: 15px;
   color: #fff;
   width: 100%;
}

.bigBtn {
   cursor: pointer;
   background-color: #000000;
   padding: 10px 20px;
   color: #fff;
   font-size: 15px;
   border-radius: 20px;
   border: 2px solid hsla(0, 0%, 100%, 1.00);
   line-height: 10px;
   width: 175px;
   height: 42px;
   font-family: 'Rubik-Medium', Helvetica, Arial, sans-serif;
}

.bigBtn:disabled {
   pointer-events: none !important;
}

.bigBtn:hover {
   background-color: #14d0e6;
   color: #000000;
}

.linka {
   color: #14d0e6;
   text-decoration: none !important;
   background-color: transparent;
   border: transparent;
}

.linka:hover {
   color: #19EA17FF;
}

/*sign up password strength styles*/
.passwordStrength {
   width: 100%;
   height: 10px;
   background-color: #ddd;
   position: relative;
   margin-bottom: 10px;
}

.strengthBar {
   position: absolute;
   top: 0;
   left: 0;
   height: 100%;
   background-color: #f00;
   width: 0%;
   transition: width 0.3s;
}

.strengthLabel {
   position: relative;
   top: 20px;
   right: 0;
   font-size: 14px;
}

.uiText {
   padding-left: 15px;
}

.uiText li {
   font-size: 13px;
   color: #939a9f;
}

.ChopraBg {
   width: 600px;
   height: 100vh;
   right: 0px;
   top: 0px;
   background-image: url(https://storage.googleapis.com/meta11/serviceplatform/Ajeed-Chopra.png);
   background-size: auto 100%;
   background-repeat: no-repeat;
   mix-blend-mode: normal;
   position: absolute;
   background-position: right top;
}

/*new design fonts march-2023*/

.LogoTitle1 {
   font-size: 50px;
   width: 100%;
   /*float: left; */
}

.LogoTitle1 .text {
   font-family: 'SolinaExBI', Helvetica, Arial, sans-serif !important;
   text-transform: uppercase;
   background: linear-gradient(to right, #17EA85, #14D0E6, #17EA85, #14D0E6);
   -webkit-text-fill-color: transparent;
   -webkit-background-clip: text;
   line-height: 55px;
}

.LogoTitle2 {
   font-size: 50px;
   font-family: 'ObjSans-HeavySlanted', Helvetica, Arial, sans-serif !important;
   color: #E61479;
}

.TopBotGaps {
   margin-top: 160px;
}

.group {
   position: relative;
   margin-bottom: 45px;
}

input[type=text] {
   width: 100%;
   box-sizing: border-box;
   border: solid 1px #3E5168;
   height: 67px;
   border-radius: 6px;
   background-color: #010e14;
   background-image: -webkit-linear-gradient(0deg, rgba(4, 21, 29, 1.00) 0%, rgba(11, 28, 53, 0.00) 100%);
   background-image: -moz-linear-gradient(0deg, rgba(4, 21, 29, 1.00) 0%, rgba(11, 28, 53, 0.00) 100%);
   background-image: -o-linear-gradient(0deg, rgba(4, 21, 29, 1.00) 0%, rgba(11, 28, 53, 0.00) 100%);
   background-image: linear-gradient(90deg, rgba(4, 21, 29, 1.00) 0%, rgba(11, 28, 53, 0.00) 100%);
   max-width: 100%;
   color: #ffffff;
   padding-left: 20px;
   padding-right: 20px;
   font-size: 16px;
   font-family: 'SolinaLight', Helvetica, Arial, sans-serif;
}

input[type=text]:focus {
   border-style: solid;
   border-width: 1px;
   /*border-image: linear-gradient(45deg, #17EA85, #14D0E6, #17EA85, #14D0E6, #17EA85, #14D0E6) 1 !important;*/
   border-radius: 6px !important;
   outline: none;
   border-color: #17EA85;
}


input[type=password] {
   display: block;
   background-image: -webkit-linear-gradient(0deg, rgba(4, 21, 29, 1.00) 0%, rgba(11, 28, 53, 0.00) 100%);
   background-image: -moz-linear-gradient(0deg, rgba(4, 21, 29, 1.00) 0%, rgba(11, 28, 53, 0.00) 100%);
   background-image: -o-linear-gradient(0deg, rgba(4, 21, 29, 1.00) 0%, rgba(11, 28, 53, 0.00) 100%);
   background-image: linear-gradient(90deg, rgba(4, 21, 29, 1.00) 0%, rgba(11, 28, 53, 0.00) 100%);
   max-width: 495px;
   color: #ffffff;
   padding-left: 20px;
   padding-right: 50px;
   font-size: 16px;
   /*font-family: 'SolinaLight';*/
   width: 100%;
   box-sizing: border-box;
   border: solid 1px #3E5168;
   height: 67px;
   border-radius: 6px;
   background-color: #04151D;
   padding-bottom: 22px;
   padding-top: 35px;
   line-height: 10px;
   /*background-image: url("https://storage.googleapis.com/meta11/serviceplatform/pw-eye.png");*/
   background-repeat: no-repeat;
   background-position: 96% center;
}

input[type=password]:focus {
   border-style: solid;
   border-width: 1px;
   /*border-image: linear-gradient(45deg, #17EA85, #14D0E6, #17EA85, #14D0E6, #17EA85, #14D0E6) 1 !important;*/
   border-radius: 6px !important;
   outline: none;
   border-color: #17EA85;
}

/* LABEL */
.group label {
   color: #95A6B7;
   font-size: 16px;
   font-weight: normal;
   position: absolute;
   pointer-events: none;
   left: 16px;
   top: 25px;
   transition: 0.2s ease all;
   -moz-transition: 0.2s ease all;
   -webkit-transition: 0.2s ease all;
}

/* active state */
input[type=password]:focus ~ label, input[type=password]:valid ~ label {
   top: 10px;
   font-size: 12px;
   color: #95A6B7;
   padding-left: 6px;

}


::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
   color: #95A6B7;
   opacity: 1; /* Firefox */
}

:-ms-input-placeholder { /* Internet Explorer 10-11 */
   color: #95A6B7;
}

::-ms-input-placeholder { /* Microsoft Edge */
   color: #95A6B7;
}

.LoginButton {
   background: linear-gradient(95.34deg, #FAFF00 6.28%, #00FFC2 111.13%);
   box-shadow: 0px 2px 0px rgba(0, 0, 0, 0.51);
   border-radius: 4px;
   transform: skew(-10deg, 0deg);
   border: 1px solid #2FFF9E;
   /*border-image-source: linear-gradient(103.05deg, #2FFF9E 7.22%, #F9FF01 93.09%);*/
   font-family: "ObjectSans-Heavy", Helvetica, Arial, sans-serif;
   font-size: 16px;
   line-height: 18px;
   text-align: center;
   letter-spacing: -0.01em;
   text-transform: uppercase;
   padding: 14px 27px;
   width: 100%;
   height: 54px;
   color: #03161F;
}

.LoginButton:hover {
   background: linear-gradient(95.34deg, #00FFC2 6.28%, #FAFF00 111.13%);
}

.LoginButton:visited {
   background: linear-gradient(95.34deg, #B0FFCA 6.28%, #F2FF7E 111.13%);
}

.LoginButton:disabled,
.LoginButton[disabled] {
   cursor: not-allowed;
   color: #000000;
   background: linear-gradient(95.34deg, #FAFF00 6.28%, #00FFC2 111.13%);
}

.BlackSkBtn {
   background-color: #0E2029;
   box-shadow: 0px 2px 0px rgba(0, 0, 0, 0.51);
   border-radius: 4px;
   transform: skew(-10deg, 0deg);
   border: 1px solid rgba(0, 255, 194, 0.09);
   font-family: "ObjectSans-Heavy", Helvetica, Arial, sans-serif;
   font-size: 14px;
   line-height: 18px;
   /* identical to box height, or 129% */
   text-align: center;
   letter-spacing: -0.01em;
   text-transform: uppercase;
   padding: 14px 27px;
   font-weight: 700;
   height: 54px;


}

.BlackSkBtn text {

   background-image: linear-gradient(0deg, #FAFF01, #39FF96);
   background-clip: text;
   color: transparent;
}

.OrSpace {
   border-top: 1px solid #162231;
   text-align: center;
   height: 1px;
   margin-top: 27px;
   margin-bottom: 30px;
}

.OrSpace text {
   color: #95A6B7 !important;
   width: 41px;
   background-color: #010E14;
   padding: 3px;
   margin-top: -10px;
   margin-bottom: 0px;
   position: absolute;
}

.Font16 {
   font-family: 'ObjectSans-Regular', Helvetica, Arial, sans-serif;
   font-size: 16px;
   color: #95A6B7;
   line-height: 18px;
}

.Font16 a {
   background-image: linear-gradient(0deg, #17EA85, #14D0E6);
   background-clip: text;
   color: transparent;
}

.Font18 {
   font-family: 'ObjectSans-Regular', Helvetica, Arial, sans-serif;
   font-size: 18px;
   color: #95A6B7;
   line-height: 18px;
}

.CorrectIcon {
   background-image: url(https://storage.googleapis.com/meta11/serviceplatform/green-correct-icon.png);
   width: 100px;
   height: 100px;
   margin-left: auto;
   margin-right: auto;
}

.Radial1 {
   width: 900px;
   height: 900px;
   left: -515px;
   top: -350px;
   background: radial-gradient(32.65% 32.65% at 50% 50%, #04F0FF 0%, rgba(20, 208, 230, 0) 100%);
   mix-blend-mode: normal;
   position: absolute;
   opacity: 0.14;
   z-index: -1;
}

.Radial2 {
   width: 400px;
   height: 600px;
   right: 0px;
   top: 100px;
   background: radial-gradient(32.65% 32.65% at 110% 50%, #E61479 0%, rgba(230, 20, 20, 0) 100%);
   mix-blend-mode: normal;
   position: absolute;
   opacity: 0.44;
   z-index: -1;
}

@media only screen and (max-width: 768px) {
   .LogoTitle1 {
      font-size: 26px;
      line-height: 30px;
   }
   .LogoTitle1 .text {
      line-height: 30px;
   }
   .TopBotGaps {
      margin-top: 70px;
   }
   .Font18 {
      font-size: 14px;
      line-height: 16px;
   }
}

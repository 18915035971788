@import '../../../scss/globals/global';
@import '../../../scss/utilities/mixins';

.LeaderBoard {
   .WinPrizesWrapper {
      display: block;
   }

   .LeaderboardView {
      width: 96%;
      margin: 0 auto;

      table {
         width: 100%;

         thead {
            background-color: #131F30;
            border: solid 1px #1F2A36;

            tr {
               th {
                  padding: 10px;
               }

               th:nth-child(1) {
                  text-align: left;
                  padding-left: 20px;
               }
            }
         }

         tbody {
            background-color: #131F30;
            border: solid 1px #1F2A36;

            tr {
               td {
                  padding-top: 10px;
                  padding-bottom: 10px;

                  img {
                     margin-right: 5px;
                  }
               }

               td:nth-child(1) {
                  text-align: left;
                  padding-left: 20px;
               }
            }

            tr:nth-child(odd) {
               // background-color: #131F30;
               background-color: #0d1829;
            }

            tr.ActiveRow {
               background-image: linear-gradient(to bottom, #FAFF01 0%, #39FF96 100%);

               td *, td {
                  color: #000;
                  font-weight: bold;
               }
            }
         }
      }

      .ProfileThumb {
         width: 36px;
         height: 36px;
         border-radius: 50%;
      }
   }
}

.TournamentSubText {
   font-weight: 400;
   font-family: 'Montserrat', sans-serif;
   font-size: 32px;
   line-height: 42px;
   text-transform: uppercase;
}

.MatchDetails {
   // padding-right: 24px !important;

   h2 {
      font-family: 'ObjectSans-Regular';
      text-align: center;
      font-size: 24px;
      text-transform: uppercase;
      font-weight: 700;
      font-style: italic;
   }

   .WinPrizes {
      position: relative;
      margin-bottom: 20px;
      border: solid 2px #112c4dcc;
      border-radius: 6px;
      background-color: #112338;
      // box-shadow: 0 8px 6px -6px #091627;

      -webkit-box-shadow: inset 0px 0px 25px 15px rgb(14 30 51);
      -moz-box-shadow: inset 0px 0px 25px 15px rgb(14 30 51);
      box-shadow: inset 0px 0px 25px 15px rgb(14 30 51);

   }

   .TournamentView {
      .tournamentContainer {
         // max-width: 620px;
         max-width: fit-content;
         margin: 0 auto;
         overflow-y: auto;
      }

      ul {
         li.TeamItem {
            > div {
               position: relative;
               transform: skew(-12deg, 0deg);
               width: 142px;
               border: 1px solid;
               border-image-source: linear-gradient(99.98deg, rgba(213, 42, 177, 0.2) -2.72%, rgba(68, 91, 204, 0.2) 128.13%);
               background: radial-gradient(87.12% 337.52% at 31.82% 50%, rgba(213, 42, 177, 0.2) 0%, rgba(68, 91, 204, 0.45) 100%);
               border-radius: 4px;

               span {
                  position: absolute;
                  top: -5px;
                  left: -8px;
                  display: block;
                  width: 30px;
                  height: 36px;
                  background-image: url("../../../assets/images/match_tree_img_node_back.webp");
                  background-repeat: no-repeat;
                  -webkit-box-shadow: 0px 0px 7px 0px rgba(0, 0, 0, 0.83);
                  -moz-box-shadow: 0px 0px 7px 0px rgba(0, 0, 0, 0.83);
                  box-shadow: 0px 0px 7px 0px rgba(0, 0, 0, 0.83);
                  border-radius: 4px;

                  img {
                     width: 32px;
                     height: 36px;
                  }
               }

               label {
                  font-size: 12px;
                  font-family: $sectionContentFont;
               }
            }
         }
      }

      .tournamentBrackets {
         display: flex;
         flex-direction: row;
         list-style-type: none;
         //background: #fdfdfd;
         margin-bottom: 50px;
         width: fit-content;
         padding-left: 20px;
         padding-right: 20px;
      }

      .tournamentContainer::-webkit-scrollbar-thumb {
         border-radius: 10px;
         -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, .3);
      }


      .bracket {
         padding-left: 0;
         display: flex;
         margin: 0;
         padding: 30px 0;
         flex-grow: 1;
         flex-direction: column;
         justify-content: space-around;
         list-style-type: none;
         // border-right: 1px dashed #ccc;
         flex: 1;
      }

      .bracket:nth-child(2) {
         padding-left: 30px;
      }

      .bracket:nth-child(3) {
         padding-left: 30px;

         .TeamItem:nth-of-type(odd) {
            // height: 140px !important;
         }
      }

      .TeamItem {
         // background-color: #555;
         padding: .5rem;
         display: block;
         margin: 0.05rem 10px;
         position: relative;
         vertical-align: middle;
         line-height: 2;
         text-align: center;
      }

      .TeamItem:after {
         content: '';
         border-color: #445bcca3;
         border-width: 2px;
         position: absolute;
         display: block;
         width: 30px;
         right: -20px;
      }

      .TeamItem:nth-of-type(odd):after {
         border-right-style: solid;
         border-top-style: solid;
         height: 100%;
         top: 50%;
      }

      .TeamItem:nth-of-type(even):after {
         border-right-style: solid;
         border-bottom-style: solid;
         height: 100%;
         top: -50%;
      }

      .TeamItem:before {
         content: '';
         border-top: 2px solid rgba(68, 91, 204, 0.6392156863);
         position: absolute;
         height: 2px;
         width: 39px;
         left: -31px;
         top: 50%;
      }

      .bracket-2 {
         .TeamItem:nth-of-type(odd):after {
            height: 200%;
            top: 50%;
         }

         .TeamItem:nth-of-type(even):after {
            height: 200%;
            top: -150%;
         }
      }

      .bracket-3 {
         .TeamItem:nth-of-type(odd):after {
            height: 350%;
            top: 50%;
         }

         .TeamItem:nth-of-type(even):after {
            height: 350%;
            top: -300%;
         }
      }

      .bracket-4 {
         .TeamItem:nth-of-type(odd):after {
            height: 700%;
            top: 50%;
         }

         .TeamItem:nth-of-type(even):after {
            height: 700%;
            top: -650%;
         }
      }

      .bracket:first-of-type {
         .TeamItem:before {
            display: none;
         }
      }

      .bracket-4 {
         .TeamItem:after {
            display: none;
         }
      }

      .bracket:last-of-type {

         .TeamItem:after {
            display: none;
         }
      }

      .TeamItem time {
         display: inline-block;
         background-color: #dbdbdb;
         font-size: .8rem;
         padding: 0 .6rem;
      }


   }

}

.FlexDataTable {
   // width: calc(100% - 24px);
   width: 100%;
   min-width: 798px;
   padding-left: 0;
   padding-right: 0;
   margin-left: 0px !important;
   margin-right: 0px !important;
   border: solid 1px #1F2A36;

   .FlexTableHeader {
      display: flex;
      gap: 5px;
      flex-flow: row wrap;
      background-color: #131F30;
      border: solid 1px #1F2A36;

      .FlexTableTh {
         // border: solid 1px #ccc;
         padding: 8px 10px;
         text-align: left;
         font-weight: bold;
      }

      .FlexTableTh:nth-child(1) {
         flex-shrink: 0;
         // flex-grow: 2;
         width: calc(30% - 50px) !important;
         min-width: 240px;
         // background-color: gray;
      }


      .FlexTableTh:nth-child(3),
      .FlexTableTh:nth-child(4),
      .FlexTableTh:nth-child(5),
      .FlexTableTh:nth-child(6) {
         min-width: 90px;
         text-align: left !important;
         width: calc((98% - 350px) / 4);
      }

      .FlexTableTh:nth-child(2) {
         min-width: 90px;
         text-align: left !important;
      }

      .FlexTableTh:nth-child(6) {
         text-align: center !important;
      }

      // .FlexTableTh:nth-child(5) {
      //    min-width: 85px;
      //    width: 85px;
      // }
   }

   .FlexTableBody {

      .FlexTableBodyRow {
         display: flex;
         gap: 5px;
         flex-flow: row wrap;
         padding-top: 4px;
         padding-bottom: 4px;
         margin-bottom: 2px;
         // padding-right: 15px;

         .FlexTableTD {
            // border: solid 1px #ccc;
            padding: 4px 10px;
            text-align: left;
            //display: flex;
            font-size: 14px;
            gap: 5px;
            align-self: center;
            width: 95px;

            label, span {
               margin-left: 2px !important;
            }

            span {
               margin-left: 4px !important;
               display: flex;
               height: 34px;
            }

            * {
               align-self: center;
            }

            label {
               display: none;
            }

            .NoPosition {
               width: 27px;
            }

         }

         .NoPositionBug {
            margin-left: -20px;
         }

         .FlexTableTD:nth-child(1) {
            display: flex;
            flex-shrink: 0;
            align-items: center;
            // flex-grow: 1;
            // width: 35%;
            width: calc(30% - 50px) !important;
            min-width: 240px;
            // background-color: gray;
            .Number {
               width: 35px;
               text-align: center;
               line-height: 35px;
            }

            .Thumb {
               width: 50px;
               justify-content: center;

               .ProfileThumb {
                  width: 36px;
                  height: 36px;
                  border-radius: 50%;
               }
            }

            .Name {
               width: 170px;
               white-space: nowrap;
               overflow: hidden;
               text-overflow: ellipsis;
               line-height: 35px;
               padding-left: 5px;
            }
         }

         .FlexTableTD:nth-child(2),
         .FlexTableTD:nth-child(3) {
            ///min-width: 90px;
            min-width: 90px;
            width: 90px;

            * {
               align-self: center;
            }

            img {
               width: 24px;
               height: 24px;
               margin: 0;
               float: left;
            }
         }

         .FlexTableTD:nth-child(4) {
            //min-width: 65px;
            min-width: 85px;
            width: 85px;

            img {
               width: 24px;
               height: 24px;
               margin: 0;
               float: left;
            }
         }

         .FlexTableTD:nth-child(2) {
            display: flex !important;

            > div:nth-child(1) {
               width: 24px !important;
               max-width: 24px;
            }

            // min-width: 85px;
            // width: 85px;
            // > div{
            //    display: grid !important;
            //    grid-template-columns: 24px 1fr;
            // }
         }


         .FlexTableTD:nth-child(3),
         .FlexTableTD:nth-child(4),
         .FlexTableTD:nth-child(5),
         .FlexTableTD:nth-child(6) {
            min-width: 90px;
            text-align: left !important;
            width: calc((98% - 350px) / 4);
         }

         .FlexTableTD:nth-child(2) {
            min-width: 70px;
         }

         .FlexTableTD:nth-child(5) {
            b {
               color: #01a9ad;
            }
         }

         .FlexTableTD:nth-child(6) {
            div:nth-child(2) {
               display: flex;
               color: #ffffff79;
               align-items: center;

               span {
                  color: #fff;
                  padding-left: 5px;
               }
            }
         }
      }

      .FlexTableBodyRow.LoaderRow {
         justify-content: center;
      }

      .FlexTableBodyRow:nth-child(even) {
         // background-color: #0d1829;
         background-color: #131f32;
      }

      .FlexTableBodyRow.ActiveRow {
         background-image: linear-gradient(to bottom, #FAFF01 0%, #39FF96 100%);

         * {
            color: #000;
            font-family: inherit;
            font-size: 16px;
            // font-weight: bold;
         }

         .FlexTableTD:nth-child(3), .FlexTableTD:nth-child(4) {
            //padding: 4px 16px;
         }

         .FlexTableTD:nth-child(5) {
            span {
               display: block;
               width: 100%;
               text-align: center;
            }
         }

         .FlexTableTD:nth-child(6) {
            > div {
               div {
                  color: #000;

                  span {
                     color: #000;
                     font-weight: 500;
                  }
               }
            }
         }

         .Thumb {
            img {
               width: 36px !important;
               height: 36px !important;
            }
         }

         >
         img {
            width: 24px !important;
            height: 24px !important;
            margin: 0;
            float: left;
         }

         .BonusPointCell, .BonusPointCell2 {
            height: 26px !important;

            > div {
               span {
                  img {
                     width: 12px !important;
                     height: 12px !important;
                     float: none;
                  }
               }

            }
         }


      }
   }
}

.FlexDataTableContainer {
   width: 100%;
   overflow-y: auto;
   padding-bottom: 10px;
   margin-bottom: 15px;
}

@media only screen and (min-width: 1200px) {
   .MatchDetails {
      //  padding-right: 24px !important;
   }
   .LeaderBoard {
      padding-right: 24px !important;
   }

}

@media only screen and (max-width: 1200px) {
   .MatchDetails {
      padding-right: 0 !important;
   }
   .LeaderboardView {
      width: 100% !important;
   }
   .FlexTableTh:nth-child(1) {
      flex-shrink: 0;
      // flex-grow: 2;
      width: calc(60% - 30px) !important;
      //min-width: 231px;
      // background-color: gray;
   }
   .FlexTableTd:nth-child(1) {
      flex-shrink: 0;
      // flex-grow: 2;
      width: calc(60% - 30px) !important;
      //min-width: 231px;
      // background-color: gray;
   }
   .FlexTableTD:nth-child(4) {
      min-width: fit-content !important;
   }
}


@media only screen and (min-width: 769px) {
   .LeaderBoard {
      .WinPrizesWrapper {
         padding-top: 0;

         // .FlexDataTable {
         //    .FlexTableHeader {
         //       .FlexTableTh:nth-child(4), .FlexTableTh:nth-child(3), .FlexTableTh:nth-child(5) {
         //          display: block;
         //       }
         //    }

         //    .FlexTableBody {
         //       .FlexTableBodyRow {
         //          .FlexTableTD:nth-child(4), .FlexTableTD:nth-child(3), .FlexTableTD:nth-child(5) {
         //             label {
         //                display: none;
         //             }
         //          }
         //       }
         //    }
         // }
      }
   }
}

@media only screen and (max-width: 768px) {
   .LeaderBoard {
      .WinPrizesWrapper {
         padding-top: 0;

         // .FlexDataTable {
         //    .FlexTableHeader {
         //       .FlexTableTh:nth-child(4), .FlexTableTh:nth-child(3), .FlexTableTh:nth-child(5) {
         //          display: none !important;
         //       }

         //       .FlexTableTh:nth-child(1) {
         //          width: 66% !important;
         //       }

         //       .FlexTableTh:nth-child(2) {
         //          width: 28% !important;
         //       }
         //    }

         //    .FlexTableBody {
         //       .FlexTableBodyRow {
         //          padding-top: 8px;
         //          padding-bottom: 8px;

         //          .FlexTableTD:nth-child(4), .FlexTableTD:nth-child(3), .FlexTableTD:nth-child(5) {
         //             width: 30%;
         //             display: block;

         //             label {
         //                font-weight: bold;
         //                display: block;
         //                width: 100%;
         //                margin-bottom: 4px;
         //             }

         //             img {
         //                margin-right: 5px;
         //             }
         //          }

         //          .FlexTableTD:nth-child(1) {
         //             width: 66% !important;
         //          }

         //          .FlexTableTD:nth-child(2) {
         //             width: 28% !important;
         //          }
         //       }
         //    }
         // }

         // .FlexTableTD:nth-child(1) {
         //    .Number {
         //       width: 35px;
         //    }

         //    .Thumb {
         //       width: 45px;

         //       .ProfileThumb {
         //          width: 36px;
         //          height: 36px;
         //       }
         //    }

         //    .Name {
         //       width: 170px;
         //       white-space: nowrap;
         //       overflow: hidden;
         //       text-overflow: ellipsis;
         //       line-height: 35px;
         //       padding-left: 5px;
         //    }
         // }

      }
   }
}

@media only screen and (max-width: 576px) {

   // .FlexDataTable {
   //    .FlexTableHeader {
   //       .FlexTableTh:nth-child(4), .FlexTableTh:nth-child(3), .FlexTableTh:nth-child(5) {
   //          display: none !important;
   //       }

   //       .FlexTableTh:nth-child(1) {
   //          width: 65% !important;
   //       }

   //       .FlexTableTh:nth-child(2) {
   //          width: 28% !important;
   //       }
   //    }

   //    .FlexTableBody {
   //       .FlexTableBodyRow {
   //          padding-top: 8px;
   //          padding-bottom: 8px;

   //          .FlexTableTD:nth-child(4), .FlexTableTD:nth-child(3), .FlexTableTD:nth-child(5) {
   //             width: 30%;
   //             display: block;

   //             label {
   //                font-weight: bold;
   //                display: block;
   //                width: 100%;
   //                margin-bottom: 4px;
   //             }

   //             img {
   //                margin-right: 5px;
   //             }
   //          }

   //          .FlexTableTD:nth-child(1) {
   //             min-width: 235px !important;

   //             .Name {
   //                max-width: 100px;
   //                white-space: nowrap;
   //                overflow: hidden;
   //                text-overflow: ellipsis;
   //             }
   //          }

   //          .FlexTableTD:nth-child(2) {
   //             width: 70px !important;
   //             max-width: 70px !important;
   //             min-width: 70px !important;
   //          }
   //       }
   //    }
   // }

}

@media only screen and (max-width: 468px) {
   // .FlexTableTD {
   //    min-width: 30% !important;
   // }
   // .FlexTableHeader {
   //    .FlexTableTh {
   //       min-width: unset !important;
   //       width: 48%;
   //    }
   // }

   // .FlexDataTable {
   //    .FlexTableHeader {
   //       .FlexTableTh:nth-child(4), .FlexTableTh:nth-child(3), .FlexTableTh:nth-child(5) {
   //          display: none !important;
   //       }

   //       .FlexTableTh:nth-child(1) {
   //          // width: 65% !important;
   //       }

   //       .FlexTableTh:nth-child(2) {
   //          width: 28% !important;
   //       }
   //    }

   //    .FlexTableBody {
   //       .FlexTableBodyRow {
   //          padding-top: 8px;
   //          padding-bottom: 8px;

   //          .FlexTableTD:nth-child(4), .FlexTableTD:nth-child(3), .FlexTableTD:nth-child(5) {
   //             width: 30%;
   //             display: block;

   //             label {
   //                font-weight: bold;
   //                display: block;
   //                width: 100%;
   //                margin-bottom: 4px;
   //             }

   //             img {
   //                margin-right: 5px;
   //             }
   //          }

   //          .FlexTableTD:nth-child(1) {
   //             min-width: 160px !important;

   //             .Thumb {
   //                width: fit-content;
   //             }

   //             .Name {
   //                max-width: 100px;
   //                white-space: nowrap;
   //                overflow: hidden;
   //                text-overflow: ellipsis;
   //             }
   //          }

   //          .FlexTableTD:nth-child(2) {
   //             width: 70px !important;
   //             max-width: 70px !important;
   //             min-width: 70px !important;
   //          }
   //       }
   //    }
   // }

}

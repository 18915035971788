@import '../../scss/utilities/variables';

.ProfilePane {
   /*width: 100%;*/
   background-image: -webkit-linear-gradient(0deg, #1A263980, #0B1C3580);
   background-image: -moz-linear-gradient(0deg, #1A263980, #0B1C3580);
   background-image: -o-linear-gradient(0deg, #1A263980, #0B1C3580);
   background-image: linear-gradient(0deg, #1A263980, #0B1C3580);
   border: 1px solid #FFFFFF0F;
}

.flatBtn {
   box-sizing: border-box;
   width: auto;
   height: 42px;
   /* Turquiose/blue for secondary buttons */
   background: #111E33;
   /* white 6% stroke */
   border: 1px solid rgba(255, 255, 255, 0.06);
   border-radius: 2px;
   font-family: 'ObjectSans-Regular';
   font-size: 16px;
   line-height: 22px;
   text-transform: uppercase;
   cursor: pointer;
}

.flatBtn:hover {
   background-color: #1A4152;
}

.Text24Solina {
   font-size: 24px;
   font-family: $bodyTextMainFont;
   text-transform: uppercase;
   font-weight: 700;
   font-style: italic;
}

.Text20Solina {
   font-size: 20px;
   font-family: 'SolinaLight';
   color: #ffffff;
}

.Text32Solina {
   font-size: 32px;
   font-family: 'SolinaLight';
   color: #ffffff;
   text-transform: uppercase;
}

.text16OS {
   font-size: 16px;
   color: $textGray2;
   font-family: 'ObjectSans-Regular';
   line-height: 20px;
}

.text18OS {
   font-size: 18px;
   color: $textGray2;
   font-family: 'ObjectSans-Regular';
}

.text14OS {
   font-size: 14px;
   color: $textGray2;
   font-family: 'ObjectSans-Regular';
   line-height: 15px;
}

.text14OSI {
   font-size: 14px;
   color: #ffffff;
   font-family: 'ObjectSans-Regular';
   line-height: 18px;
   text-transform: uppercase;
   font-style: italic;
   font-weight: 700;
}

.text12OS {
   font-size: 12px;
   color: $textGray2;
   font-family: 'ObjectSans-Regular';
}

.text16OSW {
   font-size: 16px;
   color: #ffffff;
   font-family: 'ObjectSans-Regular';
   letter-spacing: -0.01em;
   line-height: 20px;
}

.text16OSW .g {
   font-size: 16px;
   color: #17EA85;
   font-family: 'ObjectSans-Regular';
   letter-spacing: -0.01em;
}

.text18OSW {
   font-size: 18px;
   color: #ffffff;
   font-family: 'ObjectSans-Regular';

   line-height: 20px;
   font-weight: 100;
}

.text28SolinaIta {
   font-size: 28px;
   color: #ffffff;
   font-family: 'SolinaExBI';
   letter-spacing: -0.01em;
   line-height: 32px;
   font-style: italic;
}

.text24SolinaItaBold {
   font-size: 24px;
   color: $textGray2;
   font-family: 'SolinaExBI';
   line-height: 30px;
   font-style: italic;
}

.SocialSmallIcons {
   border: 1px solid #FFFFFF0F;
   width: 32px;
   height: 32px;
   border-radius: 16px;
   padding-top: 8px;
   margin-left: 14px;
   padding-left: 8px;
}

.OverviewBox {
   width: 100%;
   background-image: -webkit-linear-gradient(0deg, #1A263980, #0B1C3580);
   background-image: -moz-linear-gradient(0deg, #1A263980, #0B1C3580);
   background-image: -o-linear-gradient(0deg, #1A263980, #0B1C3580);
   background-image: linear-gradient(0deg, #1A263980, #0B1C3580);
   border: 1px solid #FFFFFF0F;
   padding: 12px 12px 12px 5px;
}

.borderTop1 {
   border-top: 1px solid;
   border-image: linear-gradient(to right, #f3dd1b00, #F3DE1B, #f3dd1b00) 1 0 0 0;
}

.borderTop2 {
   border-top: 1px solid;
   border-image: linear-gradient(to right, #f3dd1b00, #2DFEE5, #f3dd1b00) 1 0 0 0;
}

.borderTop3 {
   border-top: 1px solid;
   border-image: linear-gradient(to right, #f3dd1b00, #C668FF, #f3dd1b00) 1 0 0 0;
}


/**/
.BgKitsPatter {
   background-image: url(https://storage.googleapis.com/meta11/serviceplatform/BG_Kits_pattern.png);
   background-repeat: no-repeat;
   background-size: 100% auto;
}

/*blue borders*/
.borderTopBlue {
   border-top: 1px solid;
   border-image: linear-gradient(to right, #97d0ff, #97d0ff00) 1 0 0 0;
}

.borderLeftBlue {
   border-left: 1px solid;
   border-image: linear-gradient(to bottom, #97d0ff, #97d0ff00) 0 0 0 1;
}

.borderBotBlue {
   border-bottom: 1px solid;
   border-image: linear-gradient(to left, #97d0ff, #97d0ff00) 0 0 1 0;
}

.borderRightBlue {
   border-right: 1px solid;
   border-image: linear-gradient(to top, #97d0ff, #97d0ff00) 0 1 0 0;
}

/*blue borders*/
.borderTopYe {
   border-top: 1px solid;
   border-image: linear-gradient(to right, #F9EC35, #f9ec3500) 1 0 0 0;
}

.borderLeftYe {
   border-left: 1px solid;
   border-image: linear-gradient(to bottom, #F9EC35, #f9ec3500) 0 0 0 1;
}

.borderBotYe {
   border-bottom: 1px solid;
   border-image: linear-gradient(to left, #F9EC35, #f9ec3500) 0 0 1 0;
}

.borderRightYe {
   border-right: 1px solid;
   border-image: linear-gradient(to top, #F9EC35, #f9ec3500) 0 1 0 0;
}

/*Darfblue borders*/
.borderTopDblue {
   border-top: 1px solid;
   border-image: linear-gradient(to right, #3675FF, #3676ff00) 1 0 0 0;
}

.borderLeftDblue {
   border-left: 1px solid;
   border-image: linear-gradient(to bottom, #3675FF, #3676ff00) 0 0 0 1;
}

.borderBotDblue {
   border-bottom: 1px solid;
   border-image: linear-gradient(to left, #3675FF, #3676ff00) 0 0 1 0;
}

.borderRightDblue {
   border-right: 1px solid;
   border-image: linear-gradient(to top, #3675FF, #3676ff00) 0 1 0 0;
}

.OverviewBox {
   .smallText {
      font-size: 18px;
      color: $textGray2;
      font-family: 'ObjectSans-Regular';
      margin-left: 10px;
   }

   .mainText {
      font-size: 52px;
      font-family: 'SolinaExBI';
      color: #ffffff;
      line-height: 70px;
   }
}

.FavoUL {
   font-size: 16px;
   color: #ffffff;
   font-family: 'ObjectSans-Regular';
   margin-left: 10px;
   list-style-type: none;
   padding-left: 0px;

   li {
      margin-top: 20px;
      margin-bottom: 20px;
   }

   li:last-child {
      color: #17EA85;
   }
}


.FavoLi2 {
   margin-top: 40px;
   margin-bottom: 40px;
}

.FavoUL
.GenaricBtn {
   width: 80px;
   height: 24px;
   left: calc(50% - 80px / 2 - 88px);
   top: calc(50% - 24px / 2 + 116px);
   background: linear-gradient(92.68deg, #3421AA 10.11%, #0F7A89 92.31%);
   box-shadow: 0px 2px 0px rgba(0, 0, 0, 0.51);
   color: #ffffff;
   font-size: 12px;
   font-family: 'ObjectSans-Regular';
   text-transform: uppercase;
   border: 1px solid;
   border-image: linear-gradient(to right, #0F7B89, #3522AA) 1 1 1 1;
   transform: skew(-10deg, 0deg);
}

.PlayerBtn {
   width: 80px;
   height: 24px;
   left: calc(50% - 80px / 2 - 88px);
   top: calc(50% - 24px / 2 + 116px);
   background: linear-gradient(92.07deg, #AA219C 2.01%, #3E0AAD 100.44%);
   box-shadow: 0px 2px 0px rgba(0, 0, 0, 0.51);
   color: #ffffff;
   font-size: 12px;
   font-family: 'ObjectSans-Regular';
   text-transform: uppercase;
   border: 1px solid;
   border-image: linear-gradient(to right, #4A0DAC, #A5229D) 1 1 1 1;
   transform: skew(-10deg, 0deg);
}

.InventryBtn {
   width: auto;
   height: 24px;
   left: calc(50% - 80px / 2 - 88px);
   top: calc(50% - 24px / 2 + 116px);
   background: linear-gradient(92.07deg, #19A62F 2.01%, #DBB908 100.44%);
   box-shadow: 0px 2px 0px rgba(0, 0, 0, 0.51);
   color: #ffffff;
   font-size: 12px;
   font-family: 'ObjectSans-Regular';
   text-transform: uppercase;
   border: 1px solid;
   border-image: linear-gradient(to right, #D0B80B, #1EA72F) 1 1 1 1;
   transform: skew(-10deg, 0deg);
}

.SplashBox {
   height: 298px;
   background-image: url(https://storage.googleapis.com/meta11/serviceplatform/splash-bg.png);
   background-position: center 0%;
}

.marks {
   width: 56px;
   height: 28px;
   background: #172842;
   border: 1px solid #253342;
   border-radius: 4px;
   margin-left: auto;
   margin-right: auto;
}

/*curcle1*/

.curcleOut1 {
   width: 100%;
}

.curcleOut1 .upDown {
   margin-left: auto;
   margin-right: auto;
   width: 16px;
   height: 16px;
   margin-bottom: 5px;
}

.curcleOut1 .OutCurcle {
   margin-left: auto;
   margin-right: auto;
   background-image: linear-gradient(to bottom, #FAFF01 0%, #39FF96 100%);
   width: 104px;
   height: 104px;
   border-radius: 50%;
   padding-top: 2px;
   padding-right: 2px;
   padding-bottom: 2px;
   padding-left: 2px;
   box-shadow: 0px 0px 40px rgba(211, 255, 30, 0.43);
   -webkit-box-shadow: 0px 0px 40px rgba(211, 255, 30, 0.43);
}

.curcleOut1 .InCurcle {
   width: 100px;
   height: 100px;
   background-size: 100% auto;
   border-radius: 50%;
}

.curcleCount {
   background-image: linear-gradient(to bottom, #FAFF01 0%, #39FF96 100%);
   height: 28px;
   width: 28px;
   border-radius: 50%;
   margin-left: auto;
   margin-right: auto;
   font-size: 14px;
   text-align: center;
   padding-top: 8px;
   margin-top: -14px;
   font-family: 'ObjectSans-Heavy';
   color: #000;
}

/*curcle2*/
.curcleOut2 {
   width: 100%;
}

.curcleOut2 .upDown {
   margin-left: auto;
   margin-right: auto;
   width: 30px;
   height: 23px;
   margin-bottom: 5px;
}

.curcleOut2 .OutCurcle {
   margin-left: auto;
   margin-right: auto;
   background-image: linear-gradient(to bottom, #FAFF01 0%, #39FF96 100%);
   width: 144px;
   height: 144px;
   border-radius: 50%;
   padding-top: 2px;
   padding-right: 2px;
   padding-bottom: 2px;
   padding-left: 2px;
   box-shadow: 0px 0px 40px rgba(211, 255, 30, 0.43);
   -webkit-box-shadow: 0px 0px 40px rgba(211, 255, 30, 0.43);

}

.curcleOut2 .InCurcle {
   width: 140px;
   height: 140px;
   background-size: 100% auto;
   border-radius: 50%;
}

/*leadrerBoardTable*/
.leaderBoard {
   width: 100%;
   border-collapse: collapse;
   font-size: 16px;
   font-family: 'ObjectSans-Regular';

   td {
      padding: 7px;
      padding-top: 10px;
      padding-bottom: 10px;
   }

   tr {
      color: #fff;
      cursor: pointer;
   }

   tr:hover {
      background-image: linear-gradient(to bottom, #FAFF01 0%, #39FF96 100%);
      color: #000000;
      font-family: 'ObjectSans-Heavy';
   }

   tr:nth-child(even) {
      background-color: rgba(26, 38, 57, 0.00)
   }

   tr:nth-child(odd) {
      background-color: rgba(26, 38, 57, 0.50)
   }

   tr > td:first-child {
      width: 18%;
      text-align: center;
   }

   tr > td:last-child {
      width: 20%;
   }

}

.picSection {
   margin-right: 25px;
   position: relative;

   .heartLike {
      right: 25px;
      top: 25px;
   }
}

.puffWrapper {
   position: absolute;
   top: 30px;
   right: 20px;
}

.ResponceCont {
   max-width: 1240px;
   margin-left: auto;
   margin-right: auto;
}


.SumText {
   font-size: 18px;
   color: #ffffff;
   font-family: 'SolinaExBI';
   letter-spacing: -0.01em;
   line-height: 22px;
   font-style: italic;
}

.SumTextSm {
   font-size: 12px;
   color: $textGray2;
   font-family: 'ObjectSans-Regular';
   line-height: 16px;
}

.SubTopic {
   font-size: 24px;
   font-family: 'SolinaLight';
   color: #ffffff;
   text-transform: uppercase;
   line-height: 24px;
}

.sublinkbtn {
   background: linear-gradient(95.34deg, #FAFF00 6.28%, #00FFC2 111.13%),
   linear-gradient(107.05deg, #2FFF9E -79.56%, #F9FF01 210.2%);
   border: 1px solid;
   border-image-source: linear-gradient(107.05deg, #2FFF9E -79.56%, #F9FF01 210.2%);
   box-shadow: 0px 1px 8px 0px rgba(223, 255, 21, 1);
   font-family: 'OSHeavySlanted';
   font-size: 12px;
   transform: skew(-10deg, 0deg);
   bottom: 0px;
   padding: 0 10px;
   line-height: 17px;
   color: rgba(3, 22, 31, 1) !important;
   border-radius: 2px;
   text-transform: uppercase;
   display: inline-block;
}

.TeamProfilePage {
   .HeaderWraper {
      background: linear-gradient(180deg, rgba(26, 38, 57, 0.5) 0%, rgba(11, 28, 53, 0.5) 100%);
      border: 1px solid #FFFFFF0F;
      border-radius: 2px;
      display: grid;
      grid-template-columns:324px 1fr;
      gap: 24px;

      .HeaderLogoWrapper {
         background: #131F30;
         border: 1px solid #FFFFFF0F;
         margin: 24px;

         position: relative;
         top: 43%;
         -webkit-transform: translateY(-50%);
         -ms-transform: translateY(-50%);
         transform: translateY(-50%);

         img {
            max-width: 210px;
            margin: 0 auto;
            margin: 35px;
         }
      }

      .HeaderContentContainer {
         padding: 24px 24px 24px 0;

         h2 {
            text-transform: uppercase;
            font-family: 'ObjectSans-Regular';
            font-size: 24px;
            font-weight: 700;
            font-style: italic;
         }

         p {
            font-size: 16px;
            font-weight: normal;
            line-height: normal;
            text-align: left;
         }

         .MatchOutcomes {
            width: 100%;

            p {
               color: #95A6B7;
               margin-bottom: 6px;
            }

            .OutcomeIcons {
               margin-bottom: 12px;

               img {
                  width: 24px;
                  height: 24px;
                  margin: 2px;
               }
            }
         }
      }
   }

   .ButtonRow {
      display: grid;
      grid-template-columns: 1fr 120px;
      gap: 10px;
      margin-bottom: 24px;

      button {
         color: #ffffffdc;
         font-family: 'ObjectSans-Regular';
         text-transform: uppercase;
         font-size: 16px;
         font-weight: 400;
         background: #111E33;
         border: 1px solid #FFFFFF0F;
      }

      button:hover {
         background: #0f1a2c;
      }
   }

   .TeamPlayers {
      margin-top: 50px;

      h2 {
         font-family: 'Montserrat', sans-serif;
         font-size: 32px;
         font-weight: 400;
         text-transform: uppercase;
      }

      .TeamPlayersWrapper {
         //display: grid;
         grid-template-columns: repeat(auto-fit, minmax(180px, 1fr));
         gap: 10px;

         .TeamPlayerCard {
            // width: 23.5%;
            width: calc(92% / 4);
            margin-top: 8px;
            justify-self: center;
            float: left;
            margin-right: 2%;
            margin-bottom: 13px;

            img {
               max-width: 100px;
               height: 100px;
               //border-radius: 50%;
            }

            section {
               border-radius: 50%;
               width: 100px;
               height: 100px;
               margin-left: auto;
               margin-right: auto;
               background-size: 100%;
               margin-bottom: 13px;
               margin-top: 10px;
            }

            h3 {
               font-family: 'ObjectSans-Regular';
               color: #fff;
               font-size: 16px;
               font-style: italic;
               text-align: center;
               text-transform: uppercase;
               line-height: 20px;
               font-weight: 600;
               width: 160px;
               white-space: nowrap;
               overflow: hidden;
               text-overflow: ellipsis;
               margin: 0 auto;
            }
         }

         .TeamPlayerCard:nth-child(4) {
            margin-left: 0%;
            margin-right: 0;
         }

         @media (max-width: 768px) {

            .TeamPlayerCard {
               width: 46%;
               float: left;
               margin-right: 4%;
               margin-bottom: 13px;
            }
            .TeamPlayerCard:nth-child(2) {
               margin-left: 0%;
               margin-right: 0;
            }
         }
      }
   }
}

@media (min-width: 768px) {
   .SumText {
      font-size: 28px;
      color: #ffffff;
      font-family: 'SolinaExBI';
      letter-spacing: -0.01em;
      line-height: 32px;
      font-style: italic;
   }
   .SumTextSm {
      font-size: 16px;
      color: $textGray2;
      font-family: 'ObjectSans-Regular';
      line-height: 20px;
   }
   .SubTopic {
      font-size: 32px;
      font-family: 'SolinaLight';
      color: #ffffff;
      text-transform: uppercase;

   }

}

@media (max-width: 768px) {
   .TeamProfilePage {
      .HeaderWraper {
         grid-template-columns: 1fr;

         .HeaderLogoWrapper {
            margin: 10px !important;

            img {
               display: block;
               margin: 0 auto;
            }
         }

         .HeaderContentContainer {
            background: #131F30;
            border: 1px solid #FFFFFF0F;
            margin: 10px;
         }
      }

      .TeamPlayers {
         h2 {
            font-size: 24px;
         }
      }

      .LastMatchesCards {
         > div {
            width: calc(50% - 20px);
            margin: 2px 10px !important;
         }
      }
   }
}

@media (max-width: 576px) {
   .LastMatchesCards {
      > div {
         width: calc(100% - 20px) !important;
         margin: 2px 10px !important;
      }
   }
}

@media (max-width: 468px) {
   .TeamProfilePage {
      .TeamPlayers {
         .TeamPlayersWrapper {
            grid-template-columns: repeat(auto-fit, minmax(178px, 1fr)) !important;
         }

         h2 {
            font-size: 20px;
         }
      }
   }
   .TeamPlayersWrapper {
      .TeamPlayerCard {
         h3 {

            width: 140px !important;
         }
      }
   }
}

.noMatchText {
   font-size: 12px;
   line-height: 16px;
   letter-spacing: -0.01em;
   color: #95A6B7;
   margin-bottom: 5px;
}

.teamNameF20 {
   font-size: 20px !important;
   line-height: 24px !important;
}

.emptyMatchStatus {
   height: 28px;
   flex-wrap: wrap;
   align-items: center;
   border-radius: 90px;
   margin: 10px 4px;
   color: #95A6B7;
}

.puffWrapper {
   position: absolute;
   top: 30px;
   right: 20px;
}

.loaderContainer {
   margin: 0 auto;
   transform: translateY(400%);
   text-align: center;
}

.matchCardInnerWrapper {
   -webkit-backdrop-filter: blur(20px);
   backdrop-filter: blur(20px);
   background: linear-gradient(163.1deg, rgba(18, 34, 73, .5) .85%, rgba(8, 56, 130, 0) 111.21%);
   border: 1px solid rgba(255, 255, 255, 0.06);
   border-radius: 4px;
   display: inline-block;
   -webkit-filter: drop-shadow(10px 22px 47px rgba(0, 0, 0, .13));
   filter: drop-shadow(10px 22px 47px rgba(0, 0, 0, .13));
   margin-bottom: 25px;
   position: relative;
   width: 100%;
   -webkit-transition-property: all;
   -webkit-transition-duration: 0.4s;
   -webkit-transition-timing-function: ease;
   transition-property: all;
   transition-duration: 0.4s;
   transition-timing-function: ease;
   -moz-transition-property: all;
   -moz-transition-duration: 0.4s;
   -moz-transition-timing-function: ease;
   -o-transition-property: all;
   -o-transition-duration: 0.4s;
   -o-transition-timing-function: ease;
   -webkit-animation-name: icons-anim;
   -webkit-animation-timing-function: ease;
   -webkit-animation-iteration-count: 1;
   -webkit-animation-duration: 0.8s;
   -webkit-transform-style: preserve-3d;
   margin-right: 15px;

   &:hover {
      background: linear-gradient(163.1deg, rgb(18 34 73 / 78%) .85%, rgba(8, 56, 130, 0) 111.21%);
   }
}

.ResponceCont {
   max-width: 1240px;
   margin-left: auto;
   margin-right: auto;
}

.shield {
   height: 40px;
   width: 100px;
   border: 3px solid var(--shield-border-color);
   border-radius: 20px;
   /*  border-radius: 30px 30px 60px 60px / 30px 30px 45% 45%;
      [disabled]background-color: rgba(208,18,21,1.00); */
   clip-path: polygon(0 70%, 0 0, 100% 0, 100% 70%, 50% 100%);
   background-repeat: no-repeat;
   background-position: center center;
}

.borderTop1 {
   border-top: 1px solid !important;
   border-image: linear-gradient(to right, #f3dd1b00, #2DFEE5, #f3dd1b00) 1 0 0 0;
}

.borderTop2 {
   border-top: 1px solid #1F2A36 !important;
}

.cardFooter {
   background: linear-gradient(180deg, rgba(26, 38, 57, 0.5) 0%, rgba(11, 28, 53, 0.5) 100%);

   display: flex;
   padding: 10px;
   min-height: 57px;
   font-size: 12px;
   color: #95A6B7;
   line-height: 16px;
   border-bottom: solid 1px #1F2A36;
}

.B12text {
   font-style: italic;
   font-size: 12px;
   color: #ffffff;
   line-height: 25px;
   text-transform: uppercase;
   font-weight: 700;
}

.W12text {
   color: #ffffff !important;
   font-size: 11px;
   font-weight: 400;
   line-height: 15px;
   letter-spacing: -0.01em;
   text-align: left;
}

.W10text {
   color: #ffffff !important;
   line-height: 14px;
   font-size: 10px;
   font-style: italic;
   text-transform: uppercase;
}

.TeamLogoImg {
   height: 165px;
   max-width: 165px;
}

.mainHeaderH1 {
   font: {
      family: 'SolinaExtraBoldItalic';
      size: 115px;
   }
   line-height: 125px;
   letter-spacing: 0.02em;
   text: {
      transform: uppercase;
      fill-color: transparent;
      shadow: 0px 0px 8px rgba(20, 208, 229, 0.56);

   }
   background: linear-gradient(89.7deg, #17EA85 2.27%, #14D0E6 23.6%, #17EA85 44.41%, #14D0E6 64.17%, #17EA85 82.99%, #14D0E6 99.74%);
   -webkit: {
      background-clip: text;
      text-fill-color: transparent;
   }
   background-clip: text;
   margin-top: 50px;
}

.subMainHeaderH1 {
   font: {
      weight: 800;
      size: 52px;
   }
   line-height: 58px;
   letter-spacing: 0.02em;
}

.heartLike {
   position: absolute;
   right: 20px;
   width: 36px;
   height: 36px;
   border-radius: 90px;
   border: 1px solid #ffffff27;
   display: flex;
   align-items: center;
   justify-content: center;
   background: transparent;

   img {
      width: 16px;
   }
}

@media only screen and (max-width: 768px) {
   .mainHeaderH1 {
      font-size: 26px;
      line-height: 30px;
   }

   .loaderContainer {
      display: contents;
   }

   .Footer {
      position: fixed;
      bottom: 0;
      width: 100%;
   }
}

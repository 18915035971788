
input[type=text] {
   width: 100%;
   box-sizing: border-box;
   border: solid 1px #3E5168;
   height: 67px;
   border-radius: 6px;
   background-color: #04151D;
   background-image: -webkit-linear-gradient(0deg, rgba(4, 21, 29, 1.00) 0%, rgba(11, 28, 53, 0.00) 100%);
   background-image: -moz-linear-gradient(0deg, rgba(4, 21, 29, 1.00) 0%, rgba(11, 28, 53, 0.00) 100%);
   background-image: -o-linear-gradient(0deg, rgba(4, 21, 29, 1.00) 0%, rgba(11, 28, 53, 0.00) 100%);
   background-image: linear-gradient(90deg, rgba(4, 21, 29, 1.00) 0%, rgba(11, 28, 53, 0.00) 100%);
   max-width: 495px;
   color: #ffffff;
   padding-left: 20px;
   padding-right: 20px;
   font-size: 16px;
   font-family: 'SolinaLight', Helvetica, Arial, sans-serif;

}

.group {
   position: relative;
   margin-bottom: 10px;
}

input[type=password] {
   display: block;
   background-image: -webkit-linear-gradient(0deg, rgba(4, 21, 29, 1.00) 0%, rgba(11, 28, 53, 0.00) 100%);
   background-image: -moz-linear-gradient(0deg, rgba(4, 21, 29, 1.00) 0%, rgba(11, 28, 53, 0.00) 100%);
   background-image: -o-linear-gradient(0deg, rgba(4, 21, 29, 1.00) 0%, rgba(11, 28, 53, 0.00) 100%);
   background-image: linear-gradient(90deg, rgba(4, 21, 29, 1.00) 0%, rgba(11, 28, 53, 0.00) 100%);
   max-width: 495px;
   color: #ffffff;
   padding-left: 20px;
   padding-right: 50px;
   font-size: 16px;
   /*font-family: 'SolinaLight';*/
   width: 100%;
   box-sizing: border-box;
   border: solid 1px #3E5168;
   height: 67px;
   border-radius: 6px;
   background-color: #04151D;
   padding-bottom: 22px;
   padding-top: 35px;
   line-height: 10px;
   /* background-image: url("https://storage.googleapis.com/meta11/serviceplatform/pw-eye.png"); */
   background-repeat: no-repeat;
   background-position: 96% center;
}

input[type=password]:focus {

   border-style: solid;
   border-width: 1px;
   /*border-image: linear-gradient(45deg, #17EA85, #14D0E6, #17EA85, #14D0E6, #17EA85, #14D0E6) 1 !important;*/
   border-radius: 2px;
   outline: none
}


/* active state */
input[type=password]:focus ~ label, input[type=password]:valid ~ label, textarea:valid ~ label {
   top: 10px;
   font-size: 12px;
   color: #95A6B7;
   padding-left: 6px;
}

/*Email*/
input[type=text], input[type=number] {
   display: block;
   background-image: -webkit-linear-gradient(0deg, rgba(4, 21, 29, 1.00) 0%, rgba(11, 28, 53, 0.00) 100%);
   background-image: -moz-linear-gradient(0deg, rgba(4, 21, 29, 1.00) 0%, rgba(11, 28, 53, 0.00) 100%);
   background-image: -o-linear-gradient(0deg, rgba(4, 21, 29, 1.00) 0%, rgba(11, 28, 53, 0.00) 100%);
   background-image: linear-gradient(90deg, rgba(4, 21, 29, 1.00) 0%, rgba(11, 28, 53, 0.00) 100%);
   max-width: 495px;
   color: #ffffff;
   padding-left: 20px;
   padding-right: 50px;
   font-size: 16px;
   width: 100%;
   box-sizing: border-box;
   border: solid 1px #3E5168;
   height: 67px;
   border-radius: 6px;
   background-color: #04151D;
   padding-bottom: 22px;
   padding-top: 35px;
   line-height: 10px;
   background-repeat: no-repeat;
   background-position: 96% center;
}

input[type=text]:focus, input[type=number] {
   border-style: solid;
   border-width: 1px;
   /*border-image: linear-gradient(45deg, #17EA85, #14D0E6, #17EA85, #14D0E6, #17EA85, #14D0E6) 1 !important;*/
   border-radius: 2px;
   outline: none
}

/* active state */
input[type=text]:focus ~ label, input[type=text]:valid ~ label, input[type=number]:focus ~ label, input[type=number]:valid ~ label {
   top: 10px;
   font-size: 12px;
   color: #95A6B7;
   padding-left: 16px;
}

/*Email*/
textarea {
   display: block;
   background-image: -webkit-linear-gradient(0deg, rgba(4, 21, 29, 1.00) 0%, rgba(11, 28, 53, 0.00) 100%);
   background-image: -moz-linear-gradient(0deg, rgba(4, 21, 29, 1.00) 0%, rgba(11, 28, 53, 0.00) 100%);
   background-image: -o-linear-gradient(0deg, rgba(4, 21, 29, 1.00) 0%, rgba(11, 28, 53, 0.00) 100%);
   background-image: linear-gradient(90deg, rgba(4, 21, 29, 1.00) 0%, rgba(11, 28, 53, 0.00) 100%);
   color: #ffffff;
   padding-left: 20px;
   padding-right: 50px;
   font-size: 16px;
   /*font-family: 'SolinaLight';*/
   width: 100%;
   box-sizing: border-box;
   border: solid 1px #3E5168;
   height: 130px;
   border-radius: 6px;
   background-color: #010e14;
   padding-bottom: 22px;
   padding-top: 35px;
   line-height: 15px;
   /*background-image: url("https://storage.googleapis.com/meta11/serviceplatform/pw-eye.png");*/
   background-repeat: no-repeat;
   background-position: 96% center;

}

textarea:focus {
   border-style: solid;
   border-width: 1px;
   /*border-image: linear-gradient(45deg, #17EA85, #14D0E6, #17EA85, #14D0E6, #17EA85, #14D0E6) 1 !important;*/
   border-radius: 2px;
   outline: none
}

textarea:focus ~ label, textarea:valid ~ label {
   top: 10px;
   font-size: 12px;
   color: #95A6B7;
   padding-left: 6px;
}

/* LABEL */
.group label {
   color: #95A6B7;
   font-size: 16px;
   font-weight: normal;
   position: absolute;
   pointer-events: none;
   left: 36px;
   top: 25px;
   transition: 0.2s ease all;
   -moz-transition: 0.2s ease all;
   -webkit-transition: 0.2s ease all;
}

body {
   background-color: #010E14;
}

.Radial1 {
   width: 900px;
   height: 900px;
   left: -515px;
   top: -350px;
   background: radial-gradient(32.65% 32.65% at 50% 50%, #04F0FF 0%, rgba(20, 208, 230, 0) 100%);
   mix-blend-mode: normal;
   position: absolute;
   opacity: 0.14;
   z-index: -1;
}

.Radial2 {
   width: 400px;
   height: 800px;
   right: 0px;
   top: 150px;
   background: radial-gradient(32.65% 32.65% at 110% 50%, #E61479 0%, rgba(230, 20, 20, 0) 100%);
   mix-blend-mode: normal;
   position: absolute;
   opacity: 0.44;
   z-index: -1;
}

.Radial3 {
   width: 400px;
   height: 800px;
   right: 0px;
   top: 500px;
   background: radial-gradient(40.65% 40.65% at 110% 50%, #E61479 0%, rgba(230, 20, 20, 0) 100%);
   mix-blend-mode: normal;
   position: absolute;
   opacity: 0.44;
   z-index: -1;
}

.Radial4 {
   width: 900px;
   height: 900px;
   left: -515px;
   top: 550px;
   background: radial-gradient(32.65% 32.65% at 50% 50%, #E61479 0%, rgba(230, 20, 20, 0) 100%);
   mix-blend-mode: normal;
   position: absolute;
   opacity: 0.30;
   z-index: -1;
}


.midContent {
   width: 100%;
   height: auto;
   max-width: 1440px;
   margin-left: auto;
   margin-right: 0px;
   margin-bottom: 150px;
   z-index: 10;
}

.MainContent {
   width: 1440px;
}

.ToptextRight {
   color: #95A6B7;
   font-size: 16px;
   font-family: 'ObjectSans-Regular', Helvetica, Arial, sans-serif;
   text-align: right;
}

.ToptextRight a {
   background-image: linear-gradient(0deg, #17EA85, #14D0E6);
   background-clip: text;
   color: transparent;
}

.cursor {
   cursor: pointer;
}

.UploadDiv {

   border: #3E5168 1px dashed;
   background-image: -webkit-linear-gradient(216deg, rgba(11, 28, 53, 0.00) 0%, rgba(4, 21, 29, 1.00) 100%);
   background-image: -moz-linear-gradient(216deg, rgba(11, 28, 53, 0.00) 0%, rgba(4, 21, 29, 1.00) 100%);
   background-image: -o-linear-gradient(216deg, rgba(11, 28, 53, 0.00) 0%, rgba(4, 21, 29, 1.00) 100%);
   background-image: linear-gradient(234deg, rgba(11, 28, 53, 0.00) 0%, rgba(4, 21, 29, 1.00) 100%);
   border-radius: 6px;
   background-size: cover !important;
}

.Text22 {
   font-size: 22px;
   font-family: 'ObjectSans-Regular', Helvetica, Arial, sans-serif;
   line-height: 28px;
}

.Text22 a {
   color: #17EA85;
   text-decoration: none;
}

.Text16 {
   font-size: 16px;
   font-family: 'ObjectSans-Regular', Helvetica, Arial, sans-serif;
   text-align: center;
   line-height: 35px;
   color: #95A6B7;
}

.Text16White {
   font-size: 16px;
   font-family: 'ObjectSans-Regular', Helvetica, Arial, sans-serif;
   text-align: center;
   line-height: 20px;
   color: #ffffff;
}

.Text12 {
   font-size: 12px;
   font-family: 'ObjectSans-Regular', Helvetica, Arial, sans-serif;
   text-align: center;
   line-height: 15px;
   color: #95A6B7;
}

.Text32 {
   font-size: 32px;
   font-family: 'SolinaLight', Helvetica, Arial, sans-serif;
   text-align: center;
   line-height: 35px;
   color: #ffffff;
}

.Text18 {
   font-size: 18px;
   font-family: 'ObjectSans-Regular', Helvetica, Arial, sans-serif;
   text-align: center;
   line-height: 20px;
   color: #95A6B7;
   letter-spacing: 0px;
}

.RadioBtnBox {
   color: #ffffff;
   width: 100%;
   border: solid 1px #3E5168;
   height: 67px;
   border-radius: 6px;
   background-color: #04151D;
   padding: 0px;
}

.LockBox {
   border-right: 1px solid #3E5168;
   width: 25%;
   height: 67px;
   float: left;
   text-align: center;
   padding-top: 20px;
}

.LockText {
   width: 50%;
   float: left;
   text-align: center;
   padding-top: 0px;
   font-size: 16px;
   font-family: 'ObjectSans-Regular', Helvetica, Arial, sans-serif;
}

.LockText2 {
   width: 74%;
   float: left;
   text-align: center;
   padding-top: 0px;
   font-size: 16px;
   font-family: 'ObjectSans-Regular', Helvetica, Arial, sans-serif;
}


.RadioBox {
   width: 25%;
   float: left;
   text-align: center;
   padding-top: 24px;
}

.radioFill:checked {
   background-color: #04151d;
   border-color: #17EA85;
   background-image: url(https://storage.googleapis.com/meta11/serviceplatform/radioCheckGreen.png) !important;
   background-repeat: no-repeat;
   background-size: 60%;
}

.radioFill {
   border: 1px solid #17EA85 !important;
   width: 1.3em;
   height: 1.3em;
   background-color: #04151d;
   cursor: pointer;
}

.LoginButton {
   background: linear-gradient(95.34deg, #FAFF00 6.28%, #00FFC2 111.13%);
   box-shadow: 0px 2px 0px rgba(0, 0, 0, 0.51);
   border-radius: 4px;
   transform: skew(-10deg, 0deg);
   border: 1px solid #2FFF9E;
   /*border-image-source: linear-gradient(103.05deg, #2FFF9E 7.22%, #F9FF01 93.09%);*/
   font-family: "ObjectSans-Heavy", Helvetica, Arial, sans-serif;
   font-size: 16px;
   line-height: 18px;
   text-align: center;
   letter-spacing: -0.01em;
   text-transform: uppercase;
   padding: 14px 27px;
   font-weight: 700;
   width: 100%;
   height: 54px;
}

.BlackSkBtn {
   background-color: #0E2029;
   box-shadow: 0px 2px 0px rgba(0, 0, 0, 0.51);
   border-radius: 4px;
   transform: skew(-10deg, 0deg);
   border: 1px solid rgba(0, 255, 194, 0.09);
   font-family: "ObjectSans-Heavy", Helvetica, Arial, sans-serif;
   font-size: 16px;
   line-height: 18px;
   /* identical to box height, or 129% */

   text-align: center;
   letter-spacing: -0.01em;
   text-transform: uppercase;
   padding: 14px 27px;
   font-weight: 700;
   height: 54px;


}

.BlackSkBtn text {

   background-image: linear-gradient(0deg, #FAFF01, #39FF96);
   background-clip: text;
   color: transparent;
}

/*SocialInput*/
.SocialInput {
   display: block;
   border: none !important;
   background-image: -webkit-linear-gradient(0deg, rgba(4, 21, 29, 1.00) 0%, rgba(11, 28, 53, 0.00) 100%);
   background-image: -moz-linear-gradient(0deg, rgba(4, 21, 29, 1.00) 0%, rgba(11, 28, 53, 0.00) 100%);
   background-image: -o-linear-gradient(0deg, rgba(4, 21, 29, 1.00) 0%, rgba(11, 28, 53, 0.00) 100%);
   background-image: linear-gradient(90deg, rgba(4, 21, 29, 1.00) 0%, rgba(11, 28, 53, 0.00) 100%);
   color: #ffffff;
   padding-left: 10px !important;
   padding-right: 0px !important;
   font-size: 16px;
   width: 100%;
   box-sizing: border-box;
   height: 65px !important;
   border-radius: 6px;
   background-color: #04151D;
   padding-bottom: 22px;
   padding-top: 20px !important;
   line-height: 10px;
   background-repeat: no-repeat;
   background-position: 96% center;
}

.modal-content {
   padding: 10px !important;
   background-color: #063145 !important;
}

.modalBlur {
   backdrop-filter: blur(5px) !important;
}

.modalContentCustom {
   color: #f5f5f5 !important;
   background-color: #063145 !important;
   background-position: center top;
   background-repeat: no-repeat;
   background-size: 100% auto;
}

.modalFooterLine {
   border: 0px !important;
   display: block;
}

.closeButton {
   float: right;
   font-size: 1.5rem;
   font-weight: 700;
   line-height: 1;
   color: #FFF;
   background-color: #00000000;
   border: 0px;

}


.fileName {
   color: #000000 !important;
}

.editSection {
   width: 15%;
   position: relative;
   top: 100%;
   left: 90%;
}

.modalText {
   font-size: 16px;
   line-height: 20px;
   color: #95A6B7;
}


.faPlus {
   color: #ffffff;
   /*background: #010E14;*/
   border-radius: 50%;
   background: linear-gradient(180deg, rgba(26, 38, 57, 0.5) 0%, rgba(11, 28, 53, 0.5) 100%);
   /* white 6% stroke */

   border: 1px solid rgba(255, 255, 255, 0.06);
   backdrop-filter: blur(50px);
   /* Note: backdrop-filter has minimal browser support */
}

.editSectionProfile {

   position: relative;
   top: 87%;
   left: 55%;
}

.modalTitle {
   font-family: 'SolinaLight';
   font-size: 35px;
   color: #ffffff;
   text-transform: uppercase;
   line-height: 40px;
}

.mobhalf {
   display: inline-block;
   width: auto;
   padding: 5px 20px;
}

.mobfull {
   display: inline-block;
   width: auto;
   padding: 5px 20px;
}

.saveChangesBtnRow {
   margin-top: 20px;

   .NextButton {
      max-width: fit-content;
   }
}

@media only screen and (max-width: 1920px) {
   .MainContent {
      width: 1440px;
   }
}

@media (min-width: 1440px) {
   .midContent {
      width: 100%;
      margin-top: 40px;
      margin-left: auto;
      margin-right: auto;
      padding-right: 0px;
      padding-left: 0px;
      z-index: 10;
   }
}

@media only screen and (max-width: 1440px) {
   .MainContent {
      width: 1200px;
   }
}

@media only screen and (max-width: 1200px) {
   .MainContent {
      width: 992px;
   }
}

@media only screen and (max-width: 992px) {
   .MainContent {
      width: 100%;
   }
   .group label {
      left: 20px;
   }
}

@media only screen and (max-width: 768px) {
   .UploadDiv {
      padding: 15px;

   }

   .Text22 {
      font-size: 16px;
      line-height: 20px;
   }
   .mobhalf {
      float: left;
      width: 48%;
      font-size: 14px;
      line-height: 15px;
      padding: 5px 10px;
   }
   .mobfull {
      float: left;
      width: 100%;
      font-size: 14px;
      line-height: 18px;
   }

   .Text32 {
      font-size: 18px;
   }

   .Text18 {
      font-size: 14px;
   }

   .Radial2, .Radial3, .Radial4 {
      display: none;
   }
   .editSection {
      width: 100px;
      position: relative;
      top: 100%;
      left: 80%;
   }

}

.ResponceCont {
   max-width: 1240px;
   margin-left: auto;
   margin-right: auto;
}

.innerWith {
   max-width: 808px;
}

.innerTitle {
   font-size: 45px;
   font-family: 'Rubik-Light', Helvetica, Arial, sans-serif;
   line-height: 70px;
   font-weight: bold
}

.overviewCards {
   background-size: 100% auto;
   background-color: hsla(0, 0%, 0%, 0.47);
   color: white;
   height: 200px;
}

.coverImageStyles {
   height: 330px;
   bottom: auto;
   z-index: 0;
}

.midContent {
   width: 100%;
   max-width: 1440px;
   padding-top: 50px;
   padding-right: 20px;
   padding-left: 20px;
   padding-bottom: 50px;
   margin: 0 auto;
   font-family: 'Proxima-Nova-Rg', Helvetica, Arial, sans-serif;
}

.tab :hover {
   background: linear-gradient(to right, #19EA17 0%, #14D0E6 100%);
   -webkit-background-clip: text;
   -webkit-text-fill-color: transparent;
}

.navItem .dropdown-toggle:hover {
   background-color: black !important;
   border-color: black !important;
}

.nav-tabs .nav-link.active, .nav-tabs .navItem.show .navLink {
   background-color: rgba(0, 0, 0, 0.00);
   border-top: 0px;
   border-left: 0px;
   border-right: 0px;
   border-bottom: 3px solid #ffffff;
   font-size: 18px;
}

.profileIcoImgBigDetail {
   font-size: 14px;
   width: 150px;
   height: 60px;
   margin-left: auto;
   margin-right: auto;
   margin-bottom: 30px;
   text-align: center;
}

.profileIcoImgBig {
   width: 120px;
   height: 120px;
   border-radius: 50%;
   z-index: 90;
   position: center;
   margin: -80px auto 20px;
   background-color: #CFCFCF;
   background-position: center center;
   background-size: cover;
}

.bold {
   font-weight: 600;
   color: #FFFFFF;
}

.row {
   margin-top: auto;
   margin-right: auto;
   margin-left: auto;
   margin-bottom: auto;
}

.borderBottom {
   border-bottom: 1px solid #3c3c3c !important;
}

.tableColumnRank {
   width: 10%;
   float: left;
}

.tableColumnUser {
   width: 30%;
   float: left;
}

.tableColumnQuestions {
   width: 20%;
   float: left;
}

.slashBig {
   background-image: url("https://storage.googleapis.com/meta11/serviceplatform/slash-big.png");
   background-repeat: no-repeat;
   background-position: left bottom;
}

.slashSmall {
   background-image: url("https://storage.googleapis.com/meta11/serviceplatform/slash-small.png");
   background-repeat: no-repeat;
   background-position: left top;
}

.profileImg {
   border-radius: 20px;
   background-repeat: no-repeat;
   height: 230px;
   background-size: auto auto, 107% auto;
   width: 100%;
   background-position: center;
}

.playerSmallTitle {
   font-size: 15px;
   font-family: 'Rubik-Italic', Helvetica, Arial, sans-serif;
   text-transform: uppercase;
   text-align: center;
}

.namelist {
   margin-left: 0px;
   padding-left: 0px;
}

.namelist li {
   padding-top: 1px;
   padding-right: 0px;
   padding-left: 0px;
   padding-bottom: 4px;
   list-style-type: none;
   margin-left: 0px;
   vertical-align: bottom;
   line-height: 24px;
   font-size: 15px;
}

.namelist li img {
   margin-right: 10px

}

.profileTopBox {
   width: 100%;
   height: auto;
   background-size: auto auto, 100% auto;
   border-radius: 20px;
   background-position: right 0%;
   background-repeat: no-repeat;
}

.playerText {
   font-family: 'Rubik-SemiBoldItalic', Helvetica, Arial, sans-serif;
   font-size: 15px;
   color: #fff;
}

.font30 {
   font-size: 30px;
   font-weight: bold
}

.font15 {
   font-size: 15px;
   line-height: 25px;
}

.font15 a {
   color: #14d0e6;
   line-height: 30px;
   font-weight: bold;
   text-decoration: none;
}

.font15 a:hover {
   color: #14d0e6;
}

.font20 {
   font-size: 20px;
   line-height: 21px;
   font-family: 'Rubik-Light', Helvetica, Arial, sans-serif;
}

.font20Bold {
   font-size: 20px;
   line-height: 21px;
   font-family: 'Rubik-Medium', Helvetica, Arial, sans-serif;
}

.font25 {
   font-size: 25px;
   line-height: 30px;
   font-family: 'Rubik-Light', Helvetica, Arial, sans-serif;
}

.font23 {
   font-size: 23px;
   line-height: 30px;
   font-family: 'Rubik-Light', Helvetica, Arial, sans-serif;
}

.font50Clr {
   font-size: 50px;
   font-weight: bold;
   color: #14D0E6;
}

.blackBox {
   background-color: #000000;
   border-radius: 15px;
   padding: 20px;
   height: auto;
   float: left;
   width: 100%
}

.socialIcon {
   height: 50px;
   width: 50px;
   border-radius: 50%;
   float: left;
   margin-left: 5px;
   margin-right: 5px;
   background-image: -webkit-linear-gradient(0deg, rgba(25, 234, 23, 1.00) 0%, rgba(20, 208, 230, 1.00) 100%);
   background-image: -moz-linear-gradient(0deg, rgba(25, 234, 23, 1.00) 0%, rgba(20, 208, 230, 1.00) 100%);
   background-image: -o-linear-gradient(0deg, rgba(25, 234, 23, 1.00) 0%, rgba(20, 208, 230, 1.00) 100%);
   background-image: linear-gradient(90deg, rgba(25, 234, 23, 1.00) 0%, rgba(20, 208, 230, 1.00) 100%);
   color: hsla(0, 0%, 0%, 1.00);
}

.socialIcon i {
   font-size: 20px;
   line-height: 50px;
}

.socialMediaImg {
   width: 100%;
   height: 130px;
   background-position: top center;
   background-repeat: no-repeat;
}

.boxTitlBorder {
   border-bottom: solid 1px #14D0E6;
   color: #FFFFFF;
   line-height: 30px;
}

.boxContBorder {
   color: #FFFFFF;
   line-height: 24px;
   border-bottom: 1px solid rgba(20, 208, 230, 0.50);
}

.cardCustom2 {
   margin-top: 10px;
   margin-right: 0px;
   margin-left: 0px;
   margin-bottom: 25px;
   padding: 20px;
   color: #000000;
   border-radius: 12px;
   border-width: 0px;

}

.cardCustom2 .title {
   color: rgba(255, 255, 255, 1.00);
   font-size: 14px;
   padding-left: 20px;
   padding-right: 0px;
   margin-bottom: 0px;
   padding-top: 5px;
   padding-bottom: 5px;
}

.cardCustom2 .data-row {
   padding-bottom: 0px;
   margin-bottom: 0px;
   float: left;
}

.cardCustom2 .team {
   width: 100%;
   line-height: 45px;
   color: #C3C3C3;
   font-size: 14px;
   height: 20px;
}

.cardCustom2 .timage {
   width: 10%;
   height: 18px;
   background-color: rgba(255, 255, 255, 1.00);
   border-radius: 5px;
   margin-top: 6px;
   margin-right: 6px;
   margin-bottom: 6px;
   float: left;
   border: 1px solid #414656;
}

.listBoard {
   background-color: #000000;
   background-repeat: no-repeat;
   background-position: right top;
   background-image: url(https://storage.googleapis.com/meta11/serviceplatform/activity-corner.png);
   color: #fff;
}

.cardAdvert {
   margin-top: 10px;
   margin-right: 0px;
   margin-left: 0px;
   margin-bottom: 25px;
   padding: 20px;
   color: #ffffff;
   border-radius: 12px;
   border-width: 0px;
   height: 350px;
   background-size: 100% auto;
   background-color: hsla(0, 0%, 0%, 0.47);
}

.editIcon {
   cursor: pointer;
   opacity: 0.7;
}

.faEdit {
   color: rgb(185, 185, 185);
   font-size: 20px;
   float: right;
}

.faEdit:hover {
   color: #ffffff;
}

.imgUpdateDiv {
   border-radius: 20px;
   background-color: #051725;
   width: auto;
   padding-right: 5px;
   float: right;
   opacity: 0.7;

}

.imgUpdateDiv2 {
   border-radius: 20px;
   background-color: #051725;
   padding-right: 5px;
   float: right;
   width: 125px;
}

.imgUpdateDiv i, .imgUpdateDiv2 i {
   font-size: 20px;
   padding: 8px;

}

.imgUpdateDiv font, .imgUpdateDiv2 font {
   color: #a7a9ad;
   line-height: 30px;
}

.imgUpdateDiv2 label {
   margin: 0 !important;
}

.faPlus {
   color: #ffffff;
}

.faTrash {
   color: #ffffff;
}

.faPlus:hover {
   color: #59b10a;
}

.faTrash:hover {
   color: #f00707;
}

.modal-content {
   padding: 10px !important;
   background-color: #063145 !important;
}

.modalBlur {
   backdrop-filter: blur(5px) !important;
}

.modalContentCustom {
   color: #f5f5f5 !important;
   background-color: #03161F !important;
   background-position: center top;
   background-repeat: no-repeat;
   background-size: 100% auto;
}

.modalFooterLine {
   border: 0px !important;
   display: block;
}

.closeButton {
   float: right;
   font-size: 1.5rem;
   font-weight: 700;
   line-height: 1;
   color: #FFF;

}

.fileName {
   color: #000000 !important;
}

.smallBtn {
   background-color: #000000;
   font-family: 'Rubik-Medium', Helvetica, Arial, sans-serif;
   padding-top: 10px;
   padding-right: 20px;
   padding-bottom: 10px;
   padding-left: 20px;
   color: #000000;
   font-size: 15px;
   border-radius: 20px;
   background-image: -webkit-linear-gradient(0deg, rgba(25, 234, 23, 1.00) 0%, rgba(20, 208, 230, 1.00) 100%);
   background-image: -moz-linear-gradient(0deg, rgba(25, 234, 23, 1.00) 0%, rgba(20, 208, 230, 1.00) 100%);
   background-image: -o-linear-gradient(0deg, rgba(25, 234, 23, 1.00) 0%, rgba(20, 208, 230, 1.00) 100%);
   background-image: linear-gradient(90deg, rgba(25, 234, 23, 1.00) 0%, rgba(20, 208, 230, 1.00) 100%);
   border: 0px;
   height: 38px;
   line-height: 19px;
}

.smallBtn:hover {
   background-color: #000000;
   font-family: 'Rubik-Medium', Helvetica, Arial, sans-serif;
   padding-top: 10px;
   padding-right: 20px;
   padding-bottom: 10px;
   padding-left: 20px;
   color: white;
   font-size: 15px;
   border-radius: 20px;
   background-image: -webkit-linear-gradient(0deg, rgba(25, 234, 23, 1.00) 0%, rgba(20, 208, 230, 1.00) 100%);
   background-image: -moz-linear-gradient(0deg, rgba(25, 234, 23, 1.00) 0%, rgba(20, 208, 230, 1.00) 100%);
   background-image: -o-linear-gradient(0deg, rgba(25, 234, 23, 1.00) 0%, rgba(20, 208, 230, 1.00) 100%);
   background-image: linear-gradient(90deg, rgba(25, 234, 23, 1.00) 0%, rgba(20, 208, 230, 1.00) 100%);
   border: 0px;
   height: 38px;
   line-height: 19px;
}

.pageTitle {
   font-size: 50px;
   font-family: 'Rubik-medium', Helvetica, Arial, sans-serif;
   line-height: 70px;

}

@media (max-width: 1440px) {
   .pageTitle {
      font-size: 50px;
      font-family: 'Rubik-medium', Helvetica, Arial, sans-serif;
      line-height: 70px;

   }
}

@media (max-width: 1200px) {
   .pageTitle {
      font-size: 45px;
      font-family: 'Rubik-medium', Helvetica, Arial, sans-serif;
      line-height: 70px;

   }
}

@media (max-width: 768px) {
   .pageTitle {
      font-size: 30px;
      font-family: 'Rubik-medium', Helvetica, Arial, sans-serif;
      line-height: 50px;

   }
}

.NextButton {
   background: linear-gradient(95.34deg, #FAFF00 6.28%, #00FFC2 111.13%);
   box-shadow: 0px 2px 0px rgba(0, 0, 0, 0.51);
   border-radius: 4px;
   transform: skew(-10deg, 0deg);
   border: 1px solid #2FFF9E;
   /*border-image-source: linear-gradient(103.05deg, #2FFF9E 7.22%, #F9FF01 93.09%);*/
   text-align: center;
   letter-spacing: -0.01em;
   padding: 14px 27px;
   /*font-weight: 700;*/
   width: 30%;
   height: 42px;
   font-family: "ObjectSans-Heavy", Helvetica, Arial, sans-serif;
   font-size: 16px;
   line-height: 20px;
   text-transform: uppercase;
   width: fit-content;
}

.NextButton:disabled,
.NextButton[disabled] {
   cursor: not-allowed;
   color: rgba(16, 16, 16, 0.5);
   background: linear-gradient(95.34deg, #FAFF00 6.28%, #00FFC2 111.13%);
}

.LoginButton {
   background: linear-gradient(95.34deg, #FAFF00 6.28%, #00FFC2 111.13%);
   box-shadow: 0px 2px 0px rgba(0, 0, 0, 0.51);
   border-radius: 4px;
   transform: skew(-10deg, 0deg);
   border: 1px solid #2FFF9E;
   /*border-image-source: linear-gradient(103.05deg, #2FFF9E 7.22%, #F9FF01 93.09%);*/
   text-align: center;
   letter-spacing: -0.01em;
   padding: 14px 27px;
   /*font-weight: 700;*/
   width: 30%;
   height: 54px;
   font-family: "ObjectSans-Heavy", Helvetica, Arial, sans-serif;
   font-size: 16px;
   line-height: 20px;
   text-transform: uppercase;
}

.NextButton:hover {
   background: linear-gradient(95.34deg, #00FFC2 6.28%, #FAFF00 111.13%);
}

.LoginButton:hover {
   background: linear-gradient(95.34deg, #00FFC2 6.28%, #FAFF00 111.13%);
}


.BlackSkBtn {
   background-color: #0E2029;
   box-shadow: 0px 2px 0px rgba(0, 0, 0, 0.51);
   border-radius: 4px;
   transform: skew(-10deg, 0deg);
   border: 1px solid rgba(0, 255, 194, 0.09);
   width: 30%;
   /* identical to box height, or 129% */

   text-align: center;
   letter-spacing: -0.01em;
   text-transform: uppercase;
   padding: 14px 27px;
   font-weight: 700;
   height: 54px;
}

.TextError {
   color: #FF002E;
   line-height: 15px;
   display: block;
   height: 15px;
}

.BlackSkBtn text {

   background-image: linear-gradient(0deg, #FAFF01, #39FF96);
   background-clip: text;
   color: transparent;
   font-family: "ObjectSans-Heavy";
   font-size: 16px;
   line-height: 20px;
}

.BlackSkBtn:hover {
   background: #1A4152;
}

.BlackSkBtn:visited {
   box-shadow: 0px 2px 0px rgba(0, 0, 0, 0.51);
   cursor: not-allowed;
}

.BlackSkBtn:disabled,
.BlackSkBtn[disabled] {
   background: #0E2029;
   box-shadow: 0px 2px 0px rgba(0, 0, 0, 0.51);
   backdrop-filter: blur(45px);
}

.NextButton {
   /*padding: 14px 27px;*/
   color: #03161F;
   width: 30%;
   height: 54px;
   padding: 7px 15px;
   font-size: 16px;
   line-height: 18px;
}

.NextButton:visited, .NextButton:active {
   color: #03161F;
}

.NumIn {

   box-sizing: border-box;
   border: solid 1px #3E5168;
   height: 67px;
   border-radius: 6px;
   background-color: #04151D;
   background-image: linear-gradient(90deg, rgba(4, 21, 29, 1.00) 0%, rgba(11, 28, 53, 0.00) 100%);
   text-align: center !important;
   color: #ffffff;
   padding-left: 10px;
   padding-right: 10px;
   font-size: 16px !important;
   font-family: 'SolinaLight', Helvetica, Arial, sans-serif;


   /* float: left;*/
   padding-top: 20px;
   padding-left: 10px !important;
   padding-right: 10px !important;
}

@media (max-width: 768px) {
   .BlackSkBtn {
      width: 50%;
      padding: 7px 15px;
      height: 42px;
   }
   .BlackSkBtn text {
      font-size: 10px;
      line-height: 18px;
   }
   .LoginButton {
      padding: 14px 27px;
      width: 50%;
      height: 42px;
      padding: 7px 15px;
      font-size: 14px;
      line-height: 18px;
   }
   .NextButton {
      /*padding: 14px 27px;*/

      width: auto;
      height: 42px;
      padding: 7px 10px;
      font-size: 10px;

   }

   .NumIn {
      margin-bottom: 10px;

      padding-left: 5px !important;
      padding-right: 5px !important;
   }

}

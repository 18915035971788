@import '../../../scss/globals/global';
@import '../../../scss/utilities/mixins';

.creditsWrapper {
   width: fit-content;
   display: inline-block;
   font-size: 14px;
   margin: 0 auto;

   img {
      transform: skew(10deg, 0deg) !important;
      margin: 0 8px;
   }

   span {
      color: #17EA85;
      font-size: 14px;
      transform: skew(10deg, 0deg) !important;
   }

   label {
      text-transform: capitalize;
   }

   .credits {
      padding: 6px 22px;
      border: none;
      outline: none;
      position: relative;
      z-index: 1;
      border-radius: 2px;
      background: linear-gradient(103.05deg, #F9FF01 7.22%, #2FFF9E 93.09%);
      //    cursor: pointer;
      transform: skew(-9deg, 0deg);
      text-transform: uppercase;
      margin-right: 10px;
   }

   .credits::before {
      content: "";
      position: absolute;
      left: 1px;
      right: 1px;
      top: 1px;
      bottom: 1px;
      border-radius: 4px;
      background-color: #0E2029;
      z-index: -1;
      transition: 200ms
   }

   .credits::after {
      content: attr(data);
      font-size: 16px;
      background: linear-gradient(to left, #00FFA3, #DC1FFF);
      -webkit-background-clip: text;
      color: #fff;
      transition: 200ms
   }
}

.modalContentContainer {
   box-shadow: 0px 0px 65px 0px #80ff5f6e;
}

.PaymentConfirmmodal {
   display: block;
   background: rgba(0, 0, 0, 0.2588235294);
   backdrop-filter: blur(5px);
   -webkit-backdrop-filter: blur(5px);

   .SequenceLoginModal {
      width: 100%;
      max-width: 580px;


   }

   .Radial1 {
      @include gradientGeneralLeft(900px, 900px, -550px, 550px, $Blue03, 32.65%, 32.65%, $GradPosCenter);
      mix-blend-mode: normal;
      opacity: 0.14;
      z-index: -1;
   }

   .Radial2 {
      @include gradientGeneralRight(500px, 500px, 0, 1050px, $Blue03, 32.65%, 32.65%, $GradPosRight);
      mix-blend-mode: normal;
      opacity: 0.14;
      z-index: -1;
   }


   p {
      text-align: center;
      font-size: 16px;
      font-family: 'ObjectSans-Regular';
      line-height: 20px;
   }


   .OverviewBox {
      @include overview-box;
      padding-left: 5px;

      img {
         width: auto;
      }
   }

   .leaderBoardTitle {
      position: relative;

      .shareBtnWrapper {
         position: absolute;
         top: -4px;
         right: 0;
      }
   }

   .borderTop1 {
      @include yellow-gradient;
   }

   .borderTop2 {
      @include green-gradient;
   }

   .borderTop3 {
      @include purple-gradient;
   }


   .SplashBox {
      height: 320px;
      background-image: url(https://storage.googleapis.com/meta11/serviceplatform/splash-bg.png);
      background-position: center 0%;
   }

   .marks {
      width: 56px;
      height: 28px;
      background: #172842;
      border: 1px solid #253342;
      border-radius: 4px;
      margin-left: auto;
      margin-right: auto;
   }


   .RadioBox {
      width: 20%;
      float: left;
      text-align: center;
      padding-top: 21px;
   }

   .radioFill:checked {
      background-color: #04151d;
      border-color: #17EA85;
      background-image: url(https://storage.googleapis.com/meta11/serviceplatform/radioCheckGreen.png) !important;
      background-repeat: no-repeat;
      background-size: 60%;
   }

   .radioFill {
      border: 1px solid #17EA85 !important;
      width: 1.3em;
      height: 1.3em;
      background-color: #04151d;
      cursor: pointer;
   }


   .BlackSkBtn text {

      background-image: linear-gradient(0deg, #FAFF01, #39FF96);
      background-clip: text;
      color: transparent;
      font-family: "ObjectSans-Heavy";
      font-size: 16px;
      line-height: 18px;
   }

   .BlackSkBtn {
      background-color: #0E2029;
      box-shadow: 0px 2px 0px rgba(0, 0, 0, 0.51);
      border-radius: 4px;
      transform: skew(-10deg, 0deg);
      border: 1px solid rgba(0, 255, 194, 0.09);
      /* identical to box height, or 129% */
      text-align: center;
      letter-spacing: -0.01em;
      text-transform: uppercase;
      padding: 14px 27px;
      font-weight: 700;
      height: 54px;
      color: #95A6B7;

      :visited {
         box-shadow: 0px 2px 0px rgba(0, 0, 0, 0.51);
         cursor: not-allowed;
      }

      :disabled,
      .BlackSkBtn[disabled] {
         background: #0E2029;
         box-shadow: 0px 2px 0px rgba(0, 0, 0, 0.51);
         backdrop-filter: blur(45px);
      }
   }

   .BlackSkBtn:hover {
      background: #203455;
   }

   .warnningTex {
      padding: 0px 0px 25px 0px;
      text-align: center;
      color: rgba(255, 0, 46, 1);
      font-size: 16px;
      line-height: 20px;
      font-family: "ObjectSans-Regular";
   }

   .LoginButton {
      background: linear-gradient(95.34deg, #FAFF00 6.28%, #00FFC2 111.13%);
      box-shadow: 0px 2px 0px rgba(0, 0, 0, 0.51);
      border-radius: 4px;
      transform: skew(-10deg, 0deg);
      border: 1px solid #2FFF9E;
      /*border-image-source: linear-gradient(103.05deg, #2FFF9E 7.22%, #F9FF01 93.09%);*/
      text-align: center;
      letter-spacing: -0.01em;
      padding: 14px 27px;
      /*font-weight: 700;
      width: 100%;*/
      height: 52px;
      margin-top: 1px;
      font-family: "ObjectSans-Heavy", Helvetica, Arial, sans-serif;
      font-size: 16px;
      line-height: 18px;
      text-transform: uppercase;
      margin-left: 10px;
      // :hover {
      //   background: linear-gradient(95.34deg, #00FFC2 6.28%, #FAFF00 111.13%);
      // }

      :visited {
         background: linear-gradient(95.34deg, #B0FFCA 6.28%, #F2FF7E 111.13%);
      }
   }

   .LoginButton:hover {
      background: linear-gradient(95.34deg, #00FFC2 6.28%, #FAFF00 111.13%);
   }

   .modalBlur {
      background-color: #00000063;
   }

   //  .closeButton {
   //     border: 0px;
   //     background-color: #00000000;
   //  }

   .SequenceLoginModal {
      .modalContainer {
         .modalTitle {
            font-family: 'SolinaLight';
            font-size: 20px;
            color: #ffffff;
            text-transform: uppercase;
            line-height: 40px;
         }
      }

      .photoModifyModelBtnRow {
         width: 100%;
         margin: 0 auto;

         button:nth-child(1) {
            float: left;
         }

         button:nth-child(2) {
            float: left;
         }
      }
   }


   .DeleteAccountWrapper {
      margin: 40px 0;
      padding: 0;

      h3 {
         font-size: 32px;
         font-family: "SolinaLight", Helvetica, Arial, sans-serif;
         text-align: left;
         line-height: 35px;
         color: #ffffff;
         text-transform: uppercase;
      }

      .DeleteAccountBtnWrapper {
         .DeleteAccountBtn {
            padding: 14px 23px;
            border: none;
            outline: none;
            position: relative;
            z-index: 1;
            border-radius: 5px;
            background: linear-gradient(103.05deg, #F9FF01 7.22%, #2FFF9E 93.09%);
            cursor: pointer;
            transform: skew(-10deg, 0deg);
            text-transform: uppercase;
         }

         .DeleteAccountBtn::before {
            content: "";
            position: absolute;
            left: 1px;
            right: 1px;
            top: 1px;
            bottom: 1px;
            border-radius: 4px;
            background-color: #0E2029;
            z-index: -1;
            transition: 200ms
         }

         .DeleteAccountBtn::after {
            content: attr(data);
            font-size: 16px;
            background: linear-gradient(to left, #00FFA3, #DC1FFF);
            -webkit-background-clip: text;
            color: #fff;
            transition: 200ms
         }

         .DeleteAccountBtn:hover::before {
            opacity: 50%;
            top: 0px;
            right: 0px;
            bottom: 0px;
            left: 0px;
         }

         .DeleteAccountBtn:hover::after {
            color: white;
         }
      }

   }

   .UserProfileSplashBox {

      .SplashBox {
         min-height: 320px;
         padding-bottom: 40px !important;
         background-image: url(../../../assets/images/leader-board-splash.webp);
         background-repeat: no-repeat;
         background-position: center 0%;
      }

      .upChevron {
         width: 24px;
         height: 24px;
         margin: 0 auto;
         margin-bottom: 10px;
         background-image: url(../../../assets/images/up-chevron.webp);
         background-repeat: no-repeat;
      }

      .downChevron {
         width: 24px;
         height: 24px;
         margin: 0 auto;
         margin-bottom: 10px;
         background-image: url(../../../assets/images/down-chevron.webp);
         background-repeat: no-repeat;
      }

      .outCurcleWrapper {
         width: 144px;
         height: 144px;
         position: relative;
         margin: 0 auto;
      }

      .trophyTextW {
         font-size: 18px;
         color: #ffffff;
         font-family: "ObjectSans-Regular";
         text-transform: uppercase;
         letter-spacing: -0.01em;
         line-height: 24px;
         font-style: italic;
         font-weight: 700;
         min-height: 60px;
      }

      .curcleOut1 {
         margin-top: 27px;
      }

   }

   .SequenceLoginModal {
      position: relative;

      .closeButton {
         position: absolute;
         top: 8px;
         right: 15px;
         z-index: 2;

         img {
            display: block;
            width: 14px;
            height: 14px;
            position: absolute;
            z-index: 4;
         }
      }

      .circle1 {
         z-index: 0;
         width: 195px;
         height: 220px;
         position: absolute;
         background-image: url('../../../assets/images/circle1.webp');
         background-size: 195px 220px;
         background-repeat: no-repeat;
         background-position: top right;
         top: 0px;
         right: 0px;
      }

      .circle2 {
         z-index: 0;
         width: 98px;
         height: 200px;
         position: absolute;
         background-image: url('../../../assets/images/circle2.webp');
         background-size: 98px 200px;
         background-repeat: no-repeat;
         background-position: top right;
         top: 73px;
         left: -2px;
      }

      .circle3 {
         z-index: 0;
         width: 88px;
         height: 88px;
         position: absolute;
         background-image: url('../../../assets/images/circle3.webp');
         background-size: 88px 88px;
         background-repeat: no-repeat;
         background-position: bottom left;
         bottom: 32px;
         left: 24px;
      }

      .UserProfileModal {
         img {
            width: fit-content;
            max-width: 240px;
            margin: 0 auto;
            display: block;
         }

         h4 {
            text-transform: uppercase;
            font-family: Montserrat;
            font-size: 22px;
            font-style: italic;
            font-weight: 800;
            line-height: 32px;
            letter-spacing: 0.02em;
            text-align: center;
            text-transform: uppercase;
            background: linear-gradient(89.7deg, #17EA85 2.27%, #14D0E6 23.6%, #17EA85 44.41%, #14D0E6 64.17%, #17EA85 82.99%, #14D0E6 99.74%);
            -webkit-background-clip: text;
            -webkit-text-fill-color: transparent;
            background-clip: text;
            text-fill-color: transparent;
            text-shadow: 0px 0px 8px rgba(20, 208, 229, 0.5607843137);
         }

      }
   }

   @media (max-width: 768px) {
      .photoModifyModelBtnRow {
         display: grid !important;
         grid-template-columns: 1fr !important;
         gap: 15px !important;

         .LoginButton {
            margin-left: -13px;
         }
      }
   }
   @media (max-width: 992px) {
      .ProfilePaneWrapper {
         .subTitle {
            padding-top: 6px !important;
         }
      }
   }
}

.BuyButton {
   background: linear-gradient(95.34deg, #FAFF00 6.28%, #00FFC2 111.13%);
   box-shadow: 0px 2px 0px rgba(0, 0, 0, 0.51);
   border-radius: 4px;
   transform: skew(-10deg, 0deg);
   border: 1px solid #2FFF9E;
   /*border-image-source: linear-gradient(103.05deg, #2FFF9E 7.22%, #F9FF01 93.09%);*/
   text-align: center;
   letter-spacing: -0.01em;
   padding: 9px 27px;
   /*font-weight: 700;
   width: 100%;*/
   height: 42px;
   font-family: "ObjectSans-Heavy", Helvetica, Arial, sans-serif;
   font-size: 16px;
   /*line-height: 20px;*/
   text-transform: uppercase;
   margin-left: auto;
   margin-right: auto;
   margin-top: 12px;
   // :hover {
   //   background: linear-gradient(95.34deg, #00FFC2 6.28%, #FAFF00 111.13%);
   // }

   :visited {
      background: linear-gradient(95.34deg, #B0FFCA 6.28%, #F2FF7E 111.13%);
   }

   img {
      transform: skew(10deg, 0deg);
      margin-left: 15px;
      margin-top: -5px;
   }
}

.BuyButton:hover {
   background: linear-gradient(95.34deg, #00FFC2 6.28%, #FAFF00 111.13%);
}

.popText {
   color: rgba(149, 166, 183, 1);
   font-size: 16px;
   line-height: 20px;

   span {
      color: rgba(23, 234, 133, 1);
   }
}

.TextError {
   color: #FF002E;

}

.DivHigh {
   height: 25px;
}

.GrayButton {
   background: linear-gradient(0deg, #0E2029, #0E2029) padding-box;
   border-radius: 2px;
   transform: skew(-10deg, 0deg);
   text-transform: uppercase;
   padding: 8px 25px;
   transition: all 1s cubic-bezier(0.19, 1, 0.22, 1);
   border: 1px solid #FAFF01;
   -webkit-background-clip: text;
   background-clip: text;
   color: transparent;


   .GrayButton:hover {
      background: $grayButtonHover;
   }

   text {
      background-image: linear-gradient(0deg, #FAFF01, #39FF96);
      font-size: 16px;
      line-height: 20px;
      -webkit-background-clip: text;
      background-clip: text;
      color: transparent;
      font-family: "PPObjectSans-BoldSlanted";
   }
}

.unscrew {
   transform: skew(-10deg, 0deg);
   width: 50% !important;
   max-width: 165px;
   box-sizing: border-box;
   border: solid 1px #3E5168;
   height: 44px !important;
   border-radius: 4px;
   background-color: #04151D;
   background-image: linear-gradient(90deg, rgba(4, 21, 29, 1.00) 0%, rgba(11, 28, 53, 0.00) 100%);
   color: #ffffff;
   padding: 20px 20px !important;
   font-size: 16px;
   font-family: 'SolinaLight', Helvetica, Arial, sans-serif;

}

.Inputsmall {
   transform: skew(-10deg, 0deg) !important;
   width: 40px !important;
   margin-left: 5px;
   margin-right: 5px;
   padding-left: 10px !important;
   padding-right: 10px !important;
   text-align: center;
   padding-top: 0 !important;
   height: 44px !important;
}

.PopImg {
   max-width: 211px;
   margin-bottom: -25px;
   margin-top: -20px;
}


.text24Heading {
   font-size: 24px;
   line-height: 28px;
}

.text18 {
   font-size: 18px;
   line-height: 24px;
}

.text22 {
   font-size: 22px;
   line-height: 26px;
}

.Radial1 {
   width: 900px;
   height: 900px;
   left: -515px;
   top: -350px;
   background: radial-gradient(32.65% 32.65% at 50% 50%, #04F0FF 0%, rgba(20, 208, 230, 0) 100%);
   mix-blend-mode: normal;
   position: absolute;
   opacity: 0.14;
   z-index: -1;
}

.mainHeaderH1 > span {
   position: relative;
   display: contents;
}

.submainHead {
   font-family: 'Montserrat', sans-serif;
   font-size: 52px;
   line-height: 58px;
   font-weight: 800;
   font-style: italic;
   letter-spacing: 2%;
   text-transform: uppercase;
   background: linear-gradient(89.7deg, #17EA85 2.27%, #14D0E6 23.6%, #17EA85 44.41%, #14D0E6 64.17%, #17EA85 82.99%, #14D0E6 99.74%);
   -webkit-background-clip: text;
   -webkit-text-fill-color: transparent;
   background-clip: text;
   text-fill-color: transparent;
   text-transform: uppercase;
   text-shadow: 0px 0px 8px rgba(20, 208, 229, 0.56);
}

.Font16 {
   font-family: 'ObjectSans-Regular';
   font-size: 16px;
   color: #95A6B7;
   line-height: 20px;
}

.Font16 a {
   background-image: linear-gradient(0deg, #17EA85, #14D0E6);
   -webkit-background-clip: text;
   background-clip: text;
   color: transparent !important;
}

.simpleHeader {
   font-family: 'SolinaLight';
   font-style: normal;
   font-weight: 200;
   font-size: 32px;
   line-height: 42px;
   letter-spacing: 0.02em;
   text-transform: uppercase;
   color: #FFFFFF;
   margin: 25px 0 25px 0;
}

.h3Sub {
   font-family: 'OSRegular';
   font-size: 18px;
   line-height: 24px;
   /* identical to box height, or 133% */

   letter-spacing: -0.01em;

   /* Neutral/white */

   color: #FFFFFF;

}

.navbar-expand-lg .navbar-nav {
   justify-content: right;
}

.lightGreenButton:hover {
   background: linear-gradient(95.34deg, #00FFC2 6.28%, #FAFF00 111.13%);
}

.grayBtn {
   box-shadow: 0px 2px 0px rgba(0, 0, 0, 0.51);
   backdrop-filter: blur(45px);
   border-radius: 2px;
   font-size: 16px;
   line-height: 20px;
   text-align: center;
   letter-spacing: -0.01em;
   text-transform: uppercase;
   background: linear-gradient(180deg, #FAFF01 0.03%, #39FF96 86.64%);
   -webkit-background-clip: text;
   -webkit-text-fill-color: transparent;
   background-clip: text;
   text-fill-color: transparent;
   border: 1px solid #ffffff24;
}

.marginTop42 {
   margin-top: 42px;
}

.subMainHeaderH1Wrapper {
   display: flex;
   flex-direction: column;
   justify-content: center;
   position: relative;
}

.subMainHeaderH1 {
   font-weight: 800;
   font-size: 52px;
   line-height: 58px;
   letter-spacing: 0.02em;
}

.sub-white-heading {
   font-size: 32px;
   line-height: 42px;
   letter-spacing: 2%;
   font-family: 'SolinaLight';
   font-style: normal;
   text-transform: uppercase;
}

.font78 {
   font-style: italic;
   font-weight: 700;
   font-size: 78px;
   line-height: 90px;
   letter-spacing: 0.02em;
   text-transform: uppercase;
}

.mt30 {
   margin-top: 30px;
}

.mt40 {
   margin-top: 40px;
}

.mb40 {
   margin-bottom: 40px;
}

.mt99 {
   margin-top: 99px;
}

.ml25 {
   margin-left: 25px;
}

.copyArea {
   /*padding: 0px 44px 24px 44px;*/
   margin-top: -82px;
}

.copyArea h1 {
   text-transform: uppercase;
   /*font-family: "OSRegular";*/
   font-family: 'SolinaLight';
   font-size: 18px;
   line-height: 22px;
}

.copyArea2 {
   margin-top: -82px;
}

.copyArea2 h1 {
   text-transform: uppercase;
   /*font-family: "OSRegular";*/
   font-family: 'SolinaLight';
   font-size: 18px;
   line-height: 22px;
}

.bigAddInnerWrapper {
   border: 1px solid #ffffff36;
}

.p18 {
   font-size: 18px;
}

.mainHeaderH1 span .img-span {
   background-image: url("../../assets/images/Blue-Light-Effect-Transparent 1.webp");
   position: absolute;
   display: inline-block;
   width: 252px;
   height: 242px;
   top: -59px;
   left: -110px;

}

.margin50 {
   margin-bottom: 50px;
   margin-top: 98px;
}

.checkWrap {
   width: 100%;
   display: inline-flex;
   justify-content: center;
   margin-bottom: 30px;
}

.checkWrap svg {
   color: #17EA85;
   font-size: 55px;
}

.transCard {
   padding: 44px;
   width: 100%;
   filter: drop-shadow(10px 22px 47px rgba(0, 0, 0, 0.13));
   -webkit-backdrop-filter: blur(20px);
   backdrop-filter: blur(20px);
   border-radius: 2px;
   position: relative;
   margin-bottom: 20px;
   text-align: center;
   /* warning: gradient uses a rotation that is not supported by CSS and may not behave as expected */;


}

.transCard img {
   padding-bottom: 44px;

}

.bluePane {
   background: linear-gradient(180deg, rgba(18, 34, 73, 0.5) 0%, rgba(11, 28, 53, 0) 100%);
   border: 1px solid #0c2b3e;
}

.purplePane {
   background: linear-gradient(180deg, rgba(65, 14, 51, 0.5) 0%, rgba(53, 11, 41, 0) 100%);
   border: 1px solid rgba(230, 20, 20, 0.2);
}

.marginTop117 {
   margin-top: 117px;
}

.marginBottom-32 {
   margin-bottom: 32px;
}

.mt129 {
   margin-top: 129px;
}

.gradientBot {
   background: linear-gradient(180deg, rgba(3, 21, 30, 0) 17.39%, #010D13 90.22%);
   bottom: 50px;
   position: absolute;
   height: 138px;
   width: 100%;
}

.gradientTop {
   background: linear-gradient(180deg, rgba(1, 13, 19, 0.92) 0%, rgba(3, 21, 30, 0) 100%);
   top: 0px;
   position: absolute;
   height: 138px;
   width: 100%;
}

.btnSetList {
   list-style: none;
   padding: 0;
   margin: 0;
   margin-top: 100px;
}

.btnSetList li {
   width: 100%;
   display: inline-block;
   margin-bottom: 32px;
}

@media only screen and (max-width: 900px) {
   .btnSetList {
      list-style: none;
      padding: 0;
      margin: 0;
      margin-top: 0px;
   }
}

.dropdown .btn {
   width: 100%;
   text-align: left;
   /*padding-right: 12px;*/
}


.blue-gradient-btn {
   display: flex;
   flex-direction: row;
   justify-content: center;
   align-items: center;
   padding: 16px 24px;
   background: linear-gradient(180deg, rgba(18, 34, 73, 0.5) 0%, rgba(11, 28, 53, 0) 100%);
   filter: drop-shadow(10px 22px 47px rgba(0, 0, 0, 0.13));
   -webkit-backdrop-filter: blur(20px);
   backdrop-filter: blur(20px);
   border-radius: 2px;
   font-weight: 400;
   font-size: 16px;
   line-height: 20px;
   text-align: center;
   letter-spacing: -0.01em;
   color: #FFFFFF;
   border: 1px solid #ffffff36;
}

@media only screen and (max-width: 900px) {
   .blue-gradient-btn {
      font-size: 14px;
      padding: 15px 10px;
   }
}


/*advert section*/
.advertWrapper {
   display: flex;
   justify-content: left;
}

.doubleSizeDiv {
   display: flex;
   flex-direction: column;
   padding: 10px;
   position: relative;
   width: 60%;
}

.singleSizeDiv {
   display: flex;
   flex-direction: column;
   padding: 10px;
   width: 40%;
}

.gradientArea {
   position: absolute;
   width: 100%;
   height: 39%;
   left: 0;
   bottom: -3px;
   background: linear-gradient(180deg, rgba(3, 20, 28, 0) 9.48%, #051620 74.06%);
   z-index: 10;
}

.mainImgWrapper {
   display: flex;
   position: relative;
   z-index: -1;
}

.subAdDiv {
   display: flex;
   flex-direction: column;
   position: relative;
   margin-bottom: 15px;
   border: #ffffff4a 1px solid;
}

.subAdDiv .mutedH1Font {
   /*font-size: 24px;*/
}

.subAdDiv .mainImgWrapper {
   overflow: hidden;
   /*height: 187px;*/
}

.datelbl {
   padding: 25px 44px;
}

span.datelbl {
   position: absolute;
   bottom: 55px;
   z-index: 12;
   color: #95A6B7;
   font-size: 12px;
}

.mainImgWrapper img {
   width: 100%;
}

.readMore {
   font-family: 'ObjectSans-Regular';
   font-style: italic;
   font-weight: 800;
   font-size: 14px;
   line-height: 18px;
   text-decoration: none;
   letter-spacing: -0.01em;
   text-transform: uppercase;
   color: #17EA85 !important;
   margin-top: 24px;
   display: flex;
}

.readMore span {
   display: flex;
   align-items: center;
   padding-left: 10px;
}

.headerWrapper {
   display: inline-block;
   width: 100%;
   padding: 0px 10px 25px 10px;
}

.gradient4 {
   background: linear-gradient(180deg, rgba(3, 21, 30, 0) 5.01%, #03141C 78.82%);
   position: absolute;
   bottom: 0px;
   height: 139px;
   left: 0;
   z-index: 9;
   width: 100%;
   overflow: visible;
}

.gradient5 {
   background: linear-gradient(180deg, #03141C 0%, rgba(3, 20, 28, 0) 100%);

   position: absolute;
   top: 0px;
   height: 350px;
   left: 0;
   z-index: -1;
   width: 100%;
   overflow: visible;
}

/*team card*/
.bigAddInnerWrapper {
   border: 1px solid #ffffff36;
}

.headerWrapper {
   display: inline-block;
   width: 100%;
   padding: 0px 10px 25px 10px;
}

.uniformImgwrapper {
   position: absolute;
   right: 0;
}

.selectWrapper select {
   font-family: 'ObjectSans-Regular';
   width: auto;
   margin-right: 12px;
   float: left;
   background: #111E33;
   box-shadow: 10px 22px 47px rgba(0, 0, 0, 0.13);
   -webkit-backdrop-filter: blur(20px);
   backdrop-filter: blur(20px);
   border-radius: 4px;
   padding: 16px 45px 16px 20px;
   color: #fff;
   border: 0;
   font-size: 17px;
   font-weight: 100;
}


.selectWrapper .input-group {
   float: left;
   width: 36%;
   font-size: 18px;
}

.selectWrapper .input-group input {
   font-family: 'ObjectSans-Regular';
   background: #111E33;
   box-shadow: 10px 22px 47px rgba(0, 0, 0, 0.13);
   backdrop-filter: blur(20px);
   border-radius: 4px;
   padding: 14px 20px;
   color: #fff;
   font-size: 16px;
   border: 0;
}

.input-group input::-ms-input-placeholder { /* Edge 12-18 */
   color: #95A6B7;
}

.input-group input::placeholder {
   color: #95A6B7;
}

.selectWrapper .input-group-text {
   padding: 18px;
   background: #111E33;
   color: #fff;
   border: none;
}

.input-group input::-ms-input-placeholder {
   color: #95A6B7;
}

.input-group input::placeholder {
   color: #95A6B7;
}

textarea.form-control {
   color: #FFFFFF;
   height: 100px;
   background: linear-gradient(91.15deg, rgba(4, 21, 29, 0.5) 3.16%, rgba(11, 28, 53, 0) 94.56%);
   border: 1px solid #1F2A36;
   border-radius: 2px;
   resize: none;
}

.fc-wrapper {
   width: auto;
   float: left;
   position: relative;
}

.float-right {
   float: right;
}

.fc-wrapper svg {
   vertical-align: middle;
   position: relative;
   z-index: 10;
   /*right: 13px;*/
   pointer-events: none;
   left: -10px;
   color: #FFFFFF;
}

.fc-searctText {
   width: 50%;
}

.fc-wrapper .searchtxt {
   width: auto;
   position: relative;
   padding-left: 10px;
}

.fc-wrapper .playerSearch, .player-fc-wrapper {
   width: 100%;
}

.community .searchtxt {
   padding-left: 0px;
}

.matches-radio-wrapper .searchtxt {
   padding-right: 8px;
}

.clear-button {
   margin: 0 auto !important;
}

.idCardInnerWrapper {
   background: linear-gradient(180deg, rgba(26, 38, 57, 0.5) 0%, rgba(11, 28, 53, 0.5) 100%);
   box-shadow: 10px 22px 47px rgba(0, 0, 0, 0.13);
   backdrop-filter: blur(20px);
   /* Note: backdrop-filter has minimal browser support */
   border-radius: 2px;
   display: flex;
   flex-direction: column;
   padding: 32px;
   border: 1px solid #ffffff36;
}

.pupleidCardInnerWrapper {
   background: linear-gradient(180deg, rgba(65, 14, 51, 0.5) 0%, rgba(53, 11, 41, 0) 100%);
   filter: drop-shadow(10px 22px 47px rgba(0, 0, 0, 0.13));
   backdrop-filter: blur(20px);
   /* Note: backdrop-filter has minimal browser support */
}

.cardimgWrapper {
   display: flex;
   flex-wrap: wrap;
   justify-content: center;
   padding: 0px 0 32px 0;
}

.idCardWrapper {
   display: flex;
   flex-direction: column;
   padding: 25px 0;
}

.mt-50 {
   margin-top: 50px;
}

.socialOut label {
   color: #000;
   font-size: 9px;
   font-weight: 700;
   font-family: "OSHeavy";
}

.cardimgWrapper img {
   width: 100px;
}

.memberName {
   font-size: 20px;
   text-transform: uppercase;
   text-align: center;
   line-height: 24px;
}

label.memberrank {
   color: #95A6B7;
   display: flex;
   margin-bottom: 20px;
   margin-top: 10px;
   text-align: center;
}

.socialWrapper {
   display: flex;
   justify-content: flex-start;
}


.socialOut {
   width: 20px;
   height: 20px;
   background: linear-gradient(95.34deg, #FAFF00 6.28%, #00FFC2 111.13%);
   box-shadow: 0px 2px 0px rgba(0, 0, 0, 0.51);
   margin: 7px;
   border-radius: 50%;
   display: flex;
   justify-content: center;
   align-items: center;
}

.socialOut img {
   width: 9px;
   height: 9px;
   display: flex;
}

.datelN1 {
   color: #95A6B7;
   font-size: 12px;
   line-height: 16px;

}

@media (min-width: 768px) {
   .datelN1 {
      color: #95A6B7;
      font-size: 14px;
      line-height: 18px;

   }

   .socialOut {
      width: 32px;
      height: 32px;
      background: linear-gradient(95.34deg, #FAFF00 6.28%, #00FFC2 111.13%);
      box-shadow: 0px 2px 0px rgba(0, 0, 0, 0.51);
      margin: 7px;
      border-radius: 90px;
      display: flex;
      justify-content: center;
      align-items: center;
   }

   .socialOut img {
      width: 16px;
      height: 16px;
      display: flex;
   }

   .socialOut label {
      color: #000;
      font-size: 16px;
      font-weight: 700;
      font-family: "OSHeavy";
   }

   .cardimgWrapper img {
      width: 130px;
   }

   span.datelbl {
      position: absolute;
      bottom: 55px;
      z-index: 12;
      color: #95A6B7;
      font-size: 14px;
      line-height: 18px;
   }

   .copyArea h1 {
      text-transform: uppercase;
      /*font-family: "OSRegular";*/
      font-family: 'SolinaLight';
      font-size: 24px;
      line-height: 32px;
   }

   .copyArea2 h1 {
      text-transform: uppercase;
      /*font-family: "OSRegular";*/
      font-family: 'SolinaLight';
      font-size: 32px;
      line-height: 42px;
   }

   .copyArea {
      /*padding: 0px 44px 24px 44px;*/
      margin-top: -132px;
   }

   .readMore {
      font-family: 'OSHeavy';
      font-style: italic;
      font-weight: 700;
      font-size: 16px;
      line-height: 20px;
      text-decoration: none;
      letter-spacing: -0.01em;
      text-transform: uppercase;
      color: #17EA85 !important;
      margin-top: 24px;
      display: flex;
   }

}


.openedFaq {
   background: linear-gradient(180deg, rgba(26, 38, 57, 0.5) 0%, rgba(11, 28, 53, 0.5) 100%);
   box-shadow: 10px 22px 47px rgba(0, 0, 0, 0.13);
   backdrop-filter: blur(20px);
   /* Note: backdrop-filter has minimal browser support */
   border-radius: 40px;
   width: 32px;
   display: flex;
   height: 32px;
   justify-content: center;
   align-items: center;
   float: right;
   cursor: pointer;
}

.closedFaq {
   box-shadow: 10px 22px 47px rgba(0, 0, 0, 0.13);
   backdrop-filter: blur(20px);
   border-radius: 40px;
   width: 32px;
   display: flex;
   height: 32px;
   justify-content: center;
   align-items: center;
   float: right;
   background: linear-gradient(95.34deg, #FAFF00 6.28%, #00FFC2 111.13%);
   box-shadow: 0px 2px 0px rgba(0, 0, 0, 0.51);
   border-radius: 40px;
   cursor: pointer;
}

.faqHeading {
   margin-top: 30px;
   padding-bottom: 25px;
   float: left;
   width: 100%;
   border-top-width: 0;
   text-transform: uppercase;
}

.faqHeading h1 {
   margin: 0;
}

.faqDetail {
   padding: 0px;
   border-bottom: 0;
   /* border-bottom: 1px solid #ffffff36; */
   float: left;
   width: 100%;
   padding-right: 280px
}

.neonLeft {
   position: absolute;
   background-image: url("../../assets/images/neon flash 2.webp");
   background-position: 0 0;
   background-repeat: no-repeat;
   background-size: 100%;
   height: 750px;
   width: 400px;
   left: 0;
   top: -260px;
   opacity: 0.1;
}

.neonRight {
   position: absolute;
   background-image: url("../../assets/images/neon flash 3.webp");
   background-position: 0 0;
   background-repeat: no-repeat;
   background-size: 100%;
   height: 750px;
   width: 400px;
   right: 0;
   top: -220px;
   opacity: 0.1;
}

.lighBlueEllipseLeft {
   position: absolute;
   background-image: url("../../assets/images/Ellipse 626.webp");
   background-position: 0 0;
   background-repeat: no-repeat;
   background-size: 100%;
   height: 1000px;
   width: 500px;
   left: 0;
   top: -500px;
   opacity: .7;
   z-index: -2;
}

.lighBlueEllipseRight {
   position: absolute;
   background-image: url("../../assets/images/Ellipse 645.webp");
   background-position: 0 0;
   background-repeat: no-repeat;
   background-size: 100%;
   height: 1000px;
   width: 500px;
   right: 0;
   top: -500px;
   z-index: -2;
}

.perpleEllipseRight {
   position: absolute;
   background-image: url("../../assets/images/Ellipse 643.webp");
   background-position: 0 0;
   background-repeat: no-repeat;
   background-size: 100%;
   height: 750px;
   width: 400px;
   right: 0;
   bottom: 0px;
}

.gradientSphear {
   position: absolute;
   background-image: url("../../assets/images/Ellipse-half.webp");
   background-position: 91px 0px;
   background-repeat: no-repeat;
   background-size: 70%;
   height: 592px;
   width: 300px;
   right: 0;
   top: -320px;
   opacity: 1;
   z-index: -2;
}

@media screen and (max-width: 768px) {
   .gradientSphear {
      height: 400px;
      width: 100px;
      background-size: 120%;
      right: 0px;
   }

   .mobile {
      .mobile-lang {
         justify-content: center;
         flex-direction: column;
         align-items: center;
         /* .langDrop{
             margin: 0px 0 20px 0;
         } */
      }
   }
}

.neonLight {
   background-image: url("../../assets/images/neonLight.webp");
   background-position: 0px -2px;
   background-repeat: no-repeat;
   background-size: 100%;
   position: relative;
   top: -50px;
   padding-top: 50px;
}

.bottomGradient {
   background: radial-gradient(14.65% 27.65% at 50% 97%, #04F0FF 0%, rgba(20, 208, 230, 0) 100%);
   mix-blend-mode: normal;
   opacity: 0.23;
   position: absolute;
   left: 0;
   width: 96vw;
   height: 442px;
   bottom: -24px;
   z-index: -1;
}

.social {
   display: flex;
   float: right;
   margin-left: 26px;
   width: 52px;
   height: 52px;
   background: #0E2029;
   border-radius: 90px;
   justify-content: center;
}

.social img {
   width: 24px;
   height: 38px;
   padding-top: 14px
}

.social img {
   width: 24px;
}

.footerLinks {
   text-decoration: none;
   font-size: 16px;
   color: #fff;
   font-style: normal;
   font-weight: 400;
   line-height: 28px;
   letter-spacing: -0.01em;
}

.bottom-footer {
   margin-top: 33px;
}

/*list*/
.flexParent {
   -moz-column-count: 4;
   -webkit-column-count: 4;
   column-count: 4;
   -moz-column-gap: 0px;
   -webkit-column-gap: 0px;
   column-gap: 0px;
   margin-top: 40px;
}

.flexParentUpdate {
   max-width: 1500px;
   margin: 0 auto;
   display: grid;
   grid-gap: 20px;
   grid-template-columns: repeat(auto-fill, 23%);
   grid-auto-rows: 20px;
   justify-content: center;
   align-content: center;
}

.my-masonry-grid {
   display: -webkit-box; /* Not needed if autoprefixing */
   display: -ms-flexbox; /* Not needed if autoprefixing */
   display: flex;
   margin-left: -30px; /* gutter size offset */
   width: auto;
   margin-top: 40px;
}

.my-masonry-grid_column {
   padding-left: 20px; /* gutter size */
   background-clip: padding-box;
}

/* Style your items */
.my-masonry-grid_column > div { /* change div to reference your elements you put in <Masonry> */
   margin-bottom: 20px;
}

.playerCardInnerWrapper {
   margin-bottom: 25px;
   width: 100%;
   display: inline-block;
   padding: 15px;
   background: linear-gradient(163.1deg, rgba(18, 34, 73, 0.5) 0.85%, rgba(8, 56, 130, 0) 111.21%);
   filter: drop-shadow(10px 22px 47px rgba(0, 0, 0, 0.13));
   -webkit-backdrop-filter: blur(20px);
   backdrop-filter: blur(20px);
   border-radius: 4px;
   position: relative;
   border: 1px solid rgba(255, 255, 255, 0.06);
   height: auto;
}

.matchCardInnerWrapper {
   -webkit-backdrop-filter: blur(20px);
   backdrop-filter: blur(20px);
   background: linear-gradient(163.1deg, rgba(18, 34, 73, .5) .85%, rgba(8, 56, 130, 0) 111.21%);
   border: 1px solid rgba(255, 255, 255, 0.06);
   border-radius: 4px;
   display: inline-block;
   -webkit-filter: drop-shadow(10px 22px 47px rgba(0, 0, 0, .13));
   filter: drop-shadow(10px 22px 47px rgba(0, 0, 0, .13));
   margin-bottom: 15px;
   position: relative;
   width: 100%;
   -webkit-transition-property: all;
   -webkit-transition-duration: 0.4s;
   -webkit-transition-timing-function: ease;
   transition-property: all;
   transition-duration: 0.4s;
   transition-timing-function: ease;
   -moz-transition-property: all;
   -moz-transition-duration: 0.4s;
   -moz-transition-timing-function: ease;
   -o-transition-property: all;
   -o-transition-duration: 0.4s;
   -o-transition-timing-function: ease;
   -webkit-animation-name: icons-anim;
   -webkit-animation-timing-function: ease;
   -webkit-animation-iteration-count: 1;
   -webkit-animation-duration: 0.8s;
   -webkit-transform-style: preserve-3d;
}

.matchCardInnerWrapper:hover {
   background: linear-gradient(163.1deg, rgb(18 34 73 / 78%) .85%, rgba(8, 56, 130, 0) 111.21%);
   text-decoration: none;
}

.fourcards {
   width: calc(25% - 25px);
   margin-right: 25px;
}

.threecards {
   width: calc(33.3% - 25px);
   margin-right: 25px;
}

.playerImgWrapper {
   position: relative;
}

.playerImgWrapper img {
   width: 100%;
}

.playerImgWrapper .heartsSection {
   padding: 15px;
   backdrop-filter: blur(50px);
   border-radius: 40px;
   position: absolute;
   top: 10px;
   right: 10px;
   font-style: normal;
   font-weight: 500;
   font-size: 20px;
   line-height: 20px;
   text-align: center;
   letter-spacing: 0.02em;
   color: #FFFFFF;
   text-decoration: none;
   display: flex;
   cursor: pointer;
   border: double 4px transparent;
   border-radius: 80px;
   border: 1px solid rgba(255, 255, 255, 0.2);
   /*background-image: url(https://storage.googleapis.com/meta11/serviceplatform/white-heart.svg);
   background-repeat: no-repeat;*/
   background-color: rgba(0, 0, 0, 0.15);
}

.playerImgWrapper .heartsSection label {
   margin-left: 10px;
   cursor: pointer;
}

.playerCardInnerWrapper > label {
   /*font-style: italic;*/
   font-weight: 600;
   font-size: 12px;
   line-height: 16px;
   letter-spacing: 0.08em;
   text-transform: uppercase;
   padding: 5px 10px;
   color: #FFFFFF;
   transform: skew(-8deg, 0deg);
   margin: 15px 0;
   font-family: 'ObjectSans-Regular';

}

.blue-pl-lbl {
   box-shadow: 0px 2px 0px rgba(0, 0, 0, 0.10);
   background: linear-gradient(92.07deg, #3421AA 2.01%, #0F7A89 100.44%) padding-box, linear-gradient(to right, #0F7A89, #3421AA) border-box;
   border-radius: 0px;
   border: 1px solid transparent;
   cursor: pointer;
}

.blue-pl-lbl:hover {
   background: linear-gradient(95.34deg, #0F7A89 6.28%, #3421AA 111.13%);
}

.pink-pl-lbl {
   background: linear-gradient(92.07deg, #AA219C 2.01%, #3E0AAD 100.44%);
   box-shadow: 0px 2px 0px rgba(0, 0, 0, 0.51);
}

.blueGreen-pl-lbl {
   box-shadow: 0px 2px 0px rgba(0, 0, 0, 0.10);
   background: linear-gradient(92.07deg, #21AA50 2.01%, #0A2EAD 100.44%) padding-box, linear-gradient(to right, #0A2EAD, #21AA50) border-box;
   border-radius: 0px;
   border: 1px solid transparent;
   cursor: pointer;
}

.blueGreen-pl-lbl:hover {
   background: linear-gradient(95.34deg, #0A2EAD 6.28%, #21AA50 111.13%);
}

.pink-purple-pl-lbl {
   background: linear-gradient(92.07deg, #AA219C 2.01%, #3E0AAD 100.44%);
   box-shadow: 0px 2px 0px rgba(0, 0, 0, 0.51);

}

.salmon-pink-pl-lbl {
   box-shadow: 0px 2px 0px rgba(0, 0, 0, 0.10);
   background: linear-gradient(92.07deg, #E11653 2.01%, #AD0A93 100.44%) padding-box, linear-gradient(to right, #AD0A93, #E11653) border-box;
   border-radius: 0px;
   border: 1px solid transparent;
   cursor: pointer;
}

.salmon-pink-pl-lbl:hover {
   background: linear-gradient(95.34deg, #AD0A93 6.28%, #E11653 111.13%);
}

.playerImgWrapper .activeHeart, .playerImgWrapper .heartsSection:hover {
   background: linear-gradient(180deg, #FAFF01 0.03%, #39FF96 86.64%);
   backdrop-filter: blur(50px);
   fill: #000000;
   border: 0px;
}

.heartsSection svg {
   fill: #ffffff;
}

.heartsSection:hover svg {
   fill: #000000;
}


/* .playerImgWrapper .activeHeart .heartDefault, .playerImgWrapper .heartsSection:hover .heartDefault{
display: none;
}
.playerImgWrapper .activeHeart .heartActive,  .playerImgWrapper .heartsSection:hover .heartActive{
    display: flex;
    } */
.playerImgWrapper .activeHeart > label, .playerImgWrapper .activeHeart > svg, .playerImgWrapper .heartsSection:hover > label, .playerImgWrapper .heartsSection:hover > svg {
   color: #03161F;
   font-weight: 500;
}

.playerDetailWrapper {
   font-style: normal;
   font-weight: 400;
   font-size: 20px;
   line-height: 26px;
   letter-spacing: 0.02em;
   color: #FFFFFF;
   overflow-wrap: break-word;
   width: 100%;
   white-space: nowrap;
   overflow: hidden;
   text-overflow: ellipsis;
}

@media (max-width: 768px) {
   .playerDetailWrapper {
      font-style: normal;
      font-size: 14px;
      line-height: 18px;
   }

   .qBotFaqModal {
      h1 {
         font-size: 24px;
      }
   }

}


.playerCardUserWrapper {
   display: flex;
   margin-top: 16px;
}

.playerCardUserWrapper > span {
   display: flex;
   align-items: center;
   margin-right: 10px;
}

.playerCardUserWrapper > label {
   display: flex;
   align-items: center;
   font-style: normal;
   font-weight: 400;
   font-size: 16px;
   line-height: 20px;
   letter-spacing: -0.01em;
   color: #95A6B7;
}

@media (max-width: 768px) {
   .playerCardUserWrapper > label {
      font-size: 12px;
      line-height: 16px;
   }
}

/*match card*/
.matchCards {
   width: 100%;
   display: flex;
   flex-wrap: wrap;
}

.matchInfoHeader {
   width: 100%;
   display: flex;
   padding: 15px;
   border-bottom: 1px solid #ffffff45;
   align-items: center;
}

a {
   text-decoration: none;
   /*color: #FFFFFF !important;*/
}

a:hover {
   color: #FFFFFF;
}

.matchStatslbl {
   align-items: center;
   border-radius: 2px;
   transform: skew(-5deg, 0deg);
   height: 24px;
   padding: 10px;
   padding-left: 10px;
   padding-right: 11px;
   display: flex;
   text-transform: uppercase;
   font-family: 'ObjectSans-Regular';
   font-size: 12px;
   letter-spacing: 0.05em;
}

.matchStatslbl a {
   color: #fff;
}

.blinking {
   -webkit-animation: 1s blink ease infinite;
   -moz-animation: 1s blink ease infinite;
   -ms-animation: 1s blink ease infinite;
   -o-animation: 1s blink ease infinite;
   animation: 1s blink ease infinite;

}

@keyframes blink {
   from, to {
      opacity: 0;
   }
   50% {
      opacity: 1;
   }
}

@-moz-keyframes blink {
   from, to {
      opacity: 0;
   }
   50% {
      opacity: 1;
   }
}

@-webkit-keyframes blink {
   from, to {
      opacity: 0;
   }
   50% {
      opacity: 1;
   }
}

@-ms-keyframes blink {
   from, to {
      opacity: 0;
   }
   50% {
      opacity: 1;
   }
}

.livedot {
   width: 4px;
   height: 4px;
   background: #fff;
   border-radius: 90px;
   display: flex;
   align-items: center;
   margin: 3px;
   padding-right: 5px;
}

.matchDate {
   display: flex;
   flex-direction: column;
   justify-content: right;
   width: 100%;
   margin-left: 10px;
   font-size: 12px;
   color: #95A6B7;
}

.mtDate, .mtTime {
   display: flex;
   justify-content: right;
   margin-top: 0;
   margin-bottom: 0;
}

.teamDetail {
   /*width: 100%;*/
   display: flex;
   padding: 8px;
   justify-content: left;
   position: relative;
}

.twodots {
   display: inline-block;
   padding: 0px 9px;
   border-radius: 90px;
   background: linear-gradient(180deg, rgba(26, 38, 57, 0.18) 0%, rgba(11, 28, 53, 0.18) 100%);
   box-shadow: 10px 22px 47px rgba(0, 0, 0, 0.13);
   -webkit-backdrop-filter: blur(20px);
   backdrop-filter: blur(20px);
   height: 22px;
   width: 22px;
   margin: 10px 13px;
}


.teamLogo, .teamName, .teamScore {
   display: flex;
   align-items: center;
}

.teamLogo {

}

.teamLogo a, .teamLogo img {
   width: 100%;
   width: 46px;

}

.teamName {
   font-family: 'ObjectSans-Regular';
   /*font-style: italic;
   font-weight: 700;*/
   font-size: 14px;
   line-height: 18px;
   /* or 129% */
   letter-spacing: 0.05em;
   text-transform: uppercase;

   /* Neutral/white */
   color: #FFFFFF;
   width: 200px;
   margin-left: 10px;
   transform: skew(-8deg, 0deg);
}

span.teamScore {
   width: 100%;
   flex-direction: column;
}

label.scoreLbl {
   font-family: 'SolinaExBI';
   width: 100%;
   display: flex;
   justify-content: right;
   /*font-style: italic;*/
   font-weight: 700;
   font-size: 24px;
   line-height: 30px;
   /* identical to box height, or 125% */
   text-align: right;
   letter-spacing: 0.02em;
   text-transform: uppercase;

   /* Accent Gradients/turquoise gradient */
   background: linear-gradient(89.7deg, #17EA85 2.27%, #14D0E6 23.6%, #17EA85 44.41%, #14D0E6 64.17%, #17EA85 82.99%, #14D0E6 99.74%);
   -webkit-background-clip: text;
   -webkit-text-fill-color: transparent;
   background-clip: text;
   text-fill-color: transparent;
   padding-right: 3px;
}

label.empty {
   background: #95A6B7;
   -webkit-background-clip: text;
   -webkit-text-fill-color: transparent;
   background-clip: text;
   text-fill-color: transparent;
}

label.emptyCompleted {
   background: #95A6B7;
   -webkit-background-clip: text;
   -webkit-text-fill-color: transparent;
   background-clip: text;
   text-fill-color: transparent;
}

label.oversLbl {
   width: 100%;
   display: flex;
   justify-content: right;
   align-items: center;
   font-style: normal;
   font-weight: 400;
   font-size: 12px;
   line-height: 16px;
   /* identical to box height, or 133% */
   text-align: right;
   /* Neutral/grey */
   color: #95A6B7;
   padding-right: 3px;
}

.liveLblPink {
   box-shadow: 0px 2px 0px rgba(0, 0, 0, 0.51);
   background: linear-gradient(92.07deg, #E11653 2.01%, #AD0A93 100.44%) padding-box, linear-gradient(to right, #AD0A93, #E11653) border-box;
   border-radius: 0px;
   border: 1px solid transparent;
   color: #ffffff;
}

.liveLblPink:hover {
   background: linear-gradient(95.34deg, #AD0A93 6.28%, #E11653 111.13%);
}

.upcomeGreen {
   box-shadow: 0px 2px 0px rgba(0, 0, 0, 0.51);
   background: linear-gradient(92.07deg, #19A62F 2.01%, #DBB908 100.44%) padding-box, linear-gradient(to right, #DBB908, #19A62F) border-box;
   border-radius: 0px;
   border: 1px solid transparent;
   color: white;
   width: fit-content;
}

.upcomeGreen:hover {
   background: linear-gradient(95.34deg, #DBB908 6.28%, #19A62F 111.13%);
}

.completedBlue {
   box-shadow: 0px 2px 0px rgba(0, 0, 0, 0.51);
   background: linear-gradient(92.07deg, #3421AA 2.01%, #0F7A89 100.44%) padding-box, linear-gradient(to right, #0F7A89, #3421AA) border-box;
   border-radius: 0px;
   border: 1px solid transparent;
   color: white;
   width: fit-content;
}

.completedBlue:hover {
   background: linear-gradient(95.34deg, #0F7A89 6.28%, #3421AA 111.13%);
}

.adImgWrap {
   width: 100%;
   float: left;
}

.adImgWrap img {
   width: 100%;
}

.gray-text-sub {
   font-size: 12px;
   line-height: 16px;
   letter-spacing: -0.01em;
   color: #95A6B7;
   text-transform: uppercase;
   margin-bottom: 10px;
}

.quest-label {
   font-family: 'ObjectSans-Regular';
   font-size: 16px;
   line-height: 20px;
   letter-spacing: -0.01em;
   text-transform: uppercase;
   color: #FFFFFF;
   margin-bottom: 10px;
   font-style: italic;
   font-weight: 600;
}

.MenuDropdown {
   font-size: 16px;
   color: #fff;
   float: right;
   position: relative;
}

.MenuDropdown ul {
   list-style: none;
   padding: 0;
   background: #111E32;
   margin-top: 20px;
   position: absolute;
   min-width: 110px;
   right: 0;
   width: 159px;
   /* padding: 15px;*/
   border: 1px solid #ffffff28;
   z-index: 99;
   border-radius: 4px;
   line-height: 50px;
   font-size: 18px;
   font-family: 'ObjectSans-Regular';
}

.MenuDropdown li {
   cursor: pointer;
   padding: 0px !important;
   padding-left: 5px;
   color: #95A6B7 !important;
   /*padding-left: 25px!important;*/
   border-bottom: 1px solid #1F2A36;

}

.MenuDropdown ul li svg {
   fill: #95A6B7;
}

.MenuDropdown ul li:hover svg {
   fill: #17EA85;
}

.MenuDropdown li a {
   color: #95A6B7 !important;
   padding: 0px 10px !important;
   width: 100% !important;
   margin-top: 0 !important;
}

.MenuDropdown ul li:hover {
   color: #ffffff !important;
}

.MenuDropdown button {
   background: none;
   border: 0;
}

.teamDropdown {
   cursor: pointer;
   font-size: 14px;
   color: #fff;
   float: right;
   position: relative;
}

.teamDropdown ul {
   list-style: none;
   padding: 0;
   background: #111E32;
   margin-top: 5px;
   position: absolute;
   min-width: 200px;
   right: 0;
   padding: 15px;
   border: 1px solid #ffffff28;
   z-index: 99;
   border-radius: 4px;
}

.teamDropdown li {
   cursor: pointer;
   padding-left: 5px;
   color: #95A6B7;
   padding-left: 25px !important;
   line-height: 40px;
}

.teamDropdown li a {
   padding: 10px 10px 10px 10px;
   border-bottom: 1px solid #ffffff1a;
   float: left;
   background: #0E2029;
   width: 100%;
   border: 1px solid #ffffff0d;
   font-size: 14px;
   line-height: 1;
}

.teamDropdown li:hover {
   background-color: #111E33 !important;
   background-image: url(https://storage.googleapis.com/meta11/serviceplatform/greenCheck.webp);
   background-repeat: no-repeat;
   padding-left: 25px !important;
   background-position: 0% 18px;
   color: #ffffff !important;
   background-position: left;
}

.dropImgWrap {
   width: 25px;
   display: inline-block;
   margin-right: 10px;
}

.teamDropdown label {
   font-family: "OSSlanted";
   font-size: 16px;
   line-height: 20px;
   /*font-weight: 700;*/
   letter-spacing: 0em;
   text-transform: capitalize;
}

.dropImgWrap img {
   width: 100%;
}

.teamDropdown svg {
   margin-left: 10px;
}

.teamDropdown > ul > li > a > svg {
   margin-left: 0;
   margin-right: 10px;
}

ul.teamMateUl {
   list-style: none;
   padding: 0;
   margin: 0;
   margin-bottom: 20px;
}

.teamMateUl li {
   float: left;
   width: 100%;
   margin-bottom: 20px;
}

span.teamImgWrap {
   width: 42px;
   display: flex;
}

span.teamImgWrap img {
   width: 100%;
}

span.teammatewrapper {
   display: flex;
   align-items: center;
}

span.currentPlayer {
   width: 280px;
   position: absolute;
   right: 15px;
   top: 15px;
   background: linear-gradient(180deg, rgba(26, 38, 57, 0.5) 0%, rgba(11, 28, 53, 0.5) 100%);
   border: 1px solid rgba(255, 255, 255, 0.06);
   box-shadow: 10px 22px 47px rgba(0, 0, 0, 0.13);
   backdrop-filter: blur(20px);
   border-radius: 2px;
   padding: 15px;
}

span.matediswrap {
   display: flex;
   align-items: center;
   flex-direction: column;
   padding-left: 10px;
   margin-top: 5px;
}

span.mateName {
   width: 100%;
   display: flex;
   font-size: 16px;
   margin-bottom: 3px;
}

span.mateRole {
   display: flex;
   justify-content: left;
   width: 100%;
   font-size: 12px;
   line-height: 16px;
   letter-spacing: -0.01em;
   color: #95A6B7;
}

ul.teamMateUl li:last-child {
   margin-bottom: 0;
}

.navbar-toggler.collapsed {
   margin-right: 15px;
   border: 0;
}

/*score card*/
.table-striped tbody tr:nth-child(odd) {
   background: rgb(26 38 57 / 37%) !important;
   border: 0;
   color: #fff;
}

.table-striped {
   border: 0px !important;
   margin-bottom: 0px;
}

.table-striped tr:nth-child(even) {
   background-color: #131f30;
   line-height: 30px;
}

.table-striped tr:nth-child(odd) {
   background-color: #071625;
   line-height: 30px;
}

.table th {
   border: 0;
   color: #95A6B7;
   font-size: 16px;
   line-height: 20px;
   letter-spacing: -0.01em;
   background: none;
   font-weight: 700;
   font-family: 'ObjectSans-Regular';
}

.table thead tr {
   border: 0;
   text-align: center;
}

.table tbody tr {
   border: 0;
}

.table tbody tr td {
   color: #95A6B7 !important;
   border: 0;
   padding: 12px 2px;
   font-size: 16px;
   line-height: 20px;
   text-align: center;
   letter-spacing: -0.01em;
   vertical-align: middle;
   position: relative;
   background: none;
}

.table tbody tr td img {
   position: absolute;
   left: -10px;
}

span.tdmainTxt {
   float: left;
   width: 100%;
   text-align: left;
   font-size: 14px;
}

span.tdmainSubTxt {
   text-align: left;
   float: left;
   width: 100%;
   font-size: 12px;
   line-height: 16px;
   letter-spacing: -0.01em;
   color: #95A6B7;
}

.table th.pl20, .table td.pl20 {
   padding-left: 20px;

}

.searchFieldWrapper {
   flex-grow: 1;
}

@media (min-width: 768px) {
   .team1scoreSection,
   .team2scoreSection {
      margin-top: 20px;
   }
}

@media (max-width: 768px) {
   .table th.pl20, .table td.pl20 {
      padding-left: 10px;

   }

   .heartLike {
      top: 25px !important;
      right: 25px !important;
   }

   .heartLikeFill {
      top: 25px !important;
      right: 25px !important;
   }
}

.table td.pl20 span {
   font-family: 'ObjectSans-Regular';
   color: #FFFFFF;
   font-size: 16px;
   line-height: 20px;
   letter-spacing: -0.01em;
   text-align: center;
   /*font-weight: bold;*/

}

@media (min-width: 1240px) {
   .matchesProfileGuidanceTip {
      /* background-color: red; */
      position: relative;

      > div { /*notificationWrapper*/
         width: fit-content;
         position: absolute;
         inset: unset !important;
         right: 0 !important;
         top: 100px !important;

         .arrow-up {
            right: 40px !important;
            top: -11px;
         }
      }
   }

   .matchesMatchesGuidanceTip {
      /* background-color: red; */
      position: relative;
      height: 2px;
      width: 100%;

      > div {
         inset: unset !important;
         position: absolute;
         top: 60px !important;
         right: 0px !important;
      }
   }
}

@media (max-width: 1241px) {
   .matchesProfileGuidanceTip {
      /* background-color: red; */
      position: relative;

      > div { /*notificationWrapper*/
         width: fit-content;
         position: absolute;
         inset: unset !important;
         right: 0 !important;
         top: 100px !important;

         .arrow-up {
            right: 40px !important;
            top: -11px;
         }
      }
   }

   .matchesMatchesGuidanceTip {
      /* background-color: red; */
      position: relative;
      height: 2px;
      width: 100%;

      > div {
         inset: unset !important;
         position: absolute;
         top: 60px !important;
         right: 0px !important;
      }
   }
}

@media (max-width: 1024px) {
   .matchesMatchesGuidanceTip {
      /* background-color: red; */
      position: relative;
      height: 2px;
      width: 100%;

      > div {
         inset: unset !important;
         position: absolute;
         top: 145px !important;
         right: 0px !important;
      }
   }
}

@media (max-width: 992px) {
   .matchesProfileGuidanceTip {
      /* background-color: red; */
      position: relative;

      > div { /*notificationWrapper*/
         width: fit-content;
         position: absolute;
         inset: unset !important;
         right: -20px !important;
         top: 80px !important;

         .arrow-up {
            right: 20px !important;
            top: -11px;
         }
      }
   }
}

@media screen and (max-width: 1200px) and (min-width: 769px) {
   .searchFieldWrapper {
      .arrow-right {
         transform: rotate(-90deg) !important;
         top: -17px;
         right: 20px;
      }

      .arrow-rightOutter {
         transform: rotate(-90deg) !important;
         top: -18px;
         right: 20px;
      }
   }
}


@media (max-width: 768px) {
   .table td.pl20 span {
      font-size: 14px;
      line-height: 18px;
   }
}

.table tbody tr td.td-highlited-txt {
   font-family: 'ObjectSans-Regular';
   color: #fff !important;
   font-weight: 700;
   font-style: italic;
}

.table tbody tr td.def-text {
   color: #95A6B7;
}

/*summary*/
.matchsummary {
   background: rgba(26, 38, 57, 0.5);
   padding: 5px 20px;
   margin-bottom: 20px;
   overflow: auto;
}

@media (max-width: 768px) {
   .matchsummary {

      padding: 5px 10px;

   }
}

.statwrap .stat-lbl {
   color: #95A6B7;
   font-size: 16px;
   line-height: 20px;
   letter-spacing: -0.01em;
   font-weight: 700;
   font-family: 'ObjectSans-Regular';
}

.stat-val .scoreLbl1 {
   /*font-family: 'ObjectSans-Regular';*/
   width: 100%;
   display: flex;
   font-size: 16px;
   line-height: 20px;
   text-align: right;
   text-transform: uppercase;
   background: linear-gradient(89.7deg, #17EA85 2.27%, #14D0E6 23.6%, #17EA85 44.41%, #14D0E6 64.17%, #17EA85 82.99%, #14D0E6 99.74%);
   -webkit-background-clip: text;
   -webkit-text-fill-color: transparent;
   padding-right: 10px;
   font-weight: bold;
}

.stat-val .oversLbl {
   width: 100%;
   display: flex;
   justify-content: right;
   align-items: center;
   font-style: normal;
   font-weight: 400;
   font-size: 16px;
   line-height: 16px;
   text-align: right;
   letter-spacing: -0.01em;
   color: #95A6B7;
   padding-right: 3px;
   align-items: center;
}

.stat-val {
   padding: 0px 10px;
   background: linear-gradient(89.7deg, #17EA85 2.27%, #14D0E6 23.6%, #17EA85 44.41%, #14D0E6 64.17%, #17EA85 82.99%, #14D0E6 99.74%);
   -webkit-background-clip: text;
   -webkit-text-fill-color: transparent;
   font-family: 'ObjectSans-Heavy';
   /*display: flex;*/
   justify-content: left;
   font-size: 16px;
   font-weight: bold;
}

span.statwrap {
   display: flex;
   align-items: center;
   margin: 10px 0;
   width: 100%;
}

.upperDiv {
   display: flex;
}

.statdiv {
   display: flex;
}

.paddingScoreCard {
   padding: 20px 0 0 0 !important;
}

/*scoreboard*/
.just-right {
   justify-content: right;
}

.team1Logo, .team2Logo {
   width: 75px;
}

.team1Logo img, .team2Logo img {
   width: 100%;
   border-radius: 100%;
}

/*.team1Name:after {*/
/*    background-image: url("https://storage.googleapis.com/meta11/serviceplatform/ball.webp");*/
/*    content: "";*/
/*    margin-left: 3px;*/
/*    width: 14px;*/
/*    height: 14px;*/
/*    display: inline-block;*/
/*    background-size: contain;*/
/*    background-repeat: no-repeat;*/
/*}*/
.teamBat:after {
   background-image: url("https://storage.googleapis.com/meta11/serviceplatform/bat.webp");
   content: "";
   margin-left: 3px;
   width: 14px;
   height: 14px;
   display: inline-block;
   background-size: contain;
   background-repeat: no-repeat;
}

.team1Name, .team2Name {
   width: 100%;
   font-size: 16px;
   text-transform: uppercase;
   font-family: 'ObjectSans-Regular';
   float: right;
   padding-right: 15px;
   line-height: 19px;
   color: #ffffff;
   font-style: italic;
   font-weight: 700;
}

.team1Score, .team2Score {
   font-style: italic;
   font-size: 32px;
   line-height: 40px;
   text-align: right;
   letter-spacing: 0.02em;
   text-transform: uppercase;
   color: #17EA85;
   width: 100%;
   font-family: 'SolinaExBI';
   padding-right: 15px;
}

.team1Overs {
   float: right;
   font-size: 14px;
   line-height: 18px;
   text-align: right;
   letter-spacing: -0.01em;
   color: #95A6B7;
   padding-right: 15px;
   font-family: 'ObjectSans-Regular';
}

.team1scoreSection, .team2scoreSection {
   display: flex;
   flex-direction: column;
}

.team2Score {
   text-align: left;
   padding-left: 15px;
   letter-spacing: 0.02em;
   text-transform: uppercase;
   color: rgba(255, 255, 255, 0.4);
}

.team1Score {

   color: rgba(255, 255, 255, 0.4);
}

.team2Name {
   padding-left: 15px;
}

.team2Overs {
   padding-left: 15px;
   font-size: 14px;
   line-height: 18px;
   letter-spacing: -0.01em;
   color: #95A6B7;
   font-family: 'ObjectSans-Regular';
}

.midSect {
   display: flex;
   flex-direction: column;
   align-items: center;
   justify-content: center;
}

.midDiv {
   margin: 0 10px;
   padding: 4px 8px 2px 8px;
   background: #DE4A4A;
   border-radius: 16px;
   font-size: 12px;
}

span.statLbl {
   margin: 0;
   font-size: 12px;
   line-height: 16px;
}

.userDrop .dropImgWrap {
   width: 36px;
   height: 36px;
   border-radius: 50px;
   margin-right: 0;
}

a.userDrop {
   padding-right: 0;
   margin-top: 10%;
}


.dropdown button {
   padding: 11px 15px;
   font-family: 'ObjectSans-Regular';
   width: auto;
   margin-right: 12px;
   float: left;
   background: #111E33;
   box-shadow: 10px 22px 47px rgba(0, 0, 0, 0.13);
   -webkit-backdrop-filter: blur(20px);
   backdrop-filter: blur(20px);
   border-radius: 4px;
   padding: 14px 20px 14px 20px;
   color: #95A6B7 !important;
   border: 0;
   font-size: 18px;
   font-weight: 100;
}

.dropdown button:hover, .dropdown button:focus {
   background-color: #111E33 !important;
}

.dropdown-toggle::after {
   font-family: "Font Awesome 5 Free";
   font-weight: 600;
   content: "\f107";
   border: 0px;
   /*padding-left: 13px;*/
   float: right;
   /*transition: transform 50ms ease;   */
}

.btn.show::after {
   transform: rotate(0deg);
   /* transition: transform 50ms ease;*/
   /*padding-right: 13px;*/
   padding-left: 0px;
}

/*.dropdown-toggle::after:focus {*/
/*    transform: rotate(-180deg);*/
/*    transition: transform 150ms ease;*/

/*}*/

/*.Darrow {*/
/*    transform: rotate(-180deg);*/
/*    transition: transform 150ms ease;*/

/*}*/

/*.Uarrow {*/
/*    transform: rotate(-360deg);*/
/*    transition: transform 150ms ease;*/

/*}*/

.dropdown.show .dropdown-toggle::after {
   transition: transform 150ms ease;
   content: "\f106";
}

.heartWrapper {
   position: absolute;
   right: 10px;
   width: 42px;
   height: 42px;
   background: rgba(0, 0, 0, 0.05);
   backdrop-filter: blur(42px);
   border-radius: 33.6px;
   text-align: center;
   padding: 14px;
   top: 16px;
   color: #fff;
}

.heartWrapper:hover {
   background: linear-gradient(180deg, #FAFF01 0.03%, #39FF96 86.64%);
   backdrop-filter: blur(50px);
   color: #03161F;
}

/*teams*/
.teamsTeamDetail {
   display: flex;
   flex-direction: column;
   padding: 0;
   position: relative;
}

.TeamsteamLogo {
   text-align: center;
   padding: 20px 20px 10px 20px;
}

.heartLike {
   position: absolute;
   right: 20px;
   top: 20px;
   width: 50px;
   height: 50px;
   border-radius: 90px;
   border: 1px solid #ffffff47;
   display: flex;
   align-items: center;
   justify-content: center;
   background: transparent;
}

.heartLike svg {
   font-size: 20px;
   color: #fff;
}

@media (hover: hover) {
   .heartLike:hover {
      background: linear-gradient(180deg, #FAFF01 0.03%, #39FF96 86.64%);
      backdrop-filter: blur(50px);
   }

   .heartLike:hover svg {
      color: #000;
   }
}


.heartLikeFill svg {
   font-size: 20px;

}

@media (hover: hover) {
   .heartLikeFill:hover {
      background: linear-gradient(180deg, #FAFF01 0.03%, #39FF96 86.64%);
      backdrop-filter: blur(50px);
   }

   .heartLikeFill:hover svg {
      color: #000;
   }
}


.heartLikeFill {
   position: absolute;
   right: 20px;
   top: 20px;
   width: 50px;
   height: 50px;
   border-radius: 90px;
   border: 1px solid #ffffff47;
   display: flex;
   align-items: center;
   justify-content: center;
   background: linear-gradient(180deg, #FAFF01 0.03%, #39FF96 86.64%);
   backdrop-filter: blur(50px);
}

span.TeamsteamName {
   text-align: center;
   margin-top: 10px;
   text-transform: uppercase;
   font-family: 'OSHeavySlanted';
   margin-bottom: 5px;
   font-style: italic;
   font-weight: 700;
   font-size: 16px;
   line-height: 20px;
   letter-spacing: -0.01em;
   color: #FFFFFF;
}

span.winsLabel {
   color: #95A6B7;
   width: 100%;
   text-align: center;
   display: inline-block;
   margin-bottom: 15px;
}

.matchIndicator {
   width: 28px;
   height: 28px;
   display: flex;
   justify-content: center;
   flex-wrap: wrap;
   align-items: center;
   border-radius: 90px;
   margin: 10px 4px;
}

.matchIndicator_tournament {
   width: 28px;
   height: 28px;
   display: inline-flex;
   justify-content: center;
   flex-wrap: wrap;
   align-items: center;
   border-radius: 90px;
   margin: 10px 4px;
}

.greenInd {
   background: rgba(56, 255, 151, 0.32);
   box-shadow: 0px 6px 33px rgba(3, 21, 30, 0.5);
   border-radius: 100px;
}

.purpleInd {
   background: rgba(255, 55, 103, 0.32);
   border-radius: 100px;
}

.grayInd {
   background: rgba(232, 255, 243, 0.32);
   box-shadow: 0px 6px 33px rgba(3, 21, 30, 0.5);
   border-radius: 100px;
}

.matchStatWrapper {
   display: flex;
   align-items: center;
   justify-content: center;
   color: #ffffff;
}

.cardFooter {
   background: linear-gradient(180deg, rgba(26, 38, 57, 0.5) 0%, rgba(11, 28, 53, 0.5) 100%);
   border-width: 0px 1px;
   border-style: solid;
   border-color: #1F2A36;
   border-radius: 0px 0px 2px 2px;
   display: flex;
   padding: 10px;
   min-height: 57px;
}

.cardFooterLeft {
   display: flex;
   flex-direction: column;
   width: 65%;
}

.flUpper {
   font-size: 12px;
   line-height: 16px;
   letter-spacing: -0.01em;
   color: #95A6B7;
   margin-bottom: 5px;
}

.flLower {
   font-size: 12px;
   line-height: 16px;
   letter-spacing: -0.01em;
   color: #FFFFFF;
}

.cardFooterRight {
   display: flex;
   align-items: center;
}

/*modal*/
.qBotFaqModal {
   h1.modal-main-header {
      font-size: 36px;
      line-height: 52px;
      letter-spacing: -0.02em;
      text-transform: uppercase;
      color: #FFFFFF;
      text-align: center;
   }
}

.modal .modal-lg, .modal .modal-xl {
   --bs-modal-width: 50vw;
}

.modal-content {
   background: #03161F;
   /*min-width: 580px;*/
   border-radius: 0px !important;
}

.modal-Para {
   font-size: 16px;
   line-height: 20px;
   text-align: center;
   letter-spacing: -0.01em;
   color: #95A6B7;
   font-family: 'OSRegular';
   margin-top: 3px;
}

.modal-Para-small {
   font-size: 14px;
   line-height: 20px;
   /*text-align: center;*/
   letter-spacing: -0.01em;
   color: #95A6B7;
   font-family: 'OSRegular';
   margin-top: 3px;
}

.modal-Para-small ol {
   padding-left: 15px;
}

.modal-Para ol {
   margin-bottom: 0px;
}

.modal-Para ol li {
   font-size: 15px;
   line-height: 17px;
   text-align: left;
   margin-top: 3px;
}

.modal-Para ul {
   margin-bottom: 0px;
}

.modal-Para ul li {
   font-size: 15px;
   line-height: 17px;
   text-align: left;
   margin-top: 3px;
}

.modal-topic {
   font-size: 18px;
   line-height: 22px;
   text-align: left;
   letter-spacing: -0.01em;
   color: #95A6B7;
   font-family: 'OSRegular';
   margin-top: 7px;
}

.modal-topic-small {
   font-size: 16px;
   line-height: 22px;
   text-align: left;
   letter-spacing: -0.01em;
   color: #95A6B7;
   font-family: 'OSRegular';
   margin-top: 7px;
}

.modal-topic-big {
   font-size: 22px;
   line-height: 22px;
   text-align: left;
   letter-spacing: -0.01em;
   color: #95A6B7;
   font-family: 'OSRegular';
   margin-top: 7px;
}

.modal-emoji {
   display: inline;
   font-size: 17px;
}

.modal-body {
   padding: 30px;
}

@media (max-width: 576px) {
   .modal-content {
      background: #03161F;
      min-width: auto;
      border-radius: 0px !important;
   }
}

.iconWrapper {
   display: flex;
   justify-content: center;
   margin-bottom: 32px;
}

.iconWrapper .mod-error {
   font-size: 50px;
   color: #F44336;
}

.upload-drag-drop {
   background: linear-gradient(180deg, rgba(26, 38, 57, 0.5) 0%, rgba(11, 28, 53, 0.5) 100%);
   border: 1px dashed rgba(255, 255, 255, 0.06);
   box-shadow: 10px 22px 47px rgba(0, 0, 0, 0.13);
   backdrop-filter: blur(20px);
   border-radius: 2px;
   padding: 40px;
   display: flex;
   flex-direction: column;
   font-family: 'OSRegular';
   margin-top: 30px;
   cursor: pointer;
}

.modal-big-txt {
   font-size: 22px;
   line-height: 28px;
   text-align: center;
   letter-spacing: -0.01em;
   width: 100%;
   display: inline-block;
   color: #95A6B7;
   margin-bottom: 10px;
}

.modal-big-txt a {
   color: #17EA85;
   text-decoration: none;

}

.p-mute {
   font-size: 14px;
   line-height: 18px;
   text-align: center;
   letter-spacing: -0.01em;
   color: #95A6B7;
}

.p-mute16 {
   font-size: 16px;
   line-height: 20px;
   text-align: center;
   letter-spacing: -0.01em;
   color: #95A6B7;
}

span.upload-ico-wrap {
   width: 48px;
   height: 48px;
   background: rgba(0, 0, 0, 0.05);
   backdrop-filter: blur(50px);
   border-radius: 40px;
   font-size: 20px;
   display: inline-flex;
   align-self: center;
   justify-content: center;
   border: 1px solid #ffffff2b;
   color: #17EA85;
   margin-bottom: 25px;
   align-items: center;
}

.upload-ico-wrap {
   width: 48px;
   height: 48px;
   background: rgba(0, 0, 0, 0.05);
   backdrop-filter: blur(50px);
   /* Note: backdrop-filter has minimal browser support */
   border-radius: 40px;
   font-size: 20px;
   display: inline-flex;
   align-self: center;
   justify-content: center;
   border: 1px solid #ffffff2b;
   color: #17EA85;
   margin-bottom: 25px;
   align-items: center;
}

.upload-imagewrapper {
   width: 100%;
   float: left;
   position: relative;
   margin-top: 30px;
}

.upload-imagewrapper img {
   width: 100%;
}

.loading-wrapper {
   position: absolute;
   height: 100%;

   /* white 6% stroke */
   border: 1px solid rgba(255, 255, 255, 0.06);
   border-radius: 2px;
   width: 100%;
   display: flex;
   justify-content: center;
   align-items: center;
   background: #03161fe0;

}

/*loading*/
.lds-ring {
   display: inline-block;
   position: relative;
   width: 80px;
   height: 80px;
}

.lds-ring div {
   box-sizing: border-box;
   display: block;
   position: absolute;
   width: 64px;
   height: 64px;
   margin: 8px;
   border: 8px solid #fff;
   border-radius: 50%;
   animation: lds-ring 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
   border-color: #fff transparent transparent transparent;
}

.lds-ring div:nth-child(1) {
   animation-delay: -0.45s;
}

.lds-ring div:nth-child(2) {
   animation-delay: -0.3s;
}

.lds-ring div:nth-child(3) {
   animation-delay: -0.15s;
}

@keyframes lds-ring {
   0% {
      transform: rotate(0deg);
   }
   100% {
      transform: rotate(360deg);
   }
}

.checkWrap {
   width: 100%;
   display: inline-flex;
   justify-content: center;
   margin-bottom: 30px;
}

.checkWrap svg {
   color: #17EA85;
   font-size: 55px;
}

a.closeModal {
   color: #fff;
   position: absolute;
   top: 12px;
   right: 8px;
}

/*progress bar*/
.progressBarWrapper {
   width: 100%;
   float: left;
   padding: 20px;
}

span.progressGroove {
   width: 100%;
   float: left;
   position: relative;
   height: 6px;
   background: #355A69;
   border-radius: 2px;
}

.progresStick {
   position: absolute;
   background: linear-gradient(90deg, #1BD71C 0%, #15D5B4 20.2%, #20BCD7 38.57%, #9B0BED 60.61%, #DB0F8D 83.3%, #E8122A 102.08%);
   border-radius: 2px;
   left: 0;
   height: 6px;
}

span.playerRole {
   float: left;
   font-size: 14px;
   line-height: 18px;
   letter-spacing: -0.01em;
   color: #95A6B7;
   margin-bottom: 5px;
}

span.playerLevel {
   font-size: 14px;
   line-height: 18px;
   letter-spacing: -0.01em;
   color: #FFFFFF;
   text-transform: uppercase;
   float: right;
   margin-bottom: 5px;
   font-family: 'ObjSans-HeavySlanted';
}

.player-wrapper .teamDetail {
   /*padding: 20px;*/
   text-align: left;

}

span.progressDot {
   position: absolute;
   width: 6px;
   height: 6px;
   border-radius: 90px;
   background: #fff;
   right: 0;
   top: 0;
}

.imgInnerWrap {
   width: 156px;
   height: 156px;
   overflow: hidden;
   /*background: #ccc;*/
   border-radius: 90px;
}

.imgInnerWrap img {
   margin-left: -2.5%;
   width: 100%;
   margin-top: -2.5%;
}

.player-wrapper .TeamsteamLogo {
   display: flex;
   justify-content: center;
   padding: 9px;
}

.player-wrapper .winsLabel {
   margin-top: 5px;
   margin-bottom: 5px;
}

.player-wrapper .winsLabel img {
   width: 20px;
   margin-right: 5px;
}

.player-wrapper .cardFooter {
   display: flex;
   flex-wrap: wrap;
   padding: 15px 20px;
}

.roleLeft, .batStyle, .bowlingStyle, .roleLeft33 {
   display: inline-block;
}

span.batStyle {
   width: 45%;
}

span.bowlingStyle {
   width: 100%;
}

.roleLbl, .roleVal {
   display: inline-block;
   width: 100%;
   float: left;
   margin-bottom: 5px;
}

span.roleLeft {
   width: 55%;
}

span.roleLeft33 {
   width: 33.3%;
}

span.roleLeft18 {
   width: 18.3%;
}

.roleLbl {
   font-size: 12px;
   line-height: 16px;
   /* identical to box height, or 133% */
   letter-spacing: -0.01em;

   /* Neutral/grey */
   color: #95A6B7;
}

.roleLbl18 {
   font-size: 18px;
   line-height: 21px;
   color: #95A6B7;
}

.roleVal {
   font-size: 14px;
   line-height: 18px;
   letter-spacing: -0.01em;
   color: #FFFFFF;
   /*font-weight: 700;*/
   font-family: 'ObjectSans-Regular';
   font-style: italic;
   text-transform: uppercase;
   font-weight: 700;
}

.roleVal img {
   margin-right: 5px;
}

.roleLeft, .batStyle {
   margin-bottom: 15px;
}

.statsSection {
   width: calc(33.3% - 25px);
   margin-right: 25px;
}

.statsSection .teamDetail {
   padding: 15px;
   border-width: 4px;
   border-style: solid;
   border: 0;
   border-image: radial-gradient(49.62% 14587.71% at 50.38% 50%, #2DFEE5 0%, rgba(45, 254, 229, 0) 100%) /* warning: gradient uses a rotation that is not supported by CSS and may not behave as expected */;
}

.yellowBorderBox {
   background: linear-gradient(180deg, rgba(26, 38, 57, 0.5) 0%, rgba(11, 28, 53, 0.5) 100%);
   border: 1px solid rgba(255, 255, 255, 0.06);
   box-shadow: 10px 22px 47px rgba(0, 0, 0, 0.13);
   backdrop-filter: blur(20px);
   border-radius: 2px;
}

.greenBorderBox {
   background: linear-gradient(180deg, rgba(26, 38, 57, 0.5) 0%, rgba(11, 28, 53, 0.5) 100%);
   border: 1px solid rgba(255, 255, 255, 0.06);
   box-shadow: 10px 22px 47px rgba(0, 0, 0, 0.13);
   backdrop-filter: blur(20px);
   border-radius: 2px;
}

.purpleBorderBox {
   background: linear-gradient(180deg, rgba(26, 38, 57, 0.5) 0%, rgba(11, 28, 53, 0.5) 100%);
   border: 1px solid rgba(255, 255, 255, 0.06);
   box-shadow: 10px 22px 47px rgba(0, 0, 0, 0.13);
   backdrop-filter: blur(20px);
   border-radius: 2px;
   position: relative;
}

.purpleBorderBox::before {
   content: "";
   position: absolute;
   left: 0;
   top: 0;
   width: 100%;
   height: 1px;
   background: radial-gradient(49.62% 14587.71% at 50.38% 50%, #C668FF 0%, rgba(198, 104, 255, 0) 100%)
}

.yellowBorderBox::before {
   content: "";
   position: absolute;
   left: 0;
   top: 0;
   width: 100%;
   height: 1px;
   background: radial-gradient(49.62% 14587.71% at 50.38% 50%, #F3DE1B 0%, rgba(243, 222, 27, 0) 100%)
}

.greenBorderBox::before {
   content: "";
   position: absolute;
   left: 0;
   top: 0;
   width: 100%;
   height: 1px;
   background: radial-gradient(49.62% 14587.71% at 50.38% 50%, #2DFEE5 0%, rgba(45, 254, 229, 0) 100%)
}

.scoresText .winsLabel {
   font-family: 'ObjectSans-Regular';
   font-style: normal;
   font-size: 16px;
   line-height: 20px;
   letter-spacing: -0.01em;
   color: #FFFFFF;
   text-align: left;
   font-weight: 400;

}

.scoresText .winsLabel img {
   position: relative;
   left: 0px;
}

/*radio button*/
.matches-radio-wrapper {
   display: flex;
   flex-wrap: wrap;
   width: 100%;
   justify-content: right;
   padding-right: 0;
}

.radioButtonlbl {
   cursor: pointer;
   font-weight: 500;
   position: relative;
   overflow: hidden;
   margin-bottom: 0.375em;
   margin-left: 10px;
}

.radioButtonlbl input {
   position: absolute;
   left: -9999px;
}

.radioButtonlbl input:checked + span {
   background-color: #111E33;
}

.radioButtonlbl input:checked + span:before {
   box-shadow: inset 0 0 0 2px #111e33;
   background: #2cffa0;
   border: 1px solid #2cffa0;
}

.radioButtonlbl span {
   font-family: 'ObjectSans-Regular';
   display: flex;
   align-items: center;
   padding: 0.375em 0.75em 0.375em 0.375em;
   border-radius: 99em;
   transition: 0.25s ease;
   background: #111E33;
   box-shadow: 10px 22px 47px rgba(0, 0, 0, 0.13);
   backdrop-filter: blur(20px);
   border-radius: 4px;
   padding: 17px;
   font-size: 16px;
}

.radioButtonlbl span {
   padding: 15px 21px;
}

.radioButtonlbl span:hover {
   background-color: #1e2532;
}

.radioButtonlbl span:before {
   display: flex;
   flex-shrink: 0;
   content: "";
   background-color: #111e33;
   width: 16px;
   height: 16px;
   border-radius: 50%;
   margin-right: 0.375em;
   transition: 0.25s ease;
   /* box-shadow: inset 0 0 0 0.125em #00005c;*/
   border: #355A69 solid 2px
}

.selectWrapperRes {
   padding-right: 10px;
}

.navWrp .menuFooter {
   display: none;
}

.btn-primary {

   font-size: 16px !important;
}

.dropdown-item {
   font-size: 16px;
}

.tbSep tr td:nth-child(2) {
   border-right: 1px solid #1F2A36;
   padding-right: 30px;
}

.tbSep tr td:last-child {
   padding-right: 30px;
}

.tbSep tr th:nth-child(2) {
   border-right: 1px solid #1F2A36;
}

.LoginButton {
   background: linear-gradient(95.34deg, #FAFF00 6.28%, #00FFC2 111.13%);
   box-shadow: 0px 2px 0px rgba(0, 0, 0, 0.51);
   border-radius: 4px;
   transform: skew(-10deg, 0deg);
   border: 1px solid #2FFF9E;
   /*border-image-source: linear-gradient(103.05deg, #2FFF9E 7.22%, #F9FF01 93.09%);*/
   font-family: "ObjectSans-Heavy", Helvetica, Arial, sans-serif;
   font-size: 16px;
   line-height: 18px;
   text-align: center;
   letter-spacing: -0.01em;
   text-transform: uppercase;
   padding: 14px 27px;
   font-weight: 700;
   height: 54px;
}

.BlackSkBtn text {

   background-image: linear-gradient(0deg, #FAFF01, #39FF96);
   background-clip: text;
   color: transparent;
   font-family: "ObjectSans-Heavy";
   font-size: 16px;
   line-height: 18px;
}

.BlackSkBtn {
   background-color: #0E2029;
   box-shadow: 0px 2px 0px rgba(0, 0, 0, 0.51);
   border-radius: 4px;
   transform: skew(-10deg, 0deg);
   border: 1px solid rgba(0, 255, 194, 0.09);
   /* identical to box height, or 129% */
   text-align: center;
   letter-spacing: -0.01em;
   text-transform: uppercase;
   padding: 14px 27px;
   font-weight: 700;
   height: 54px;
}

.BlackSkBtn:hover {
   background: #1A4152;
}

.BlackSkBtn:visited {
   box-shadow: 0px 2px 0px rgba(0, 0, 0, 0.51);
   cursor: not-allowed;
}

.BlackSkBtn:disabled,
.BlackSkBtn[disabled] {
   background: #0E2029;
   box-shadow: 0px 2px 0px rgba(0, 0, 0, 0.51);
   backdrop-filter: blur(45px);
}

@media (max-width: 768px) {
   .blackBtn {
      background: #111E33;
      border: 1px solid rgba(255, 255, 255, 0.06);
      border-radius: 2px;
      color: #fff;
      padding: 10px 10px 10px 15px;
      font-size: 14px;
      line-height: 18px;
      letter-spacing: -0.01em;
      color: #FFFFFF;
      margin-bottom: 15px;
      text-transform: uppercase;
   }

   .teamDropdown label {
      font-family: "OSSlanted";
      font-size: 12px !important;
      line-height: 14px;
      font-weight: 700;
      letter-spacing: 0em;
      text-transform: capitalize;
   }

}

/*loader*/
.lds-facebook {
   display: inline-block;
   position: relative;
   width: 80px;
   height: 80px;
}

.lds-facebook div {
   display: inline-block;
   position: absolute;
   left: 8px;
   width: 16px;
   background: #fff;
   animation: lds-facebook 1.2s cubic-bezier(0, 0.5, 0.5, 1) infinite;
}

.lds-facebook div:nth-child(1) {
   left: 8px;
   animation-delay: -0.24s;
}

.lds-facebook div:nth-child(2) {
   left: 32px;
   animation-delay: -0.12s;
}

.lds-facebook div:nth-child(3) {
   left: 56px;
   animation-delay: 0;
}

@keyframes lds-facebook {
   0% {
      top: 8px;
      height: 64px;
   }
   50%, 100% {
      top: 24px;
      height: 32px;
   }
}

.cs-message__content {
   font-size: 14px !important;
   line-height: 18px !important;
   border-radius: 12px 12px 12px 2px !important;
   background-color: #111E33 !important;
   border: 1px solid #1F2A36;
   padding: 15px !important;
   color: #ffffff !important;
   font-family: 'ObjectSans-Regular' !important;
   font-size: 14px !important;
   word-break: initial !important;
}

.cs-message--outgoing .cs-message__content {
   background-image: linear-gradient(to bottom, #FAFF01 0%, #39FF96 100%);
   border-radius: 12px 12px 2px 12px !important;
   padding: 15px;
   color: #03161F !important;
   font-size: 14px !important;
}

.cs-message-list .cs-message.cs-message--outgoing, .cs-message-list .cs-message-group.cs-message-group--outgoing {
   margin-top: 10px !important;
   margin-bottom: 10px !important;
}

.cs-message-input__content-editor-wrapper {
   background-color: #E4E4E4 !important;
   border-radius: 0px !important;
}

.cs-message-input__content-editor {
   background-color: #e4e4e400 !important;

}

.cs-message-input__content-editor[data-placeholder]:empty:before {
   color: #95A6B7 !important;
   font-family: 'ObjectSans-Regular';
   font-size: 14px !important;
}

.svg-inline--fa.fa-w-16 {
   color: #ffffff !important;
   width: 12px;
   transform: rotate(50deg);

}

.cs-message__avatar {
   justify-content: flex-start !important;
}

.white-text {
   color: #fff;
}

.cs-message-list .cs-message.cs-message--incoming, .cs-message-list .cs-message-group.cs-message-group--incoming {
   margin-top: 10px !important;
   margin-bottom: 10px !important;
}

.cs-message--incoming .cs-message__content p {
   font-size: 14px !important;
   line-height: 18px !important;
}

div.ac-container.ac-adaptiveCard {
   padding: 0 !important;
}

.cs-message-list .cs-typing-indicator {
   background-color: #02131b !important;
}

.cs-typing-indicator__dot {
   background-color: #17EA85 !important;
}

.cs-typing-indicator__text {
   color: #17EA85 !important;
}

.cs-message-list__scroll-wrapper > .cs-message {
   max-width: unset !important;

   .cs-message__content-wrapper {
      width: 100% !important;
   }
}

.chatbothead {
   font-weight: 400;
   font-size: 24px;
   line-height: 32px;
   letter-spacing: 0.02em;
   color: #FFFFFF;
   margin: 0;
   width: 100%;
   padding: 20px;
   background-color: #091623;
   border-bottom: 1px solid #1F2A36;
}

.cs-main-container .cs-chat-container {
   border: 0px !important;
}

.cs-main-container {
   border: 0px !important;
}

.chatboticon {
   float: right;
   width: 25px;
}

.cs-chat-container .cs-message-input {
   background-color: #091623 !important;
   border-top: 1px solid #1F2A36 !important;
   padding: 20px !important;
}

.cs-chat-container .cs-message-input .cs-message-input__content-editor-wrapper:first-child {
   background-color: #091623 !important;
   border: 1px solid #1F2A36 !important;
   border-right: 0px solid #1F2A36 !important;
   border-radius: 6px 0 0 6px !important;
   margin-left: 0px !important
}

.cs-message-input__tools {
   border: 1px solid #1F2A36 !important;
   border-left: 0px solid #1F2A36 !important;
   border-radius: 0 6px 6px 0;
}

.cs-message-input__content-editor {
   background-color: #091623 !important;
   color: #95A6B7 !important;
}

.ac-textBlock {
   color: #ffffff !important;
   line-height: 10px;
}

.cs-message-input__tools:last-child .cs-button:last-child {
   background-color: #17EA85 !important;
   border-radius: 50%;
   width: 24px;
   height: 24px;
   margin-top: 0px;
   padding: 0;
   line-height: 20px;
}

.cs-message-input__tools {
   width: 40px;
   padding-bottom: 7px;
}

.selected-radio label {
   background-color: #FFFFFF !important;
   color: #17EA85 !important;
   border: 1px #17EA85 !important;
}

.cs-message__content label {
   font-weight: normal;
   text-align: center !important;
   display: inline-block;
   padding: 6px;
   line-height: 0.62px !important;
   margin: 3px;
   color: #17EA85 !important;
   /* border: 1px solid #17EA85; */
   border-radius: 12px;
   cursor: pointer;
}

label.qbotLabel {
   overflow: hidden;
   font-family: "Segoe UI", "Helvetica Neue", sans-serif;
   font-size: 14px;
   color: rgb(51, 51, 51);
   font-weight: 400;
   text-align: start;
   line-height: 18.62px;
   overflow-wrap: break-word;
   box-sizing: border-box;
   flex: 1 1 auto;
   display: inline-block;
   margin-left: 6px;
   vertical-align: middle;
   width: 100%;
}

label.selected-input {
   text-align: center !important;
   display: inline-block;
   padding: 10px;
   line-height: 18.62px !important;
   margin: 3px;
   color: #000000 !important;
   border: 1px solid #FFFFFF;
   border-radius: 12px;
   cursor: pointer;
   background-color: #17EA85 !important;
}

label.deselected-resolve-input {
   text-align: center !important;
   display: inline-block;
   padding: 10px;
   line-height: 18.62px !important;
   margin: 3px;
   color: #17EA85 !important;
   border: 1px solid #17EA85;
   background-color: #FF002E;
   border-radius: 12px;
   cursor: pointer;
}

label.deselected-input {
   text-align: center !important;
   display: inline-block;
   padding: 10px;
   line-height: 18.62px !important;
   margin: 3px;
   color: #17EA85 !important;
   border: 1px solid #17EA85;
   border-radius: 12px;
   cursor: pointer;
}

label.ball-selected-input {
   font-family: 'Khand' !important;
   font-size: 18px;
   text-align: center !important;
   display: inline-block;
   /* padding: 10px; */
   padding: 10px 5px 10px 5px;
   line-height: 0.62px !important;
   margin: 3px;
   color: #000000 !important;
   border: 1px solid #FFFFFF;
   border-radius: 12px;
   cursor: pointer;
   color: #fff;
   background-color: #17EA85 !important;
   width: 32px;
   padding-top: 11px;
}

label.ball-deselected-input {
   font-family: 'Khand';
   font-size: 18px;
   text-align: center !important;
   display: inline-block;
   padding: 10px;
   line-height: 0.62px !important;
   margin: 3px;
   color: #17EA85 !important;
   border: 1px solid #17EA85;
   border-radius: 12px;
   cursor: pointer;
   border: none;
   padding-top: 12px;
}

.botCardWrapper {
   label.ball-deselected-input {
      border: none;
   }
}

.bot_card_body {
   background-color: #111E33 !important;
   color: #FFFFFF;
   border: 0px;
   padding: 0px !important;
}

.card {
   border: 0px !important;
}

.card-subtitle {
   color: #FFFFFF;
}

.cs-message__content input[type="radio"] {
   position: absolute;
   z-index: -1;
}

td.ac-fact-value {
   width: 15px;
}

.timerLbl {
   display: inline-block;
   padding: 0 10px 0 0;
   font-size: 20px;
   font-family: 'OSSlanted';
   color: #95A6B7;
}

.timerSec {
   display: inline-block;
   width: 30px;
   font-family: 'OSHeavy';
   text-align: right;
   color: #fff;
}

.greenText {
   color: #17EA85;

   font-size: 16px;
   font-weight: 800;
}

@media (max-width: 768px) {
   .greenText {
      color: #17EA85;

      font-size: 14px;
      font-weight: 800;
   }

   .chatbothead {

      /* padding: 5px 20px; */

   }

   .timerSec {
      width: 100%;
      text-align: center;
   }

   .timerLbl {
      font-size: 15px;
      width: 25%;
      text-align: center;
   }

   div.ac-input {
      width: 100%;
      display: flex;
      flex-wrap: wrap;
   }

   div.ac-input div {
      /* Remove width property to allow content-based width */
      flex: 0 0 auto;
      width: 50%;
   }

   .like-btn-wrapper div span.like-btn {
      top: -7px !important;
   }
}

/*table.ac-factset tr td:nth-child(2){*/
/*    display: none;*/
/*}*/
table.ac-factset tr td:nth-child(1) {
   width: 40%;
}

table.ac-factset tr:nth-child(3) td:nth-child(1) {
   width: 85%;
}

td.ac-fact-value {
   text-align: right;
}

.scoreBoard {
   border: 1px solid #1F2A36;
   box-shadow: 10px 22px 47px rgba(0, 0, 0, 0.13);
   backdrop-filter: blur(20px);
   border-radius: 2px;
   width: 100%;
   margin-bottom: 0px;
   background-image: url('../../assets/images/mgroup.webp'), linear-gradient(91.25deg, rgba(57, 45, 252, 0.5) 1.34%, rgba(255, 122, 0, 0.5) 100%);
   background-size: 100%;
   align-items: center;
   display: flex;
}

.vsLbl {
   display: none;
}

.liveScore .teamDetail {
   width: 44%;
}

.liveScore .sched {
   width: 41%;
}

.mobile-hide {
   display: inline-block;
}

.mobile-show {
   display: none;
}

.shedmatchplayersubwrap {
   background-image: url("https://storage.googleapis.com/meta11/serviceplatform/LineClr-Dashed.png");
   float: left;
   width: 100%;
   padding: 10px 0;
   background-repeat: no-repeat;
   background-position: 0 bottom;
   text-transform: uppercase;
   display: flex;
   flex-direction: row;
   justify-content: space-between;

}

.shedmatchplayersubwrap:last-child {
   background-image: none;
}

.shedmatchplayersubwrap .playerattr {
   font-family: 'ObjectSans-Regular';
   font-style: italic;
   font-weight: 700;
   font-size: 12px;
   line-height: 16px;
   font-size: 12px;
   line-height: 16px;
}

.shedmatchplayersubwrap .playerattrScore {
   background: linear-gradient(89.7deg, #17EA85 2.27%, #14D0E6 23.6%, #17EA85 44.41%, #14D0E6 64.17%, #17EA85 82.99%, #14D0E6 99.74%);
   -webkit-background-clip: text;
   -webkit-text-fill-color: transparent;
   background-clip: text;
   text-fill-color: transparent;
   font-family: 'OSHeavySlanted';
   float: right;
}

.sub-section-match {
   min-height: 180px;
}

/* chatbot scroll section */
div.scrollbar-container.cs-message-list__scroll-wrapper.ps {
   touch-action: pan-y !important;
   margin-right: -8px;
}

/******* Sign-up-to-secure Model styling ******/
.Sign-up-to-secure {
   box-shadow: 0px 0px 45px 0px #80FF5F80 !important;
}

.Sign-up-to-secure img {
   width: 100%;
}

.like-btn-wrapper {
   position: relative;
}

.like-btn-wrapper div span.like-btn {
   z-index: 999;
   display: block;
   position: absolute;
   right: -14px;
   top: -24px;
}

.table-bordered thead th, .table-bordered thead td {
   border: 0px !important;
}

.leadTitile {

   font-family: 'SolinaLight';
   font-size: 38px;
   line-height: 52px;
}

.CapIMG img {
   width: 38px;
   height: 38px;
}

.CorrectPresent {
   line-height: 15px;
}

@media (max-width: 768px) {
   .CapIMG img {
      width: 30px;
      height: 30px;
   }

   .CorrectPresent {
      font-size: 12px;
   }
}

.cs-message__header {
   color: #FFFFFF !important;
   z-index: 1500 !important;
   height: 20px;
}

.cs-message--outgoing .cs-message__sender-name {
   display: block !important;
   color: #FFFFFF !important;
}

.cs-message--outgoing .cs-message__sent-time {
   display: block !important;
   color: #FFFFFF !important;
}

.cs-message--outgoing {
   width: fit-content;
}

.cs-message--incoming .cs-message__sender-name {
   display: block !important;
   color: #FFFFFF !important;
}

.cs-message--incoming .cs-message__sent-time {
   display: block !important;
   color: #FFFFFF !important;
}

@import '../../scss/globals/global';

html, body {
   overflow-x: hidden;
   // max-width: 1240px;
   margin: 0 auto;
}

body {
   width: 100%;
}

.pageWrapper {
   margin: 0 auto;
}

.bodyLeaderBoard {
   // background-image: url(https://storage.googleapis.com/meta11/serviceplatform/GradientBlue1.png),
   // 			  url(https://storage.googleapis.com/meta11/serviceplatform/GradentRed1.png),
   // 			  url(https://storage.googleapis.com/meta11/serviceplatform/GradentRed2.png);

   // background: {
   //   repeat: no-repeat, no-repeat, no-repeat;
   //   position: left top, right top, left 850px;
   //   size: auto auto;
   // }
   .TournamentButton {
      //border-radius: 90px !important;
      //padding: 12px 16px;
      border: 1px solid white;
      color: whitesmoke;
      background-color: whitesmoke;
      color: red;
      //background: #111E33;
      //color: #fff;
   }

   .ResponceCont {
      max-width: 1240px;
      margin: 0 auto;
   }

   .topLeadersRow {
      margin-bottom: 40px;

      > div {
         > div {
            padding: 0;
         }
      }
   }

   .rightBannerRow {
      margin-bottom: 25px;

      img {
         width: 100%
      }
   }

   .horizontalDividerGradient {
      @include horizontal-divider-gradient;
   }

   .topBtnWrapper {
      padding: 0;
   }
}

h2.sectionTitle {
   margin: 30px 0 20px 14px;
   @include matchPageSectionTitle;
   padding-right: 30px;
}

.BuyButton {
   background: linear-gradient(95.34deg, #FAFF00 6.28%, #00FFC2 111.13%);
   box-shadow: 0px 2px 0px rgba(0, 0, 0, 0.51);
   border-radius: 4px;
   transform: skew(-10deg, 0deg);
   border: 1px solid #2FFF9E;
   /*border-image-source: linear-gradient(103.05deg, #2FFF9E 7.22%, #F9FF01 93.09%);*/
   text-align: center;
   letter-spacing: -0.01em;
   padding: 9px 27px;
   /*font-weight: 700;
   width: 100%;*/
   height: 42px;
   font-family: "ObjectSans-Heavy", Helvetica, Arial, sans-serif;
   font-size: 16px;
   /*line-height: 20px;*/
   text-transform: uppercase;
   margin-left: auto;
   margin-right: auto;
   margin-top: 12px;
   // :hover {
   //   background: linear-gradient(95.34deg, #00FFC2 6.28%, #FAFF00 111.13%);
   // }

   :visited {
      background: linear-gradient(95.34deg, #B0FFCA 6.28%, #F2FF7E 111.13%);
   }

   img {
      transform: skew(10deg, 0deg);
      margin-left: 15px;
      margin-top: -5px;
   }
}

.BuyButton:hover {
   background: linear-gradient(95.34deg, #00FFC2 6.28%, #FAFF00 111.13%);
}

.transactionsTable {
   table {
      font-family: "ObjectSans-Regular";

      th {
         padding: 10px;
         // border: 1px solid #dddddd29;
         font-weight: normal;
      }

      td {
         padding: 10px;
         // border: 1px solid #dddddd1a;
         //color: #95a6b7;
      }
   }

   .PointsTableWrapper {
      margin-bottom: 50px;
   }
}

.showAllBtn {
   width: fit-content;
   color: #17EA85;
   font-weight: 700;
   font-size: 16px;
   text-align: right;
   background-color: transparent;
   border: none;
   margin-top: 35px;
   margin-right: 9px;
   float: right
}

.topBgGradientGreen {
   @include bgGradientGreen;
   top: -350px;
   left: -500px;
}

.centerBgGradientRed {
   @include bgGradientRed;
   top: 130px;
   left: 0;
   right: 0;
   margin-left: auto;
   margin-right: auto;
}

.rightBgGradientGreen {
   @include bgGradientGreen;
   top: 630px;
   right: -500px;
}

.OverviewBox {
   @include overview-box;
}


.OverviewBox {
   .smallText {
      font-size: 18px;
      color: #95A6B7;
      font-family: 'ObjectSans-Regular';
      margin-left: 10px;
   }

   .mainText {
      font-size: 36px;
      font-family: 'SolinaExBI';
      color: #ffffff;
      line-height: 70px;
   }

   .smallText {
      font-size: 18px;
      color: #95A6B7;
      font-family: 'ObjectSans-Regular';
      margin-left: 10px;
   }

   img {
      width: auto;
   }
}


.SplashBox {
   min-height: 320px;
   padding-bottom: 40px !important;
   background-image: url(../../assets/images/leader-board-splash.webp);
   background-repeat: no-repeat;
   background-position: center 0%;
}

.upChevron {
   width: 24px;
   height: 24px;
   margin: 0 auto;
   margin-bottom: 10px;
   background-image: url(../../assets/images/up-chevron.webp);
   background-repeat: no-repeat;
}

.downChevron {
   width: 24px;
   height: 24px;
   margin: 0 auto;
   margin-bottom: 10px;
   background-image: url(../../assets/images/down-chevron.webp);
   background-repeat: no-repeat;
}


.Footer {
   position: fixed;
   bottom: 0;
   width: 100%;
   left: 0;
}

.loaderContainer {
   position: fixed;
   top: 50%;
   left: 50%;
   transform: translate(-50%, -50%);
}

.accordionBtnWrapper {
   position: absolute;
   right: 20px;
   top: 12px;
}

.proImg {
   margin-right: 10px;
   height: 36px;
   width: 36px;
   border-Radius: 50%;
   //z-index: -100;
}

.proTextImg {
   margin-right: 10px;
   height: 36px;
   width: 36px;
   display: flex;
   align-items: center;
   background-image: url('../../assets/images/LbTextAvatarBg.svg');
   background-size: cover;
   justify-content: center;
}

.proTextImgLetters {
   background: -webkit-linear-gradient(#FAFF01, #39FF96);
   -webkit-background-clip: text;
   -webkit-text-fill-color: transparent;
   font-weight: 700;
   font-family: 'Montserrat', sans-serif;

}

.proTopUserImg {
   height: 100%;
   width: 100%;
   border-Radius: 50%;
}

.proTopUserTextImg {
   height: 100%;
   width: 100%;
   display: flex;
   align-items: center;
   background-image: url('../../assets/images/LbTextAvatarBgWithoutBorder.svg');
   background-size: cover;
   justify-content: center;
   z-index: -10;
}

.proTopUserTextImgLetters {
   background: -webkit-linear-gradient(#FAFF01, #39FF96);
   -webkit-background-clip: text;
   -webkit-text-fill-color: transparent;
   font-weight: 500;
   font-size: 50px;
   font-family: 'Montserrat', sans-serif;
}

.notFound {
   margin: 0 auto;
   transform: translateY(400%);
   text-align: center;
   position: absolute;
}

.leaderBoardShareBtn {
   position: absolute;
   top: 20px;
   right: 15px;
}

.WithdrawBtn {
   float: right;
   background: linear-gradient(90deg, #F9FF01, #2FFF9E);
   padding: 2px;
   border-radius: 5px;
   transform: skew(-10deg, 0deg);
   margin: 2px;
}

.TransactionsPage {
   .transactionsTable {
      table * {
         font-size: 13px;

         td:nth-child(3) {
            min-width: 135px;
         }

         td:nth-child(5) {
            min-width: 90px;
         }

         td:nth-child(6) {
            min-width: 140px;
         }

         td {
            img {
               float: left;
            }
         }
      }

      table {
         min-width: 850px;

         thead {
            tr {
               background-color: #0c1926;
               line-height: 30px;

               th {
                  font-weight: bold;
               }
            }
         }

         tr:nth-child(even) {
            background-color: #131f30;
            line-height: 30px;
         }

         .tr:nth-child(odd) {
            background-color: #0c1926;
            line-height: 30px;
         }
      }

      .TableWrapper {
         overflow-x: auto;
         min-height: calc(100vh - 600px);
      }
   }

   .sectionTitle {
      display: grid;
      grid-template-columns: 1fr auto;
      align-items: center;
   }
}

@media (max-width: 1200px) {
   .topBgGradientGreen {
      display: none !important;
   }
   .centerBgGradientRed {
      display: none !important;
   }
   .rightBgGradientGreen {
      display: none !important;
   }
   .rightBannerRow:nth-child(2) {
      display: none;
   }
}

@media (min-width: 1024px) {
   .accordionBtnUp,
   .accordionBtnDown {
      display: none;
   }
   .mobileCellRow {
      display: none;;
   }
   .leaderBoard {
      margin-bottom: 20px;
   }
}

@media (max-width: 1024px) {
   .leaderBox {
      > a {
         div {
            font-size: 12px;
         }
      }
   }
   .leaderboardRow {
      margin-bottom: 20px;
   }
}

@media (max-width: 768px) {
   .leaderBox {
      height: 250px;
   }
   .SplashBox {
      min-height: 250px;
   }
   .outCurcleWrapper {
      height: 75px !important;
      width: fit-content;
   }

   .OverviewBox .mainText {
      width: 100%;
      font-size: 18px;
      font-family: 'SolinaExBI';
      color: #ffffff;
      line-height: 22px;
   }

   .OverviewBox .smallText {
      font-size: 12px;
      color: #95A6B7;
      font-family: 'ObjectSans-Regular';
      margin-left: 0px;
      line-height: 40px;
   }

   .OverviewBox .mainText img {
      max-width: 42px;
      margin-right: 80%;
      margin-bottom: 5px;
      margin-left: -10px;
   }

   .curcleOut1 .InCurcle {
      width: 60px;
      height: 60px;
      background-size: 100% auto;
      border-radius: 50%;
   }

   .curcleOut1 .OutCurcle {
      width: 64px;
      height: 64px;
   }

   .curcleOut2 {
      .InCurcle {
         width: 80px;
         height: 80px;
         background-size: 100% auto;
         border-radius: 50%;
      }

      .OutCurcle {
         width: 84px;
         height: 84px;
      }
   }

   .trophyTextW {
      height: 50px;
      font-size: 18px;
      color: #ffffff;
      font-family: 'ObjectSans-Regular';
      text-transform: uppercase;
      letter-spacing: -0.01em;
      line-height: 24px;
      font-style: italic;
      font-weight: 700;
   }

   .trophyText {
      font-Size: 14px;
      color: #17EA85;
      margin-left: 3px;
   }
   .trophyTextW {
      height: 50px;
      font-size: 18px;
      letter-spacing: -0.01em;
      line-height: 24px;
   }


   .trophyText {
      font-Size: 12px;
      color: #17EA85;
      margin-left: 3px;
   }

   .trophyTextW {
      height: 35px;
      font-size: 14px;
      letter-spacing: -0.01em;
      line-height: 18px;
   }

   .trophyTextW {
      height: 35px;
      font-size: 14px;
      letter-spacing: -0.01em;
      line-height: 18px;
   }
   // table.leaderBoard tr{
   //   position: relative;
   //   display: block;
   // }
   .leaderBoard tr > td:first-child {
      width: 7%;
      text-align: center;

   }

   .leaderBoard tr > td:last-child {
      width: 28%;
      padding: 0;
      padding-right: 5px;
   }

   .curcleCount {
      width: 22px;
      height: 22px;
      font-size: 13px;
      line-height: 7px;
   }
   .sectionTitle {
      display: grid;
      grid-template-columns: 1fr auto;
      align-items: center;
   }
   .TransactionsPage {
      .sectionTitle {
         font-size: 20px;
         text-align: center;
      }
   }

}

@media (max-width: 576px) {
   .leaderboardRow table.leaderBoard tr td:nth-child(1) > div > div a span {
      width: 100px;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      text-align: left;
   }
   .accordionBtnWrapper {
      position: absolute;
      right: 5px;
      top: 14px;
   }
   .leaderboardRow {
      table.leaderBoard {
         tr {
            td:nth-child(1) { //1st col
               width: fit-content;
               min-width: 75px;
            }

            td:nth-child(5) { //1st col
               width: fit-content;

               > div {
                  > div {
                     display: flex;
                     align-items: center;
                     justify-content: end;
                  }
               }
            }
         }
      }
   }
   .leaderBox {
      > a {
         div {
            max-width: 85px;
            text-align: center;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
            margin: 0 auto;
         }
      }
   }
   .sectionTitle {
      font-size: 20px;
   }
   .SplashBox {
      min-height: auto;
      padding-bottom: 5px !important;
   }
   .leaderboardRow {
      > div {
         padding-left: 0;
         padding-right: 0;
         // overflow-x: scroll !important;
      }

      table.leaderBoard {
         width: 1216px;

         thead {
            tr {
               th {
                  font-size: 12px;
               }

               th:nth-child(1), th:nth-child(2) {
                  width: 40px !important;
                  min-width: 40px !important;
                  background-color: rgb(12 25 38 /1);
                  width: 40px !important;
                  min-width: 40px !important;
                  position: sticky;
                  left: 0 !important;
                  z-index: 1;
               }

               th:nth-child(2) {
                  left: 40px;
               }
            }
         }

         tr {
            td * {
               font-size: 12px;
            }

            td:nth-child(1), td:nth-child(2) {
               width: 40px !important;
               min-width: 40px !important;
               position: sticky;
               left: 0;
               background-color: rgb(19 31 48 / 1);
               z-index: 1;
            }

            td:nth-child(2) {
               label {
                  max-width: 100px;
                  white-space: nowrap;
                  overflow: hidden;
                  text-overflow: ellipsis;
               }
            }
         }

         tr.leaderBoardHigh {
            td:nth-child(1), td:nth-child(2) {
               width: 40px !important;
               min-width: 40px !important;
               position: sticky;
               left: 0;
               background-image: linear-gradient(to bottom, #FAFF01 0%, #39FF96 100%);
            }
         }
      }
   }
   .TransactionsPage {
      .sectionTitle {
         grid-template-columns: 1fr;
         justify-content: center;
         gap: 15px;
      }
   }
}

.SplashBox {
   min-height: 320px;
   padding-bottom: 40px !important;
   background-image: url(../../../assets/images/leader-board-splash.webp);
   background-repeat: no-repeat;
   background-position: center 0%;
}

.upChevron {
   width: 24px;
   height: 24px;
   margin: 0 auto;
   margin-bottom: 10px;
   background-image: url(../../../assets/images/up-chevron.webp);
   background-repeat: no-repeat;
}

.downChevron {
   width: 24px;
   height: 24px;
   margin: 0 auto;
   margin-bottom: 10px;
   background-image: url(../../../assets/images/down-chevron.webp);
   background-repeat: no-repeat;
}

.curcleOut1 {
   width: 100%;
   position: relative;

   .upDown {
      margin-left: auto;
      margin-right: auto;
      width: 16px;
      height: 16px;
      margin-bottom: 5px;
   }

   .OutCurcle {
      margin-left: auto;
      margin-right: auto;
      background-image: linear-gradient(to bottom, #FAFF01 0%, #39FF96 100%);
      width: 124px;
      height: 124px;
      border-radius: 50%;
      padding-top: 2px;
      padding-right: 2px;
      padding-bottom: 2px;
      padding-left: 2px;
      box-shadow: 0px 0px 40px rgba(211, 255, 30, 0.20);
      -webkit-box-shadow: 0px 0px 40px rgba(211, 255, 30, 0.20);
   }

   .InCurcle {
      width: 120px;
      height: 120px;
      background-size: 100% auto;
      border-radius: 50%;
   }
}


.curcleCount {
   position: absolute;
   width: fit-content;
   height: 28px;
   padding: 0 5px;
   height: 28px;
   border-radius: 6px;
   left: 0;
   right: 0;
   margin-left: auto;
   margin-right: auto;
   background-image: linear-gradient(to bottom, #FAFF01 0%, #39FF96 100%);
   font-size: 14px;
   text-align: center;
   padding-top: 8px;
   margin-top: -14px;
   font-family: 'ObjectSans-Heavy';
   color: #000;
   line-height: 14px;
}

.curcleOut2 {
   width: 100%;
   padding-top: 26px;

   .upDown {
      margin-left: auto;
      margin-right: auto;
      width: 30px;
      height: 23px;
      margin-bottom: 10px;
   }

   .OutCurcle {
      margin-left: auto;
      margin-right: auto;
      background-image: linear-gradient(to bottom, #FAFF01 0%, #39FF96 100%);
      width: 144px;
      height: 144px;
      border-radius: 50%;
      padding-top: 2px;
      padding-right: 2px;
      padding-bottom: 2px;
      padding-left: 2px;
      box-shadow: 0px 0px 40px rgba(211, 255, 30, 0.30);
      -webkit-box-shadow: 0px 0px 40px rgba(211, 255, 30, 0.30);

   }

   .InCurcle {
      width: 140px;
      height: 140px;
      background-size: 100% auto;
      border-radius: 50%;
   }

}

.leaderBox {
   > a {
      div {
         height: 50px;
         font-size: 18px;
         color: #ffffff;
         font-family: 'ObjectSans-Regular';
         text-transform: uppercase;
         letter-spacing: -0.01em;
         line-height: 24px;
         font-style: italic;
         font-weight: 700;
      }
   }

   .marks {
      width: fit-content;
      padding: 0 10px;
      height: 28px;
      background: #172842;
      border: 1px solid #253342;
      border-radius: 4px;
      margin-left: auto;
      margin-right: auto;

      i {
         font-size: 10px;
         padding-right: 5px;
      }

      span {
         font-size: 14px;
         color: rgb(23, 234, 133);
      }
   }

   > a:hover {
      text-decoration: none;
   }
}

.proImg {
   margin-right: 10px;
   height: 36px;
   width: 36px;
   border-Radius: 50%;
   z-index: -100;
}

.proTextImg {
   margin-right: 10px;
   height: 36px;
   width: 36px;
   display: flex;
   align-items: center;
   background-image: url('../../../assets/images/LbTextAvatarBg.svg');
   background-size: cover;
   justify-content: center;
}

.proTextImgLetters {
   background: -webkit-linear-gradient(#FAFF01, #39FF96);
   -webkit-background-clip: text;
   -webkit-text-fill-color: transparent;
   font-weight: 700;
   font-family: 'Montserrat', sans-serif;
}

.proTopUserImg {
   height: 100%;
   width: 100%;
   border-Radius: 50%;
}

.proTopUserTextImg {
   height: 100%;
   width: 100%;
   display: flex;
   align-items: center;
   background-image: url('../../../assets/images/LbTextAvatarBgWithoutBorder.svg');
   background-size: cover;
   justify-content: center;
   z-index: -10;
}

.proTopUserTextImgLetters {
   background: -webkit-linear-gradient(#FAFF01, #39FF96);
   -webkit-background-clip: text;
   -webkit-text-fill-color: transparent;
   font-weight: 500;
   font-size: 50px;
   font-family: 'Montserrat', sans-serif;
}

.notFound {
   margin: 0 auto;
   transform: translateY(400%);
   text-align: center;
}

.inputHome[type="text"] {
   display: block;
   background-image: -webkit-linear-gradient(
         0deg,
         rgba(4, 21, 29, 1) 0%,
         rgba(11, 28, 53, 0) 100%
   );
   background-image: -moz-linear-gradient(
         0deg,
         rgba(4, 21, 29, 1) 0%,
         rgba(11, 28, 53, 0) 100%
   );
   background-image: -o-linear-gradient(
         0deg,
         rgba(4, 21, 29, 1) 0%,
         rgba(11, 28, 53, 0) 100%
   );
   background-image: linear-gradient(
         90deg,
         rgba(4, 21, 29, 1) 0%,
         rgba(11, 28, 53, 0) 100%
   );
   max-width: 100%;
   color: #ffffff;
   padding-left: 20px;
   padding-right: 50px;
   font-size: 16px;
   /*font-family: 'SolinaLight';*/
   width: 100%;
   box-sizing: border-box;
   border: solid 1px #3e5168;
   height: 54px;
   border-radius: 3px;
   background-color: #04151d;
   padding-bottom: 22px;
   padding-top: 20px;
   line-height: 10px;
   /*background-image: url("https://storage.googleapis.com/meta11/serviceplatform/pw-eye.png");*/
   background-repeat: no-repeat;
   background-position: 96% center;
   transform: skew(-10deg, 0deg);
   text-transform: skew(10deg, 0deg);
}

.inputHome[type="text"]:focus {
   border-style: solid;
   border-width: 1px;
   border-radius: 2px;
   outline: none;
}

/* active state */
.inputHome[type="text"]:focus ~ label,
.inputHome[type="text"]:valid ~ label {
   top: 10px;
   font-size: 12px;
   color: #95a6b7;
   padding-left: 6px;
}

.ellipse.e29 {
   width: 85%;
   height: 85%;
   background: radial-gradient(32.65% 32.65% at 0% 100%, #14D0E6 0%, rgba(20, 208, 230, 0) 100%);
   position: absolute;
   mix-blend-mode: normal;
   z-index: 0;
   bottom: 0px;
   left: 0;
   opacity: 0.3;
}

.ellipse.e30 {
   width: 85%;
   height: 85%;
   background: radial-gradient(32.65% 32.65% at 100% 0%, #149AE6 0%, rgba(20, 208, 230, 0) 100%);
   position: absolute;
   mix-blend-mode: normal;
   z-index: 0;
   top: 0px;
   right: 0;
   opacity: 0.3;
}

@media (max-width: 768px) {
   .inputHome[type=text] {
      padding-bottom: 17px;
      padding-top: 15px;
      height: 39px;
   }
}

@media (max-width: 576px) {
   .SplashBox {
      min-height: 290px;
      padding-bottom: 20px !important;
   }

}

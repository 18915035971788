@import '../../../scss/globals/global';
@import '../../../scss/utilities/mixins';

.modalContentContainer {
   box-shadow: 0px 0px 65px 0px #80ff5f6e;

   > div {
      min-height: 138px;
   }
}

.FantasyMatchHistoryModal {
   display: block;
   background: rgba(0, 0, 0, 0.2588235294);
   backdrop-filter: blur(5px);
   -webkit-backdrop-filter: blur(5px);

   .CreditPurchaseModal {
      width: 100%;
      max-width: 800px;

      .closeButton {
         position: absolute;
         z-index: 1;
         top: 10px;
         right: 15px;
      }
   }

   .ProfilePicWrapper {
      width: 494px;
      background-image: url("../../../assets/images/backgrounds/splashbg.webp");
      background-repeat: no-repeat;
      display: flex;
      margin: 0 auto;
      padding: 32px 32px 40px 32px;
      position: relative;
      justify-content: center;

      .ProfilePicBorder {
         background: linear-gradient(180deg, #FAFF01 0.03%, #39FF96 86.64%);
         width: 140px;
         height: 140px;
         border-radius: 50%;
         display: flex;
         justify-content: center;
         align-items: center;

         .ProfilePic {
            background-image: url("https://storage.googleapis.com/meta11/players/LeiWei.png");
            background-color: #010e14;
            width: 138px;
            height: 138px;
            border-radius: 50%;
            background-size: contain;
            background-repeat: no-repeat;
            background-position: center;
         }
      }
   }

   .ModalButtonRow {
      margin-top: 35px;
      margin-bottom: 35px;

      button {
         padding: 14px;
         font-size: 16px;
         width: 165px;
         margin: 0 auto;
      }
   }

   .FantasyHistoryTable::-webkit-scrollbar {
      height: 10px;
   }

   /* Track */
   .FantasyHistoryTable::-webkit-scrollbar-track {
      background: #f1f1f1;
   }

   /* Handle */
   .FantasyHistoryTable::-webkit-scrollbar-thumb {
      background: linear-gradient(180deg, #224755 -19.6%, #878787 48.63%, #646464 110.8%);
      border-radius: 5px;
   }

   /* Handle on hover */
   .FantasyHistoryTable::-webkit-scrollbar-thumb:hover {
      background: #555;
   }

   .FantasyHistoryTable {
      width: 680px;
      height: 400px;
      display: block;
      margin: 0 auto;
      overflow-y: auto;
      overflow-x: auto;
      position: relative;

      .NoFantasyHistoryData {
         width: 100%;
         text-align: center;
         padding-top: 10%;
      }

      table {
         position: absolute;
         z-index: 1;
         width: 650px;
         max-height: 400px;
         margin: 0 auto;
         border: solid 1px #1f2a36;
         margin-bottom: 32px;

         thead {
            background: linear-gradient(180deg, #FAFF01 0.03%, #39FF96 86.64%);
            color: #03161F;
            font-style: italic;
            border: solid 1px #1f2a36;
            top: 0;
            position: sticky;
            width: 100%;

            tr {
               border: solid 1px #1f2a36;

               th {
                  padding: 10px;
               }
            }
         }

         tbody {
            tr {
               background-color: #0b1a28;
               border: solid 1px #1f2a36;
               height: 28px;

               td {
                  padding: 10px 5px;

                  button {
                     color: #17EA85;
                     border: solid 1px #17EA85;
                     background-color: #152d39;
                     font-size: 14px;
                     border-radius: 6px;
                     padding: 4px 8px;
                  }
               }

               td:nth-child(1) {
                  padding-left: 15px;
                  max-width: 168px;
                  white-space: nowrap;
                  overflow: hidden;
                  text-overflow: ellipsis;
               }
            }

            tr:nth-child(odd) {
               background-color: #131f30;
            }
         }
      }
   }

   @mixin eMulticolorFull {
      position: absolute;
      background-image: url("../../../assets/images/backgrounds/MulticolorFullEllipse.webp");
      background-repeat: no-repeat;
      background-size: 100% 100%;
   }
   @mixin eMulticolorHalf {
      position: absolute;
      background-image: url("../../../assets/images/Ellipse-half.webp");
      background-repeat: no-repeat;
      background-size: 100% 100%;
   }

   .Radial1 {
      @include eMulticolorFull;
      width: 125px;
      height: 125px;
      top: 138px;
      right: 78px;
      mix-blend-mode: normal;

   }

   .Radial2 {
      @include eMulticolorHalf;
      width: 102px;
      height: 186px;
      top: 110px;
      mix-blend-mode: normal;
      transform: rotate(180deg);
   }

   .Radial3 {
      @include eMulticolorFull;
      width: 163px;
      height: 163px;
      right: 50px;
      bottom: 74px;
      z-index: 0;
      mix-blend-mode: normal;

   }

   .EdgeRadial1 {
      position: absolute;
      background-image: url("../../../assets/images/backgrounds/EllipsePinkBackground.webp");
      background-repeat: no-repeat;
      background-size: 100% 100%;

      width: 315px;
      height: 212px;
      top: 0;
      right: 0;
      z-index: 0;
   }

   .EdgeRadial2 {
      position: absolute;
      background-image: url("../../../assets/images/backgrounds/EdgeBlueEllipse2.webp");
      background-repeat: no-repeat;
      background-size: 100% 100%;

      width: 100px;
      height: 250px;
      bottom: 0;
      left: 0;
      z-index: 0;

   }

   .PlayerNameWrapper {
      h4 {
         font-family: 'ObjectSans-Regular';
         text-transform: uppercase;
         font-size: 24px;
         font-weight: 700;
         font-style: italic;
      }
   }
}

.OverviewBox {
   @include overview-box;
   padding-left: 5px;

   img {
      width: auto;
   }
}

.leaderBoardTitle {
   position: relative;

   .shareBtnWrapper {
      position: absolute;
      top: -4px;
      right: 0;
   }
}

.borderTop1 {
   @include yellow-gradient;
}

.borderTop2 {
   @include green-gradient;
}

.borderTop3 {
   @include purple-gradient;
}

.SplashBox {
   height: 320px;
   background-image: url(https://storage.googleapis.com/meta11/serviceplatform/splash-bg.png);
   background-position: center 0%;
}

.marks {
   width: 56px;
   height: 28px;
   background: #172842;
   border: 1px solid #253342;
   border-radius: 4px;
   margin-left: auto;
   margin-right: auto;
}

.RadioBox {
   width: 20%;
   float: left;
   text-align: center;
   padding-top: 21px;
}

.radioFill:checked {
   background-color: #04151d;
   border-color: #17EA85;
   background-image: url(https://storage.googleapis.com/meta11/serviceplatform/radioCheckGreen.png) !important;
   background-repeat: no-repeat;
   background-size: 60%;
}

.radioFill {
   border: 1px solid #17EA85 !important;
   width: 1.3em;
   height: 1.3em;
   background-color: #04151d;
   cursor: pointer;
}

.BlackSkBtn text {
   background-image: linear-gradient(0deg, #FAFF01, #39FF96);
   background-clip: text;
   color: transparent;
   font-family: "ObjectSans-Heavy";
   font-size: 16px;
   line-height: 18px;
}

.GrayButton {
   @include GrayButtonAtributes($grayButtonColor1, $grayButtonColor2, $buttonColor1, $buttonColor2, $BtnBorderRadius, $Btntransform, $BtntextTransform, $BtnPaddingGray);
   @include css3Anim();
   border: 1px solid $buttonColor1;
   background-clip: text;
   color: transparent;
   padding: 12px 20px;
   border-radius: 5px;

   text {
      @include GrayButtonText($buttonColor1, $buttonColor2, $BtnfontSize, $BtnlineHeight);
      -webkit-background-clip: text;
      background-clip: text;
      color: transparent;
      font-family: $buttonTextFont;
   }
}

.GrayButton:hover {
   background: $grayButtonHover;
}

.BlackSkBtn {
   background-color: linear-gradient(0deg, #0E2029, #0E2029) padding-box2029;
   box-shadow: 0px 2px 0px rgba(0, 0, 0, 0.51);
   border-radius: 4px;
   transform: skew(-10deg, 0deg);
   border: 1px solid rgba(0, 255, 194, 0.09);
   /* identical to box height, or 129% */
   text-align: center;
   letter-spacing: -0.01em;
   text-transform: uppercase;
   padding: 1px;
   font-weight: 700;
   height: 54px;

   .BlackSkBtn text {
      color: linear-gradient(0deg, #FAFF01, #39FF96) 5A6B7;
   }

   :visited {
      box-shadow: 0px 2px 0px rgba(0, 0, 0, 0.51);
      cursor: not-allowed;
   }

   :disabled,
   .BlackSkBtn[disabled] {
      background: #0E2029;
      box-shadow: 0px 2px 0px rgba(0, 0, 0, 0.51);
      backdrop-filter: blur(45px);
   }
}

.BlackSkBtn:hover {
   background: #203455;
}

.LoginButton {
   background: linear-gradient(95.34deg, #FAFF00 6.28%, #00FFC2 111.13%);
   box-shadow: 0px 2px 0px rgba(0, 0, 0, 0.51);
   border-radius: 4px;
   transform: skew(-10deg, 0deg);
   border: 1px solid #2FFF9E;
   /*border-image-source: linear-gradient(103.05deg, #2FFF9E 7.22%, #F9FF01 93.09%);*/
   text-align: center;
   letter-spacing: -0.01em;
   padding: 10px 27px;
   /*font-weight: 700;
   width: 100%;*/
   height: 48px;
   margin-top: 1px;
   font-family: "ObjectSans-Heavy", Helvetica, Arial, sans-serif;
   font-size: 16px;
   line-height: 18px;
   text-transform: uppercase;
   margin-left: 10px;
   // :hover {
   //   background: linear-gradient(95.34deg, #00FFC2 6.28%, #FAFF00 111.13%);
   // }

   :visited {
      background: linear-gradient(95.34deg, #B0FFCA 6.28%, #F2FF7E 111.13%);
   }
}

.LoginButton:hover {
   background: linear-gradient(95.34deg, #00FFC2 6.28%, #FAFF00 111.13%);
}

.modalBlur {
   background-color: #00000063;
}

.CreditPurchaseModal {
   .modalContainer {
      .modalTitle {
         font-family: 'SolinaLight';
         font-size: 20px;
         color: #ffffff;
         text-transform: uppercase;
         line-height: 40px;
      }
   }

   .photoModifyModelBtnRow {
      display: flex;
      width: fit-content;
      margin: 0 auto;

      > a {
         float: right;
         margin-right: -25px;

         > button:hover {
            -webkit-text-fill-color: unset !important;
            cursor: pointer;
         }
      }

      > a:hover {
         -webkit-text-fill-color: unset !important;
         color: #000;

      }
   }
}

.DeleteAccountWrapper {
   margin: 40px 0;
   padding: 0;

   h3 {
      font-size: 32px;
      font-family: "SolinaLight", Helvetica, Arial, sans-serif;
      text-align: left;
      line-height: 35px;
      color: #ffffff;
      text-transform: uppercase;
   }

   .DeleteAccountBtnWrapper {
      .DeleteAccountBtn {
         padding: 14px 23px;
         border: none;
         outline: none;
         position: relative;
         z-index: 1;
         border-radius: 5px;
         background: linear-gradient(103.05deg, #F9FF01 7.22%, #2FFF9E 93.09%);
         cursor: pointer;
         transform: skew(-10deg, 0deg);
         text-transform: uppercase;
      }

      .DeleteAccountBtn::before {
         content: "";
         position: absolute;
         left: 1px;
         right: 1px;
         top: 1px;
         bottom: 1px;
         border-radius: 4px;
         background-color: #0E2029;
         z-index: -1;
         transition: 200ms
      }

      .DeleteAccountBtn::after {
         content: attr(data);
         font-size: 16px;
         background: linear-gradient(to left, #00FFA3, #DC1FFF);
         -webkit-background-clip: text;
         color: #fff;
         transition: 200ms
      }

      .DeleteAccountBtn:hover::before {
         opacity: 50%;
         top: 0px;
         right: 0px;
         bottom: 0px;
         left: 0px;
      }

      .DeleteAccountBtn:hover::after {
         color: white;
      }
   }

}

.UserProfileSplashBox {

   .SplashBox {
      min-height: 320px;
      padding-bottom: 40px !important;
      background-image: url(../../../assets/images/leader-board-splash.webp);
      background-repeat: no-repeat;
      background-position: center 0%;
   }

   .upChevron {
      width: 24px;
      height: 24px;
      margin: 0 auto;
      margin-bottom: 10px;
      background-image: url(../../../assets/images/up-chevron.webp);
      background-repeat: no-repeat;
   }

   .downChevron {
      width: 24px;
      height: 24px;
      margin: 0 auto;
      margin-bottom: 10px;
      background-image: url(../../../assets/images/down-chevron.webp);
      background-repeat: no-repeat;
   }

   .outCurcleWrapper {
      width: 144px;
      height: 144px;
      position: relative;
      margin: 0 auto;
   }

   .trophyTextW {
      font-size: 18px;
      color: #ffffff;
      font-family: "ObjectSans-Regular";
      text-transform: uppercase;
      letter-spacing: -0.01em;
      line-height: 24px;
      font-style: italic;
      font-weight: 700;
      min-height: 60px;
   }

   .curcleOut1 {
      margin-top: 27px;
   }

}

.CreditPurchaseModal {
   position: relative;

   .closeButton {
      position: absolute;
      top: 15px;
      right: 16px;
      z-index: 2;

      img {
         display: block;
         width: 14px;
         height: 14px;
         position: absolute;
         z-index: 4;
      }
   }

   .circle1 {
      z-index: 0;
      width: 195px;
      height: 220px;
      position: absolute;
      background-image: url('../../../assets/images/circle1.webp');
      background-size: 195px 220px;
      background-repeat: no-repeat;
      background-position: top right;
      top: -24px;
      right: -24px;
   }

   .circle2 {
      z-index: 0;
      width: 98px;
      height: 200px;
      position: absolute;
      background-image: url('../../../assets/images/circle2.webp');
      background-size: 98px 200px;
      background-repeat: no-repeat;
      background-position: top right;
      top: 73px;
      left: -24px;
   }

   .circle3 {
      z-index: 0;
      width: 88px;
      height: 88px;
      position: absolute;
      background-image: url('../../../assets/images/circle3.webp');
      background-size: 88px 88px;
      background-repeat: no-repeat;
      background-position: bottom left;
      bottom: 32px;
      left: 24px;
   }

   .UserProfileModal {
      img {
         width: fit-content;
         max-width: 240px;
         margin: 0 auto;
         display: block;
      }

      h4 {
         text-transform: uppercase;
         font-family: Montserrat;
         font-size: 28px;
         font-style: italic;
         font-weight: 800;
         line-height: 32px;
         letter-spacing: 0.02em;
         text-align: center;
         text-transform: uppercase;
         background: linear-gradient(89.7deg, #17EA85 2.27%, #14D0E6 23.6%, #17EA85 44.41%, #14D0E6 64.17%, #17EA85 82.99%, #14D0E6 99.74%);
         -webkit-background-clip: text;
         -webkit-text-fill-color: transparent;
         background-clip: text;
         text-fill-color: transparent;
         text-shadow: 0px 0px 8px rgba(20, 208, 229, 0.5607843137);
         padding-top: 30px !important;
      }

   }

   .SaveTag {
      color: rgba(222, 74, 74, 1);
      background-color: #291421;
      width: fit-content;
      padding-left: 10px !important;
      padding-right: 10px !important;
      position: absolute;
      top: -14px;
      left: 50%;
      transform: translate(-50%, 0);
      font-size: 12px;
   }
}

//Created Team ConfirmationModal

.CreatedTeamConfirmationModal {
   .modalContentContainer {
      padding: 40px;

      .ModalButtonRow {
         display: flex;

         width: fit-content;
         margin: 0 auto;
         margin-top: 30px;

         .grayButton {
            width: fit-content;
            padding: 10px 15px;
            height: 44px;
            line-height: unset;
            margin-top: 4px;
         }

         .ContinueBtn {
            float: right;
            padding: 0;
            background: linear-gradient(90deg, #F9FF01, #2FFF9E);
            padding: 2px;
            border-radius: 5px;
            transform: skew(-10deg, 0deg);
            margin: 2px;
            padding: 10px 15px;
            width: fit-content;

            span {
               color: #000;
               font-weight: 700;
               text-transform: uppercase;
               font-family: "ObjectSans-Heavy";
            }
         }
      }

   }
}

.PlayerBoosterHistoryModal {
   .modalContentContainer {
      max-width: 690px;
      min-height: 320px;

      .FantasyHistoryTable {
         height: unset;
         min-height: 200px;
      }

      .NoFantasyHistoryData {
         padding: 14px;
         text-align: center;
         font-size: 22px;
      }

      .HeaderMsg {
         display: flex;
         justify-content: center;
         font-size: 18px;
         margin-bottom: 20px;
         text-align: center;
      }

      .FooterMsg {
         text-align: center;
         font-size: 18px;

         span {
            padding: 0 5px;

            b {
               color: #2ddb78;
            }
         }
      }
   }
}

@media (max-width: 992px) {
   .ProfilePaneWrapper {
      .subTitle {
         padding-top: 6px !important;
      }
   }
}

@media (max-width: 768px) {
   .photoModifyModelBtnRow {
      display: grid !important;
      grid-template-columns: 1fr !important;
      gap: 15px !important;

      .LoginButton {
         margin-left: -13px;
      }
   }
   .CreditPurchaseModal {
      > .modalContentContainer {
         width: 95vw;

         .OmniCardWrapper {
            position: relative;

            .OmniCard {
               position: relative;
               width: 98%;
               margin: 0 auto;
               margin-bottom: 15px;

               > div {
                  width: fit-content;
                  margin: 0 auto;
                  margin-top: 4px;
               }
            }
         }

         fieldset {
            .OmniCal {
               display: block;

               > span {
                  display: none;
               }

               > div {
                  display: block;
                  margin-bottom: 10px;
               }
            }
         }
      }
   }
}



.fantasyPointsTable {
   table {
      font-family: "ObjectSans-Regular";

      th {
         background-color: #95a6b74a;
         color: #95a6b7;
         padding: 10px;
         border: 1px solid #dddddd29;
         font-weight: normal;
      }

      td {
         padding: 10px;
         border: 1px solid #dddddd1a;
         color: #95a6b7;
      }
   }

   .PointsTableWrapper {
      margin-bottom: 50px;
   }
}

@media (max-width: 1200px) {
   .fantasyPointsTable {
      table {
         th, td {
            text-align: left;
         }
      }
   }
}

@media (max-width: 678px) {
   .fantasyPointsTable {
      .PointsTableWrapper {
         width: 100%;
         overflow-x: auto;

         table {
            width: 600px;
            font-size: 12px;
         }
      }

      .PointsTableWrapper::-webkit-scrollbar {
         height: 10px;
      }

      .PointsTableWrapper::-webkit-scrollbar-track {
         background: #f1f1f1;
      }

      .PointsTableWrapper::-webkit-scrollbar-thumb {
         background: linear-gradient(180deg, #224755 -19.6%, #878787 48.63%, #646464 110.8%);
         border-radius: 5px;
      }

      .PointsTableWrapper::-webkit-scrollbar-thumb:hover {
         background: #555;
      }

   }
}

body {
   background-color: #010E14;
}

.midContent {
   width: 100%;
   height: 95vh;
   max-width: 1440px;
   margin-left: auto;
   margin-right: 0px;
   margin-bottom: 0;
}

.midContentTitle {
   font-family: 'Rubik-medium', Helvetica, Arial, sans-serif;
   font-size: 48px;
   text-decoration: none;
   text-align: center;
   margin-left: auto;
   font-weight: bold;
   color: hsla(0, 0%, 100%, 1.00);
}

.midContent2 {
   width: 100%;
   max-width: 1440px;
   padding-top: 10px;
   padding-right: 20px;
   padding-left: 20px;
   padding-bottom: 10px;
   margin-right: auto;
   margin-left: auto;
}

.midContent2Title {
   font-family: 'Rubik-medium', Helvetica, Arial, sans-serif;
   font-size: 20px;
   text-align: left;
   font-weight: 600;
   padding-top: 0px;
   width: 0%;
}

.bold {
   font-weight: 600;
   color: #FFFFFF;
}

.smallBtn2 {
   background-color: #000000;
   padding-top: 10px;
   padding-right: 20px;
   padding-bottom: 10px;
   padding-left: 20px;
   color: #fff;
   font-size: 15px;
   border-radius: 20px;
   border: 4px solid hsla(0, 0%, 100%, 1.00);
   line-height: 14px;
}

.left-menu li .active {
   /*background-color: #AB47BC!important;*/
   color: #fff !important;
}

.customBox {
   margin-top: 1%;
}

.font30Bold {
   font-size: 30px;
   line-height: 21px;
   font-family: 'Rubik-Medium', Helvetica, Arial, sans-serif;
}

.textf {
   border: 1px solid #0f2e46;
   background-color: #0b1e2c !important;
   height: 40px;
   padding: 10px;
   font-size: 15px;
   color: #fff;
   width: 100%;
}

.bigBtn {
   cursor: pointer;
   background-color: #000000;
   padding: 10px 20px;
   color: #fff;
   font-size: 15px;
   border-radius: 20px;
   border: 2px solid hsla(0, 0%, 100%, 1.00);
   line-height: 10px;
   width: 175px;
   height: 42px;
   font-family: 'Rubik-Medium', Helvetica, Arial, sans-serif;
}

.bigBtn:disabled {
   pointer-events: none !important;
}

.bigBtn:hover {
   background-color: #14d0e6;
   color: #000000;
}

.linka {
   color: #14d0e6;
   text-decoration: none !important;
   background-color: transparent;
   border: transparent;
}

.linka:hover {
   color: #19EA17FF;
}

/*sign up password strength styles*/
.passwordStrength {
   width: 100%;
   height: 10px;
   background-color: #ddd;
   position: relative;
   margin-bottom: 10px;
}

.strengthBar {
   position: absolute;
   top: 0;
   left: 0;
   height: 100%;
   background-color: #f00;
   width: 0%;
   transition: width 0.3s;
}

.strengthLabel {
   position: relative;
   top: 15px;
   right: 0;
   font-size: 12px;
}

.uiText {
   padding-left: 15px;
}

.uiText li {
   font-size: 13px;
   color: #939a9f;
}

.Radial1 {
   width: 900px;
   height: 900px;
   left: -515px;
   top: -350px;
   background: radial-gradient(32.65% 32.65% at 50% 50%, #04F0FF 0%, rgba(20, 208, 230, 0) 100%);
   mix-blend-mode: normal;
   position: absolute;
   opacity: 0.14;
   z-index: -1;
}

.Radial2 {
   width: 800px;
   height: 70vh;
   right: 0px;
   top: 150px;
   background: radial-gradient(32.65% 32.65% at 110% 50%, #E61479 0%, rgba(230, 20, 20, 0) 100%);
   mix-blend-mode: normal;
   position: absolute;
   opacity: 0.44;
}

.checkIndent {
   /*padding-left: 30px;*/
   position: relative;
   line-height: 17px;
}

.checkIndent input[type=checkbox] {
   /*position: absolute;
   left: 0;
   top:3px;*/
}

.PakistanBg {
   width: 600px;
   right: 0px;
   top: 0px;
   background-size: auto 100%;
   background-repeat: no-repeat;
   mix-blend-mode: normal;
   position: absolute;
   background-position: right top;
   justify-content: end;
   padding: 0;
   z-index: -1;
}

/*new design fonts march-2023*/


.LogoTitle1 {
   font-size: 52px;
   width: 100%;
   float: left;
}

.LogoTitle1 .text {
   /*position: absolute;*/
   font-family: 'SolinaExBI', Helvetica, Arial, sans-serif !important;
   text-transform: uppercase;
   background: linear-gradient(to right, #17EA85, #14D0E6, #17EA85, #14D0E6);
   -webkit-text-fill-color: transparent;
   -webkit-background-clip: text;
   font-size: 52px;
   /*font-size: min(3vw, 52px);*/
}

.LogoTitle2 {
   font-size: 50px;
   font-family: 'ObjSans-HeavySlanted', Helvetica, Arial, sans-serif !important;
   color: #E61479;
}

.TopBotGaps {
   // margin-top: 6vh;
}

input[type=text] {
   width: 100%;
   box-sizing: border-box;
   border: solid 1px #3E5168;
   height: 67px;
   border-radius: 6px;
   background-color: #04151D;
   background-image: -webkit-linear-gradient(0deg, rgba(4, 21, 29, 1.00) 0%, rgba(11, 28, 53, 0.00) 100%);
   background-image: -moz-linear-gradient(0deg, rgba(4, 21, 29, 1.00) 0%, rgba(11, 28, 53, 0.00) 100%);
   background-image: -o-linear-gradient(0deg, rgba(4, 21, 29, 1.00) 0%, rgba(11, 28, 53, 0.00) 100%);
   background-image: linear-gradient(90deg, rgba(4, 21, 29, 1.00) 0%, rgba(11, 28, 53, 0.00) 100%);
   max-width: 495px;
   color: #ffffff;
   padding-left: 20px;
   padding-right: 20px;
   font-size: 16px;
   font-family: 'SolinaLight', Helvetica, Arial, sans-serif;

}

.group {
   position: relative;
   margin-bottom: 5px;
}

input[type=password] {
   display: block;
   background-image: -webkit-linear-gradient(0deg, rgba(4, 21, 29, 1.00) 0%, rgba(11, 28, 53, 0.00) 100%);
   background-image: -moz-linear-gradient(0deg, rgba(4, 21, 29, 1.00) 0%, rgba(11, 28, 53, 0.00) 100%);
   background-image: -o-linear-gradient(0deg, rgba(4, 21, 29, 1.00) 0%, rgba(11, 28, 53, 0.00) 100%);
   background-image: linear-gradient(90deg, rgba(4, 21, 29, 1.00) 0%, rgba(11, 28, 53, 0.00) 100%);
   max-width: 100% !important;
   color: #ffffff;
   padding-left: 20px;
   padding-right: 50px;
   font-size: 16px;
   width: 100%;
   box-sizing: border-box;
   border: solid 1px #3E5168;
   height: 67px;
   border-radius: 6px;
   background-color: #04151D;
   padding-bottom: 22px;
   padding-top: 35px;
   line-height: 10px;
   /*background-image: url("https://storage.googleapis.com/meta11/serviceplatform/pw-eye.png");*/
   background-repeat: no-repeat;
   background-position: 96% center;
}

input[type=password]:focus {

   border-style: solid;
   border-width: 1px;
   /*border-image: linear-gradient(45deg, #17EA85, #14D0E6, #17EA85, #14D0E6, #17EA85, #14D0E6) 1 !important;*/
   border-radius: 6x;
   outline: none;

}

.FormCheckInput {
   height: 18px !important;
   width: 18px !important;
   border: solid 1px #3E5168 !important;
   background-color: #04151D !important;
   margin-top: -3px;
}


/* LABEL */
.group label {
   color: #95A6B7;
   font-size: 16px;
   font-weight: normal;
   position: absolute;
   pointer-events: none;
   left: 16px;
   top: 25px;
   transition: 0.2s ease all;
   -moz-transition: 0.2s ease all;
   -webkit-transition: 0.2s ease all;
}

/* active state */
input[type=password]:focus ~ label, input[type=password]:valid ~ label {
   top: 10px;
   font-size: 12px;
   color: #95A6B7;
   padding-left: 6px;
}

/*Email*/
input[type=text] {
   display: block;
   background-image: -webkit-linear-gradient(0deg, rgba(4, 21, 29, 1.00) 0%, rgba(11, 28, 53, 0.00) 100%);
   background-image: -moz-linear-gradient(0deg, rgba(4, 21, 29, 1.00) 0%, rgba(11, 28, 53, 0.00) 100%);
   background-image: -o-linear-gradient(0deg, rgba(4, 21, 29, 1.00) 0%, rgba(11, 28, 53, 0.00) 100%);
   background-image: linear-gradient(90deg, rgba(4, 21, 29, 1.00) 0%, rgba(11, 28, 53, 0.00) 100%);
   max-width: 495px;
   color: #ffffff;
   padding-left: 20px;
   padding-right: 50px;
   font-size: 16px;
   width: 100%;
   box-sizing: border-box;
   border: solid 1px #3E5168;
   height: 67px;
   border-radius: 6px;
   background-color: #04151D;
   padding-bottom: 22px;
   padding-top: 35px;
   line-height: 10px;
   background-repeat: no-repeat;
   background-position: 96% center;
}

input[type=text]:focus {
   border-style: solid;
   border-width: 1px;
   /*border-image: linear-gradient(45deg, #17EA85, #14D0E6, #17EA85, #14D0E6, #17EA85, #14D0E6) 1 !important;*/
   border-radius: 6px;
   border-color: #17EA85;
   outline: none
}

/* active state */
input[type=text]:focus ~ label, input[type=text]:valid ~ label {
   top: 10px;
   font-size: 12px;
   color: #95A6B7;
   padding-left: 6px;
}


.NumIn {
   width: 68px !important;
   box-sizing: border-box;
   border: solid 1px #3E5168;
   height: 67px;
   border-radius: 6px;
   background-color: #04151D;
   background-image: -webkit-linear-gradient(0deg, rgba(4, 21, 29, 1.00) 0%, rgba(11, 28, 53, 0.00) 100%);
   background-image: -moz-linear-gradient(0deg, rgba(4, 21, 29, 1.00) 0%, rgba(11, 28, 53, 0.00) 100%);
   background-image: -o-linear-gradient(0deg, rgba(4, 21, 29, 1.00) 0%, rgba(11, 28, 53, 0.00) 100%);
   background-image: linear-gradient(90deg, rgba(4, 21, 29, 1.00) 0%, rgba(11, 28, 53, 0.00) 100%);
   text-align: center !important;
   color: #ffffff;
   padding-left: 20px;
   padding-right: 20px;
   font-size: 32px !important;
   font-family: 'SolinaLight', Helvetica, Arial, sans-serif;
   margin-left: 4px;
   margin-right: 3px;
}


::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
   color: #95A6B7;
   opacity: 1; /* Firefox */
}

:-ms-input-placeholder { /* Internet Explorer 10-11 */
   color: #95A6B7;
}

::-ms-input-placeholder { /* Microsoft Edge */
   color: #95A6B7;
}

.FormBox {
   position: relative;
   margin-top: 5px;
   float: left;
   width: 100%;

   .group1 {
      // margin-top:45px;
   }

   .group {
      label {
         top: 18px;
      }
   }

   input[type=text], input[type=password] {
      height: 56px !important;
   }
}


.LoginButton {
   background: linear-gradient(95.34deg, #FAFF00 6.28%, #00FFC2 111.13%);
   box-shadow: 0px 2px 0px rgba(0, 0, 0, 0.51);
   border-radius: 4px;
   transform: skew(-10deg, 0deg);
   border: 1px solid #2FFF9E;
   /*border-image-source: linear-gradient(103.05deg, #2FFF9E 7.22%, #F9FF01 93.09%);*/
   font-family: "ObjectSans-Heavy", Helvetica, Arial, sans-serif;
   font-size: 14px;
   line-height: 18px;
   text-align: center;
   letter-spacing: -0.01em;
   text-transform: uppercase;
   padding: 14px 27px;
   font-weight: 700;
   width: 100%;
   height: 48px;
}

.buttonColor {
   color: #000000;
}

.BlackSkBtn {
   background-color: #0E2029;
   box-shadow: 0px 2px 0px rgba(0, 0, 0, 0.51);
   border-radius: 4px;
   transform: skew(-10deg, 0deg);
   border: 1px solid rgba(0, 255, 194, 0.09);
   font-family: "ObjectSans-Heavy", Helvetica, Arial, sans-serif;
   font-size: 14px;
   line-height: 18px;
   /* identical to box height, or 129% */

   text-align: center;
   letter-spacing: -0.01em;
   text-transform: uppercase;
   padding: 14px 27px;
   font-weight: 700;
   height: 54px;


}

.BlackSkBtn text {

   background-image: linear-gradient(0deg, #FAFF01, #39FF96);
   background-clip: text;
   color: transparent;
}

.OrSpace {
   border-top: 1px solid #162231;
   text-align: center;
   height: 1px;
   margin-top: 27px;
   margin-bottom: 30px;
   line-height: 0;
}

.OrSpace text {
   color: #95A6B7 !important;
   width: 41px;
   background-color: #010E14;
   padding: 3px;
   margin-top: -10px;
   margin-bottom: 0px;
   /*position: absolute;*/

}

.Font16 {
   font-family: 'ObjectSans-Regular', Helvetica, Arial, sans-serif;
   font-size: 16px;
   color: #95A6B7;
   line-height: 20px;
   float: left;
}

.Font16 a {
   background-image: linear-gradient(0deg, #17EA85, #14D0E6);
   background-clip: text;
   color: transparent;
}

.colra {
   background-image: linear-gradient(0deg, #17EA85, #14D0E6);
   background-clip: text;
   color: transparent !important;
}

/*modal classes*/
.ModalCont {
   width: 616px;
   margin-left: auto;
   margin-right: auto;
   background-color: #03161F;
}

.ModalTitle {
   font-size: 48px;
   text-transform: uppercase;
   font-family: 'SolinaLight', Helvetica, Arial, sans-serif;
}

.ModalSmallText {
   font-size: 16px;
   color: #95A6B7;
   font-family: 'ObjectSans-Regular', Helvetica, Arial, sans-serif;
   line-height: 20px;
}

.ModalSmallText2 {
   font-size: 16px;
   color: #ffffff;
   font-family: 'ObjSans-HeavySlanted', Helvetica, Arial, sans-serif;
   line-height: 20px;
   text-transform: uppercase;
}

.ModalSmallText2 font {
   text-transform: uppercase;
   background: linear-gradient(to right, #17EA85, #14D0E6, #17EA85, #14D0E6);
   -webkit-text-fill-color: transparent;
   -webkit-background-clip: text;
}

@font-face {
   font-family: 'asterix';
   src: url('fonts/asterix.eot');
   src: url('fonts/asterix.eot') format('embedded-opentype'),
   url('fonts/asterix.woff2') format('woff2'),
   url('fonts/asterix.woff') format('woff'),
   url('fonts/asterix.ttf') format('truetype'),
   url('fonts/asterix.svg') format('svg');
   font-weight: normal;
   font-style: normal;
}

.asteriskInput {
   font-family: 'asterix', Helvetica, Arial, sans-serif !important;
   font-style: normal;
   font-weight: normal;
   speak: never;
   display: inline-block;
   text-decoration: inherit;
   width: 1em;
   margin-right: .2em;
   font-variant: normal;
   text-transform: none;
   line-height: 1em;
   font-size: 80% !important;
   -webkit-font-smoothing: antialiased;
   -moz-osx-font-smoothing: grayscale;
}


.Error {
   border: 1px solid #FF002E !important;
}

.normalEye:before {
   position: absolute;
   font-family: 'FontAwesome';
   display: block;
   color: #15D9C5;
   top: 50%;
   width: 20px;
   height: 20px;
   transform: translateY(-50%);
   right: 3%;
   font-size: 17px;
   cursor: pointer;
   content: "\f06e";
}

.slashEye:before {
   position: absolute;
   font-family: 'FontAwesome';
   display: block;
   color: #15D9C5;
   top: 50%;
   width: 20px;
   height: 20px;
   transform: translateY(-50%);
   right: 3%;
   font-size: 17px;
   cursor: pointer;
   content: "\f070";
}

.passwordStrengthWrapper {
   margin-bottom: 45px;
}

.agreementstWrapper {
   display: block;
   width: 100%;
   margin-bottom: 18px;
   height: 50px;

   > div {
      display: block;
      width: 100%;
      height: 23px;

      input {
         display: block;
         float: left;
      }

      > div {
         width: calc(100% - 60px);
         float: left;
         margin-left: 30px;
         height: 16px;
         margin-top: 2px;
      }
   }
}

.agreeToTextWrapper {
   width: calc(100% - 40px);
}

.FormWrapper {
   max-width: 496px;
}

.SocialButtonWrapper {
   margin-top: 38px;

   button {
      width: 100%;
      height: 54px;
      margin-bottom: 16px;
      padding: unset;
      display: flex;
      justify-content: center;
      align-items: center;
      gap: 16px;

      img {
         transform: skew(10deg, 0deg);
      }
   }
}

@media only screen and (min-width: 1440px) {
   .imgSize {
      width: 70%;
   }
   .midContent {
      width: 100%;
      margin-top: 0px;
      margin-left: auto;
      margin-right: auto;
      padding-right: 0px;
      padding-left: 0px;
   }

   .loginLogo {
      width: 167px;
   }

   .LoginFont {
      font-family: 'Rubik-medium', Helvetica, Arial, sans-serif;
      font-size: 30px;
      color: #FFFFFF;
   }
}

@media only screen and (max-width: 1440px) {
   .imgSize {
      width: 70%;
   }
   .LogoTitle1 {
      margin-top: 10px;
   }
}

@media only screen and (max-width: 1366px) {
   .imgSize {
      width: 75%;
   }
}

@media only screen and (max-width: 1200px) {
   .imgSize {
      width: 90%;
   }
   .loginLogo {
      width: 150px;
   }

   .LoginFont {
      font-family: 'Rubik-medium', Helvetica, Arial, sans-serif;
      font-size: 25px;
      color: #FFFFFF;
   }
}

@media only screen and (max-width: 992px) {
   .LogoTitle1 {

      margin-top: 50px;
   }

   .LogoTitle1 .text {
      font-size: 26px;
   }

   .marginTopMd {
      margin-top: 50px;
   }

   .FormBox {
      margin-top: 20px;
   }
   .FormWrapper {
      margin: 0 auto;
   }
}

@media (max-width: 768px) {
   .loginLogo {
      width: 120px;
   }

   .LoginFont {
      font-family: 'Rubik-medium', Helvetica, Arial, sans-serif;
      font-size: 20px;
      color: #FFFFFF;
   }
}

@media only screen and (max-width: 552px) {
   .midContent {
      position: relative;
   }

   .Radial2 {
      display: none;
   }

   .Radial1 {
      left: 0;
      top: 0;
      background: radial-gradient(32.65% 32.65% at 0% 0%, #04F0FF 0%, rgba(20, 208, 230, 0) 100%);
      height: 100vh;
   }

   .LoginButton {
      font-size: 14px;
      padding: 10px 27px;
      height: 46px;
   }

}

@media (min-height: 650px) and (max-height: 812px) and (max-width: 552px) {
   .Font16 {
      /*position: absolute;*/
      bottom: 30px;
      left: 10px;
   }
}

@import '../../../scss/globals/global';
@import '../../../scss/utilities/mixins';

.LeagueTournamentPage {
   .ResponceCont {
      max-width: 1240px;
      margin-left: auto;
      margin-right: auto;
   }

   h2 {
      text-transform: uppercase;
      font-size: 32px;
      font-family: "Montserrat", sans-serif;
      display: block;
      float: left;
      margin-top: 40px;
      // transform: skew(-5deg, 0deg);
   }

   h1 {
      margin: 40px 0;
      font-size: 42px;
      line-height: 44px;
      text-align: left;
      margin-top: 10px;
      font-family: "Montserrat";
      font-weight: 800;
      font-style: italic;
      letter-spacing: 0.02em;
      text-transform: uppercase;
      /* text-shadow: 0px 0px 8px rgba(20, 208, 229, 0.56); */
      background: linear-gradient(89.7deg, #17EA85 2.27%, #14D0E6 23.6%, #17EA85 44.41%, #14D0E6 64.17%, #17EA85 82.99%, #14D0E6 99.74%);
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
      background-clip: text;
      text-fill-color: transparent;
      margin-bottom: 18px;
      width: 800px;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
   }

   .LimitedTournamentHeader {
      height: 220px;
      background-repeat: no-repeat;
      background-position: left top;

      > div > div {
         position: relative;

         .NowPlayingBlock, .UpcomingBlock {
            background-image: url('../../../assets/images/Now_playing_block.webp');
            background-repeat: no-repeat;
            width: 526px;
            height: 300px;
            position: absolute;
            right: -26px;
            z-index: 1;
            top: -40px;

            .NowPlayingContainer {
               width: 260px;
               height: 180px;
               float: right;
               margin: 60px 45px;

               h2 {
                  text-transform: uppercase;
                  font-size: 22px;
                  font-family: 'ObjectSans-Regular';
                  font-style: italic;
                  text-align: right;
               }

               .Row1 {
                  display: flex;
                  gap: 10px;
                  align-content: end;

                  h2 {
                     padding: 0;
                     margin: 0;
                     text-align: right;
                  }

                  .liveTag {
                     display: block;
                     width: -moz-fit-content;
                     width: fit-content;
                     background: linear-gradient(96.32deg, #B10B8F 1.99%, #E01655 99.35%), linear-gradient(92.2deg, #E11653 2.13%, #AD0A93 106.58%);
                     transform: skew(-10deg, 0deg);
                     bottom: 2px;
                     font-family: "ObjectSans-Regular";
                     letter-spacing: 2px;
                     font-size: 12px;
                     padding: 2px 10px;
                     height: 24px;
                     color: rgb(255, 255, 255);
                     -webkit-text-fill-color: #FFFFFF !important;
                     text-transform: uppercase;

                     span {
                        width: 3px;
                        height: 3px;
                        background-color: #fff;
                        display: inline-block;
                        margin-right: 4px;
                        margin-bottom: 2px;
                        animation: OngoingTournaments_blink__Cysya 2s infinite;
                     }

                  }
               }

               .Row2 {
                  display: flex;
                  flex-direction: column;
                  margin-top: 24px;

                  .SubRow {
                     display: flex;
                     gap: 10px;
                     margin-bottom: 10px;

                     h4 {
                        font-size: 14px;
                        font-style: italic;
                        font-family: "ObjectSans-Regular";
                        text-transform: uppercase;
                        font-weight: 700;
                        margin-top: 7px;

                        width: 116px;
                        display: -webkit-box;
                        max-width: 100px;
                        -webkit-line-clamp: 2;
                        -webkit-box-orient: vertical;
                        overflow: hidden;
                        height: 35px;
                        max-height: 35px;
                     }

                     span {
                        font-size: 24px;
                        color: #95A6B7;
                        font-weight: 800;
                        font-style: italic;

                     }

                     label {
                        margin-top: -0px;
                        display: block;
                     }
                  }

                  .Logo {
                     width: 46px;
                     height: 46px;
                     background-color: #000;
                     border-radius: 50%;
                     padding: 2px;
                     border: 2px solid #000;
                  }

                  .BattBallIcon {
                     width: 16px;
                     height: 16px;
                     margin-top: 6px;

                     img {
                        width: 100%;
                     }
                  }
               }
            }
         }

         .UpcomingBlock {

            .upcomingTag {
               display: block;
               width: -moz-fit-content;
               width: fit-content;
               font-family: "ObjectSans-Regular";
               letter-spacing: 2px;
               font-size: 12px;
               background: linear-gradient(91.36deg, #19A62F 1.34%, #DBB908 66.71%);
               border: 1px solid;
               border-image-source: linear-gradient(92.52deg, #D0B80B 0.84%, #1EA72F 41.93%);
               transform: skew(-10deg, 0deg);
               padding: 2px 14px;
               text-transform: uppercase;
               height: 22px;
            }

            .Row1 {
               display: block;
               gap: 10px;
               align-content: end;
            }

            .Row2 {
               h2 {
                  font-family: "OdibeeSans-Regular";
                  font-size: 21px;
                  font-weight: 400;
                  line-height: 27px;
                  letter-spacing: 0.28em;
                  text-align: left;
                  text-transform: uppercase;
                  background: -webkit-linear-gradient(45deg, #FAFF00, #00FFC2 80%);
                  -webkit-background-clip: text;
                  -webkit-text-fill-color: transparent;
                  // margin-top: 20px;
                  margin-top: -10px;
               }

               .countdown {
                  width: fit-content;
                  margin: 0 auto;
                  margin-top: 10px;
                  display: grid;
                  grid-template-columns: repeat(3, 78px);
                  gap: 0px;
                  scale: 1.3;

                  > div {
                     .cercalBack {
                        width: 56px;
                        height: 56px;
                        // background-image: url('../images/backgrounds/countdown_circle-01.svg');
                        background-image: url('../../../assets/images/backgrounds/countdown_circle-01.svg');
                        background-position: top left;
                        background-repeat: no-repeat;
                        position: relative;
                        margin-left: 13px;
                     }

                     .cercal {
                        width: 56PX;
                        height: 56px;
                        border-radius: 50%;
                        /* border: 1.69px solid; */
                        border-color: linear-gradient(143.92deg, #FAFF01 13.27%, #39FF96 61.67%);

                        > span {
                           position: absolute;
                           width: 50px;
                           top: 19%;
                           left: 5%;
                           margin-left: auto;
                           margin-right: auto;
                           font-family: $countdown-count-font;
                           // font-size: $countdown-count;
                           font-size: 21px;
                           letter-spacing: 2px;
                           background: -webkit-linear-gradient(45deg, #FAFF00, #00FFC2 80%);
                           -webkit-background-clip: text;
                           -webkit-text-fill-color: transparent;
                           text-align: center;
                        }

                     }

                     > span {
                        text-transform: uppercase;
                        font-weight: 400;
                        display: block;
                        text-align: center;
                        font-family: $countdown-bottom-font;
                        font-size: $countdown-bottom-title;
                        margin-top: 8px;
                        background: -webkit-linear-gradient(45deg, #FAFF00, #00FFC2 80%);
                        -webkit-background-clip: text;
                        -webkit-text-fill-color: transparent;
                     }

                  }

                  .timerWrapper {
                     width: fit-content;
                     position: absolute;
                     top: -62px;
                     margin-left: 1px;

                     div {
                        max-width: 54px;

                        > svg {
                           max-width: 54px;
                           margin-left: 13px;

                           > :nth-child(1) {
                              stroke: unset !important;
                           }
                        }
                     }

                  }
               }
            }

            .nextTournamentWrapper {
               position: relative;
               // left: 0;
               right: 10vw;
               margin-left: auto;
               margin-right: auto;

               bottom: 0;
               //width: 480px;
               // height: 182px;
               border-radius: 3px;
               //box-shadow: 0px 0px 55.573062896728516px 0px rgba(0, 255, 194, 0.7);
               //background: #00000042;
               //backdrop-filter: blur(5px);
               //-webkit-backdrop-filter: blur(5px);
               //border: solid 1px;
               //border-image: linear-gradient(45deg, #F9FF01, #2FFF9E) 1;
               //border-width: 2px;
               padding-bottom: 10px;

               // h2 {
               //    font-family: $countdown-bottom-font;
               //    font-size: 21px;
               //    font-weight: 400;
               //    line-height: 27px;
               //    letter-spacing: 0.28em;
               //    text-align: center;
               //    text-transform: uppercase;
               //    background: -webkit-linear-gradient(45deg, #FAFF00, #00FFC2 80%);
               //    -webkit-background-clip: text;
               //    -webkit-text-fill-color: transparent;
               //    margin-top: 20px;
               // }


            }
         }
      }
   }

   .NowPlaying {
      position: absolute;
      width: 526px;
      height: 300px;
      right: 0;
      top: -80px;
      background-image: url('../../../assets/images/limited_tournament_right_col_back.webp');
      background-repeat: no-repeat;
      background-position: left top;

      .NowPlayingContent {
         // padding: 20px;
         height: 100px;
         margin-top: 70px;
         margin-right: 40px;
         float: right;
         width: 260px;

         .liveLblPink {
            float: left;
            width: 55px;
            margin-right: 10px;
            display: flex;
            transform: skew(-5deg, 0deg);
            text-transform: uppercase;
            font-size: 12px;
            margin-top: 2px;

            .blinking {
               margin: 8px 5px 0 6px !important;
            }
         }

         .row1 {
            margin-top: 18px;
         }

         .row1 {
            display: grid;
            grid-template-columns: 46px 1fr 14px 80px;
            gap: 5px;

            .thumb img {
               max-width: 46px;
               max-height: 46px;
               border-radius: 50%;
            }

            .name {
               font-family: 'ObjectSans-Regular';
               font-size: 14px;
               transform: skew(-8deg, 0deg);
               text-transform: uppercase;
            }

            .BatOrBall {
               margin-top: 0px;
            }

            .scoreDetail {
               text-align: right;

               > div {
                  font-family: 'SolinaExBI';
                  font-weight: 700;
                  font-size: 24px;
                  color: #95A6B7 !important;
               }

               sapn {
                  display: block;
                  margin-top: -8px;
                  font-style: normal;
                  font-weight: 400;
                  font-size: 12px;
                  text-align: right;
                  color: #95A6B7 !important;
                  padding-right: 3px;
               }
            }
         }
      }
   }

   .MatchWrapper {
      .MatchBox {
         margin-bottom: 25px;

         .Container {
            background: linear-gradient(180deg, rgba(26, 38, 57, 0.5) 0%, rgba(11, 28, 53, 0.5) 100%);
            padding: 0x;
            border: 1px solid rgba(255, 255, 255, 0.06);
            position: relative;

            .liveLblPink {
               width: 55px;
               display: flex;
               transform: skew(-5deg, 0deg);
               text-transform: uppercase;
               font-size: 12px;

               .blinking {
                  margin: 8px 5px 0 6px !important;
               }
            }

            .upcomeGreen {
               width: fit-content;
               box-shadow: 0px 2px 0px rgba(0, 0, 0, 0.51);
               background: linear-gradient(92.07deg, #19A62F 2.01%, #DBB908 100.44%) padding-box, linear-gradient(to right, #DBB908, #19A62F) border-box;
               border-radius: 0px;
               border: 1px solid transparent;
               align-items: center;
               border-radius: 2px;
               transform: skew(-5deg, 0deg);
               height: 24px;
               padding: 10px;
               padding-left: 10px;
               padding-right: 11px;
               display: flex;
               text-transform: uppercase;
               font-family: 'ObjectSans-Regular';
               font-size: 12px;
               letter-spacing: 0.05em;

               a {
                  text-decoration: none;
                  color: #fff;
               }
            }

            .upcomeGreen:hover {
               background: linear-gradient(95.34deg, #DBB908 6.28%, #19A62F 111.13%);
               text-decoration: none;
            }

            .shareBtnWrapper {
               position: absolute;
               right: 12px;
               top: 9px;
            }

            .matchDate {
               display: grid;
               grid-template-columns: 100px 1fr;
               font-size: 12px;
               color: #95A6B7;
               margin-top: 8px;

               label {
                  padding: 0;
               }

               .mtDate {
                  text-align: left;
               }

               .mtTime {
                  text-align: right;
               }
            }

            > div {
               padding: 0;

               > div {
                  padding: 15px;
               }
            }

            .row1 {
               padding-bottom: 0;
            }

            .row2 {
               padding-top: 0;
               padding-bottom: 0;
               border-bottom: 1px solid #ffffff45;
            }

            .row3 {
               padding-bottom: 15px;

               .teamDetail {
                  display: grid;
                  grid-template-columns: 46px 1fr 80px;
                  gap: 5px;

                  .thumb img {
                     width: 46px;
                     height: 46px;
                     border-radius: 50%;
                  }

                  .name {
                     font-family: 'ObjectSans-Regular';
                     font-size: 14px;
                     transform: skew(-8deg, 0deg);
                     text-transform: uppercase;
                  }

                  .scoreDetail {
                     text-align: right;

                     > div {
                        font-family: 'SolinaExBI';
                        font-weight: 700;
                        font-size: 24px;
                     }

                     sapn {
                        font-style: normal;
                        font-weight: 400;
                        font-size: 12px;
                        text-align: right;
                        color: #95A6B7 !important;
                        padding-right: 3px;
                     }
                  }
               }
            }
         }
      }
   }


   ////////////////////////////////////


   // .WinPrizes {
   //    position: relative;
   //    margin-top: 20px;
   //    margin-bottom: 20px;
   //    border: solid 2px #112c4dcc;
   //    border-radius: 6px;
   //    background-color: #112338;
   //    // box-shadow: 0 8px 6px -6px #091627;

   //    -webkit-box-shadow: inset 0px 0px 25px 15px rgb(14 30 51);
   //    -moz-box-shadow: inset 0px 0px 25px 15px rgb(14 30 51);
   //    box-shadow: inset 0px 0px 25px 15px rgb(14 30 51);

   //    .WinPrizesWrapper {
   //       background-color: #031020;
   //       margin: 12px;
   //       border-radius: 6px;
   //       border: solid 2px #112c4dcc;
   //       padding: 32px 0;
   //       -webkit-box-shadow: inset 0px 0px 25px 15px rgb(11 29 45);
   //       -moz-box-shadow: inset 0px 0px 25px 15px rgb(11 29 45);
   //       box-shadow: inset 0px 0px 25px 15px rgb(11 29 45);
   //       text-align: center;

   //       .CreditWrapper {
   //          display: grid;
   //          grid-template-columns: 1fr 1fr;
   //          gap: 10px;
   //          max-width: 160px;
   //          margin: 0 auto;
   //          margin-top: 10px;

   //          > * {
   //             display: flex;
   //          }
   //       }
   //    }

   //    @mixin ConnerImg {
   //       width: 15px;
   //       height: 15px;
   //       background-image: url("../../../assets/images/backgrounds/tournaments/reflection.png");
   //       background-repeat: no-repeat;
   //       position: absolute;
   //    }

   //    .Conner1 {
   //       @include ConnerImg;
   //       left: 0;
   //       top: 0;
   //    }

   //    .Conner2 {
   //       @include ConnerImg;
   //       right: 0;
   //       top: 0;
   //       transform: rotate(-90deg);
   //    }

   //    .Conner3 {
   //       @include ConnerImg;
   //       bottom: 0;
   //       right: 0;
   //    }

   //    .Conner4 {
   //       @include ConnerImg;
   //       left: 0;
   //       bottom: 0;
   //       transform: rotate(-90deg);
   //    }

   //    @mixin Shades {
   //       background-repeat: no-repeat;
   //       position: absolute;
   //    }

   //    .Shade1 {
   //       @include Shades;
   //       background-image: url("../../../assets/images/backgrounds/tournaments/shade_right_bottom.png");
   //       background-repeat: no-repeat;
   //       width: 64px;
   //       height: 156px;
   //       bottom: 0;
   //       right: 0;
   //    }

   //    .Shade2 {
   //       @include Shades;
   //       background-image: url("../../../assets/images/backgrounds/tournaments/shade_left_bottom.png");
   //       background-repeat: no-repeat;
   //       width: 64px;
   //       height: 156px;
   //       bottom: 0;
   //       left: 0;
   //    }

   //    .Shade3 {
   //       @include Shades;
   //       background-image: url("../../../assets/images/backgrounds/tournaments/shade_right_middle.png");
   //       background-repeat: no-repeat;
   //       width: 64px;
   //       height: 210px;
   //       bottom: 130px;
   //       right: 0;
   //    }

   //    .Shade4 {
   //       @include Shades;
   //       background-image: url("../../../assets/images/backgrounds/tournaments/shade_left_middle.png");
   //       background-repeat: no-repeat;
   //       width: 64px;
   //       height: 210px;
   //       bottom: 130px;
   //       left: 0;
   //    }

   //    img {
   //       margin: 0 auto;
   //    }

   //    .LineWrapper {
   //       height: 38px;
   //       width: fit-content;
   //       margin: 0 auto;

   //       img {
   //          display: block;
   //          margin: 0 auto;
   //          height: 46px;
   //       }
   //    }
   // }

   ////////////////////////////////////
   .PlayerContainerWrapper {
      background: linear-gradient(180deg, #224755 -19.6%, #07A88F 48.63%, #1E4F56 110.8%);

      margin: 20px 30px !important;
      padding: 5px;
      margin: 0 auto;
      border-radius: 6px;

      .PlayerContainer {
         background-color: #020d15;
         border-radius: 6px;
         padding: 5px;
         min-height: 238px;

         table {
            width: 100%;
            min-width: 600px;

            thead {
               background-color: #020d15;

               th {
                  padding: 8px 10px;
                  font-family: "ObjectSans-Regular";
                  font-weight: normal;
                  font-size: 14px;
                  font-weight: bold;
                  color: #95A6B7;
                  font-style: italic;
               }

               th:nth-child(1) {
                  width: 40%;
               }
            }

            tbody {
               tr:nth-child(odd) {
                  background-color: #0c1728;
               }

               td {
                  padding: 8px 10px;
                  font-family: "ObjectSans-Regular";
                  font-weight: normal;
                  font-size: 14px;

                  a {
                     text-decoration: none;
                     color: #FFFFFF;
                  }

                  img {
                     //width: 36px;
                     max-width: 36px;
                     height: 36px;
                     border-radius: 50%;
                  }

                  img.PointsIcon {
                     width: 28px;
                     height: 28px;
                     padding: 2px;
                  }
               }

               td:nth-child(1) {
                  width: 10%;
                  min-width: 80px;
                  padding-right: 0;
               }

               td:nth-child(2) {
                  min-width: 180px;

                  > span {
                     display: block;
                     width: 180px;
                     white-space: nowrap;
                     overflow: hidden;
                     text-overflow: ellipsis;
                  }
               }

               td:nth-child(3) {
                  min-width: 212px;
               }
            }
         }
      }

      .PlayerContainer {
         overflow-x: auto;
      }

      .PlayerContainer::-webkit-scrollbar {
         // width: 10px;
         height: 10px;
      }

      /* Track */
      .PlayerContainer::-webkit-scrollbar-track {
         background: #f1f1f1;
      }

      /* Handle */
      .PlayerContainer::-webkit-scrollbar-thumb {
         background: linear-gradient(180deg, #224755 -19.6%, #8bfe7b 48.63%, #1E4F56 110.8%);
      }

      /* Handle on hover */
      .PlayerContainer::-webkit-scrollbar-thumb:hover {
         background: #555;
      }
   }

   .rightCol {
      .bannerWrapper {
         img {
            width: 100%;
         }
      }

      .WinPrizes {
         .WinPrizesWrapper {
            .LineWrapper {
               height: 38px;
               width: fit-content;
               margin: 0 auto;

               img {
                  display: block;
                  margin: 0 auto;
                  height: 46px;
               }
            }

            .FirstPlace,
            .SecondPlace,
            .ThirdPlace {
               > img {
                  display: block;
                  margin: 0 auto;
                  height: 55px;
               }
            }
         }

         .WinnerWrapper {
            width: 208px;
            height: 155px;
            background-image: url("../../../assets/images/winner_background.webp");
            background-repeat: no-repeat;
            padding: 15px;
            margin: 0 auto;
            margin-bottom: 20px;

            h4 {
               font-family: 'ObjectSans-Regular';
               font-size: 12px;
               text-transform: uppercase;
               text-align: center;
               font-style: italic;
               margin-top: 8px;
               padding-bottom: 0;
               border: none;
            }

            .WinnerProfilePic {
               width: 56px;
               height: 56px;
               border-radius: 50%;
               border: 1px solid;
               margin: 0 auto !important;
               background-image: linear-gradient(to bottom, #FAFF01 0%, #39FF96 100%);
               box-shadow: 0px 0px 10px 0px rgba(86, 255, 128, 0.5);
               position: relative;

               img {
                  position: absolute;
                  left: 0;
                  top: 0;
                  width: 55px;
                  height: 55px;
                  border-radius: 50%;
                  padding: 2px;
               }

            }

            .WinnerPointsWrapper {
               display: flex;
               gap: 5px;
               width: 100%;

               > div:nth-child(1), div:nth-child(3) {
                  flex: 1;
               }

               > div:nth-child(1) {
                  .IconWithValues {
                     font-weight: bold;
                     color: #17EA85;
                  }
               }
            }

            .WinnerPlace {
               width: 30px;
               background-image: url("../../../assets/images/place_background.webp");
               background-repeat: no-repeat;

               span {
                  color: #000;
                  font-weight: bold;
                  font-size: 17px;
                  padding-top: 3px;
                  display: block;
                  width: 100%;
                  text-align: center;
               }
            }

            .IconWithValues {
               display: flex;

               img {
                  width: 16px;
                  height: 16px;
                  margin-top: 4px;
               }
            }
         }

         .WinnerWrapper:last-child {
            margin-bottom: 0;
         }
      }

      .OverviewWrapper {
         h4 {
            text-align: left;
            font-size: 20px;
            font-family: "Montserrat", sans-serif;
            border-bottom: 1px solid transparent;
            -webkit-border-bottom-width: 1px;
            border-style: solid;
            border-image: linear-gradient(to right, rgba(38, 200, 103, 0), rgb(38, 200, 103), rgba(38, 200, 103, 0)) 0.8;
            -webkit-mask-image: linear-gradient(to right, rgba(38, 200, 103, 0), rgb(38, 200, 103), rgba(38, 200, 103, 0)) 0.8;
            border-top: 0;
            padding-bottom: 10px;
         }

         span {
            text-align: left;
         }

         p {
            text-align: left;
            margin-top: 10px;
         }

         .Date {
            font-size: 14px;
            font-weight: 400;
            text-align: left;
         }

         .WinPrizesWrapper {
            > div {
               justify-content: center;
            }

            button {
               width: fit-content;
               margin: 0 auto;
               background: linear-gradient(90deg, #F9FF01, #2FFF9E);
               border-radius: 2px;
               transform: skew(-10deg, 0deg);
               width: -moz-fit-content;
               width: fit-content;
               height: 45px;
               display: flex;
               justify-content: center;
               align-items: center;
               width: fit-content;
               padding: 1px;
               margin-bottom: 0 !important;

               span {
                  background-color: #0E2029;
                  border-radius: 4px;
                  color: #fff;
                  text-transform: uppercase;
                  font-size: 14px;
                  font-weight: 600;
                  padding: 10px 18px;
                  border: 0;
               }
            }
         }
      }

      .playNow {
         background: linear-gradient(95.34deg, #FAFF00 6.28%, #00FFC2 111.13%);
         box-shadow: 0px 2px 0px rgba(0, 0, 0, 0.51);
         border-radius: 2px;
         transform: skew(-10deg, 0deg);
         border: 1px solid #2FFF9E;
         /* border-image-source: linear-gradient(103.05deg, #2FFF9E 7.22%, #F9FF01 93.09%); */
         text-align: center;
         letter-spacing: -0.01em;
         padding: 12px;
         /* font-weight: 700; */
         width: -moz-fit-content;
         width: fit-content;
         height: 46px;
         color: #03161F;
         font-family: "ObjSans-HeavySlanted";
         font-size: 14px;
         text-transform: uppercase;
         transform: skew(-10deg, 0deg);
      }

      .playNow:hover {
         background: linear-gradient(95.34deg, #00FFC2 6.28%, #FAFF00 111.13%);
         color: #ffffff;
      }
   }

   .MatchDetails {
      padding-right: 24px !important;

      h2 {
         text-align: center;
         font-size: 24px;
         text-transform: uppercase;
      }

      .WinPrizes {
         position: relative;
         margin-bottom: 20px;
         border: solid 2px #112c4dcc;
         border-radius: 6px;
         background-color: #112338;
         // box-shadow: 0 8px 6px -6px #091627;

         -webkit-box-shadow: inset 0px 0px 25px 15px rgb(14 30 51);
         -moz-box-shadow: inset 0px 0px 25px 15px rgb(14 30 51);
         box-shadow: inset 0px 0px 25px 15px rgb(14 30 51);

      }

      .TournamentView {
         text-transform: uppercase;

         .tournamentContainer {
            max-width: 600px;
            margin: 0 auto;
         }

         .tournamentContainer::-webkit-scrollbar {
            // width: 10px;
            height: 10px;
         }

         /* Track */
         .tournamentContainer::-webkit-scrollbar-track {
            background: #f1f1f1;
         }

         /* Handle */
         .tournamentContainer::-webkit-scrollbar-thumb {
            background: linear-gradient(180deg, #224755 -19.6%, #8bfe7b 48.63%, #1E4F56 110.8%);
         }

         /* Handle on hover */
         .tournamentContainer::-webkit-scrollbar-thumb:hover {
            background: #ccc;
         }

         ul {
            li.TeamItem {
               > div {
                  position: relative;
                  transform: skew(-12deg, 0deg);
                  width: 142px;
                  border: 1px solid;
                  border-image-source: linear-gradient(99.98deg, rgba(213, 42, 177, 0.2) -2.72%, rgba(68, 91, 204, 0.2) 128.13%);
                  background: radial-gradient(87.12% 337.52% at 31.82% 50%, rgba(213, 42, 177, 0.2) 0%, rgba(68, 91, 204, 0.45) 100%);
                  border-radius: 4px;

                  span {
                     position: absolute;
                     top: -5px;
                     left: -8px;
                     display: block;
                     width: 30px;
                     height: 36px;
                     background-image: url("../../../assets/images/match_tree_img_node_back.webp");
                     background-repeat: no-repeat;
                     -webkit-box-shadow: 0px 0px 7px 0px rgba(0, 0, 0, 0.83);
                     -moz-box-shadow: 0px 0px 7px 0px rgba(0, 0, 0, 0.83);
                     box-shadow: 0px 0px 7px 0px rgba(0, 0, 0, 0.83);
                     border-radius: 4px;

                     img {
                        width: 32px;
                        height: 36px;
                     }
                  }

                  label {
                     font-size: 12px;
                     font-family: $sectionContentFont;
                     padding-left: 14px;
                  }
               }
            }

            li.EmptyTeamItem {
               > div {
                  > span {
                     position: absolute;
                     top: -5px;
                     left: -8px;
                     display: block;
                     width: 30px;
                     height: 36px;
                     background: rgb(213, 42, 177);
                     background: linear-gradient(121deg, rgba(213, 42, 177, 1) 36%, rgba(68, 91, 204, 1) 100%);
                     border-radius: 4px;
                     -webkit-box-shadow: 0px 0px 7px 0px rgba(0, 0, 0, 0.83);
                     -moz-box-shadow: 0px 0px 7px 0px rgba(0, 0, 0, 0.83);
                     box-shadow: 0px 0px 7px 0px rgba(0, 0, 0, 0.83);
                     border-radius: 4px;

                     label {
                        font-size: 24px;
                        font-weight: bolder;
                        position: absolute;
                        left: -6px;
                        top: -4px;
                     }
                  }

               }
            }


         }

         .tournamentBrackets {
            display: flex;
            flex-direction: row;
            list-style-type: none;
            //background: #fdfdfd;
            margin-bottom: 50px;
            overflow-x: auto;
         }

         .tournamentBrackets::-webkit-scrollbar {
            // width: 10px;
            height: 10px;
         }

         /* Track */
         .tournamentBrackets::-webkit-scrollbar-track {
            background: #f1f1f1;
         }

         /* Handle */
         .tournamentBrackets::-webkit-scrollbar-thumb {
            background: linear-gradient(180deg, #224755 -19.6%, #8bfe7b 48.63%, #1E4F56 110.8%);
         }

         /* Handle on hover */
         .tournamentBrackets::-webkit-scrollbar-thumb:hover {
            background: #ccc;
         }

         .bracket {
            padding-left: 0;
            display: flex;
            margin: 0;
            padding: 30px 0;
            flex-grow: 1;
            flex-direction: column;
            justify-content: space-around;
            list-style-type: none;
            // border-right: 1px dashed #ccc;
            flex: 1;
         }

         .bracket:nth-child(2) {
            padding-left: 30px;
         }

         .bracket:nth-child(3) {
            padding-left: 30px;

            .TeamItem:nth-of-type(odd) {
               // height: 140px !important;
            }
         }

         .TeamItem {
            // background-color: #555;
            padding: .5rem;
            display: block;
            margin: 0.05rem 10px;
            position: relative;
            vertical-align: middle;
            line-height: 2;
            text-align: center;
         }

         .TeamItem:after {
            content: '';
            border-color: #445BCC80;
            border-width: 2px;
            position: absolute;
            display: block;
            width: 30px;
            right: -20px;
         }

         .TeamItem:nth-of-type(odd):after {
            border-right-style: solid;
            border-top-style: solid;
            height: 100%;
            top: 50%;
         }

         .TeamItem:nth-of-type(even):after {
            border-right-style: solid;
            border-bottom-style: solid;
            height: 100%;
            top: -50%;
         }

         .TeamItem:before {
            content: '';
            border-top: 2px solid #445BCC80;;
            position: absolute;
            height: 2px;
            width: 39px;
            left: -31px;
            top: 50%;
         }

         .bracket2 {
            .TeamItem:nth-of-type(odd):after {
               height: 200%;
               top: 50%;
            }

            .TeamItem:nth-of-type(even):after {
               height: 200%;
               top: -150%;
            }
         }

         .bracket3 {
            .TeamItem:nth-of-type(odd):after {
               height: 350%;
               top: 50%;
            }

            .TeamItem:nth-of-type(even):after {
               height: 350%;
               top: -300%;
            }
         }

         .bracket4 {
            .TeamItem:nth-of-type(odd):after {
               height: 700%;
               top: 50%;
            }

            .TeamItem:nth-of-type(even):after {
               height: 700%;
               top: -650%;
            }
         }

         .bracket:first-of-type {
            .TeamItem:before {
               display: none;
            }
         }

         .bracket4 {
            .TeamItem:after {
               display: none;
            }
         }

         .bracket:last-of-type {
            .TeamItem:before,
            .TeamItem:after {
               display: none;
            }
         }

         .TeamItem time {
            display: inline-block;
            background-color: #dbdbdb;
            font-size: .8rem;
            padding: 0 .6rem;
         }

         .bracketFInal {
            li.TeamItem {
               div {
                  span {
                     background: rgb(250, 255, 0);
                     background: linear-gradient(121deg, rgba(250, 255, 0, 1) 36%, rgba(0, 255, 194, 1) 100%);

                     label {
                        color: #000;
                     }

                     img {
                        position: absolute;
                        width: 20px;
                        height: 15px;
                        top: -17px;
                        left: 3px;
                        transform: skew(11deg, 0deg);
                     }
                  }
               }

               h4 {
                  font-size: 14px;
                  font-family: 'ObjectSans-Regular';
                  margin-top: 4px;
               }
            }

            > li::before {
               content: '';
               border-top: 2px solid #445BCC80;
               position: absolute;
               height: 2px;
               width: 39px;
               left: -31px;
               top: 50%;
            }
         }
      }

   }

   .MatchOrDetailsWrapper {
      display: grid;
      grid-template-columns: 1fr 1fr 1fr;
      gap: 10px;
      margin-top: 20px;
      margin-bottom: 15px;

      .RadioBtnWrapper {
         border-radius: 6px;
         min-width: max-content;

         input {
            scale: 1.5;
            margin-top: 12px;
            margin-left: 8px;
            display: block;
            float: left;
         }

         label {
            margin-top: 5px;
            margin-left: 8px;
            color: #95A6B7;
            display: block;
         }

         input[type='radio'] {
            accent-color: #17EA85;
            background-color: #17EA85;
            color: #95A6B7;
         }
      }
   }

   .LeaderBoard {
      .WinPrizesWrapper {
         display: block;
      }

      .LeaderboardView {
         width: 90%;
         margin: 0 auto;

         table {
            width: 100%;

            thead {
               background-color: #131F30;
               border: solid 1px #1F2A36;

               tr {
                  th {
                     padding: 10px;
                  }

                  th:nth-child(1) {
                     text-align: left;
                     padding-left: 20px;
                  }
               }
            }

            tbody {
               background-color: #131F30;
               border: solid 1px #1F2A36;

               tr {
                  td {
                     padding-top: 10px;
                     padding-bottom: 10px;

                     img {
                        margin-right: 5px;
                     }
                  }

                  td:nth-child(1) {
                     text-align: left;
                     padding-left: 20px;
                  }
               }

               tr:nth-child(odd) {
                  // background-color: #131F30;
                  background-color: #0d1829;
               }

               tr.ActiveRow {
                  background-image: linear-gradient(to bottom, #FAFF01 0%, #39FF96 100%);

                  td *, td {
                     color: #000;
                     font-weight: bold;
                  }
               }
            }
         }

         .ProfileThumb {
            width: 36px;
            height: 36px;
            border-radius: 50%;
         }
      }
   }

   .FlexDataTable {
      width: calc(100% - 24px);
      padding-left: 0;
      padding-right: 0;
      margin-left: 12px !important;
      margin-right: 12px !important;
      border: solid 1px #1F2A36;

      .FlexTableHeader {
         display: flex;
         gap: 10px;
         flex-flow: row wrap;
         background-color: #131F30;
         border: solid 1px #1F2A36;

         .FlexTableTh {
            // border: solid 1px #ccc;
            padding: 8px 10px;
            text-align: left;
            font-weight: bold;
         }

         .FlexTableTh:nth-child(1) {
            flex-shrink: 0;
            flex-grow: 2;
            min-width: 231px;
            // background-color: gray;
         }

         .FlexTableTh:nth-child(2),
         .FlexTableTh:nth-child(3) {
            min-width: 81px;
         }

         .FlexTableTh:nth-child(4) {
            min-width: 66px;
         }
      }

      .FlexTableBody {

         .FlexTableBodyRow {
            display: flex;
            gap: 10px;
            flex-flow: row wrap;
            padding-top: 4px;
            padding-bottom: 4px;
            margin-bottom: 2px;

            .FlexTableTD {
               // border: solid 1px #ccc;
               padding: 4px 10px;
               text-align: left;
               display: flex;
               font-size: 14px;
               gap: 5px;
               align-self: center;

               * {
                  align-self: center;
               }
            }

            .FlexTableTD:nth-child(1) {
               flex-shrink: 0;
               flex-grow: 1;
               min-width: 230px;
               // background-color: gray;
               .Number {
                  width: 30px;

               }

               .Thumb {
                  width: 40px;
                  justify-content: center;

                  .ProfileThumb {
                     width: 36px;
                     height: 36px;
                     border-radius: 50%;
                  }
               }

               .Name {
                  width: 170px;
                  white-space: nowrap;
                  overflow: hidden;
                  text-overflow: ellipsis;
               }
            }

            .FlexTableTD:nth-child(2),
            .FlexTableTD:nth-child(3) {
               min-width: 80px;

               * {
                  align-self: center;
               }
            }

            .FlexTableTD:nth-child(4) {
               min-width: 65px;
            }
         }

         .FlexTableBodyRow:nth-child(even) {
            background-color: #0d1829;
         }

         .FlexTableBodyRow.ActiveRow {
            background-image: linear-gradient(to bottom, #FAFF01 0%, #39FF96 100%);

            * {
               color: #000;
               font-weight: bold;
               font-family: inherit;
               font-size: 16px;
               // font-weight: bold;
            }

            .FlexTableTD:nth-child(3), .FlexTableTD:nth-child(4) {
               padding: 4px 16px;
            }

            .FlexTableTD:nth-child(5) {
               span {
                  display: block;
                  width: 100%;
                  text-align: center;
               }
            }
         }
      }
   }
}

.SearchWrapper {
   .MatchOrDetailsWrapper {
      display: grid;
      grid-template-columns:250px 75px 88px 126px 126px;
      gap: 10px;
      float: right;

      .SearchFieldWrapper {
         input {
            width: 100%;
            font-family: 'ObjectSans-Regular';
            background: #111E33;
            border: none;
            padding: 15px;
            border-radius: 6px;

            ::placeholder {
               color: #95A6B7;
               opacity: 1;
            }

            ::-ms-input-placeholder {
               color: #95A6B7;
            }
         }
      }

   }
}


@media only screen and (min-width: 1200px) {
   .MatchDetails {
      padding-right: 24px !important;
   }
}

@media only screen and (max-width: 1200px) {
   .LeagueTournamentPage {
      // margin-left: 10px;
      // margin-right: 10px;
   }
   .MatchWrapper {
      .MatchDetails {
         padding-right: 0px !important;
      }
   }
   .WinPrizesWrapper {
      display: flex;
      justify-content: center;
   }
   .LeagueTournamentPage {
      h1 {
         font-size: 38px;
         width: 650px;
      }
   }
   .MainContnetWrapper {
      padding-right: 0 !important
   }
}

@media only screen and (max-width: 1024px) {
   .FilterWrapper {
      display: grid;
      grid-template-columns: 1fr;

      > div {
         width: 100%;
         display: block;
         padding: 0;
         margin-bottom: 10px;

         .MatchOrDetailsWrapper {
            float: right;
            grid-template-columns: 127px 126px;
         }

      }

      .SearchWrapper > .MatchOrDetailsWrapper {
         grid-template-columns: 250px 75px 88px 126px 126px !important;
      }
   }
   .LeagueTournamentPage {
      h1 {
         font-size: 28px;
         width: calc(100% - 335px)
      }
   }
}

.CurrentStatusOfGame {
   .TournamentView {
      > div {
         display: grid;
         grid-template-columns: 232px 232px;
         gap: 10px;
         width: fit-content;
         margin: 0 auto;

         .PlayerContainerWrapper {
            margin: 0 !important;
            width: 100%;

            h4 {
               text-transform: uppercase;
               font-size: 18px;
               font-style: italic;
               display: -webkit-box;
               -webkit-box-orient: vertical;
               -webkit-line-clamp: 2;
               overflow: hidden;
               text-overflow: ellipsis;
               min-height: 42px;
            }

            .LogoContainer {
               width: 98px;
               height: 98px;
               background-color: #000;
               border-radius: 50%;
               margin: 0 auto;

               img {
                  width: 100%;
                  padding: 10px;
               }
            }

            .PointsWrapper {
               margin-bottom: 15px;
               margin-top: 15px;

               img {
                  margin: 2px;
               }
            }
         }
      }
   }
}

@media only screen and (max-width: 992px) {
   .LeagueTournamentPage {
      .MatchOrDetailsWrapper {
         margin-top: 65px;
      }
   }
}

@media only screen and (max-width: 836px) {
   .LeagueTournamentPage {
      .MatchOrDetailsWrapper {
         margin-top: 54px;
      }
   }
}

@media only screen and (min-width: 768px) {
   .FlexDataTable {
      .FlexTableBodyRow {
         .FlexTableTD {
            > label {
               display: none;
            }
         }
      }
   }
}

@media only screen and (max-width: 768px) {
   .LeagueTournamentPage {
      .PlayerContainerWrapper {
         margin: 16px 10px !important;
      }

      .MatchDetails {
         margin: 0 auto;
         width: fit-content;
         padding: 0 !important;
         margin-left: 10px;
         margin-right: 10px;
      }
   }
   .WinPrizesWrapper {
      margin: 0 auto;
      margin-top: 30px;
      width: fit-content;
      // padding: 0 !important;
      margin-left: 10px;
      margin-right: 10px;
   }
   .tournamentContainer {
      margin: 30px 10px 10px 10px !important;

      .tournamentBrackets {
         margin: 16px 10px !important;
      }
   }
   .PlayerContainer {
      overflow-x: auto;
      width: 600px;
   }
   .WinPrizesWrapper {
      width: calc(100% - 24px);

      .PlayerContainer {
         width: 100%;
      }
   }
   .MatchOrDetailsWrapper .radioButtonlbl label {
      font-size: 14px !important;
   }
   .RadioBtnWrapper {
      .radioButtonlbl {
         label {
            font-size: 14px !important;
         }
      }
   }
   .FilterWrapper {
      .MatchOrDetailsWrapper {
         width: fit-content;
         margin: 0 auto;
         clear: both;
         float: none !important;
      }

      .SearchWrapper {
         width: fit-content;
         margin: auto;

         .MatchOrDetailsWrapper {
            display: grid;
            grid-template-columns: repeat(4, 1fr) !important;

            > div:nth-child(1) {
               grid-column: 1 / -1 !important;
            }

            // > div:nth-child(3), div:nth-child(4),div:nth-child(5){
            //     width: 25%;
            // }
         }
      }
   }
   .WinPrizesWrapper {
      display: block;
   }
   .LeagueTournamentPage {
      margin-top: -35px;

      h1 {
         line-height: normal;
         margin-top: 0;
         margin-bottom: 10px;
         width: 95%;
      }

      .MatchOrDetailsWrapper {
         margin-top: 63px;
      }

      .NowPlayingBlock, .UpcomingBlock {
         scale: .7;
         right: -79px !important;
         top: 16px !important;
      }
   }

   .FlexDataTable {
      .FlexTableHeader {
         .FlexTableTh:nth-child(2),
         .FlexTableTh:nth-child(3),
         .FlexTableTh:nth-child(4) {
            visibility: hidden;
            display: none;
         }
      }

      .FlexTableBody {
         .FlexTableBodyRow {
            .FlexTableTD:nth-child(1) {
               .Name {
                  width: 150px;
                  white-space: nowrap;
                  overflow: hidden;
                  text-overflow: ellipsis;
               }
            }
         }
      }

      .FlexTableBodyRow {
         .FlexTableTD {
            > label {
               display: block;
               font-style: italic;
            }
         }

         .FlexTableTD:nth-child(2n), .FlexTableTD:nth-child(3n), .FlexTableTD:nth-child(4n) {
            display: flex;
            flex-wrap: wrap;
            justify-content: flex-start;

            > label {
               width: 100%;
            }

            > img {
               width: 24px;
               height: 24px;
            }

            > span {
               width: calc(100% - 30px);
            }
         }
      }

   }


}

@media only screen and (max-width: 576px) {
   .MatchOrDetailsWrapper {
      .RadioBtnWrapper {
         input {
            scale: 1.5;
            margin-top: 9px !important;
            margin-left: 8px;
            display: block;
            clear: both;
            float: none;
            margin: 0 auto;
         }

      }
   }
   .LeagueTournamentPage h1 {
      font-size: 28px;
      line-height: normal;
      margin-bottom: 10px !important;
   }
   .LeagueTournamentPage h2 {
      font-size: 20px;
   }
   .CurrentStatusOfGame {
      .TournamentView {
         > div {
            width: 90%;
            display: grid;
            grid-template-columns: 1fr;
            gap: 20px;

            .PlayerContainerWrapper {
               margin: 0 !important;
               width: 100%;

               h4 {
                  text-transform: uppercase;
                  font-size: 18px;
                  font-style: italic;
               }

               .LogoContainer {
                  width: 98px;
                  height: 98px;
                  background-color: #000;
                  border-radius: 50%;
                  margin: 0 auto;

                  img {
                     width: 100%;
                     padding: 10px;
                  }
               }

               .PointsWrapper {
                  margin-bottom: 15px;
                  margin-top: 15px;

                  img {
                     margin: 2px;
                  }
               }
            }
         }
      }
   }
   .LeagueTournamentPage {
      margin-top: -35px;

      h1 {
         line-height: normal;
         margin-top: 0;
         margin-bottom: 48px;
      }

      .MatchOrDetailsWrapper {
         margin-top: 63px;
      }

      .NowPlayingBlock {
         scale: .65;
         right: -94px !important;
         top: 6px !important;
      }
   }
   .LimitedTournamentHeader {
      background-position: -219px top !important;
   }

}

.CompletedMatchInfoBox {
   background-image: url('../../../assets/images/Now_playing_block.webp');
   background-repeat: no-repeat;
   width: 526px;
   height: 300px;
   position: absolute !important;
   right: -26px;
   z-index: 1;
   top: -40px;

   .completedTag {
      width: fit-content;
      font-size: 12px;
      background: linear-gradient(92.07deg, #3421AA 2.01%, #0F7A89 100.44%) padding-box, linear-gradient(to right, #0F7A89, #3421AA) border-box;
      align-items: center;
      border-radius: 2px;
      transform: skew(-5deg, 0deg);
      height: 24px;
      padding: 10px;
      padding-left: 10px;
      padding-right: 11px;
      display: flex;
      text-transform: uppercase;
      font-family: 'ObjectSans-Regular';
      letter-spacing: 0.05em;
   }

   .ResultInfoWrapper {
      display: flex;
      gap: 5px;
   }

}

//common
.botCardWrapper {
   width: 100%;
   //padding: 20px;
   background: none;

   .botCardBody {
      width: 100%;
      border-radius: 12px 12px 12px 2px;
      background: rgba(17, 30, 51, 1);
      max-width: 313px;
      padding: 0px !important;
      float: left;
      margin-left: -4px;

      h6 {
         border: none;
      }
   }

   > div {
      padding-bottom: 4px;
   }
}

//Bot Card 1
.ballBody {
   position: relative;
   width: 100%;
   // display: flex;
   display: grid;
   grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr;
   gap: 6px;
   margin-top: 8px;

   .ballWrapper {
      width: 34px;
      height: 34px;
      background: url("../../assets/images/card1Ball.webp");
      background-size: 34px 34px;
      border: solid 1px #dfff15;
      border-radius: 50%;
      // gap: 4px;
      // padding: 7px;
      float: left;
      position: relative;

      span {

         font-family: 'Khand';
         box-shadow: 0px 0px 4px 2px rgba(134, 134, 134, 0.5) inset;
         background: #fff;
         width: 26px;
         display: block;
         height: 26px;
         border-radius: 90px;
         justify-content: center;
         font-size: 16px;
         line-height: normal;
         text-align: center;
         margin: 0 auto;
         margin-top: 3px;
         padding-top: 3px;
      }

      .muted {
         background: linear-gradient(83.19deg, rgb(57 45 252 / 19%) 19.41%, rgba(255, 122, 0, 0.3) 152.48%), linear-gradient(54.21deg, #ff7a0024 -4.13%, #392dfc94 101.35%);
         border: 0.5px solid #7f3c3c;
         width: 18px;
         height: 18px;
         border-radius: 90px;
         position: absolute;
         right: 0;
         bottom: -7px;
         color: #fff;
         font-weight: 700;
         font-size: 10px;
         display: flex;
         align-items: center;
         justify-content: center;
         font-family: "Montserrat";
      }

      span.active {
         box-shadow: 0px 0px 4px 2px rgba(134, 134, 134, 0.5) inset, 0px 0px 10px 0px rgb(23, 234, 133);
      }
   }

   .ballGlow {
      background-color: rgb(255 0 0 / 60%);
      width: 33px;
      height: 33px;
      position: absolute;
      top: -1px;
   }
}

.ballSecondBody {
   width: 100%;
   display: flex;
   margin-top: 16px;

   display: grid;
   grid-template-columns: 1fr 1fr 1fr 1fr;
   gap: 10px;
   margin-bottom: 16px;

   .scoreWrapper {
      width: 44px;
      height: 32px;
      border-radius: 50px;
      border: rgba(23, 234, 133, 1) 2px solid;
      display: flex;
      justify-content: center;
      margin-right: 6px;
   }

   .ptstxtlabel {
      color: #fff !important;
      animation: scalePoints 3s ease-in-out infinite;
   }

   > span {
      > label:nth-child(1) {
         border: unset;
         color: #fff !important;
      }

      > label:nth-child(2) {
         font-size: 18px;
         width: 32px;
         border: unset;
         color: #fff;
         color: rgba(23, 234, 133, 1);
         margin-top: 4px;
      }

   }

   .boardWrapper {
      span {
         display: block !important;
         text-align: center;

         label:nth-child(1) {
            color: #fff !important;
         }
      }

      .borderBottomNion {
         margin-top: 15px !important;
      }
   }
}

.ballArrangementWrapper {
   // background-color: #2dfee63a;
   // margin-right: 4px;
}

.predictionWrapper {
   margin-left: 4.5px;
}

//bot Card 2
.bigBallWrapper {
   width: 64px;
   height: 64px;

   margin-right: 6px;
   padding: 12px;
   display: flex;
   justify-content: center;
   margin-left: -10px;

   span {
      box-shadow: 0px 0px 4px 2px rgba(134, 134, 134, 0.5) inset;
      background: #fff;
      width: 40px;
      display: flex;
      height: 40px;
      border-radius: 90px;
      justify-content: center;
      align-items: center;
      font-weight: 700;


   }

   .muted {
      background: linear-gradient(83.19deg, rgb(57 45 252 / 19%) 19.41%, rgba(255, 122, 0, 0.3) 152.48%), linear-gradient(54.21deg, #ff7a0024 -4.13%, #392dfc94 101.35%);
      border: 0.5px solid #7f3c3c;
      width: 18px;
      height: 18px;
      border-radius: 90px;
      position: absolute;
      right: 0;
      bottom: -7px;
      color: #fff;
      font-weight: 700;
      font-size: 10px;
      display: flex;
      align-items: center;
      justify-content: center;
      font-family: "Montserrat";
   }
}

.smallBall {
   width: 40px;
   height: 40px;
   padding: 6px;
   background-size: 100% !important;

   span {
      width: 28px;
      height: 28px;
      line-height: 20px;
      font-family: "Khand";
      font-size: 18px;
      font-weight: 700;
   }
}

.bigBallGreen {
   background: url("../../assets/images/bigBall.webp");
}

.bigBallRed {
   background: url("../../assets/images/bigBall2.webp");
}

.ptstxtlabel {
   padding-right: 42px;
   // background: url("../../assets/images/reflection.png");
   // background-position: -8px 0px;
   background-size: 111%;
   background-repeat: no-repeat;
   border: unset;
   font-family: 'ObjectSans-Heavy';
   text-shadow: rgba(23, 234, 133, 1) 1px 0 10px;

   .pts {
      margin-bottom: -10px;
      font-size: 28px;
      margin-left: 5px;

   }
}

label.mnstxtlabel {
   font-family: 'ObjectSans-Heavy';
   text-shadow: rgb(121 245 187) 2px 0 14px;
   color: rgb(255, 255, 255) !important;


   .pts {
      // margin-bottom: -10px;
      font-size: 28px;
      margin-left: 5px;
   }

   > div {
      animation: scalePoints 3s ease-in-out infinite;
   }

   @keyframes scalePoints {
      0% {
         transform: scale(0);
      }
      10% {
         transform: scale(1.8);
      }
      15% {
         transform: scale(1);
      }
      100% {
         transform: scale(1);
      }
   }
}


@-webkit-keyframes rotate-center {
   0% {
      -webkit-transform: rotate(0);
      transform: rotate(0);
   }
   100% {
      -webkit-transform: rotate(360deg);
      transform: rotate(360deg);
   }
}

@keyframes rotate-center {
   0% {
      -webkit-transform: rotate(0);
      transform: rotate(0);
   }
   100% {
      -webkit-transform: rotate(360deg);
      transform: rotate(360deg);
   }
}


.wideBall {
   background-color: red;
}

.wicketBall {
   background-color: green
}

.glowBack {
   background: url("../../assets/images/reflection.webp");
   background-position: 17px 24px;
   background-repeat: no-repeat;


   animation: backGlowAni 4s infinite;

   width: 100%;
   height: 100px;
   padding-top: 10px;
   padding-bottom: 10px;

   //   display: grid;
   //   grid-template-columns: 70px 1fr;
}

@keyframes backGlowAni {
   0% {
      background-position: 17px 24px;
   }
   80% {
      background-position: 104px 24px;
   }
   100% {
      background-position: 17px 24px;
   }
}


//bot card 3
.BallWrapper40 {
   width: 34px;
   height: 34px;
   // margin-right: 6px;
   // padding: 7px;
   display: flex;
   justify-content: center;
   background-size: 100%;
   position: relative;

   span {
      // box-shadow: 0px 0px 4px 2px rgba(134, 134, 134, 0.5) inset;
      // background: #fff;
      // width: 30px;
      // display: flex;
      // height: 20px;
      // border-radius: 90px;
      // justify-content: center;
      // align-items: center;
      // font-weight: 700;
      // font-size: 16px;
      font-family: 'Khand';
      text-transform: uppercase;
      box-shadow: 0px 0px 4px 2px rgba(134, 134, 134, 0.5) inset;
      background: #fff;
      width: 26px;
      /* height: 30px; */
      /* display: flex; */
      height: 26px;
      border-radius: 90px;
      justify-content: center;
      align-items: center;
      font-weight: 700;
      font-size: 16px;
      display: block;
      text-align: center;
      margin: 0 auto;
      margin-top: 4px;

   }

   .muted {
      background: linear-gradient(83.19deg, rgb(57 45 252 / 19%) 19.41%, rgba(255, 122, 0, 0.3) 152.48%), linear-gradient(54.21deg, #ff7a0024 -4.13%, #392dfc94 101.35%);
      border: 0.5px solid #7f3c3c;
      width: 18px;
      height: 18px;
      border-radius: 90px;
      position: absolute;
      right: 0;
      bottom: -7px;
      color: #fff;
      font-weight: 700;
      font-size: 10px;
      display: flex;
      align-items: center;
      justify-content: center;
      font-family: "Montserrat";
   }
}

.summarytxtlabel {
   padding-right: 0;
   background: url("../../assets/images/reflection.webp");
   background-position: -2px -4px;
   background-size: 97% 130%;
   background-repeat: no-repeat;
   padding-right: 0;
   border: 0px !important;
}

.borderBottomNion {
   background: radial-gradient(49.62% 14587.71% at 50.38% 50%, #2DFEE5 0%, rgba(45, 254, 229, 0) 100%) /* warning: gradient uses a rotation that is not supported by CSS and may not behave as expected */
;
   width: 100%;
   height: 1px;
   float: left;
   width: 100%;
}

@media (max-width: 1199px) {
   .botCardWrapper {
      width: fit-content;
      margin: 0 auto;
   }
}

@import '../../scss/globals/global';

.playerName {
   text-align: center;
   margin-top: 10px;
   text-transform: uppercase;
   font-family: 'OSHeavySlanted';
   margin-bottom: 5px;
   font-size: 16px;
   color: #ffffff;
}

.playerSummary {
   width: calc(100% - 25px);

   .Row2 {
      margin-top: 10px;
   }

   .playerNameHeader {
      display: grid;
      grid-template-columns: 1fr 220px 60px;
   }
}

.playerSummary .winsLabel img {
   width: 20px;
   margin-right: 5px;
   float: left;
   left: unset;
   position: relative;
}

.playerNameLbl {
   font-family: $bodyTextMainFont;
   font-size: 24px;
   line-height: 28px;
   letter-spacing: 0.02em;
   color: #FFFFFF;
   margin-bottom: 5px;
   font-weight: 700;
   text-transform: uppercase;
   font-style: italic;
   display: block;
   width: fit-content;

   > span {
      font-size: 16px;
      margin-left: 10px;
      margin-top: -3px;
      position: absolute;
      font-weight: normal;
   }

   > span.AgeLbl {
      font-size: 16px;
      text-transform: capitalize;
      margin-top: 0px;
   }

}

.progressBarWrapper {
   padding: 0 0 0 40px !important;
}

.mainHeadingInfoWrapper {
   // display: flex;
   // flex-wrap: wrap;
}

.picSection {
   width: calc(33.3% - 25px);
   margin-right: 25px;
   position: relative;

   .heartLike {
      right: 15px;
      top: 15px;
   }
}


.profilePicWrapper {
   width: 100%;
   overflow: hidden;
   margin-bottom: 25px;
   /*background: linear-gradient(131.65deg, #4035FE 0%, #100E3E 102.56%);*/
   /* white 6% stroke */
   border: 1px solid rgba(255, 255, 255, 0.06);
   border-radius: 6px;
}

.infoSection {
   display: flex;
   width: 66.6%;
}

.statValue label {
   font-family: 'SolinaExtraBoldItalic';
   font-size: 52px;
   line-height: 58px;
   letter-spacing: 0.02em;
   text-transform: uppercase;
   color: #FFFFFF;
   margin-bottom: 10px;
}


.teamOne, .teamTwo {
   display: flex;
   flex-wrap: wrap;
   justify-content: space-between;
}

.teamOne .teamName, .teamTwo .teamName {
   width: calc(70% - 10px);
   display: flex;
}

.teamOne .teamScore, .teamTwo .teamScore {
   width: calc(30% - 10px);
   display: flex;
}

.teamOne .teamScore, .teamTwo .teamScore {
   justify-content: right;
   flex-direction: row;
   align-items: center;
}

span.team01ScoreWrapper .winsLabel {
   text-align: left;
   color: #fff;
}

.scoreNow {
   background: linear-gradient(89.7deg, #17EA85 2.27%, #14D0E6 23.6%, #17EA85 44.41%, #14D0E6 64.17%, #17EA85 82.99%, #14D0E6 99.74%),
   linear-gradient(0deg, #95A6B7, #95A6B7);
   background-clip: text;
   -webkit-background-clip: text;
   -webkit-text-fill-color: transparent;
   font-family: 'ObjectSans-Regular';
   padding-right: 5px;
   font-style: italic;
   font-size: 16px;
   font-weight: bold;
}

.scoreNowWte {
   color: #FFFFFF;
   font-family: 'ObjectSans-Regular';
   font-size: 16px;
   font-weight: bold;
   font-style: italic;
   padding-right: 5px;
}

.oversNow {
   color: #95A6B7;
   font-family: 'ObjectSans-Regular';
   font-style: italic;
   font-size: 16px;
   font-weight: bold;
}

.loaderContainer {
   margin: 0 auto;
   transform: translateY(400%);
   text-align: center;
}

.puffWrapper {
   position: absolute;
   top: 30px;
   right: 20px;
}

.ResponceCont {
   max-width: 1240px;
   margin-left: auto;
   margin-right: auto;
}

.playerWrapper .TeamsteamLogo {
   padding: 20px;
}

.OverviewBox {
   width: 100%;
   @include overview-box;
   border: 1px solid #FFFFFF0F;
   padding: 10px;
   position: relative;
}

.OverviewBoxT {
   width: 100%;
   @include overview-boxT;
   border: 1px solid #FFFFFF0F;
   padding: 25px;
   position: relative;
   // height: 440px;

   .Title {
      font-family: 'ObjectSans-Regular';
      font-size: 24px;
      line-height: 28px;
      color: #FFFFFF;
      font-style: italic;
      font-weight: 700;
      text-transform: uppercase;
   }

   .content {
      font-family: 'ObjectSans-Regular';
      font-size: 16px;
      line-height: 20px;
      color: #95A6B7;
   }

   .content2 {
      font-family: 'ObjectSans-Regular';
      font-size: 14px;
      line-height: 18px;
      color: #95A6B7;

      span {
         font-family: 'ObjectSans-Regular';
         font-size: 16px;
         line-height: 28px;
         color: #fff;
         font-weight: 700;
         font-style: italic;
         text-transform: uppercase;
      }
   }
}

.PlayerProfilePage {
   .playerSummary {
      h3.playerNameLbl {
         border-image: linear-gradient(90deg, #f3dd1b00, #2dfee5, #f3dd1b00) 0 0 1 0;
         border-bottom: 1px solid;
         padding-bottom: 6px;
         text-align: center;
         width: fit-content;
         margin: 0 auto;
      }

      p {
         font-size: 16px;
         line-height: 20px;
         margin-top: 10px;
      }
   }

   .LeftColInfoWrapper {
      // width: 100%;
      .indicatorWrap {
         display: block;
         overflow: hidden;
         background-image: linear-gradient(0deg, rgba(26, 38, 57, 0.5019607843), rgba(11, 28, 53, 0.5019607843));
         border-left: 1px solid rgba(255, 255, 255, 0.0588235294);
         border-right: 1px solid rgba(255, 255, 255, 0.0588235294);
         border-bottom: 1px solid rgba(255, 255, 255, 0.0588235294);
      }

      h3 {
         // margin-top: 20px;
         font-size: 18px;
         text-transform: uppercase;
         background: linear-gradient(89.7deg, #17EA85 2.27%, #14D0E6 23.6%, #17EA85 44.41%, #14D0E6 64.17%, #17EA85 82.99%, #14D0E6 99.74%);
         -webkit-background-clip: text;
         -webkit-text-fill-color: transparent;
         background-clip: text;
         text-fill-color: transparent;
         text-shadow: 0px 0px 8px rgba(20, 208, 229, 0.5607843137);
      }

      h4 {
         font-size: 16px;
         text-transform: uppercase;
         font-style: italic;
         color: #95A6B7;
      }

      p {
         font-size: 14px;
      }
   }

   .StatIcon {
      position: absolute;
      right: 10px;
      top: 28px;
      width: 30px;
      height: 30px;
   }

   .StatisticsCards {
      > div {
         margin-bottom: 15px;
      }
   }

   .LastMatchOutcomes {
      .OverviewBox {
         padding: 30px;

         div {
            width: fit-content;
            margin: 0 auto;
         }

         img {
            width: 30px;
            height: 30px;
            margin: 2px 4px;
         }
      }
   }
}

@media (max-width: 902px) {
   .PlayerProfilePage {
      .playerSummary {
         .playerNameHeader {
            width: calc(100% - 55px);
            grid-template-columns: 1fr 200px;

            h2.playerNameLbl {
               font-size: 21px;

               > span {
                  position: relative;
               }
            }

            .progressBarWrapper {
               padding-left: 30px !important;
               padding-right: 10px !important;
            }
         }
      }
   }
}

@media (min-width: 769px) {
   .PlayerProfilePage {
      .LeftColInfoWrapper {
         display: block;
         order: 1;
      }

      .rightColInfoWrapper {
         order: 2;
      }

      // .LeftColInfoWrapperInMobile{
      //     display: none;
      // }
   }
}

@media (max-width: 768px) {
   .OverviewBoxT {
      padding: 10px;
      height: auto;

      .Title {
         font-size: 20px;
         line-height: 22px;
      }

      .content {
         font-size: 14px;
         line-height: 16px;
      }
   }
   .scoreNowWte {
      font-size: 12px;
   }
   .LeftColInfoWrapper {
      order: 2;
   }
   .rightColInfoWrapper {
      order: 1;
   }
   // .LeftColInfoWrapperInMobile{
   //     display: block;
   //     margin-top: 35px;
   //     > div{
   //         display: block;
   //         overflow: hidden;
   //     }
   // }
}

.roleLbl {
   font-size: 14px;
   line-height: 18px;
   font-family: $bodyTextMainFont;
   color: #95A6B7;
   font-weight: 400;
}

.roleVal {
   font-size: 16px;
   line-height: 20px;
   letter-spacing: -0.01em;
   color: #FFFFFF;
   font-weight: 700;
   font-family: $bodyTextMainFont;
   font-style: italic;
   text-transform: uppercase;
   width: 100%;
}

@media (max-width: 768px) {
   .roleLbl {
      font-size: 12px;
      line-height: 16px;
   }
   .roleVal {
      font-size: 12px;
      line-height: 16px;
   }
}

.borderTop1 {
   border-top: 1px solid;
   border-image: linear-gradient(to right, #f3dd1b00, #F3DE1B, #f3dd1b00) 1 0 0 0;
}

.borderTop2 {
   border-top: 1px solid;
   border-image: linear-gradient(to right, #f3dd1b00, #2DFEE5, #f3dd1b00) 1 0 0 0;
}

.borderTop3 {
   border-top: 1px solid;
   border-image: linear-gradient(to right, #f3dd1b00, #C668FF, #f3dd1b00) 1 0 0 0;
}

.OverviewBox {
   .mainText {
      font-size: 52px;
      font-family: 'SolinaExBI';
      color: #ffffff;
      line-height: 58px;
   }

   .smallText {
      font-size: 18px;
      color: #95A6B7;
      font-family: 'ObjectSans-Regular';
      margin-left: 0px;
      line-height: 24px;
   }

   .dateText {
      font-size: 12px;
      line-height: 16px;
      color: #95A6B7;
      font-family: 'ObjectSans-Regular';
   }

   .TitleText {
      font-size: 18px;
      line-height: 24px;
      color: #ffffff;
      font-family: 'ObjectSans-Regular';
      margin-top: 12px;
      margin-bottom: 8px;
   }

   .NewsText {
      font-size: 14px;
      line-height: 18px;
      color: #95A6B7;
      font-family: 'ObjectSans-Regular';
   }
}

.subLinkPurpe {
   background: linear-gradient(95.83deg, #445BCC -25.53%, #D52AB1 98.46%), linear-gradient(90.37deg, #D52AB1 29.3%, #445BCC 161.3%);
   border: 1px solid;
   border-image-source: linear-gradient(95.83deg, #445BCC -25.53%, #D52AB1 98.46%);
   box-shadow: 0px 1px 8px 0px rgba(213, 42, 177, 1);
   font-family: 'OSHeavySlanted';
   font-size: 12px;
   transform: skew(-10deg, 0deg);
   bottom: 0px;
   padding: 0 10px;
   line-height: 17px;
   color: rgb(255, 255, 255) !important;
   border-radius: 2px;
   text-transform: uppercase;
   display: inline-block;
}

@media (max-width: 768px) {
   .OverviewBox {
      .mainText {
         font-size: 32px;
         font-family: 'SolinaExBI';
         color: #ffffff;
         line-height: 38px;
      }

      .smallText {
         font-size: 14px;
         color: #95A6B7;
         font-family: 'ObjectSans-Regular';
         margin-left: 0px;
         line-height: 18px;
      }
   }
   .profilePicWrapper {
      height: 300px;
   }
   .scoreNow {
      font-size: 12px;
      line-height: 16px;
      font-family: 'ObjectSans-Heavy';
   }
   .oversNow {
      font-size: 12px;
      line-height: 16px;
      font-family: 'OSHeavySlanted';
   }
}

@media (max-width: 576px) {
   .profilePicWrapper {
      height: auto;
   }
   .latestRunsWrapper {
      .teamName {
         a > span {
            font-size: 18px;
         }
      }

      .scoreNowWte {
         font-size: 14px;
      }
   }
   .teamScore, .scoreNow {
      font-size: 14px !important;
   }
   .playerNameHeader {
      display: grid;
      grid-template-columns: 1fr !important;

      > div:nth-child(2) {
         margin-top: 20px;

         .progressBarWrapper {
            padding: 0 !important;
         }
      }
   }
}

.puffWrapper {
   position: absolute;
   top: 30px;
   right: 20px;
}

.loaderContainer {
   margin: 0 auto;
   transform: translateY(400%);
   text-align: center;
}

.ResponceCont {
   max-width: 1240px;
   margin: {
      left: auto;
      right: auto;
   }
}

.subLinkPurpe {
   background: linear-gradient(95.83deg, #445BCC -25.53%, #D52AB1 98.46%), linear-gradient(90.37deg, #D52AB1 29.3%, #445BCC 161.3%);
   border: 1px solid;
   border-image-source: linear-gradient(95.83deg, #445BCC -25.53%, #D52AB1 98.46%);
   box-shadow: 0px 1px 8px 0px rgba(213, 42, 177, 1);
   font-family: 'ObjSans-HeavySlanted';
   font-size: 10px;
   transform: skew(-10deg, 0deg);
   bottom: 0px;
   padding: 0 10px;
   line-height: 16px;
   color: rgb(255, 255, 255) !important;
   border-radius: 2px;
   text-transform: uppercase;
   display: inline-block;
}

.mainHeaderH1 {
   font: {
      family: 'SolinaExtraBoldItalic';
      size: 115px;
   }
   line-height: 125px;
   letter-spacing: 0.02em;
   text: {
      transform: uppercase;
      fill-color: transparent;
      shadow: 0px 0px 8px rgba(20, 208, 229, 0.56);

   }
   background: linear-gradient(89.7deg, #17EA85 2.27%, #14D0E6 23.6%, #17EA85 44.41%, #14D0E6 64.17%, #17EA85 82.99%, #14D0E6 99.74%);
   -webkit: {
      background-clip: text;
      text-fill-color: transparent;
   }
   background-clip: text;
   margin-top: 50px;
}

.subMainHeaderH1 {
   font: {
      weight: 800;
      size: 52px;
   }
   line-height: 58px;
   letter-spacing: 0.02em;
}

@media only screen and (max-width: 768px) {
   .mainHeaderH1 {
      font-size: 26px;
      line-height: 30px;
   }

   .loaderContainer {
      display: contents;
   }

   .Footer {
      position: fixed;
      bottom: 0;
   }
}

@import "../../scss/globals/global";
@import "../../scss/utilities/mixins";


@mixin FantacyCard {

   position: relative;
   background: linear-gradient(0deg, #224755, #068F7A, #224755);
   border-radius: 8px;

   width: 160px;
   max-width: 160px;
   padding: 2px;
   .CardBackground {
      background-color: #0A1627;
      border-radius: 8px;
      padding: 10px;
      position: relative;

      > div {
         justify-content: center;
         position: relative;
      }
   }
   .ProfilePic {
      width: 80px;
      height: 80px;
      border-radius: 50%;
      background-size: cover;
      background-repeat: no-repeat;
      background-color: #010E14;
      border: solid 1px rgba(204, 204, 204, 0.3607843137);
   }
   .TrashIcon {
      position: absolute;
      top: 5px;
      right: 20px;
   }
   .MoreInfoIconBtn {
      position: absolute;
      right: 3px;
      top: -7px;
      width: 20px;
      height: 20px;
   }
   .MoreInfoIconBtn:hover {
      opacity: .8;
   }
   .HistoryInfoIconBtn {
      position: absolute;
      left: -13px;
      top: -7px;
      width: 20px;
      height: 20px;
   }
   .HistoryInfoIconBtn:hover {
      opacity: .8;
   }
   .BtnRow {
      display: flex;
      justify-content: center;

      button {
         font-family: 'ObjectSans-Regular';
         border: solid 1px #17EA85;
         background-color: #152D39;
         color: #17EA85;
         border-radius: 4px;
         margin-bottom: 4px;
         font-size: 14px;
      }
   }

   .TitleRow {
      justify-content: center;
      display: flex;

      span {
         color: #17EA85;
         font-size: 14px;
         text-align: center;
         margin-top: 2px;
         padding: 0;
         width: fit-content;
      }

      h4 {
         font-family: 'ObjectSans-Regular';
         font-size: 12px;
         line-height: 20px;
         font-weight: 700;
         font-style: italic;
         text-transform: uppercase;
         text-align: center;
         padding: 0;
         margin: 0;
         margin-top: 5px;

         max-width: 108px;
         white-space: nowrap;
         overflow: hidden;
         text-overflow: ellipsis;
      }
   }
}

.TournamentFantasySection {
   .NoDataWrapper {
      padding: 40px 20px;
      gap: 10px;
      display: flex;
      justify-content: center;

      h3 {
         font-size: 24px;
         text-transform: uppercase;
         font-style: italic;
      }

      button {
         margin-top: 40px;
         min-width: fit-content;
      }
   }

   .FantacyTeamContainer {

      .SelectedPlayersCol {
         border: solid 1px #355A69;
         border-radius: 4px;
         padding-top: 18px;
         background-color: #010B14;

         .CreateTeamName {
            max-width: 480px;
            margin: 0 auto;
            position: relative;
            transform: skew(-10deg, 0deg);
            border-radius: 4px;
         }

         .EditTeamBtn {
            float: right;
            padding: 0;
            background: linear-gradient(90deg, #F9FF01, #2FFF9E);
            padding: 2px;
            border-radius: 5px;
            transform: skew(-10deg, 0deg);
            margin: 2px;

            > div {
               background-color: #0E2029;
               border-radius: 4px;
               color: #fff;
               text-transform: uppercase;
               font-size: 16px;
               font-weight: 600;
               padding: 10px 18px;
            }
         }

         .CaptainRow {
            margin-top: 40px;
            display: flex;
            gap: 16px;
            justify-content: center;

            .Card {
               @include FantacyCard;
               height: 200px;

               h4 {
                  margin-bottom: 2px
               }
            }

            .CardBackground {
               height: 196px;

               > div {
                  padding-top: 20px;
               }

               .Circle {
                  right: 7px;
                  top: 58px;
               }
            }
         }

         .OtherPlayers {
            .Card {
               @include FantacyCard;
               height: 170px;

               h4 {
                  margin-bottom: 4px;
               }

               .CardBackground {
                  height: 165px;
                  // > div{
                  //     padding-top: 15px;
                  // }
                  .TitleRow {
                     padding-top: 12px;
                  }
               }
            }

            .Wrapper {
               margin-top: 16px;
               display: flex;
               gap: 16px;
               flex-flow: row wrap;
               justify-content: center;
            }
         }

         .TeamPlayerCounter {
            width: fit-content;
            display: flex;
            margin: 0 auto;
            margin-top: 30px;
            margin-bottom: 30px;
            gap: 7px;
            background: linear-gradient(45deg, #262E35, #1e2429);

            > div {
               width: 26px;
               height: 16px;
               border-radius: 2px;
               border: solid 1px #326668;
               transform: skew(12deg, 0deg);
            }
         }

         .PlayerPointsRow {
            padding-top: 5px !important;

            .PlayerPoints {
               min-width: 58px;
               max-width: 58px;
            }
         }
      }

   }


}

.NoFantasyDataWrapper {
   display: block;
   margin: 0 auto;
   background-color: #131F30;
   margin: 12px;
   padding: 15px;
   border-radius: 8px;
   border: solid 1px #1F2A36;

   p {
      font-size: 13px;
      margin-bottom: 0;
      line-height: normal;
   }

   > div {
      display: flex;

      > div {
         width: 50%;
         padding: 0;
      }
   }
}

.SeeFantasyPointsCalculation {
   text-align: center;
   color: #17EA85 !important;
   text-decoration: underline;
   text-underline-offset: 5px;
   margin-bottom: 0 !important;
}

.SeeFantasyPointsCalculation:hover {
   opacity: .8;
   cursor: pointer;
}

.NoFantasyDataButtonWrapper {
   display: block;
   margin: 0 auto;
   margin: 8px;
   padding: 18px;
   border-radius: 8px;
   margin-top: 23px;

   > button {
      display: block;
      padding: 10px 13px;
      font-size: 14px;
      margin: 0 auto;
      width: 100%;
   }
}

.PreviousFantasyTeams {
   background: linear-gradient(90deg, #F9FF01, #2FFF9E);
   padding: 1px 2px;
   border-radius: 2px;
   transform: skew(-10deg, 0deg);
   margin: 2px;
   display: block;
   width: fit-content;
   margin-bottom: 10px;
   margin-top: -10px;
   float: right;

   button {
      background-color: #0E2029;
      border-radius: 4px;
      color: #fff;
      text-transform: uppercase;
      font-size: 14px;
      font-weight: 600;
      padding: 10px 18px;
      border: 0;
   }
}

.ActiveStatusTagWrapper {
   margin-top: 10px;
}

@media (min-width: 1200px) {
   .TournamentFantasySection {
      padding-right: 24px !important;
   }
}


@media (min-width: 438px) {
   .TotalEarningRow {
      .TotalEarningWrapper {
         .TotalEarning {
            .Title {
               span:nth-child(1) {
                  display: block;
               }

               span:nth-child(2) {
                  display: none;
               }
            }
         }
      }
   }

}

@media (max-width: 438px) {
   .TotalEarningRow {
      .TotalEarningWrapper {
         background-position: -22px 0;
         width: 302px;
         height: 100px;

         .TotalEarning {
            .Title {
               span:nth-child(1) {
                  display: none !important;
               }

               span:nth-child(2) {
                  display: block;
               }

            }
         }
      }
   }
   .CaptainRow, .OtherPlayers {
      .Card {
         scale: .9 !important;
      }
   }
   .OtherPlayers {
      .Wrapper {
         gap: 15px;
      }
   }
   .TournamentFantasySection .FantacyTeamContainer .SelectedPlayersCol .OtherPlayers {
      .Wrapper {
         gap: 15px;

         .Card {
            width: calc(90% / 2);
         }
      }
   }
   .TournamentFantasySectionRow1 {
      display: grid !important;
      grid-template-columns: 1fr 170px !important;
      gap: 5px;
      margin-bottom: 10px;
      margin-top: -24px;

      > div {
         padding: 0 !important;
         margin: 0 !important;
         clear: both;
         float: none;
         display: flex;
         justify-content: center;
         margin-top: 2px;
         margin-bottom: 2px;
      }

      h2 {
         margin-top: 32px;
         font-size: 18px !important;
      }

      .PreviousFantasyTeams button {

      }
   }
   .TournamentFantasySection .FantacyTeamContainer .SelectedPlayersCol .CaptainRow .Card .TitleRow h4 {
      max-width: 103px;
   }
}



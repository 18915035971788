@import '../../scss/globals/global';

.scoreBoard {
   border: 1px solid #1F2A36;
   box-shadow: 10px 22px 47px rgba(0, 0, 0, 0.13);
   backdrop-filter: blur(20px);
   border-radius: 2px;
   width: 100%;
   margin-bottom: 25px;
   background-image: url('../../assets/images/mgroup.webp'), linear-gradient(91.25deg, rgba(57, 45, 252, 0.5) 1.34%, rgba(255, 122, 0, 0.5) 100%);
   background-size: 100%;
}

.scoreBoardDark {
   border: 1px solid #1F2A36;
   box-shadow: 10px 22px 47px rgba(0, 0, 0, 0.13);
   backdrop-filter: blur(20px);
   border-radius: 2px;
   width: 100%;
   margin-bottom: 10px;
   background-image: url('../../assets/images/mgroup.webp'), linear-gradient(91.25deg, rgba(15, 15, 15, 0.5) 1.34%, rgba(37, 5, 52, 0.5) 100%);
   background-size: 100%;
   align-items: center;
   display: flex;
}

.MatchTournamentHeader {
   font-family: Montserrat;
   font-size: 32px;
   font-style: italic;
   font-weight: 800;
   line-height: 42px;
   letter-spacing: 0.02em;
   text-align: center;
   padding-top: 24px;
}

.questWrap {
   background: linear-gradient(180deg, rgba(26, 38, 57, 0.5) 0%, rgba(11, 28, 53, 0.5) 100%);
   border: 1px solid #1F2A36;
   box-shadow: 10px 22px 47px rgba(0, 0, 0, 0.13);
   backdrop-filter: blur(20px);
   border-radius: 2px;
   margin-bottom: 25px;

   .notificationWrapper {
      .contentWrapper {
         width: 460px !important;
      }
   }
}

.questWrap .header {
   border-bottom: 1px solid #ffffff28;
   padding: 20px;
}

.questWrap H2 {
   font-family: "OSRegular";
   font-weight: 400;
   font-size: 24px;
   line-height: 32px;
   letter-spacing: 0.02em;
   color: #FFFFFF;
   margin: 0;
}

.header H2 {
   font-family: 'Montserrat-Regular';
   font-weight: 400;
   font-size: 24px;
   line-height: 32px;
   letter-spacing: 0.02em;
   color: #FFFFFF;
   margin: 0;
}

.questWrap .content {
   padding: 20px 20px 0 20px;
   display: flex;
   flex-direction: column;
   width: 100%;
   background-color: #071625;
}

.questWrap table {
   background: linear-gradient(180deg, rgba(26, 38, 57, 0.5) 0%, rgba(11, 28, 53, 0.5) 100%) !important;
   border: 1px solid #1F2A36;
   box-shadow: 10px 22px 47px rgba(0, 0, 0, 0.13) !important;
   backdrop-filter: blur(20px) !important;
}

.questWrap table tr td {
   color: #ffffff;
}

.questWrap table tr td a {
   color: #ffffff;
}

.answerBtn {
   background: linear-gradient(180deg, rgba(26, 38, 57, 0.5) 0%, rgba(11, 28, 53, 0.5) 100%);
   border: 1px solid #1F2A36;
   box-shadow: 10px 22px 47px rgba(0, 0, 0, 0.13);
   backdrop-filter: blur(20px);
   border-radius: 2px 2px 0px 0px;
   padding: 10px;
   width: 100%;
   text-align: left;
}

.answerBtn img {
   width: 23px;
   margin-right: 5px;
}

.answerBtn label {
   color: #fff;
   font-size: 14px;
   line-height: 18px;
   letter-spacing: -0.01em;
}

.answerBtn svg {
   display: none;
}

.answerBtnActive, .answerBtn:hover {
   background: linear-gradient(95.34deg, #FAFF00 6.28%, #00FFC2 111.13%);
   border: 1px solid #1F2A36;
   box-shadow: 10px 22px 47px rgba(0, 0, 0, 0.13);
   backdrop-filter: blur(20px);
   border-radius: 2px 2px 0px 0px;
}

.answerBtnActive label, .answerBtn:hover label {
   color: #03161F;
   font-family: 'OSHeavy';
}

.answerBtnActive svg, .answerBtn:hover svg {
   float: right;
   position: relative;
   top: 3px;
   font-size: 18px;
   display: inline-block;
}

.answerActiveBtn {
   background: linear-gradient(180deg, rgba(65, 14, 51, 0.5) 0%, rgba(53, 11, 41, 0) 100%);
}

.questionWrap {
   margin-bottom: 25px;
   width: 100%;
   float: left;
   animation: fadeIn 3s;
}

@keyframes fadeIn {
   0% {
      opacity: 0;
   }
   100% {
      opacity: 1;
   }
}

.liveLbl {
   float: left;
   padding: 5px 10px;
   background: #DE4A4A;
   border-radius: 2px;
   margin-left: 10px;
}

.liveLbl .livedot {
   width: 4px;
   height: 4px;
   background: #fff;
   border-radius: 90px;
   display: flex;
   align-items: center;
   margin: 5px 5px 0 0;
   float: left;
}

.replayLbl {
   float: left;
   padding: 5px 10px;
   background: #20be97;
   border-radius: 2px;
   margin-left: 10px;
   top: 50%;
}

.replayLbl:before {
   font-family: FontAwesome;
   position: relative;
   color: #FFFFFF;
   margin-right: 5px;
   align-items: center;
   float: left;
   font-size: 14px;
   content: "\f01e";
}

.teamBall {
   background-image: url("../../assets/images/ball.svg");
   content: "";
   margin: 0 4px;
   width: 14px;
   height: 14px;
   display: inline-block;
   background-size: contain;
   background-repeat: no-repeat;
   margin-left: 8px;
}

.teamBat {
   background-image: url("../../assets/images/bat.svg");
   content: "";
   margin: 0 2px;
   width: 18px;
   height: 18px;
   display: inline-block;
   background-size: contain;
   background-repeat: no-repeat;
   margin-left: 5px;
}

.rightGradient {
   background: radial-gradient(69.65% 37.65% at 100% 50%, #04F0FF 0%, rgba(20, 208, 230, 0) 100%);
   mix-blend-mode: normal;
   opacity: 0.23;
   position: absolute;
   right: 0;
   width: 442px;
   height: 760px;
   top: 310px;
   z-index: -1;
}

.leftGradient {
   background: radial-gradient(60.65% 40.65% at 0% 16%, #04F0FF 0%, rgba(20, 208, 230, 0) 100%);
   mix-blend-mode: normal;
   opacity: 0.23;
   position: absolute;
   left: 0;
   width: 420px;
   height: 535px;
   top: 0px;
   z-index: -1;
}

.matchIdWrapper {
   display: inline-block;
}

.matchIdLbl {
   font-family: 'OSHeavySlanted';
   display: inline-block;
   font-size: 12px;
   padding-right: 10px;
}

.matchIdVal {
   font-family: 'OSSlanted';
   display: inline-block;
   font-size: 12px;
}

.matchIdVal a {
   background: #111E33;
   border: 1px solid rgba(255, 255, 255, 0.06);
   border-radius: 2px;
   padding: 5px 10px;
   line-height: 20px;
   text-transform: uppercase;
   display: inline-block;
   margin-left: 10px;
}

.matchIdVal a:hover {
   background: #203455;
}

.matchIdVal svg {
   font-size: 16px;
}

.ResponceCont {
   max-width: 1240px;
   margin-left: auto;
   margin-right: auto;
}

.loaderContainer {
   margin: 0 auto;
   transform: translateY(400%);
   text-align: center;
}

.scoreBoardDark text {
   font-size: 25px;
   text-align: center;
   line-height: 135px;
   margin-left: auto;
   margin-right: auto;
}

.NotitWrap {
   background: linear-gradient(180deg, rgba(26, 38, 57, 0.5) 0%, rgba(11, 28, 53, 0.5) 100%);
   border: 1px solid #1F2A36;
   box-shadow: 10px 22px 47px rgba(0, 0, 0, 0.13);
   backdrop-filter: blur(20px);
   border-radius: 2px;
   margin-bottom: 25px;
}

.NotitWrap .header {
   border-bottom: 1px solid #ffffff28;
   padding: 20px;
}

.NotifyUi {
   list-style-image: none;
   list-style-type: none;
   width: 100%;
   padding-left: 0px;
   margin-bottom: 0px;
   max-height: 550px;
   overflow-y: auto;
}

.NotifyUi img {
   padding-right: 5px;
   float: left;
   width: 30px;
}

.NotifyUi li .subtext {
   color: #95A6B7;
   font-size: 12px;
   line-height: 16px;
   margin-top: 8px;
}

.NotifyUi li:nth-child(even) {
   background-color: #131F30;
   padding: 15px;
   font-size: 14px;
   line-height: 18px;
}

.NotifyUi li:nth-child(odd) {
   background-color: none;
   padding: 15px;
   font-size: 14px;
   line-height: 18px;
}

/*leadrerBoardTable*/
.leaderBoard {
   width: 100%;
   border-collapse: collapse;
   font-size: 16px;
   font-family: 'ObjectSans-Regular';
   background-color: #0c1926;

   > tr:first-child > td {
      // padding: 0 !important;
      height: 0 !important;
   }
}

.leaderBoard td {
   padding: 7px;
   padding-top: 10px;
   padding-bottom: 10px;
}

.leaderBoard tr {
   color: #fff;
   cursor: pointer;
}

.leaderBoard tr a {
   color: #fff;
   cursor: pointer;
}

.leaderBoardHigh {
   color: #000000 !important;
   background-image: linear-gradient(to bottom, #FAFF01 0%, #39FF96 100%);
   font-weight: bold;

   > td {
      > div {
         > div:nth-child(3) {
            padding-left: 4px !important;

            > label {
               text-indent: 4px !important;
               width: 49% !important;
               margin: 0px 4px 0px 0px !important;

               span {
                  color: #333;
               }
            }
         }
      }
   }

   .greenText {
      color: #000000 !important;
   }
}

.leaderBoardHigh td, .leaderBoardHigh td a {
   color: #000000 !important;
}

.leaderBoardHigh div .greenText {
   color: #000000 !important;
}

.leaderBoard text {
   color: #17EA85 !important;
}

.highlightedRow {
   background-image: linear-gradient(to bottom, #FAFF01 0%, #39FF96 100%);
   color: #000000 !important;
   font-family: 'ObjectSans-Heavy';
}

.leaderBoard tr:nth-child(even) {
   background-color: #131f30;
   line-height: 30px;
}

.leaderBoard tr:nth-child(odd) {
   background-color: #0c1926;
   line-height: 30px;
}

.leaderBoard tr > td:first-child {
   width: 7%;
   text-align: center;
}

.leaderBoard tr > td:last-child {
   width: 15%; //23
}

.leaderBoard > tr:first-child {
   padding: 0 !important;
   height: 0 !important;

   > td {
      height: unset !important;
      // padding: 0 !important;
      height: 0 !important;
      min-height: 0 !important;
      max-height: 0 !important;

   }
}

.userInfoCell {
   a {

      img {
         display: block;
         width: 36px;
         height: 36px;
         float: left;
      }

      span {
         display: block;
         float: left;
         width: calc(100% - 60px);
      }

      .proTextImg {
         all: unset;
         display: block !important;
         width: 36px;
         height: 36px;
         float: left;
      }

   }
}

.group {
   position: relative;
   margin-bottom: 5px;
}

.showAll {
   color: #17EA85;
   font-size: 16px;
   line-height: 30px;
   text-align: right;
   cursor: pointer;
}

.BlackSkBtn text {

   background-image: linear-gradient(0deg, #FAFF01, #39FF96);
   background-clip: text;
   color: transparent;
   font-family: "ObjectSans-Heavy";
   font-size: 16px;
   line-height: 18px;
}

.BlackSkBtn {
   background-color: #0E2029;
   box-shadow: 0px 2px 0px rgba(0, 0, 0, 0.51);
   border-radius: 4px;
   transform: skew(-10deg, 0deg);
   border: 1px solid rgba(0, 255, 194, 0.09);

   /* identical to box height, or 129% */
   width: 48%;
   text-align: center;
   letter-spacing: -0.01em;
   text-transform: uppercase;
   padding: 14px 27px;
   font-weight: 700;
   height: 54px;
}

.BlackSkBtn:hover {
   background: #1A4152;
}

.BlackSkBtn:visited {
   box-shadow: 0px 2px 0px rgba(0, 0, 0, 0.51);
   cursor: not-allowed;
}

.BlackSkBtn:disabled,
.BlackSkBtn[disabled] {
   background: #0E2029;
   box-shadow: 0px 2px 0px rgba(0, 0, 0, 0.51);
   backdrop-filter: blur(45px);
}

.text14OS {
   font-size: 14px;
   color: #95A6B7;
   font-family: 'ObjectSans-Regular';
   line-height: 15px;
}

.text14OSW {
   font-size: 14px;
   color: #ffffff;
   font-family: 'ObjectSans-Regular';
   line-height: 15px;
}

.text14OSI {
   font-size: 14px;
   color: #ffffff;
   font-family: 'ObjectSans-Regular';
   line-height: 18px;
   text-transform: uppercase;
   font-style: italic;
   font-weight: 700;
}

.Text24Solina {
   font-size: 24px;
   font-family: 'SolinaLight';
}

.team2Logo img {
   width: 75px;
}

.tableText {
   color: #95A6B7;
   font-size: 12px;
   line-height: 16px;
   margin-top: 8px;
}

.table-bordered thead th, .table-bordered thead td {
   border: 0px !important;
}

.table thead th {
   border: 0px !important;
}

.leadTitile {
   color: red !important;
}

.guidanceTipWrapper {
   position: relative;
   // height: 1px;
   > .notificationWrapper {
      inset: unset !important;
      position: absolute;
      top: 0px !important;
      z-index: 99;
   }

   .blueNotificationFooter {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-top: 15px;

      .val01 {
         margin-right: 5px;
      }

      .val02 {
         margin-left: 5px;
      }
   }

   .titleText {
      font-size: 18px;
      font-weight: 600;
      margin-bottom: 10px;
      text-align: left;
   }

   .tipCount {
      color: #95A6B7;
      font-size: 14px;
      margin-bottom: -24px;
   }

   .gradientGreenBtnNotification {
      background: linear-gradient(103.05deg, #F9FF01 7.22%, #2FFF9E 93.09%),
      linear-gradient(95.34deg, #FAFF00 6.28%, #00FFC2 111.13%);
      font-size: $b4FontSize;
      text-transform: uppercase;
      padding: 7px 24px;
      font-family: $buttonText;
      color: rgba(3, 22, 31, 1);
      border: 0;
   }

   .gradientGreenBtnNotification:hover {
      background: linear-gradient(103.05deg, #2FFF9E 7.22%, #F9FF01 93.09%),
      linear-gradient(95.34deg, #00FFC2 6.28%, #FAFF00 111.13%);
   }

   button {
      padding: 5px 24px !important;
   }

}

.matchShareBtnWrapper {
   // float: right;
   margin-right: 10px;
}

.userInfoRowWrapper {
   > div {
      //
      > div:nth-child(3) {
         padding-left: .5rem !important;

         > div {
            width: fit-content;
            float: left;
         }
      }
   }
}

.headerBtnWrapper {
   padding-left: 11px;
   padding-right: 13px;
   display: flex;
}

// @media only screen and (max-width: 1200px) {
//     .QbotCol{
//         order: 1;
//     }
//     .questWrapRow{
//         order: 2;
//     }
//     .ScoreBoardRow{
//         order: 3;
//     }
//     .LeaderBoardRow{
//         order: 4;
//     }
// }
// .questWrapRow{
//     order: 3;
//     border: solid 1px red;
// }
// .ScoreBoardRow{
//             border: solid 1px;
//             order: 4;
// }

.matchScoreHeaderWrapper {
   > div {
      display: block;
   }

   h3 {
      text-transform: uppercase;
      width: 100%;
      text-align: center;
      display: block !important;
      font-style: italic;
      font-size: 24px;
      padding-top: 20px;
      margin-bottom: 0;
   }

   .ScoreHeaderContainer {
      display: flex;
   }
}

.CompletedMatchSummaryWrapper {
   h3 {
      font-size: 24px;
      width: 90%;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      margin: 0 auto;
      text-transform: uppercase;
      text-align: center;
      padding-top: 10px;
   }

   .scoreBoardDark {
      display: block;

      > div {
         display: flex;
         justify-content: center;
         align-items: center;
      }
   }

   .team1Name {
      font-family: 'ObjectSans-Regular';
   }
}

.StreamSwitch {
   position: relative;
   display: inline-block;
   width: 100px;
   height: 42px;
   float: right;
   margin-right: 10px;
   margin-left: auto;
   border: 1px solid rgba(255, 255, 255, 0.10);

   input {
      display: none;
   }

   button {
      width: 140px;
      height: 100%;
      background-color: #111E33;
      border-radius: 2px;
      transition: 0.4s;
      border: none;

      &.active {
         background: linear-gradient(to right, #19EA17 0%, #14D0E6 100%) !important;
      }

      &.inactive {

      }

      img {
         width: 24px;
         height: 24px;
      }
   }

   label {
      position: absolute;
      display: flex;
      justify-content: space-between;
      align-items: center;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background-color: #111E33;
      border-radius: 2px;
      transition: 0.4s;
      cursor: pointer;

      .icon1 {
         margin-left: 18px;
         display: block;
         width: 20px;
         height: 20px;
         background: url('../../assets/images/icons/camera-video-off-fill.svg') no-repeat center;
         background-size: contain;
         z-index: 1;
      }

      .icon2 {
         margin-right: 18px;
         display: block;
         width: 20px;
         height: 20px;
         background: url('../../assets/images/icons/camera-video-fill.svg') no-repeat center;
         background-size: contain;
         z-index: 1;
      }
   }

   label:before {
      content: "";
      position: absolute;
      top: 5px;
      left: 5px;
      width: 45%;
      height: 32px;
      background: linear-gradient(to right, #19EA17 0%, #14D0E6 100%) !important;
      transition: 0.4s;
      border-radius: 2px;
   }

   input:checked + label {
   }

   input:checked + label:before {
      transform: translateX(45px);
   }
}
.matchsummaryWrapper{
   .MatchSummaryHeader{
      padding-bottom: 10px;
      .Col1{
         width: 300px;
         display: flex;
         > span{
            display: flex;
            flex-direction: column;
            text-align: left;
            margin-right: auto !important;
            span{
               margin-right: auto !important;
            }
            label{
               display: flex;
               margin-bottom: 0;
               text-align: left;
               justify-content: left;
               margin-right: auto;
               margin-bottom: 5px;
               color: #95A6B7;
               font-size: 16px;
               line-height: 20px;
               letter-spacing: -0.01em;
               font-weight: 700;
               font-family: 'ObjectSans-Regular';
            }
         }

      }
      .OverSummary{
         all: unset;
         display: flex;
         width: calc(100% - 310px);
         gap: 30px;
         justify-content: right;
         .Over{
            display: flex;
            gap: 4px;
            .Ball{
               display: flex;
               justify-content: center;
               align-items: center;
               width: 32px;
               height: 32px;
               border-radius: 50%;
               border: solid 1px #b5fd3f;
               
               > div{
                  font-family:'ObjectSans-Heavy';
                  width: 24px;
                  height: 24px;
                  border-radius: 50%;
                  color: #000;
                  background-color: #fff;
                  box-shadow: 0px 0px 9px -1px rgba(0, 0, 0, 0.75)  inset;
                  -webkit-box-shadow: 0px 0px 9px -1px rgba(0, 0, 0, 0.75) inset;
                  -moz-box-shadow: 0px 0px 9px -1px rgba(0, 0, 0, 0.75)  inset;
                  font-size: 14px;
                  font-weight: bold;
                  text-align: center;
                  align-items: center;
                  display: flex;
                  justify-content: center;
                  text-shadow: none;
                  font-style: normal;
               }
            }
         }
         label{
            color: #95A6B7;
            font-size: 16px;
            line-height: 20px;
            letter-spacing: -0.01em;
            font-weight: 700;
            font-family: 'ObjectSans-Regular';
         }
      }
   }
}
@media only screen and (max-width: 992px) {
   .squadWrap {
      margin-left: 0;
   }
   .userInfoRowWrapper {
      > div {
         > div:nth-child(3) {
            padding-left: unset !important;

            img {
               width: 33px;
               padding-right: 5px;
            }
         }

         .pointsIconWrapper {
            width: fit-content;

            > div {
               width: fit-content;
               float: left;
            }
         }
      }
   }
}

@media (max-width: 768px) {

   .squadWrap {
      margin-left: 0;
   }
   .scoreBoardDark {
      height: auto !important;
      display: inline-block;
   }

   .teamBall, .teamBat {
      float: right;
      /*display: none;*/
   }

   .team1Name, .team2Name {
      font-size: 14px;;
   }

   .questWrap H2 {
      font-size: 20px;
   }

   .bowlindWrapper {
      width: 100%;
      float: left;
      overflow: auto;
   }

   .bowlindWrapper .table {
      width: 500px;
   }
   .leaderBoard tr > td:first-child {
      width: 7%;
      text-align: center;

   }

   .leaderBoard tr > td:last-child {
      width: 28%;
      padding: 0;
      padding-right: 5px;
      vertical-align: bottom;
      padding-bottom: 15px;
   }
   .scoreBoardDark text {
      margin-left: 15px;
      font-size: 20px;
      line-height: 100px;
   }
   .twodotsWrapper {
      img {
         // position: absolute;
         // top: -30px;
      }
   }
   .teamNameWrapper {
      font-size: 14px !important;
   }
   .teamScoreWrapper {
      font-size: 16px;
   }
   .headerBtnWrapper {
      button {
         font-size: 13px;
         padding: 9px 9px 9px 10px;
      }

      .StreamSwitch {
         height: 37px;

         input:checked + label:before, label:before {
            height: 28px;
         }
      }
   }
   .matchScoreHeaderWrapper {
      h3 {
         font-size: 20px;
         width: 90%;
         white-space: nowrap;
         overflow: hidden;
         text-overflow: ellipsis;
         margin: 0 auto;
      }

      .ScoreHeaderContainer {
         display: flex;
         justify-content: center;

         .midSect {
            max-width: 32px;

            > div {
               margin: 0;
            }
         }

         .teamDetail {
            width: calc(100% - 38px) !important;
            display: flex;
            align-items: center;

            .team1Logo {
               display: flex;
               width: 55px;
               align-items: center;
            }

            .team1scoreSection {
               width: calc(100% - 35px) !important;

               > a {
                  display: flex;
                  align-items: center;
                  width: calc(100% - 65px) !important;
                  // width: 100% !important;
                  left: 0;
               }

               .team1Name {
                  padding: 0;
                  float: left;
                  width: 100%;
               }

               .team1Score {
                  font-size: 16px;
                  padding-top: 2px !important;
               }
            }

            .team2Score {
               font-size: 16px;
            }
         }
      }
   }
   .CompletedMatchSummaryWrapper {
      h3 {
         font-size: 20px;
      }
   }
   .LiveMatchScore {
      .ScoreHeaderContainer {
         .teamDetail {
            .team1Score, .team2Score {
               // width: 75px !important;
               width: 100% !important;
               padding: 0;
               margin-right: 0;
               text-align: right;
            }

            .team1Overs, .team2Overs {
               // width: 75px !important;
               width: 100% !important;
               padding: 0;
               margin-right: 0;
               text-align: right;
            }
         }

         .teamDetail:first-child, .teamDetail:last-child {
            .team1Logo, .team2Logo {
               order: 1;
               width: 66px;

               a {
                  img {
                     width: 65px;
                  }
               }
            }

            .team1scoreSection, .team2scoreSection {
               order: 2;
               position: relative;
            }

            .team2scoreSection {
               width: 100%;

               > a {
                  left: 0;
               }
            }
         }
      }
   }
   .MatchSummaryHeader {
      flex-direction: column;
     .Col1{
         width: 300px !important;
         justify-content: center !important;
         margin: auto !important;
         padding-bottom: 10px;
         > span{
            flex-direction: row !important;
         }
         label{
            padding-right: 10px !important;
            margin-right: 0 !important;
         }
      }
      .OverSummary{
         width: unset !important;
         justify-content: center !important;
      }
   }
}

@media only screen and (max-width: 576px) {
   .matchSummaryWrapper {
      .scoreBoardDark {
         .backBtnScore {
            width: 20px;
            float: left;
            margin-top: 10px;

            > svg {
               max-width: 16px;
            }
         }

         .nextBtnScore {
            width: 20px;
            float: right;
            margin-top: 10px;
         }

         > div:nth-child(1) {
            width: unset;
            width: 40% !important;
            max-width: 40% !important;
            margin-left: 10px !important;
            // background-color: #1A4152;
         }

         > div:nth-child(2) {
            width: 44% !important;
            margin-left: 13px !important;
            margin-right: 5px;
         }

      }

      .teamNameWrapper {
         font-size: 12px !important;
         line-height: 15px;

         img {
            max-width: 15px;
         }
      }

      > .scoreBoardDark {
         > div:nth-child(1),
         > div:nth-child(3) {
            width: 48% !important;
            max-width: 48% !important;
            margin: 0;

            .teamNameWrapper {
               font-size: 10px !important;
            }
         }

         > div:nth-child(2) {
            width: unset !important;
            width: 0 !important;
            display: none;
         }
      }

   }
   .matchScoreHeaderWrapper {
      padding-left: 0;
      padding-right: 0;

      .CompletedMatchSummaryWrapper {
         font-size: 16px;
         margin-bottom: 4px;
      }

      .ScoreHeaderContainer {
         .teamDetail {
            padding-left: 0px;
            margin-left: 0 !important;

            .team1Logo {
               width: 35px !important;

               > a {
                  width: 35px !important;
               }
            }

            .team1Score {
               height: 20px;
               max-width: 60px;
            }
         }

         .teamScoreWrapper {
            font-size: 12px !important;
         }
      }
   }
   .matchShareBtnWrapper {
      margin-right: 6px !important;
   }

   .matchScoreHeaderWrapper {
      h3 {
         font-size: 16px;
         padding-top: 2px;
         margin-bottom: 4px;
      }

      .ScoreHeaderContainer {
         display: flex;
      }
   }

   .ScoreHeaderContainer {

      .teamDetail {
         .team1scoreSection {
            .team1Score {
               font-size: 14px !important;
            }
         }

         .team2Score {
            font-size: 14px !important;
         }
      }
   }
   .CompletedMatchSummaryWrapper {
      .scoreBoardDark {
         > div {
            display: unset !important;
         }
      }
   }
   .CompletedMatchSummaryWrapper {
      h3 {
         font-size: 16px !important;
         margin-bottom: 4px;
      }

      .teamDetail {
         // width: 48% !important;
         width: calc(50% - 5px) !important;
      }

      .teamDetail:first-child {
         margin-left: 0 !important;
      }

      .teamDetail:last-child {
         float: right !important;
      }

      .scoreBoardDark {
         > div:nth-child(2) {
            margin-left: 0 !important;
            display: block !important;
            width: 100% !important;
         }
      }

      .teamNameWrapper {
         font-size: 10px !important;
      }

   }

   .matchScoreHeaderWrapper {
      .LiveMatchScore {
         .ScoreHeaderContainer {
            .teamDetail {
               .team1Score, .team2Score {
                  // width: 75px !important;
                  width: 100% !important;
                  padding: 0;
                  margin-right: 0;
                  text-align: right;
                  max-width: unset;
               }

               .team1Overs, .team2Overs {
                  // width: 75px !important;
                  width: 100% !important;
                  padding: 0;
                  margin-right: 0;
                  text-align: right;
               }
            }

            .teamDetail:first-child, .teamDetail:last-child {
               .team1Logo, .team2Logo {
                  order: 1;
                  width: 30px;
                  left: 0;

                  a {
                     img {
                        width: 30px;
                     }
                  }
               }

               .team1scoreSection, .team2scoreSection {
                  order: 2;
                  position: relative;
                  width: 100% !important;

                  a {
                     min-height: unset;
                  }
               }

               .team2scoreSection {
                  width: 100%;

                  > a {
                     left: 0;
                  }
               }
            }
         }
      }
   }
   .LiveMatchScore {
      .twodots {
         display: block !important;
         padding: 0px 4px;
         height: 22px;
         width: 12px;
         margin: 10px 0px;
      }
   }
   .CompletedMatchSummaryWrapper {
      .twodots {
         display: block !important;
         padding: 0px 4px;
         height: 22px;
         width: 12px;
         margin: 10px 0px;
      }

      .scoreBoardDark > div:nth-child(2) {
         display: flex !important;
      }
   }
   .matchScoreHeaderWrapper .ScoreHeaderContainer .teamDetail .team1scoreSection {
      width: 100% !important;
   }
   .LiveLeaderboard {
      table {
         td {
            .userInfoRowWrapper {

            }

            .pointsIconWrapper {
               min-width: 40px;
               margin-right: 2px;
            }

            .userInfoCell2 {
               label:nth-child(1) {
                  min-width: 80px;
               }

               label:nth-child(2) {
                  min-width: 50px;
                  width: 50px !important;
               }
            }

            .LeaderPoints {
               span {
                  font-size: 12px;
               }
            }
         }
      }

   }
   .MatchSummaryHeader{
      .Col1{
         width: 300px !important;
         justify-content:flex-start !important;
         margin: unset !important;
         margin-right: auto !important;
         padding-bottom: 10px;
         > span{
            flex-direction: row !important;
         }
         label{
            padding-right: 10px !important;
            margin-right: 0 !important;
         }
      }
      .OverSummary{
         flex-direction: column !important;
         // margin: auto !important;
         gap: 10px !important;
         padding-top: 20px !important;
      }
   }
}

@media only screen and (max-width: 471px) {
   .headerBtnWrapper {
      button {
         margin-bottom: 8px !important;
      }

      .StreamSwitch {
         width: 110px;
         margin-bottom: 8px !important;
         margin-right: 0;
      }
   }

}

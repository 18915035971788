@import '../../scss/globals/global';
@import '../../scss/utilities/mixins';

.Radial1 {
   @include gradientGeneralLeft(900px, 900px, -550px, 550px, $Blue03, 32.65%, 32.65%, $GradPosCenter);
   mix-blend-mode: normal;
   opacity: 0.14;
   z-index: -1;
}

.Radial2 {
   @include gradientGeneralRight(500px, 500px, 0, 1050px, $Blue03, 32.65%, 32.65%, $GradPosRight);
   mix-blend-mode: normal;
   opacity: 0.14;
   z-index: -1;
}

.TextError {
   color: #FF002E;
   font-size: 13px;
   line-height: 15px;
   display: block;
}

.MaxFileSizeBtn {
   height: 45px;
   padding: 0 25px;
}

.Text32Solina {
   font-size: 32px;
   font-family: 'SolinaLight';
   color: #ffffff;
   text-transform: uppercase;
}

.SocialSmallIcons {
   border: 1px solid #FFFFFF0F;
   width: 32px;
   height: 32px;
   border-radius: 16px;
   // padding-top: 8px;
   margin-left: 10px;
   padding-left: 8px;
   background-color: #111E33;
}

.overviewWrapper {
   display: grid;
   grid-template-columns: 1fr 1fr 1fr;

   > div {
      width: 100%;
   }
}

.OverviewBox {
   @include overview-box;
   padding-left: 5px;

   img {
      width: auto;
   }
}

.leaderBoardTitle {
   position: relative;

   .shareBtnWrapper {
      position: absolute;
      top: -4px;
      right: 0;
   }
}

.borderTop1 {
   @include yellow-gradient;
}

.borderTop2 {
   @include green-gradient;
}

.borderTop3 {
   @include purple-gradient;
}

.AccStat {
   color: #95A6B7;
   font-Size: 13px;
   width: auto;
   line-Height: 20px;
   float: left;
   margin-Top: 10px;
   border-Left: 1px solid #95a6b7;
   margin-left: 20px;
   padding-left: 20px;
}

.FavMatchUpUL {
   font-size: 16px;
   color: #ffffff;
   font-family: 'ObjectSans-Regular';
   margin-left: 10px;
   list-style-type: none;
   padding-left: 0px;
   line-height: 2.5;

   li {
      margin-top: 20px;
      margin-bottom: 20px;
      display: flex;

      span {
         display: block;
         max-width: 180px;
         white-space: nowrap;
         overflow: hidden;
         text-overflow: ellipsis;
      }
   }

   li:last-child {
      color: #17EA85;
   }
}


.FavPlayerUL {
   font-size: 16px;
   color: #ffffff;
   font-family: 'ObjectSans-Regular';
   margin-left: 10px;
   list-style-type: none;
   padding-left: 0px;
   line-height: 20px;

   li {
      margin-top: 20px;
      margin-bottom: 20px;
   }

   li:last-child {
      color: #17EA85;
   }
}

.SplashBox {
   height: 320px;
   background-image: url(https://storage.googleapis.com/meta11/serviceplatform/splash-bg.png);
   background-position: center 0%;
}

.marks {
   width: fit-content;
   padding-left: 10px;
   padding-right: 10px;
   height: 28px;
   background: #172842;
   border: 1px solid #253342;
   border-radius: 4px;
   margin-left: auto;
   margin-right: auto;
}

/*curcle1*/

.curcleOut1 {
   width: 100%;

   .upDown {
      margin-left: auto;
      margin-right: auto;
      width: 16px;
      height: 16px;
      margin-bottom: 5px;
   }

   .OutCurcle {
      margin-left: auto;
      margin-right: auto;
      background-image: linear-gradient(to bottom, #FAFF01 0%, #39FF96 100%);
      width: 104px;
      height: 104px;
      border-radius: 50%;
      padding-top: 2px;
      padding-right: 2px;
      padding-bottom: 2px;
      padding-left: 2px;
      box-shadow: 0px 0px 40px rgba(211, 255, 30, 0.20);
      -webkit-box-shadow: 0px 0px 40px rgba(211, 255, 30, 0.20);
   }

   .InCurcle {
      width: 100px;
      height: 100px;
      background-size: 100% auto;
      border-radius: 50%;
   }
}

.curcleCount {
   background-image: linear-gradient(to bottom, #FAFF01 0%, #39FF96 100%);
   height: 28px;
   width: 28px;
   border-radius: 50%;
   margin-left: auto;
   margin-right: auto;
   font-size: 14px;
   text-align: center;
   padding-top: 8px;
   margin-top: -14px;
   font-family: 'ObjectSans-Heavy';
   color: #000;
   line-height: 14px;
}

/*curcle2*/
.curcleOut2 {
   width: 100%;

   .upDown {
      margin-left: auto;
      margin-right: auto;
      width: 30px;
      height: 23px;
      margin-bottom: 5px;
   }

   .OutCurcle {
      margin-left: auto;
      margin-right: auto;
      background-image: linear-gradient(to bottom, #FAFF01 0%, #39FF96 100%);
      width: 144px;
      height: 144px;
      border-radius: 50%;
      padding-top: 2px;
      padding-right: 2px;
      padding-bottom: 2px;
      padding-left: 2px;
      box-shadow: 0px 0px 40px rgba(211, 255, 30, 0.30);
      -webkit-box-shadow: 0px 0px 40px rgba(211, 255, 30, 0.30);

   }

   .InCurcle {
      width: 140px;
      height: 140px;
      background-size: 100% auto;
      border-radius: 50%;
   }
}

.emptyFavsCircle {
   width: 42px;
   height: 42px;
   background-size: 100% auto;
}

/*leadrerBoardTable*/
.leaderBoard {
   width: 100%;
   border-collapse: collapse;
   font-size: 16px;
   font-family: 'ObjectSans-Regular';

   thead {
      tr {
         th {
            background-color: rgba(26, 38, 57, 0.50);
            padding: 5px 6px;
            color: #95A6B7;
            font-size: 15px;
            font-style: italic;
            opacity: .8;
         }

         th:last-child {
            position: relative;

            span {
               display: block;
               width: fit-content;
               position: absolute;
               bottom: 7px;
               left: 23px;
            }
         }
      }
   }

   td {
      padding: 7px;
      padding-top: 10px;
      padding-bottom: 10px;
      color: #fff;

      > img {
         width: 36px;
         height: 36px;
      }
   }

   tr, .leaderBoard tr a {
      color: #fff;
      cursor: pointer;
   }

   tr:nth-child(odd) {
      background-color: rgba(26, 38, 57, 0.00);
      line-height: 30px;
   }

   tr:nth-child(even) {
      background-color: rgba(26, 38, 57, 0.50);
      line-height: 30px;
   }

   tr > td:first-child {
      width: 10%;
      text-align: center;
   }
}

.group {
   position: relative;
   margin-bottom: 5px;

   label {
      color: #95A6B7;
      font-size: 16px;
      font-weight: normal;
      position: absolute;
      pointer-events: none;
      left: 15px;
      top: 25px;
      transition: 0.2s ease all;
      -moz-transition: 0.2s ease all;
      -webkit-transition: 0.2s ease all;
   }
}

.trophyText {
   font-Size: 14px;
   color: #17EA85;
   margin-left: 3px;
}

.trophyTextW {
   font-size: 18px;
   color: #ffffff;
   font-family: 'ObjectSans-Regular';
   letter-spacing: -0.01em;
   line-height: 24px;
}

/* active state */
input[type=password]:focus ~ label, input[type=password]:valid ~ label {
   top: 10px;
   font-size: 12px;
   color: #95A6B7;
   padding-left: 6px;
}

.imgUpdateDiv {
   padding: 10px;
   margin-bottom: -25px;
   position: absolute;
   background-color: #253f46;
   border-radius: 15px;
   margin-left: 15px;
   width: 75px;
   height: 45px;

   label:hover {
      opacity: .6;
   }
}

.imgUpdateDivSmall {
   margin-bottom: 0;
   margin-left: 0;
   width: auto;
   left: 20px;
   top: 10px;
}

.Text32 {
   font-size: 28px;
   font-family: 'SolinaLight', Helvetica, Arial, sans-serif;
   text-align: center;
   line-height: 35px;
   color: #ffffff;
}

.Text18 {
   font-size: 18px;
   font-family: 'ObjectSans-Regular', Helvetica, Arial, sans-serif;
   text-align: center;
   line-height: 20px;
   color: #95A6B7;
   letter-spacing: 0px;
}

.HeartIcon {
   position: absolute;
   padding-left: 150px;
   padding-top: 10px;
}

.heartsSection {
   padding: 15px;
   backdrop-filter: blur(50px);
   border-radius: 40px;
   position: absolute;
   top: 10px;
   right: 10px;
   font-style: normal;
   font-weight: 500;
   font-size: 20px;
   line-height: 20px;
   text-align: center;
   letter-spacing: 0.02em;
   color: #FFFFFF;
   text-decoration: none;
   display: flex;
   cursor: pointer;

   label {
      margin-left: 10px;
      cursor: pointer;
   }
}


.loaderContainer {
   margin: 0 auto;
   transform: translateY(400%);
   text-align: center;
}

/*radio checkbox*/

.RadioBtnBox {
   color: #ffffff;
   width: 100%;
   border: solid 1px #3E5168;
   height: 56px;
   border-radius: 6px;
   background-color: #04151D;
   padding: 0px;
}

.LockBox {
   border-right: 1px solid #3E5168;
   width: 25%;
   height: 56px;
   float: left;
   text-align: center;
   padding-top: 13px;
}

.LockText {
   width: 55%;
   float: left;
   text-align: center;
   padding-top: 17px !important;
   font-size: 14px;
   font-family: 'ObjectSans-Regular', Helvetica, Arial, sans-serif;
}

.LockText2 {
   width: 74%;
   float: left;
   text-align: center;
   padding-top: 0px;
   font-size: 16px;
   font-family: 'ObjectSans-Regular', Helvetica, Arial, sans-serif;
}


.RadioBox {
   width: 20%;
   float: left;
   text-align: center;
   padding-top: 15px;
}

.radioFill:checked {
   background-color: #04151d;
   border-color: #17EA85;
   background-image: url(https://storage.googleapis.com/meta11/serviceplatform/radioCheckGreen.png) !important;
   background-repeat: no-repeat;
   background-size: 60%;
}

.radioFill {
   border: 1px solid #17EA85 !important;
   width: 1.3em;
   height: 1.3em;
   background-color: #04151d;
   cursor: pointer;
}

.SocialInput {
   display: block;
   border: none !important;
   background-image: -webkit-linear-gradient(0deg, rgba(4, 21, 29, 1.00) 0%, rgba(11, 28, 53, 0.00) 100%);
   background-image: -moz-linear-gradient(0deg, rgba(4, 21, 29, 1.00) 0%, rgba(11, 28, 53, 0.00) 100%);
   background-image: -o-linear-gradient(0deg, rgba(4, 21, 29, 1.00) 0%, rgba(11, 28, 53, 0.00) 100%);
   background-image: linear-gradient(90deg, rgba(4, 21, 29, 1.00) 0%, rgba(11, 28, 53, 0.00) 100%);
   color: #ffffff;
   padding-left: 10px !important;
   padding-right: 0px !important;
   font-size: 16px;
   width: 100%;
   box-sizing: border-box;
   height: 65px !important;
   border-radius: 6px;
   background-color: #04151D;
   padding-bottom: 22px;
   padding-top: 20px !important;
   line-height: 10px;
   background-repeat: no-repeat;
   background-position: 96% center;
}


.BlackSkBtn text {

   background-image: linear-gradient(0deg, #FAFF01, #39FF96);
   background-clip: text;
   color: transparent;
   font-family: "ObjectSans-Heavy";
   font-size: 16px;
   line-height: 18px;
}

.BlackSkBtn {
   background-color: #0E2029;
   box-shadow: 0px 2px 0px rgba(0, 0, 0, 0.51);
   border-radius: 4px;
   transform: skew(-10deg, 0deg);
   border: 1px solid rgba(0, 255, 194, 0.09);
   /* identical to box height, or 129% */
   text-align: center;
   letter-spacing: -0.01em;
   text-transform: uppercase;
   padding: 14px 27px;
   font-weight: 700;
   height: 54px;
   color: #95A6B7;

   :visited {
      box-shadow: 0px 2px 0px rgba(0, 0, 0, 0.51);
      cursor: not-allowed;
   }

   :disabled,
   .BlackSkBtn[disabled] {
      background: #0E2029;
      box-shadow: 0px 2px 0px rgba(0, 0, 0, 0.51);
      backdrop-filter: blur(45px);
   }
}

.BlackSkBtn:hover {
   background: #203455;
}

.LoginButton {
   background: linear-gradient(95.34deg, #FAFF00 6.28%, #00FFC2 111.13%);
   box-shadow: 0px 2px 0px rgba(0, 0, 0, 0.51);
   border-radius: 4px;
   transform: skew(-10deg, 0deg);
   border: 1px solid #2FFF9E;
   /*border-image-source: linear-gradient(103.05deg, #2FFF9E 7.22%, #F9FF01 93.09%);*/
   text-align: center;
   letter-spacing: -0.01em;
   padding: 14px 27px;
   /*font-weight: 700;
   width: 100%;*/
   height: 51px;
   font-family: "ObjectSans-Heavy", Helvetica, Arial, sans-serif;
   font-size: 16px;
   line-height: 18px;
   text-transform: uppercase;
   margin-left: 10px;
   // :hover {
   //   background: linear-gradient(95.34deg, #00FFC2 6.28%, #FAFF00 111.13%);
   // }

   :visited {
      background: linear-gradient(95.34deg, #B0FFCA 6.28%, #F2FF7E 111.13%);
   }
}

.LoginButton:hover {
   background: linear-gradient(95.34deg, #00FFC2 6.28%, #FAFF00 111.13%);
}

.modalBlur {
   background-color: #00000063;
}

.closeButton {
   border: 0px;
   background-color: #00000000;
}

.userProfileModalWrapper {
   .modalContainer {
      .modalTitle {
         font-family: 'SolinaLight';
         font-size: 20px;
         color: #ffffff;
         text-transform: uppercase;
         line-height: 40px;
      }
   }

   .photoModifyModelBtnRow {
      display: grid;
      grid-template-columns: 1fr 1fr;
      gap: 10px;

      > button {
         width: 100%;
         height: 52px;
      }
   }

   .InputRow {
      .UserName {
         color: rgb(23, 234, 133) !important;
      }
   }

   input[type=text] {
      padding-top: 0;
      padding-bottom: 0;
      height: 56px;
   }
}


.socialMediaWrapper {
   position: relative;
   bottom: -44px;
}

.modalText {
   font-size: 16px;
   line-height: 20px;
   color: #95A6B7;
}

.NumIn {
   box-sizing: border-box;
   border: solid 1px #3E5168;
   height: 67px;
   border-radius: 6px;
   background-color: #04151D;
   background-image: linear-gradient(90deg, rgba(4, 21, 29, 1.00) 0%, rgba(11, 28, 53, 0.00) 100%);
   /*text-align: center !important;*/
   color: #ffffff;
   padding-left: 10px;
   padding-right: 10px;
   font-size: 16px !important;
   font-family: 'SolinaLight', Helvetica, Arial, sans-serif;
   padding-top: 20px;
   padding-left: 14px !important;
   padding-right: 10px !important;
}

.sepaBorder {
   border-Left: 1px solid #95A6B7;
   padding-left: 12px;
}

.statsmallText {
   color: #95A6B7;
   padding-top: 10px;
}

.highlightedRow {
   background-image: linear-gradient(to bottom, #FAFF01 0%, #39FF96 100%);
   font-family: 'ObjectSans-Heavy';

   > * {
      color: #000000 !important;
   }
}

/*history board*/
.playerHistory {
   display: flex;
   flex-direction: column;
}

.playerHistory {
   .topSection {
      display: block;
      position: relative;
      margin-bottom: 20px;
      align-items: center;

      > img {
         display: block;
         width: fit-content;
         max-width: 155px;
         margin: 0 auto;
         margin-top: -30px;
      }

      > .showAllBtn {
         position: absolute;
         top: 0;
         right: 0;
      }
   }
}

.topSectionModal {
   display: flex;
   justify-content: space-between;
   margin-bottom: 20px;
   align-items: center;
}

.playerHistoryWrapper {
   ul {
      list-style: none;
      padding: 0;
      margin: 0;

   }

   li {
      padding: 25px;
   }
}

.bottomSection {
   display: flex;
   justify-content: center;
   align-items: center;
}

.footerSect {
   text-align: center;
   padding: 20px 10px 0;
}

.player1Details, .player2Details {
   display: flex;
   flex-direction: column;
}

.leftPlayer img, .rightPlayer img {
   height: 70px;
}

.rightPlayer img {
   margin-right: 15px;
}

.leftPlayer img {
   margin-left: 15px;
}

.player1Details {
   align-items: end;
   margin-right: 0px;
   position: relative;

   .cupIcon {
      position: absolute;
      right: -24px;
      top: 2px;
   }
}

.player2Details {
   align-items: start;
   margin-left: 15px;
}

.leftPlayer {
   display: flex;
}

.midleDots {
   padding: 2px 8px;
   border: rgb(255 255 255 / 26%) solid 1px;
   border-radius: 90px;
   margin: 2px 15px;
}

.rightPlayer {
   display: flex;

   span {
      margin-left: 0px;
   }

   div.cupIcon {
      //position: absolute;
      //margin-top: 2px;
      //right: -25px;
   }
}

.oditem {
   background: rgba(9, 23, 37, 0.5);
}

.teamBall {
   background-image: url("../../assets/images/ball.svg");
   content: "";
   margin: 0 4px;
   width: 14px;
   height: 14px;
   display: inline-block;
   background-size: contain;
   background-repeat: no-repeat;
}

.teamBat {
   background-image: url("../../assets/images/bat.svg");
   content: "";
   margin: 0 2px;
   width: 18px;
   height: 18px;
   display: inline-block;
   background-size: contain;
   background-repeat: no-repeat;
}

.cupIcon {
   background-image: url("../../assets/images/cupIcon.svg");
   content: "";
   margin: 0 2px;
   width: 18px;
   height: 14px;
   display: inline-block;
   background-size: contain;
   background-repeat: no-repeat;
}

.questionSection {
   width: 100%;
   display: inline-block;
   padding: 20px 100px;
   // .questionGreenBtn{
   //   // background: linear-gradient(103.05deg, #F9FF01 7.22%, #2FFF9E 93.09%);

   // }
   // .blackBtn{
   //   background-color:
   //   #DE4A4A;
   // }
   .answerBtnWrapper {
      width: fit-content;
      margin: 0 auto;
   }

   .winnerPrediction {
      margin-top: 20px;

      span {
         padding: 5px;
      }

      span:nth-child(1) {
         text-transform: uppercase;
      }

      span.points {
         color: #17EA85;
      }
   }
}

.OverSummary {
   padding-top: 7px;
   padding-bottom: 7px;

   h7 {
      font-family: Montserrat;
      font-size: 24px;
      font-weight: 700;
      line-height: 32px;
      letter-spacing: 0em;
      text-align: center;
      color: #fff;
      text-shadow: 0px 0px 6px #17EA85;
   }

   p.points {
      font-family: Montserrat;
      font-size: 20px;
      font-weight: 700;
      line-height: 24px;
      letter-spacing: 0em;
      text-align: center;
      color: #17EA85;
   }

   .summaryWrapper {
      max-width: 270px;
      margin: 0 auto;

      label {
         text-transform: uppercase;

         span {
            color: #17EA85;
         }
      }
   }

   .borderHr {
      border-image: linear-gradient(to right, rgba(243, 221, 27, 0), #2DFEE5, rgba(243, 221, 27, 0)) 1 0 0 0;
      border-top: 1px solid;
      margin-top: 5px;
      margin-bottom: 5px;
   }
}

.ballBodyWrapper {
   max-width: 305px;
   margin: 0 auto;
   display: grid;
   grid-template-columns:repeat(6, 1fr);
   gap: 8px;
   margin-bottom: 20px;

   .ballWrapper {
      position: relative;
      width: 40px;
      height: 40px;
      border-radius: 50%;

      .ballBody {
         width: 28px;
         height: 28px;
         background-color: #fff;
         box-shadow: 0px 0px 4px 2px #86868680 inset;
         border-radius: 50%;
         margin: 6px;
         font-family: 'Khand';
         color: #000;
         font-size: 18px;
         font-weight: 700;
         line-height: 24px;
         letter-spacing: 0em;
         text-align: center;

         > span {
            display: block;
            padding-top: 3px;
         }
      }

      .pointsCell {
         width: 20px;
         height: 20px;
         border-radius: 50%;
         font-weight: 600;
         right: 3px;
         bottom: -6px;
         position: absolute;
         text-align: center;
         font-style: italic;
         font-family: "Montserrat-Regular";
         font-size: 12px;
         background: linear-gradient(83.19deg, rgba(57, 45, 252, 0.3) 19.41%, rgba(255, 122, 0, 0.3) 152.48%), linear-gradient(54.21deg, #FF7A00 -4.13%, #392dfcd6 101.35%);
         border: 1px solid;
         border-image-source: linear-gradient(54.21deg, #FF7A00 -4.13%, #392DFC 101.35%);
      }
   }
}

.questionArea {
   background: radial-gradient(96.12% 96.12% at 50% 50%, #1e454033 0%, rgba(45, 254, 229, 0) 100%),
   linear-gradient(180deg, rgba(45, 254, 229, 0.1) 0%, rgba(45, 254, 229, 0) 165.58%);
   border: 1px solid #2dfee524;
   display: flex;
   padding: 30px 100px !important;
   width: 100%;
   flex-direction: column;
   align-items: center;
   text-align: center;
   margin-bottom: 10px;
}

.mainText {
   font-family: SolinaExBI;
   font-size: 38px;
   line-height: 58px;
   font-style: italic;

   > img {
      margin-top: -6px;
   }
}

.mobLbl {
   display: none;
}

.player1Details {
   .name {
      text-align: right;
   }
}

//Bot History
.botHistory {

   h2 {

      font-family: 'ObjectSans-Regular';
      font-size: 22px;
      font-weight: 400;
      line-height: 28px;
      letter-spacing: -0.01em !important;
      text-align: center;
      font-weight: normal;
      margin-bottom: 15px;

   }

   .participatedMatchesWrapper {
      border-image: linear-gradient(to right, rgba(243, 221, 27, 0), #2DFEE5, rgba(243, 221, 27, 0)) 1 0 0 0;
      border-top: 1px solid;
   }

   .showAllBtn {
      color: #17EA85;
      font-weight: 700;
      font-size: 16px;
      background: transparent;
      border: none;
      padding: 0;
   }

   ul {
      li {
         background-color: #0d1926;

         .topSection {
            label {
               font-size: 16px;
               color: #95A6B7;
            }
         }
      }

      li:nth-child(odd) {
         background-color: #121f2f;
      }
   }
}

.userProfileWrapper {
   .userProfileRightCol {
      .OverviewBox {
         h2 {
            font-family: "Montserrat-Regular";
            font-size: 20px;
            line-height: 28px;
            text-transform: capitalize;
            border-bottom: 1px solid #FFFFFF0F;
            padding: 10px;
            color: #fff;
         }

         .notificationRow {
            border-bottom: 1px solid #FFFFFF0F;
            padding-bottom: 12px !important;
            margin-bottom: 15px;
            display: grid;
            grid-template-columns: 30px 1fr;
            gap: 5px;

            > div:nth-child(1) {
               padding: 7px 10px 10px 10px;

               img {
                  width: 26px;
                  height: 26px;
               }
            }
         }
      }
   }
}

.OverviewBox {
   > .heading4 {
      background-color: #00FFC2;
      font-size: 20px !important;
   }

   .heading4 {
      font-size: 20px !important;
   }
}

.answerBtnWrapper {
   display: block !important;
   display: grid;
   grid-template-columns: 1fr 1fr;
   gap: 10px;

   .blackBtn, .redSolidBtn, .greenBtn {
      all: unset;
      max-height: 32px;
      font-family: Montserrat;
      font-size: 18px !important;
      font-weight: 500;
      line-height: 24px;
      letter-spacing: -0.01em;
      text-align: center;
      border-radius: 2px;
      margin: 10px 10px;
   }

   .blackBtn {
      border: solid 1px;
      border-image: linear-gradient(45deg, #111E33, #111E33) 1;
      border-width: 3px;
   }

   .greenBtn {
      border: solid 1px;
      background: linear-gradient(180deg, #FAFF01 0.03%, #39FF96 86.64%);
      border-image: linear-gradient(45deg, #FAFF01, #39FF96) 1;
      border-width: 1px;

      span {
         font-size: 18px !important;
         font-family: Montserrat !important;
         font-weight: 600;
         color: #000;
      }
   }

   .redSolidBtn {
      border: solid 1px;
      background: #DE4A4A;
      border-image: linear-gradient(45deg, #DE4A4A, #DE4A4A) 1;
      // border-width: px;
   }

   // .greenBtn {
   //   background: linear-gradient(180deg, #FAFF01 0.03%, #39FF96 86.64%);
   //   padding: 13px 14px !important;

   //   span {
   //     color: #000;
   //   }
   // }

   .greenBorderBtn {
      border: solid 1px;
      border-image: linear-gradient(45deg, #F9FF01, #2FFF9E) 1;
      border-width: 2px;
      background: linear-gradient(0deg, #111E33, #111E33),
      linear-gradient(0deg, rgba(255, 255, 255, 0.06), rgba(255, 255, 255, 0.06));
      color: #fff;
      padding: 13px 14px !important;
      height: 60px !important;
      margin-top: 10px;
      width: 100% !important;
   }

   .redSolidBtn {
      background-color: #DE4A4A;
      padding: 13px 14px !important;
      color: #fff;
   }

   .blackBtn {
      background: linear-gradient(0deg, #111E33, #111E33),
      linear-gradient(0deg, rgba(255, 255, 255, 0.06), rgba(255, 255, 255, 0.06));
      color: #fff;
   }

   button {
      border: none;
      float: left;
      // height: unset;
      margin: 0;
      margin-top: 5px;
      margin-right: 5px;
      font-size: 12px;
      font-family: 'Montserrat', sans-serif;
      width: 140px !important;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      padding: 14px 14px !important;
      border-radius: 2px;
   }
}

.userProfilePaginationWrapper {
   ul {
      li:nth-child(odd) {
         background-color: transparent;
      }
   }
}

.qBotHistoryTitle {
   font-family: "SolinaLight";
   font-size: 32px;
   line-height: 35px;
   text-transform: uppercase;
   color: #fff;
}

.photoModifyModelBtnRow {
   .BlackSkBtn {
      color: #95A6B7;
   }
}

.noDataMsg {
   font-family: "ObjectSans-Regular";
   font-size: 22px;
   font-weight: 400;
   line-height: 28px;
   letter-spacing: -0.01em !important;
   text-align: center;
   font-weight: normal;
   margin-bottom: 15px;
   font-style: italic;
   margin-top: -40px;
   margin-bottom: 56px;
}

.ErrWrapper {
   padding-top: 2px !important;

   > * {
      font-size: 14px !important;
   }
}

.DeleteAccountWrapper {
   margin: 15px 0;
   padding: 0;
   padding-top: 20px;
   border-image: linear-gradient(to right, rgba(243, 221, 27, 0), #2DFEE5, rgba(243, 221, 27, 0)) 1 0 0 0;
   border-top: 1px solid;
   margin-top: 25px;

   h3 {
      font-size: 32px;
      font-family: "SolinaLight", Helvetica, Arial, sans-serif;
      text-align: left;
      line-height: 35px;
      color: #ffffff;
      text-transform: uppercase;
   }

   .DeleteAccountBtnWrapper {
      .DeleteAccountBtn {
         padding: 14px 23px;
         border: none;
         outline: none;
         position: relative;
         z-index: 1;
         border-radius: 5px;
         background: linear-gradient(103.05deg, #F9FF01 7.22%, #2FFF9E 93.09%);
         cursor: pointer;
         transform: skew(-10deg, 0deg);
         text-transform: uppercase;
      }

      .DeleteAccountBtn::before {
         content: "";
         position: absolute;
         left: 1px;
         right: 1px;
         top: 1px;
         bottom: 1px;
         border-radius: 4px;
         background-color: #0E2029;
         z-index: -1;
         transition: 200ms
      }

      .DeleteAccountBtn::after {
         content: attr(data);
         font-size: 16px;
         background: linear-gradient(to left, #00FFA3, #DC1FFF);
         -webkit-background-clip: text;
         color: #fff;
         transition: 200ms
      }

      .DeleteAccountBtn:hover::before {
         opacity: 50%;
         top: 0px;
         right: 0px;
         bottom: 0px;
         left: 0px;
      }

      .DeleteAccountBtn:hover::after {
         color: white;
      }
   }

}

.UserProfileSplashBox {

   .SplashBox {
      min-height: 320px;
      padding-bottom: 40px !important;
      background-image: url(../../assets/images/leader-board-splash.webp);
      background-repeat: no-repeat;
      background-position: center 0%;
   }

   .upChevron {
      width: 24px;
      height: 24px;
      margin: 0 auto;
      margin-bottom: 10px;
      background-image: url(../../assets/images/up-chevron.webp);
      background-repeat: no-repeat;
   }

   .downChevron {
      width: 24px;
      height: 24px;
      margin: 0 auto;
      margin-bottom: 10px;
      background-image: url(../../assets/images/down-chevron.webp);
      background-repeat: no-repeat;
   }

   .outCurcleWrapper {
      width: 144px;
      height: 144px;
      position: relative;
      margin: 0 auto;
   }

   .trophyTextW {
      font-size: 18px;
      color: #ffffff;
      font-family: "ObjectSans-Regular";
      text-transform: uppercase;
      letter-spacing: -0.01em;
      line-height: 24px;
      font-style: italic;
      font-weight: 700;
      min-height: 60px;
   }

   .curcleOut1 {
      margin-top: 27px;
   }

}

.ProfilePicCol img {
   max-width: 220px;
}

.AnswerGrid {
   display: grid;
   grid-template-columns: inherit;
   gap: 10px;

   .AnswerBtn {
      display: block;
      height: 65px;
      // width: 180px !important;
      > button {
         margin: 0 !important;
         height: unset;
         height: 56px !important;
         padding: 10px !important;
         font-size: 13px !important;
         // width: 175px !important;
         > span {
            all: unset;
            display: block;
            font-size: 13px;
            line-height: 18px;
         }
      }

      > button.greenBorderBtn {
         height: 55px !important;
         margin-top: 2px !important;
      }

      > button.greenBtn {
         height: 55px !important;
         margin-top: 2px !important;

         span {
            color: #000;
            font-size: 13px !important;
            // font-weight: bold;
         }
      }
   }
}


.UserProfileModalWrapper {
   position: relative;

   .closeButton {
      position: absolute;
      top: 3px;
      right: 0;
      z-index: 2;

      img {
         display: block;
         width: 14px;
         height: 14px;
         position: absolute;
         z-index: 4;
      }
   }

   .circle1 {
      z-index: 0;
      width: 195px;
      height: 220px;
      position: absolute;
      background-image: url('../../assets/images/circle1.webp');
      background-size: 195px 220px;
      background-repeat: no-repeat;
      background-position: top right;
      top: -24px;
      right: -24px;
   }

   .circle2 {
      z-index: 0;
      width: 98px;
      height: 200px;
      position: absolute;
      background-image: url('../../assets/images/circle2.webp');
      background-size: 98px 200px;
      background-repeat: no-repeat;
      background-position: top right;
      top: 73px;
      left: -24px;
   }

   .circle3 {
      z-index: 0;
      width: 88px;
      height: 88px;
      position: absolute;
      background-image: url('../../assets/images/circle3.webp');
      background-size: 88px 88px;
      background-repeat: no-repeat;
      background-position: bottom left;
      bottom: 32px;
      left: 24px;
   }

   .UserProfileModal {
      img {
         width: fit-content;
         margin: 0 auto;
         display: block;
      }

      h4 {
         text-transform: uppercase;
         font-family: Montserrat;
         font-size: 28px;
         font-style: italic;
         font-weight: 800;
         line-height: 32px;
         letter-spacing: 0.02em;
         text-align: center;
         text-transform: uppercase;
         background: linear-gradient(89.7deg, #17EA85 2.27%, #14D0E6 23.6%, #17EA85 44.41%, #14D0E6 64.17%, #17EA85 82.99%, #14D0E6 99.74%);
         -webkit-background-clip: text;
         -webkit-text-fill-color: transparent;
         background-clip: text;
         text-fill-color: transparent;
         text-shadow: 0px 0px 8px rgba(20, 208, 229, 0.5607843137);
      }

   }
}


@media (min-width: 1199px) {
   .userProfileName {
      > div:nth-child(1) {
         max-width: 485px;
      }

   }
}

@media (max-width: 1199px) {
   .questionSection { //  }
      width: 100%;
      padding: 20px 0px;
   }
   .questionArea {
      padding: 40px 10px !important;
   }
   .FavMatchUpUL {
      li {
         span {
            max-width: 80%;
         }
      }
   }
}

@media (max-width: 1024px) {
   .userProfileWrapper {
      .overviewWrapper {
         display: unset;

         .OverviewBox {
            .mainText {
               > span {
                  font-size: 24px;
               }
            }
         }

         .CreditBox {
            width: 100%;
         }

         > div:nth-child(2), div:nth-child(3) {
            width: 50%;
            float: left;
         }
      }
   }
   .qBotHistoryTitle {
      zoom: .6;
   }
}

@media (max-width: 992px) {
   .userProfileName {
      // max-width: 360px;
      // min-width: 360px;
      > div:nth-child(1) {
         width: 100% !important;
      }
   }
   .DatOfBirthWrapper {
      display: grid;
      grid-template-columns: 1fr 1fr 1fr;
      gap: 10px;
      margin-right: 7px;

      > div {
         width: 100% !important;
      }

      input {
         width: 100%;
      }
   }
   .ProfilePaneWrapper {
      .subTitle {
         // padding-top: 6px !important;
      }
   }
}

@media (min-width: 768px) {
   .HeartIcon {
      position: absolute;
      padding-left: 170px;
      padding-top: 10px;
   }
   .UserProfileSplashBox {
      .curcleOut1 {
         margin-top: 47px !important;
      }
   }
   .ProfilePaneWrapper {
      position: relative;

      .ProfileEditBtn {
         position: absolute;
         top: -8px;
         right: 2px;
      }
   }


}

.ProfilePaneWrapper {
   .TexareatWrapper {
      width: unset !important;
      width: 100% !important;
   }
}

.ProfilePaneWrapper {
   .rightCol {
      input[type=text] {
         height: 53px !important;
      }

      input[type=text]:valid ~ label {
         top: 5px !important;
      }
   }
}

@media (max-width: 768px) {
   .ProfilePaneWrapper {
      padding: 20px 14px !important;

      .rightCol {
         .statsmallTextW {
            min-height: 50px !important;
            padding-bottom: 0 !important;
         }

         .statsmallTexareatWrapper.col-md-9 {
            width: unset;
            width: 100%;
         }
      }

      .SocialSmallIcons {
         width: 30px !important;
         margin-left: 8px !important;
         height: 30px !important;
      }
   }
   .OverviewBox .mainText img {
      max-width: 42px;
      margin-right: 80%;
      margin-bottom: 5px;
   }
   .Text32Solina {
      font-size: 20px;
      line-height: 24px;
   }
   .Text24Solina {
      font-size: 18px;
      line-height: 22px;
   }
   .curcleOut1 {
      .InCurcle {
         width: 60px;
         height: 60px;
         background-size: 100% auto;
         border-radius: 50%;
      }

      .OutCurcle {
         width: 64px;
         height: 64px;
      }
   }

   .curcleOut2 {
      .InCurcle {
         width: 80px;
         height: 80px;
         background-size: 100% auto;
         border-radius: 50%;
      }

      .OutCurcle {
         width: 84px;
         height: 84px;
      }
   }
   .trophyText {
      font-Size: 12px;
      color: #17EA85;
      margin-left: 3px;
   }
   .trophyTextW {
      font-size: 14px;
      color: #ffffff;
      font-family: 'ObjectSans-Regular';
      letter-spacing: -0.01em;
      line-height: 18px;
      word-wrap: break-word;
   }
   .leaderBoard {
      width: 100%;
      border-collapse: collapse;
      font-size: 14px;
      font-family: 'ObjectSans-Regular';

      tr > td:first-child {
         width: 12%;
         text-align: center;

      }

      tr > td:last-child {
         // width: 25%;
         padding: 0;

         > div > div {
            display: flex;

            i {
               margin-top: 9px;
            }

            img {
               width: 16px;
               height: 16px;
               margin-top: 6px;
            }
         }
      }

   }

   .BlackSkBtn {
      background-color: #0E2029;
      box-shadow: 0px 2px 0px rgba(0, 0, 0, 0.51);
      border-radius: 4px;
      transform: skew(-10deg, 0deg);
      border: 1px solid rgba(0, 255, 194, 0.09);
      /* identical to box height, or 129% */
      text-align: center;
      letter-spacing: -0.01em;
      text-transform: uppercase;
      padding: 7px 15px;
      font-weight: 700;
      height: 40px;

      text {
         background-image: linear-gradient(0deg, #FAFF01, #39FF96);
         background-clip: text;
         color: transparent;
         font-family: "ObjectSans-Heavy";
         font-size: 14px;
         line-height: 14px;
      }
   }
   .LoginButton {
      font-family: "ObjectSans-Heavy";
      font-size: 14px;
      line-height: 14px;
      padding: 7px 15px;
      font-weight: 700;
      height: 38px;
   }
   .NumIn {
      margin-bottom: 10px;
      padding-left: 5px !important;
      padding-right: 5px !important;
   }
   .mainText {
      font-size: 18px;
      line-height: 22px;
   }
   .questionArea {
      padding: 20px !important;
   }
   .answerBtnWrapper {
      button {
         display: block;
         clear: both;
         float: none;
         margin-bottom: 8px;
         width: 200px !important;
      }
   }
   .historyContainer {
      .topSectionModal {
         > label {
            font-size: 13px;
         }
      }
   }
   .leaderBoardTitle {
      .shareBtnWrapper {
         top: -11px;
      }
   }

   .botHistory {
      .topSection {
         label {
            font-size: 12px !important;
         }
      }

      .showAllBtn {
         font-size: 14px;
      }
   }
   .UserProfileSplashBox {
      .trophyTextW {
         font-size: 12px;
      }
   }
   .ProfilePicCol {
      button {
         margin-bottom: 22px !important;
      }

      img {
         display: block;
         max-width: 200px;
         margin: 0 auto;
         padding-bottom: 10px;
      }
   }
   .socialWrapper {
      a:first-child {
         > .SocialSmallIcons {
            margin-left: unset !important;
         }
      }
   }
   .socialWrapperRow {
      // display: grid;
      // grid-template-columns: 155px 1fr;
      > div:nth-child(2) {
         margin-top: -22px !important;
      }
   }
   .ProfilePaneWrapper {
      > .rightCol {
         padding-left: 0 !important;
      }
   }

   .AnswerGrid {
      display: grid;
      grid-template-columns: 1fr;
      gap: 10px;

      .AnswerBtn {
         display: block;
         height: 65px;
         // width: 180px !important;
         > button {
            margin: 0 !important;
            height: unset;
            height: 56px !important;
            padding: 10px !important;
            font-size: 16px !important;
            // width: 175px !important;
            > span {
               all: unset;
               display: block;
               font-size: 16px;
               //line-height: 18px;
            }
         }

         > button.greenBorderBtn {
            height: 55px !important;
            margin-top: 2px !important;
         }
      }
   }

}

@media (max-width: 605px) {
   .AccStat {
      color: #95A6B7;
      font-Size: 13px;
      width: 150px;
      line-Height: 20px;
      float: left;
      margin-Top: 0px;
      border-Left: 0px solid #95a6b7;
      margin-left: 0px;
      padding-left: 0px;
   }
}

@media (min-width: 576px) {
   .rightCol {
      .userProfileNameCol.col-9 {
         display: grid;
         grid-template-columns: 1fr;
         gap: 10px;
         text-align: left;
         width: 100% !important;
      }
   }
}

@media (max-width: 576px) {
   .player1Details {
      margin-right: 5px;
   }
   .player2Details {
      margin-left: 5px;
   }
   .teamBall, .teamBat {
      display: none;
   }
   .leftPlayer {
      align-items: center;

      img {
         height: 40px;
      }
   }
   .rightPlayer {
      align-items: center;

      img {
         height: 40px;
      }
   }
   .midleDots {
      margin: 0 5px;
      padding: 0;
      height: 16px;
      width: 16px;
      position: relative;

      img {
         height: 8px;
         position: absolute;
         top: 4px;
         left: 5px;
      }
   }
   .playerHistory {
      .topSection {
         margin-bottom: 0;

         img {
            display: none;
         }

         label {
            margin: 0;
         }
      }

      .mobLbl {
         display: flex;
         justify-content: center;
      }

      .playerHistoryWrapper li {
         padding: 20px 10px;
      }

      .botHistory {
         h2 {
            font-size: 16px;
         }
      }

      .playerHistory {
         .topSection {
            > img {
               display: none;
            }
         }
      }

      .historyContainer {
         .topSectionModal {
            > label {
               position: absolute;
               left: 50%;
               transform: translate(-50%, 0);
               top: 70px;
            }
         }
      }

      .ProfilePaneWrapper {
         display: grid;
         grid-template-columns: 100px 1fr;

         > div {
            padding-left: unset !important;
            min-width: 100%;
         }
      }

      .userProfileNameCol {
         width: unset;
         width: 100% !important;

         .rightCol {
            margin-left: 20px !important;
         }
      }
   }
   .botHistory {
      .playerHistoryWrapper {
         > ul {
            li {
               padding: 20px 15px;
            }
         }
      }
   }
   .leaderBoardTableWrapper {
      width: 100%;
      overflow-x: scroll;

      thead {
         tr {
            th {
               font-size: 12px;
            }

            th:nth-child(1), th:nth-child(2) {
               width: 40px !important;
               min-width: 40px !important;
               background-color: rgb(12 25 38 /1);
               width: 40px !important;
               min-width: 40px !important;
               position: sticky;
               left: 0 !important;
               z-index: 1;
            }

            th:nth-child(2) {
               left: 40px;
            }
         }
      }

      tr {
         td * {
            font-size: 12px;
         }

         td:nth-child(1), td:nth-child(2) {
            width: 40px !important;
            min-width: 40px !important;
            position: sticky;
            left: 0;
            background-color: rgb(19 31 48 / .7);
         }

         td:nth-child(2) {
            label {
               max-width: 100px;
               white-space: nowrap;
               overflow: hidden;
               text-overflow: ellipsis;
            }
         }
      }

      .highlightedRow {
         td:nth-child(1), td:nth-child(2) {
            width: 40px !important;
            min-width: 40px !important;
            position: sticky;
            left: 0;
            background-color: #ffffff52 !important;
         }
      }
   }
   .ProfilePaneWrapper {
      h3 {
         font-size: 20px;
         margin-top: 10px !important;
      }
   }
   .DeleteAccountWrapper {
      margin: 40px 0 !important;
      margin-bottom: 15px !important;
   }
   .socialWrapper {
      display: block !important;
   }
   .rightCol {
      .statsmallText {
         > div {
            display: block;
            width: 100%;
            border: none;
            padding-left: 0;
         }
      }

      > .userProfileNameCol {
         width: 100% !important;
      }
   }
   .FavMatchUpUL {
      li {
         span {
            max-width: 180px;
         }
      }
   }
}


@media (max-width: 768px) {
   .photoModifyModelBtnRow {
      display: grid !important;
      grid-template-columns: 1fr !important;
      gap: 15px !important;

      .LoginButton {
         margin-left: -13px;
      }
   }
}

.BuyButton {
   background: linear-gradient(95.34deg, #FAFF00 6.28%, #00FFC2 111.13%);
   box-shadow: 0px 2px 0px rgba(0, 0, 0, 0.51);
   border-radius: 4px;
   transform: skew(-10deg, 0deg);
   border: 1px solid #2FFF9E;
   /*border-image-source: linear-gradient(103.05deg, #2FFF9E 7.22%, #F9FF01 93.09%);*/
   text-align: center;
   letter-spacing: -0.01em;
   padding: 9px 27px;
   /*font-weight: 700;
   width: 100%;*/
   height: 42px;
   font-family: "ObjectSans-Heavy", Helvetica, Arial, sans-serif;
   font-size: 16px;
   /*line-height: 20px;*/
   text-transform: uppercase;
   margin-left: auto;
   margin-right: auto;
   margin-top: 12px;
   // :hover {
   //   background: linear-gradient(95.34deg, #00FFC2 6.28%, #FAFF00 111.13%);
   // }

   :visited {
      background: linear-gradient(95.34deg, #B0FFCA 6.28%, #F2FF7E 111.13%);
   }

   img {
      transform: skew(10deg, 0deg);
      margin-left: 15px;
      margin-top: -5px;
   }
}

.BuyButton:hover {
   background: linear-gradient(95.34deg, #00FFC2 6.28%, #FAFF00 111.13%);
}

.buyNumBoxYellow {
   width: 90%;
   height: 40px;
   background-color: rgba(243, 222, 27, 0.13);
   border-radius: 14px;
   border: 1px solid rgba(243, 222, 27, 0.32);
   margin-left: auto;
   margin-right: auto;
   padding: 5px 15px;
   font-size: 18px;
   font-weight: 700;
   font-style: italic;

   .div1 {
      width: 50%;
      border-right: 1px solid rgba(243, 222, 27, 1);
      float: left;
   }

   .div2 {
      width: 50%;
      float: left;
   }
}

@import '../../../scss/globals/global';
@import '../../../scss/utilities/mixins';

.creditsWrapper {
   width: fit-content;
   display: inline-block;
   font-size: 14px;
   margin: 0 auto;

   img {
      transform: skew(10deg, 0deg) !important;
      margin: 0 8px;
   }

   span {
      color: #17EA85;
      font-size: 14px;
      transform: skew(10deg, 0deg) !important;
   }

   label {
      text-transform: capitalize;
   }

   .credits {
      padding: 6px 22px;
      border: none;
      outline: none;
      position: relative;
      z-index: 1;
      border-radius: 2px;
      background: linear-gradient(103.05deg, #F9FF01 7.22%, #2FFF9E 93.09%);
      //    cursor: pointer;
      transform: skew(-9deg, 0deg);
      text-transform: uppercase;
      margin-right: 10px;
   }

   .credits::before {
      content: "";
      position: absolute;
      left: 1px;
      right: 1px;
      top: 1px;
      bottom: 1px;
      border-radius: 4px;
      background-color: #0E2029;
      z-index: -1;
      transition: 200ms
   }

   .credits::after {
      content: attr(data);
      font-size: 16px;
      background: linear-gradient(to left, #00FFA3, #DC1FFF);
      -webkit-background-clip: text;
      color: #fff;
      transition: 200ms
   }
}

.modalContentContainer {
   box-shadow: 0px 0px 65px 0px #80ff5f6e;
}

.PaymentConfirmmodal {
   display: block;
   background: rgba(0, 0, 0, 0.2588235294);
   backdrop-filter: blur(5px);
   -webkit-backdrop-filter: blur(5px);

   .CreditPurchaseModal {
      width: 100%;
      max-width: 580px;

      .closeButton {
         position: absolute;
         z-index: 1;
         top: 10px;
         right: 15px;
      }

      .ModalHeader {
         background: rgba(17, 30, 51, 1);
         margin-bottom: 40px;

         h4 {
            font-size: 20px;
            font-family: Montserrat;
            padding: 20px;

            img {
               margin: -6px 10px 0 0;
            }
         }
      }
   }

   .CreditRate {
      width: fit-content;
      margin: 0 auto;

      img {
         width: 24px;
         height: 24px;
      }

      * {
         padding: 3px;
      }

      p {
         text-align: center;

         a {
            color: #17ea85;
            text-decoration: underline;
            display: unset;
         }
      }
   }

   .OmniCardWrapper {
      margin-top: 15px;

      .OmniCard {
         display: block;
         position: relative;
         width: calc(50% - 13px);
         float: left;
         padding: 5px 10px;
         background-color: #062024;
         border: solid 1px #102531;
         margin: 6px;
         border-radius: 4px;
         margin-top: 20px;

         * {
            padding: 4px;
         }

         img {
            width: 32px;
            height: 32px;
         }

         > div {
            display: flex;
            justify-content: center;
         }
      }

      .OmniCardClicked {
         border-color: rgba(23, 234, 133, 1);
         cursor: pointer;
      }

      label, span {
         font-size: 14px;
      }
   }

   fieldset {
      border-top: solid 1px #1b3341;
      margin: 30px 0px;

      legend {
         all: unset;
         margin: 0 auto;
         color: rgba(149, 166, 183, 1);
         font-size: 16px;
         padding-left: 10px;
         padding-right: 10px;
      }

      .OmniCal {
         margin-top: 30px;
         display: grid;
         grid-template-columns: 1fr 30px 1fr;
         gap: 5px;

         input {
            width: 100%;
            padding: 10px;
            border: solid 1px rgb(28 45 74);
            background-color: rgba(17, 30, 51, 1);
            border-radius: 4px;
         }

         input:focus-visible {
            border: solid 1px rgb(50 68 100) !important;
            outline: none;
         }

         span {
            display: block;
            text-align: center;
            color: rgba(149, 166, 183, 1);
            padding-top: 42px;
         }

         label {
            color: rgba(149, 166, 183, 1);
         }
      }
   }

   .photoModifyModelBtnRow {
      display: block !important;
      width: 90% !important;
      margin-left: auto;
      margin-right: auto;

      .BlackSkBtn {
         float: left;
         background: linear-gradient(103.05deg, #F9FF01 7.22%, #2FFF9E 93.09%);
         border: 0 !important;

         span {
            // background-image: linear-gradient(0deg, #FAFF01, #39FF96);
            // font-size: 16px;
            // line-height: 20px;
            // -webkit-background-clip: text;
            // background-clip: text;
            // color: transparent;
            // background-image: linear-gradient(90deg, #FAFF01, #39FF96);

            font-size: 16px;
            line-height: 20px;
            background-color: #000 !important;
            padding: 15px 27px;
            border-radius: 4px;
            // -webkit-background-clip: text;
            // background-clip: text;
            // color: transparent;
         }
      }

      .LoginButton {
         float: left;
      }

      .LoginButton.ProceedButton {
         float: right;
      }

   }
}

.Radial1 {
   @include gradientGeneralLeft(900px, 900px, -550px, 550px, $Blue03, 32.65%, 32.65%, $GradPosCenter);
   mix-blend-mode: normal;
   opacity: 0.14;
   z-index: -1;
}

.Radial2 {
   @include gradientGeneralRight(500px, 500px, 0, 1050px, $Blue03, 32.65%, 32.65%, $GradPosRight);
   mix-blend-mode: normal;
   opacity: 0.14;
   z-index: -1;
}


// p {
//    text-align: center;
//    font-size: 18px;
//    font-family: 'ObjectSans-Regular';
// }


.OverviewBox {
   @include overview-box;
   padding-left: 5px;

   img {
      width: auto;
   }
}

.leaderBoardTitle {
   position: relative;

   .shareBtnWrapper {
      position: absolute;
      top: -4px;
      right: 0;
   }
}

.borderTop1 {
   @include yellow-gradient;
}

.borderTop2 {
   @include green-gradient;
}

.borderTop3 {
   @include purple-gradient;
}


.SplashBox {
   height: 320px;
   background-image: url(https://storage.googleapis.com/meta11/serviceplatform/splash-bg.png);
   background-position: center 0%;
}

.marks {
   width: 56px;
   height: 28px;
   background: #172842;
   border: 1px solid #253342;
   border-radius: 4px;
   margin-left: auto;
   margin-right: auto;
}


.RadioBox {
   width: 20%;
   float: left;
   text-align: center;
   padding-top: 21px;
}

.radioFill:checked {
   background-color: #04151d;
   border-color: #17EA85;
   background-image: url(https://storage.googleapis.com/meta11/serviceplatform/radioCheckGreen.png) !important;
   background-repeat: no-repeat;
   background-size: 60%;
}

.radioFill {
   border: 1px solid #17EA85 !important;
   width: 1.3em;
   height: 1.3em;
   background-color: #04151d;
   cursor: pointer;
}


.BlackSkBtn text {

   background-image: linear-gradient(0deg, #FAFF01, #39FF96);
   background-clip: text;
   color: transparent;
   font-family: "ObjectSans-Heavy";
   font-size: 16px;
   line-height: 18px;
}

.GrayButton {
   @include GrayButtonAtributes($grayButtonColor1, $grayButtonColor2, $buttonColor1, $buttonColor2, $BtnBorderRadius, $Btntransform, $BtntextTransform, $BtnPaddingGray);
   @include css3Anim();
   border: 1px solid $buttonColor1;
   background-clip: text;
   color: transparent;
   padding: 12px 20px;
   border-radius: 5px;

   text {
      @include GrayButtonText($buttonColor1, $buttonColor2, $BtnfontSize, $BtnlineHeight);
      -webkit-background-clip: text;
      background-clip: text;
      color: transparent;
      font-family: $buttonTextFont;
   }
}

.GrayButton:hover {
   background: $grayButtonHover;
}

.BlackSkBtn {
   background-color: linear-gradient(0deg, #0E2029, #0E2029) padding-box2029;
   box-shadow: 0px 2px 0px rgba(0, 0, 0, 0.51);
   border-radius: 4px;
   transform: skew(-10deg, 0deg);
   border: 1px solid rgba(0, 255, 194, 0.09);
   /* identical to box height, or 129% */
   text-align: center;
   letter-spacing: -0.01em;
   text-transform: uppercase;
   padding: 1px;
   font-weight: 700;
   height: 54px;

   .BlackSkBtn text {
      color: linear-gradient(0deg, #FAFF01, #39FF96) 5A6B7;
   }

   :visited {
      box-shadow: 0px 2px 0px rgba(0, 0, 0, 0.51);
      cursor: not-allowed;
   }

   :disabled,
   .BlackSkBtn[disabled] {
      background: #0E2029;
      box-shadow: 0px 2px 0px rgba(0, 0, 0, 0.51);
      backdrop-filter: blur(45px);
   }
}

.BlackSkBtn:hover {
   background: #203455;
}

.LoginButton {
   background: linear-gradient(95.34deg, #FAFF00 6.28%, #00FFC2 111.13%);
   box-shadow: 0px 2px 0px rgba(0, 0, 0, 0.51);
   border-radius: 4px;
   transform: skew(-10deg, 0deg);
   border: 1px solid #2FFF9E;
   /*border-image-source: linear-gradient(103.05deg, #2FFF9E 7.22%, #F9FF01 93.09%);*/
   text-align: center;
   letter-spacing: -0.01em;
   padding: 10px 27px;
   /*font-weight: 700;
   width: 100%;*/
   height: 48px;
   margin-top: 1px;
   font-family: "ObjectSans-Heavy", Helvetica, Arial, sans-serif;
   font-size: 16px;
   line-height: 18px;
   text-transform: uppercase;
   margin-left: 10px;
   // :hover {
   //   background: linear-gradient(95.34deg, #00FFC2 6.28%, #FAFF00 111.13%);
   // }

   :visited {
      background: linear-gradient(95.34deg, #B0FFCA 6.28%, #F2FF7E 111.13%);
   }
}

.LoginButton:hover {
   background: linear-gradient(95.34deg, #00FFC2 6.28%, #FAFF00 111.13%);
}

.modalBlur {
   background-color: #00000063;
}

//  .closeButton {
//     border: 0px;
//     background-color: #00000000;
//  }

// .CreditPurchaseModal {
//    .modalContainer {
//       .modalTitle {
//          font-family: 'SolinaLight';
//          font-size: 20px;
//          color: #ffffff;
//          text-transform: uppercase;
//          line-height: 40px;
//       }
//    }

//    .photoModifyModelBtnRow {
//       display: flex;
//       width: fit-content;
//       margin: 0 auto;

//       > a {
//          float: right;
//          margin-right: -25px;

//          > button:hover {
//             -webkit-text-fill-color: unset !important;
//             cursor: pointer;
//          }
//       }

//       > a:hover {
//          -webkit-text-fill-color: unset !important;
//          color: #000;

//       }
//    }
// }


.DeleteAccountWrapper {
   margin: 40px 0;
   padding: 0;

   h3 {
      font-size: 32px;
      font-family: "SolinaLight", Helvetica, Arial, sans-serif;
      text-align: left;
      line-height: 35px;
      color: #ffffff;
      text-transform: uppercase;
   }

   .DeleteAccountBtnWrapper {
      .DeleteAccountBtn {
         padding: 14px 23px;
         border: none;
         outline: none;
         position: relative;
         z-index: 1;
         border-radius: 5px;
         background: linear-gradient(103.05deg, #F9FF01 7.22%, #2FFF9E 93.09%);
         cursor: pointer;
         transform: skew(-10deg, 0deg);
         text-transform: uppercase;
      }

      .DeleteAccountBtn::before {
         content: "";
         position: absolute;
         left: 1px;
         right: 1px;
         top: 1px;
         bottom: 1px;
         border-radius: 4px;
         background-color: #0E2029;
         z-index: -1;
         transition: 200ms
      }

      .DeleteAccountBtn::after {
         content: attr(data);
         font-size: 16px;
         background: linear-gradient(to left, #00FFA3, #DC1FFF);
         -webkit-background-clip: text;
         color: #fff;
         transition: 200ms
      }

      .DeleteAccountBtn:hover::before {
         opacity: 50%;
         top: 0px;
         right: 0px;
         bottom: 0px;
         left: 0px;
      }

      .DeleteAccountBtn:hover::after {
         color: white;
      }
   }

}

.UserProfileSplashBox {

   .SplashBox {
      min-height: 320px;
      padding-bottom: 40px !important;
      background-image: url(../../../assets/images/leader-board-splash.webp);
      background-repeat: no-repeat;
      background-position: center 0%;
   }

   .upChevron {
      width: 24px;
      height: 24px;
      margin: 0 auto;
      margin-bottom: 10px;
      background-image: url(../../../assets/images/up-chevron.webp);
      background-repeat: no-repeat;
   }

   .downChevron {
      width: 24px;
      height: 24px;
      margin: 0 auto;
      margin-bottom: 10px;
      background-image: url(../../../assets/images/down-chevron.webp);
      background-repeat: no-repeat;
   }

   .outCurcleWrapper {
      width: 144px;
      height: 144px;
      position: relative;
      margin: 0 auto;
   }

   .trophyTextW {
      font-size: 18px;
      color: #ffffff;
      font-family: "ObjectSans-Regular";
      text-transform: uppercase;
      letter-spacing: -0.01em;
      line-height: 24px;
      font-style: italic;
      font-weight: 700;
      min-height: 60px;
   }

   .curcleOut1 {
      margin-top: 27px;
   }

}

.CreditPurchaseModal {
   position: relative;

   .closeButton {
      position: absolute;
      top: 15px;
      right: 16px;
      z-index: 2;

      img {
         display: block;
         width: 14px;
         height: 14px;
         position: absolute;
         z-index: 4;
      }
   }

   .circle1 {
      z-index: 0;
      width: 195px;
      height: 220px;
      position: absolute;
      background-image: url('../../../assets/images/circle1.webp');
      background-size: 195px 220px;
      background-repeat: no-repeat;
      background-position: top right;
      top: -24px;
      right: -24px;
   }

   .circle2 {
      z-index: 0;
      width: 98px;
      height: 200px;
      position: absolute;
      background-image: url('../../../assets/images/circle2.webp');
      background-size: 98px 200px;
      background-repeat: no-repeat;
      background-position: top right;
      top: 73px;
      left: -24px;
   }

   .circle3 {
      z-index: 0;
      width: 88px;
      height: 88px;
      position: absolute;
      background-image: url('../../../assets/images/circle3.webp');
      background-size: 88px 88px;
      background-repeat: no-repeat;
      background-position: bottom left;
      bottom: 32px;
      left: 24px;
   }

   .UserProfileModal {
      img {
         width: fit-content;
         max-width: 240px;
         margin: 0 auto;
         display: block;
      }

      .TitleWrapper h4 {
         text-transform: uppercase;
         font-family: Montserrat;
         font-size: 28px;
         font-style: italic;
         font-weight: 800;
         line-height: 32px;
         letter-spacing: 0.02em;
         text-align: center;
         text-transform: uppercase;
         background: linear-gradient(89.7deg, #17EA85 2.27%, #14D0E6 23.6%, #17EA85 44.41%, #14D0E6 64.17%, #17EA85 82.99%, #14D0E6 99.74%);
         -webkit-background-clip: text;
         -webkit-text-fill-color: transparent;
         background-clip: text;
         text-fill-color: transparent;
         text-shadow: 0px 0px 8px rgba(20, 208, 229, 0.5607843137);
         padding-top: 30px !important;
      }

   }

   .SaveTag {
      color: rgba(222, 74, 74, 1);
      background-color: #291421;
      width: fit-content;
      padding-left: 10px !important;
      padding-right: 10px !important;
      position: absolute;
      top: -14px;
      left: 50%;
      transform: translate(-50%, 0);
      font-size: 12px;
   }
}

//Created Team ConfirmationModal

.CreatedTeamConfirmationModal {
   .modalContentContainer {
      padding: 40px;
   }
}

@mixin FantacyCard {

   position: relative;
   background: linear-gradient(0deg, #224755, #068F7A, #224755);
   border-radius: 8px;

   width: 160px;
   max-width: 160px;
   padding: 2px;
   .CardBackground {
      background-color: #0A1627;
      border-radius: 8px;
      padding: 10px;
      position: relative;
      min-height: 206px;

      > div {
         justify-content: center;
         padding-top: 12px;
      }

      h4 {
         color: red;
         width: 115px;
         white-space: nowrap;
         overflow: hidden;
         text-overflow: ellipsis;
      }
   }
   .ProfilePic {
      position: relative;
      width: 80px;
      height: 80px;
      border-radius: 50%;
      background-size: cover;
      background-repeat: no-repeat;
      background-color: #010E14;
      background-image: url('../../../assets/images/PlayerProfilePic.webp');
      background-repeat: no-repeat;
   }
   .EmptyProfilePic {
      background-image: url('../../../assets/images/PlayerProfilePic.webp');
      background-repeat: no-repeat;
   }
   .TrashIcon {
      position: absolute;
      top: 5px;
      right: 20px;
   }
   .BtnRow {
      display: flex;
      justify-content: center;

      button {

         font-family: 'ObjectSans-Regular';
         border: solid 1px #17EA85;
         background-color: #152D39;
         color: #17EA85;
         border-radius: 4px;
         margin-bottom: 4px;
         font-size: 14px;
      }

      button:disabled {
         filter: grayscale(1);
      }
   }

   .TitleRow {
      justify-content: center;

      span {
         color: #17EA85;
         font-size: 14px;
         text-align: center;
         margin-top: -4px;
         margin-bottom: 4px;
      }

      h4 {
         font-family: 'ObjectSans-Regular';
         font-size: 12px;
         font-weight: 700;
         font-style: italic;
         text-transform: uppercase;
         text-align: center;
         padding: 0;
         margin: 0;
         padding-top: 10px !important;
         color: #fff;
         background: unset;
         text-shadow: none;
         -webkit-text-fill-color: unset;
      }
   }
   .HistoryInfoIconBtn {
      position: absolute;
      left: -7px;
      top: 7px;
      width: 20px;
      height: 20px;
   }
   .HistoryInfoIconBtn:hover {
      opacity: .8;
   }
   .MoreInfoIconBtn {
      position: absolute;
      right: 11px;
      top: 7px;
      width: 20px;
      height: 20px;
   }
   .MoreInfoIconBtn:hover {
      opacity: .8;
   }

}

.ModalButtonRow {
   display: flex;
   width: fit-content;
   justify-content: center;
   margin-bottom: 30px;

   button text {
      color: #000 !important;
   }
}

.ViewFantacyTeam {
   .CreditPurchaseModal {
      max-width: 800px !important;
   }

   .CaptainRow {
      margin-top: 10px;
      display: flex;
      gap: 16px;
      justify-content: center;

      .Card {
         @include FantacyCard;

         h4 {
            margin-bottom: 2px
         }
      }
   }

   .OtherPlayers {
      .Card {
         @include FantacyCard;

         .CardBackground {
            min-height: 170px;
         }

         h4 {
            margin-bottom: 4px;
         }
      }

      > div {
         margin-top: 16px;
         display: flex;
         gap: 16px;
         flex-flow: row wrap;
         justify-content: center;
      }
   }

   .TotalEarningRow {
      display: flex;
      align-items: center;

      .TotalEarningWrapper {
         display: flex;
         background-image: url('../../../assets/images/backgrounds/TotalEarning.webp');
         background-repeat: no-repeat;
         width: 435px;
         height: 126px;
         margin: 0 auto;
         align-items: center;
         justify-content: center;

         .TotalEarning {
            display: flex;
            width: 330px;
            height: 30px;
            justify-content: center;

            .Title {
               text-transform: uppercase;
               font-size: 16px;
               font-style: italic;
               font-weight: 700;
               margin-right: 5px;
               color: #fff;
            }

            div {
               font-size: 14px;
               display: flex;
               align-items: center;
               font-weight: 500;
               color: #fff;

               img {
                  margin: 0 4px;
                  width: 24px;
                  height: 24px;
               }
            }
         }
      }

      .ModalButtonRow {
         display: flex;
         width: fit-content;
         justify-content: center;
         margin-bottom: 30px;

         button text {
            color: #000 !important;
         }
      }
   }

   .PlayerPoints {
      display: flex;
      right: -10px;
      bottom: 0;
      width: 28px;
      height: 28px;
      background: linear-gradient(95.34deg, #FAFF00 6.28%, #00FFC2 111.13%);
      justify-content: center;
      align-items: center;
      border-radius: 50%;
      font-size: 12px;
      font-style: italic;
      font-weight: 700;
      color: #000;
      justify-content: center;
      position: absolute;
   }
}

.NoFantasyTeamModalWrapper {
   padding-top: 40px;
   padding-bottom: 30px;

   .NoFantasyTeamTitle {
      text-align: center;
      text-transform: uppercase;
      padding: 20px 0;
   }

   .NoFantasyCloseBtn {
      width: fit-content !important;
   }
}

@media (max-width: 992px) {
   .ProfilePaneWrapper {
      .subTitle {
         padding-top: 6px !important;
      }
   }
}

@media (max-width: 768px) {
   .photoModifyModelBtnRow {
      display: grid !important;
      grid-template-columns: 1fr !important;
      gap: 15px !important;

      .LoginButton {
         margin-left: -13px;
      }
   }
   .CreditPurchaseModal {
      > .modalContentContainer {
         width: 95vw;

         .OmniCardWrapper {
            position: relative;

            .OmniCard {
               position: relative;
               width: 98%;
               margin: 0 auto;
               margin-bottom: 15px;

               > div {
                  width: fit-content;
                  margin: 0 auto;
                  margin-top: 4px;
               }
            }
         }

         fieldset {
            .OmniCal {
               display: block;

               > span {
                  display: none;
               }

               > div {
                  display: block;
                  margin-bottom: 10px;
               }
            }
         }
      }
   }
}


@media (max-width: 576px) {
   .TotalEarningRow {
      > div {
         padding: 0;
         height: 110px;
      }

      .TotalEarningWrapper {
         background-image: url('../../../assets/images/backgrounds/TotalEarningMobile.webp');
         width: 345px;
         height: 100px;
      }
   }
   .TournamentFantasySection .FantacyTeamContainer .SelectedPlayersCol .OtherPlayers .Card {
      width: 159px;
   }
}

@media (max-width: 438px) {
   .TotalEarningRow {
      .TotalEarningWrapper {
         background-position: -22px 0;
         width: 302px;
         height: 100px;
      }
   }
   .CaptainRow, .OtherPlayers {
      .Card {
         scale: .9 !important;
      }
   }
   .OtherPlayers {
      padding-left: 0;
      padding-right: 0;

      .Wrapper {
         gap: 15px;
      }
   }
   .TournamentFantasySection .FantacyTeamContainer .SelectedPlayersCol .OtherPlayers {
      .Wrapper {
         gap: 15px;

         .Card {
            width: calc(90% / 2);
         }
      }
   }
   .ViewFantacyTeam .OtherPlayers .Card {
      max-width: 145px !important;
   }
}



@import '../../../scss/globals/global';
@import '../../../scss/utilities/mixins';

.modalContentContainer {
   box-shadow: 0px 0px 65px 0px #80ff5f6e;

   > div {
      min-height: 400px;
   }
}

.FantasyMatchHistoryModal {
   display: block;
   background: rgba(0, 0, 0, 0.2588235294);
   backdrop-filter: blur(5px);
   -webkit-backdrop-filter: blur(5px);

   .CreditPurchaseModal {
      width: 100%;
      max-width: 800px;

      .closeButton {
         position: absolute;
         z-index: 1;
         top: 4px;
         right: 10px;
      }
   }

   .ProfilePicWrapper {
      width: 494px;
      background-image: url("../../../assets/images/backgrounds/splashbg.webp");
      background-repeat: no-repeat;
      display: flex;
      margin: 0 auto;
      padding: 32px 32px 40px 32px;
      position: relative;
      justify-content: center;

      .ProfilePicBorder {
         background: linear-gradient(180deg, #FAFF01 0.03%, #39FF96 86.64%);
         width: 140px;
         height: 140px;
         border-radius: 50%;
         display: flex;
         justify-content: center;
         align-items: center;

         .ProfilePic {
            background-image: url("https://storage.googleapis.com/meta11/players/LeiWei.png");
            background-color: #010e14;
            width: 138px;
            height: 138px;
            border-radius: 50%;
            background-size: contain;
            background-repeat: no-repeat;
            background-position: center;
         }
      }
   }

   .ModalButtonRow {
      margin-top: 35px;
      margin-bottom: 35px;

      button {
         padding: 14px;
         font-size: 16px;
         width: 165px;
         margin: 0 auto;
      }
   }

   @mixin eMulticolorFull {
      position: absolute;
      background-image: url("../../../assets/images/backgrounds/MulticolorFullEllipse.webp");
      background-repeat: no-repeat;
      background-size: 100% 100%;
   }
   @mixin eMulticolorHalf {
      position: absolute;
      background-image: url("../../../assets/images/Ellipse-half.webp");
      background-repeat: no-repeat;
      background-size: 100% 100%;
   }

   .Radial1 {
      @include eMulticolorFull;
      width: 125px;
      height: 125px;
      top: 138px;
      right: 78px;
      mix-blend-mode: normal;

   }

   .Radial2 {
      @include eMulticolorHalf;
      width: 128px;
      height: 254px;
      top: 110px;
      mix-blend-mode: normal;
      transform: rotate(180deg);
   }

   .Radial3 {
      @include eMulticolorFull;
      width: 163px;
      height: 163px;
      right: 50px;
      bottom: 74px;
      z-index: 0;
      mix-blend-mode: normal;

   }

   .EdgeRadial1 {
      position: absolute;
      background-image: url("../../../assets/images/backgrounds/EllipsePinkBackground.webp");
      background-repeat: no-repeat;
      background-size: 100% 100%;

      width: 315px;
      height: 212px;
      top: 0;
      right: 0;
      z-index: 0;
   }

   .EdgeRadial2 {
      position: absolute;
      background-image: url("../../../assets/images/backgrounds/EdgeBlueEllipse2.webp");
      background-repeat: no-repeat;
      background-size: 100% 100%;

      width: 250px;
      height: 250px;
      bottom: 0;
      left: 0;
      z-index: 0;

   }

   .PlayerNameWrapper {
      h4 {
         font-family: 'ObjectSans-Regular';
         text-transform: uppercase;
         font-size: 24px;
         font-weight: 700;
         font-style: italic;
      }
   }
}

.OverviewBox {
   @include overview-box;
   padding-left: 5px;

   img {
      width: auto;
   }
}

.leaderBoardTitle {
   position: relative;

   .shareBtnWrapper {
      position: absolute;
      top: -4px;
      right: 0;
   }
}

.borderTop1 {
   @include yellow-gradient;
}

.borderTop2 {
   @include green-gradient;
}

.borderTop3 {
   @include purple-gradient;
}

.SplashBox {
   height: 320px;
   background-image: url(https://storage.googleapis.com/meta11/serviceplatform/splash-bg.png);
   background-position: center 0%;
}

.marks {
   width: 56px;
   height: 28px;
   background: #172842;
   border: 1px solid #253342;
   border-radius: 4px;
   margin-left: auto;
   margin-right: auto;
}

.RadioBox {
   width: 20%;
   float: left;
   text-align: center;
   padding-top: 21px;
}

.radioFill:checked {
   background-color: #04151d;
   border-color: #17EA85;
   background-image: url(https://storage.googleapis.com/meta11/serviceplatform/radioCheckGreen.png) !important;
   background-repeat: no-repeat;
   background-size: 60%;
}

.radioFill {
   border: 1px solid #17EA85 !important;
   width: 1.3em;
   height: 1.3em;
   background-color: #04151d;
   cursor: pointer;
}

.BlackSkBtn text {
   background-image: linear-gradient(0deg, #FAFF01, #39FF96);
   background-clip: text;
   color: transparent;
   font-family: "ObjectSans-Heavy";
   font-size: 16px;
   line-height: 18px;
}

.GrayButton {
   @include GrayButtonAtributes($grayButtonColor1, $grayButtonColor2, $buttonColor1, $buttonColor2, $BtnBorderRadius, $Btntransform, $BtntextTransform, $BtnPaddingGray);
   @include css3Anim();
   border: 1px solid $buttonColor1;
   background-clip: text;
   color: transparent;
   padding: 12px 20px;
   border-radius: 5px;

   text {
      @include GrayButtonText($buttonColor1, $buttonColor2, $BtnfontSize, $BtnlineHeight);
      -webkit-background-clip: text;
      background-clip: text;
      color: transparent;
      font-family: $buttonTextFont;
   }
}

.GrayButton:hover {
   background: $grayButtonHover;
}

.BlackSkBtn {
   background-color: linear-gradient(0deg, #0E2029, #0E2029) padding-box2029;
   box-shadow: 0px 2px 0px rgba(0, 0, 0, 0.51);
   border-radius: 4px;
   transform: skew(-10deg, 0deg);
   border: 1px solid rgba(0, 255, 194, 0.09);
   /* identical to box height, or 129% */
   text-align: center;
   letter-spacing: -0.01em;
   text-transform: uppercase;
   padding: 1px;
   font-weight: 700;
   height: 54px;

   .BlackSkBtn text {
      color: linear-gradient(0deg, #FAFF01, #39FF96) 5A6B7;
   }

   :visited {
      box-shadow: 0px 2px 0px rgba(0, 0, 0, 0.51);
      cursor: not-allowed;
   }

   :disabled,
   .BlackSkBtn[disabled] {
      background: #0E2029;
      box-shadow: 0px 2px 0px rgba(0, 0, 0, 0.51);
      backdrop-filter: blur(45px);
   }
}

.BlackSkBtn:hover {
   background: #203455;
}

.PowerBoosterPurchaseModal {
   h2 {
      text-transform: uppercase;
      background: linear-gradient(89.7deg, #17EA85 2.27%, #14D0E6 23.6%, #17EA85 44.41%, #14D0E6 64.17%, #17EA85 82.99%, #14D0E6 99.74%);
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
      background-clip: text;
      text-fill-color: transparent;
      text-shadow: 0px 0px 3px rgba(20, 208, 229, 0.5607843137);
      margin-top: 10px;
      font-size: 28px;
      text-align: left;
      margin-top: 35px;
      font-family: "Montserrat";
      font-weight: 800;
      font-style: italic;
      text-align: center;

      width: calc(100% - 30px);
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
   }

   p {
      width: 100%;
      text-align: center;
      font-size: 16px;
   }

   .BoostWrapper {
      display: flex;
      justify-content: left;
      max-width: 685px;
      width: fit-content;
      margin: auto;
      margin-top: 30px;
      gap: 15px;
      overflow-x: auto;
      padding-bottom: 30px;

      .BoostCard {
         min-width: 136px;
         max-width: 136px;
         height: 146px;
         border-radius: 10px;
         background: linear-gradient(180deg, #222C3E 0%, #0B1C35 100%);
         position: relative;
         margin-top: 6px;

         // border-image: linear-gradient(90deg, #f3dd1b00, #00B1ED, #f3dd1b00) 1 0 1 0;
         border-bottom: 2px solid;

         .CardHeader {
            padding: 6px 10px;
            display: flex;
            justify-content: center;
            align-items: center;

            label {
               font-size: 12px;
               padding: 0;
               margin: 0;
               margin-bottom: 4px;
               display: flex;
               justify-content: center;
            }

            img {
               margin-right: 3px;
            }

            select {
               background-color: #36424d;
               color: #fff;
               border-radius: 4px;
               padding: 2px 4px;
               width: 100px;
            }

            option:hover {
               cursor: pointer;
            }

            option:focus {
               cursor: pointer;
            }

            select:hover {
               cursor: pointer;
            }

            > div {
               display: flex;
               flex-direction: column;
               justify-content: center;
            }
         }

         .CardBody {
            padding: 6px 10px;
            padding-top: 2px;

            p {
               font-family: 'ObjectSans-Regular';
               text-transform: uppercase;
               font-size: 13px;
               font-style: italic;
               word-break: break-word;
               padding: 1px;
               text-align: left;
               margin-bottom: 0;
               margin-top: 4px;
               line-height: 19px;

               display: -webkit-box;
               -webkit-line-clamp: 2;
               -webkit-box-orient: vertical;
               overflow: hidden;
            }
         }

         .CardFooter {
            position: absolute;
            display: flex;
            justify-content: right;
            padding: 0 10px;
            margin-top: -4px;
            bottom: 4px;
            right: -6px;

            img {
               margin-left: 5px;
            }
         }

         .TopLeft {
            width: 115px;
            height: 115px;
            border-radius: 8px 0 115px 0;
            position: absolute;
            top: 0;
            left: 0;
            // background: radial-gradient(100% 100% at 0% 0%, #14d0e66e 0%, rgba(20, 208, 230, 0) 80%);
         }

         .BottomRight {
            width: 115px;
            height: 115px;
            border-radius: 115px 0 8px 0;
            position: absolute;
            bottom: 0;
            right: 0;
            // background: radial-gradient(100% 100% at 100% 100%, #14d0e66e 0%, rgba(20, 208, 230, 0) 80%);
         }
      }

      .BoostCard.Sellected {
         select {
            background: linear-gradient(0deg, rgb(37 143 118) 0%, rgb(127 213 192) 100%);
            margin-left: 0 !important;
            min-width: 100px;

            option {
               background-color: #36424d;
            }

            option[selected] {
               background-color: #00FFC2;
            }
         }


      }
   }

   .PlayerProfileWrapper {
      width: 196px;
      height: 267px;
      background-image: url("../../../assets/images/backgrounds/PlayerBackground.svg");
      background-repeat: no-repeat;
      position: relative;
      margin-left: auto;
      margin-right: 50px;

      .Header {
         display: grid;
         grid-template-columns: 1fr 1fr;
         gap: 5px;
         margin-top: 10px;

         > div {
            padding: 0;
            justify-content: center;
            text-align: center;
         }

         * {
            font-size: 12px;
            text-transform: uppercase;
            color: #2FFF9E;
            font-weight: bold;
         }
      }

      .Footer {
         position: absolute;
         width: 196px;
         height: 134px;
         //background-image: url("../../../assets/images/backgrounds/BoostProfileFooter.svg");
         background-repeat: no-repeat;
         bottom: 2px;
         left: 0px;

         .BoostIconWrapper {
            left: 61%;
            transform: translate(-50%, 0);

            .Circle {
               -webkit-box-shadow: 0px 0px 19px 6px rgba(45, 255, 196, 0.85);
               -moz-box-shadow: 0px 0px 19px 6px rgba(45, 255, 196, 0.85);
               box-shadow: 0px 0px 19px 6px rgba(45, 255, 196, 0.85);
            }
         }
      }
   }

   .BoostInfoWrapper {

      h3 {
         text-transform: uppercase;
         font-size: 22px;
         font-style: italic;
      }

      span {
         color: #95A6B7;
         display: block;
         padding-bottom: 8px;
      }

      p {
         text-align: left;
         font-weight: 500;
         font-size: 14px;
         line-height: 18px;
         padding-top: 20px;
         padding-bottom: 20px;
         max-width: 252px;
         word-break: break-word;
      }

      > div:nth-child(2) {
         padding-right: 20px;
         padding-bottom: 13px;
      }

      .ValidityWrapper {
         background: linear-gradient(150.32deg, rgba(40, 48, 62, 0.4) 1.69%, rgba(11, 28, 53, 0.4) 110.84%);
         border: 1px solid #1F2A36;
         padding: 10px;
         font-size: 14px;
         width: 200px;
         position: relative;
         display: flex;
         align-items: center;

         > div {
            padding-top: 10px;
            padding-bottom: 3px;
         }

         img {
            position: absolute;
            right: 6px;
            top: 6px;
         }

         img:hover {
            opacity: .8;
            cursor: pointer;
         }

         label {
            background-color: #36424d;
            color: #fff;
            border-radius: 4px;
            padding: 4px 10px;
            margin-left: 14px;
         }
      }
   }

   .BodyWrapper {
      position: relative;

      > div {
         background-size: cover;
         background-repeat: no-repeat;
         width: 165px;
         height: 198px;
         background-position: center;
      }

      > div:nth-child(2) {
         background: rgb(0, 0, 0);
         background: linear-gradient(0deg, rgba(0, 0, 0, 1) 0%, rgba(0, 0, 0, 0) 100%);
         height: 75px;
         width: 100%;
         position: absolute;
         bottom: 0;
      }
   }


   .BoostWrapper::-webkit-scrollbar {
      height: 10px;
   }

   /* Track */
   .BoostWrapper::-webkit-scrollbar-track {
      background: #f1f1f13c;
   }

   /* Handle */
   .BoostWrapper::-webkit-scrollbar-thumb {
      background: linear-gradient(180deg, #224755 -19.6%, #878787 48.63%, #9a7878 110.8%);
      border: solid .5px #cccccc33;
      border-radius: 5px;
   }

   /* Handle on hover */
   .BoostWrapper::-webkit-scrollbar-thumb:hover {
      background: #555;
   }

   .LeftChevron:hover, .RightChevron:hover {
      cursor: pointer;
      opacity: .6;
   }

   .LeftChevron {
      width: 54px;
      height: 54px;
      display: flex;
      align-self: center;
   }

   .RightChevron {
      width: 54px;
      height: 54px;
      display: flex;
      align-self: center;
      transform: rotate(180deg);
   }

   .PlayerProfileRow {
      display: grid;
      grid-template-columns: 1fr 1fr;
      margin-top: 50px;
   }

   .ModalButtonRow {
      width: fit-content;
      max-width: 500px;
      display: flex;
      gap: 15px;
      // display: grid;
      // grid-template-columns: 1fr 1fr;
      button {
         width: fit-content;
         padding: 13px 20px;
         height: 46px;
         margin-top: 2px;
      }

      .BoostBtn {
         background: linear-gradient(#0E2029, #0E2029) padding-box, linear-gradient(90deg, #39FF96, #FAFF01) border-box;
         border-radius: 2px;
         transform: skew(-10deg, 0deg);
         text-align: center;
         letter-spacing: -0.01em;
         text-transform: uppercase;
         padding: 10px 20px 25px 20px;
         font-weight: 700;
         border: 1px solid transparent;
         width: fit-content;
         height: 48px;
         min-width: 215px;

         span {
            background-image: linear-gradient(90deg, #FAFF01, #39FF96);
            font-size: 16px;
            line-height: 20px;
            -webkit-background-clip: text;
            background-clip: text;
            color: transparent;
            // font-family: "PPObjectSans-BoldSlanted";
            label {
               font-family: "Audiowide-Regular";
               background: -webkit-linear-gradient(45deg, #FAFF00, #00FFC2 80%);
               background-image: unset;
               -webkit-background-clip: unset;
               color: #fff;
               margin-left: 5px;
               margin-right: 5px;
               -webkit-background-clip: text;
               background-clip: text;
               color: transparent;
            }
         }
      }
   }

   .Radial2 {
      top: 355px;
      opacity: .5;
   }

   .Radial1 {
      top: unset;
      bottom: 138px;
      right: -7px;
   }
}

.PowerBoosterPurchaseModal {
   .modal-content {
      width: 80% !important;
   }
}

@media (max-width: 992px) {
   .BoostWrapper {
      display: flex;
      max-width: calc(100% - 120px) !important;
   }
}

@media (min-width: 768px) {
   .PowerBoosterPurchaseModal {
      .LeftChevron, .RightChevron {
         //display: none;
      }
   }
}

@media (max-width: 768px) {
   .PowerBoosterPurchaseModal {
      // .LeftChevron, .RightChevron {
      //    display: block;
      // }

      .modal-content {
         width: 80% !important;
      }

      .BoostWrapper {
         justify-content: unset !important;
      }
   }

}

@media (max-width: 668px) {
   .PowerBoosterPurchaseModal {
      .modal-content {
         width: 80% !important;
      }

      .PlayerProfileRow {
         grid-template-columns: 1fr;
         margin-top: 20px;

         .PlayerProfileWrapper {
            margin: auto;
         }

         .BoostInfoWrapper {
            margin-top: 20px;
            width: fit-content;
            margin: 0 auto;
            text-align: center;

            p {
               text-align: center;
            }

            .ValidityWrapper {
               margin: 0 auto;
            }
         }
      }

      .ModalButtonRow .BoostBtn {
         width: fit-content;
      }

      .LeftChevron, .RightChevron {
         padding: 0;
         width: 38px;
      }

      .BoostWrapper {
         max-width: calc(100% - 77px) !important;

         .BoostCard {
            scale: .95;
         }
      }
   }

}

@media (max-width: 576px) {
   .PowerBoosterPurchaseModal .ModalButtonRow {
      display: flex;
      flex-direction: column;
   }
}

@supports (-webkit-touch-callout: none) {
   .PowerBoosterPurchaseModal {
      h2 {
         -webkit-text-fill-color: #00FFC2 !important;
      }
   }
}

.soundBtnWrapper {
   position: absolute;
   top: 0px;
   right: 0px;
   transform: scale(0.75);

   .soundBtnContainer {
      background-color: #000;
      border: solid 2px #5BFFB0;
      border-radius: 16px;
      width: 50px;
      height: 27.5px;
      position: relative;

      .soundBtn {
         position: absolute;
         left: 0;
         zoom: .5;
         background-color: #fff;
         width: fit-content;
         border-radius: 50%;
         margin: 3px;
         height: 42px;
         width: 42px;

         > a {
            margin-left: 8px;
            margin-top: 5px;
         }

         .speaker {
            height: 31px;
            width: 38px;
            // position: relative;
            overflow: hidden;
            display: inline-block;
            position: absolute;
            top: 1px;

            span {
               display: block;
               width: 8px;
               height: 8px;
               background: #000;
               margin: 11px 0 0 2px;

               &:after {
                  content: '';
                  position: absolute;
                  width: 0;
                  height: 0;
                  border-style: solid;
                  border-color: transparent #000 transparent transparent;
                  border-width: 10px 14px 10px 15px;
                  left: -13px;
                  top: 6px;
               }

               &:before {
                  transform: rotate(45deg);
                  border-radius: 0 50px 0 0;
                  content: '';
                  position: absolute;
                  width: 15px;
                  height: 15px;
                  // border-style: double;
                  // border-color: #000;
                  // border-width: 7px 7px 0 0;
                  left: 13px;
                  top: 8px;
                  transition: all 0.2s ease-out;
               }
            }

            &:hover {

               span:before {
                  transform: scale(.8) translate(-3px, 0) rotate(42deg);
               }
            }

            &.mute {
               span:before {
                  transform: scale(.5) translate(-15px, 0) rotate(36deg);
                  opacity: 0;
               }
            }
         }
      }

      .muteSoundBtn {
         left: unset;
         right: 0;
         transition: right 1s linear;
         position: absolute;
         zoom: .5;
         background-color: #fff;
         width: fit-content;
         border-radius: 50%;
         margin: 3px;
         height: 42px;
         width: 42px;

         > a {
            margin-left: 8px;
            margin-top: 5px;
         }

         .speaker {
            height: 31px;
            width: 38px;
            position: relative;
            overflow: hidden;
            display: inline-block;
            position: absolute;

            span {
               display: block;
               width: 8px;
               height: 8px;
               background: #000;
               margin: 11px 0 0 2px;

               &:after {
                  content: '';
                  position: absolute;
                  width: 0;
                  height: 0;
                  border-style: solid;
                  border-color: transparent #000 transparent transparent;
                  border-width: 10px 14px 10px 15px;
                  left: -13px;
                  top: 6px;
               }

               &:before {
                  transform: rotate(45deg);
                  border-radius: 0 50px 0 0;
                  content: '';
                  position: absolute;
                  width: 15px;
                  height: 15px;
                  // border-style: double;
                  // border-color: #000;
                  // border-width: 7px 7px 0 0;
                  left: 13px;
                  top: 8px;
                  transition: all 0.2s ease-out;
               }
            }

            &:hover {

               span:before {
                  transform: scale(.8) translate(-3px, 0) rotate(42deg);
               }
            }

            &.mute {
               span:before {
                  transform: scale(.5) translate(-15px, 0) rotate(36deg);
                  opacity: 0;
               }
            }
         }
      }
   }

   .soundBtn {
      b {
         color: #000;
         font-family: Arial, sans-serif;
         position: absolute;
         top: -16.5px;
         right: 11px;
         font-size: 16px;
         font-weight: 700;
      }
   }

   .muteSoundBtn {
      b {
         display: none;
      }

      span:before {
         transform: rotate(45deg);
         border-radius: 0 50px 0 0;
         content: '';
         position: absolute;
         width: 15px;
         height: 15px;
         border-style: double;
         border-color: #000;
         border-width: 7px 7px 0 0;
         left: 13px;
         top: 8px;
         transition: all 0.2s ease-out;
      }
   }

   .soundBtnContainerWihtoutBack {
      background-color: #000;
   }
}

.quizBoardWrapper {
   .contentWrapper {
      .mapessageListWrapper {
         // > div{
         //   margin-right: -10px !important;
         // }
         .msg1 {

         }

         .msg2 {
            > div {
               max-width: 100%;

               > div {
                  padding: 5px !important;

                  .customContentWrapper {
                  }
               }
            }
         }
      }
   }

}

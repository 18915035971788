.ProfilePane {
   /*width: 100%;*/
   background-image: -webkit-linear-gradient(0deg, #1A263980, #0B1C3580);
   background-image: -moz-linear-gradient(0deg, #1A263980, #0B1C3580);
   background-image: -o-linear-gradient(0deg, #1A263980, #0B1C3580);
   background-image: linear-gradient(0deg, #1A263980, #0B1C3580);
   border: 1px solid #FFFFFF0F;
   position: relative;
   z-index: 1;
}

.backElipsys {
   position: absolute;
   width: 100%;
   height: 50vh;
   left: 0px;
   top: 0;
   background: radial-gradient(36.65% 28.65% at 70% 70%, #E61479 0%, rgba(230, 20, 20, 0) 100%);
   mix-blend-mode: normal;
   opacity: 0.5;
   z-index: 0;
}

.playerattrScoreTxt {
   -webkit-text-fill-color: unset !important;
   color: #17EA85 !important;
   letter-spacing: 3px;
}

.flatBtn {
   box-sizing: border-box;
   width: auto;
   height: 42px;
   /* Turquiose/blue for secondary buttons */
   background: #111E33;
   /* white 6% stroke */
   border: 1px solid rgba(255, 255, 255, 0.06);
   border-radius: 2px;
   font-family: 'ObjectSans-Regular';
   font-size: 16px;
   line-height: 22px;
   text-transform: uppercase;
   color: #ffffff;
}

.Text24Solina {
   font-size: 24px;
   font-family: 'SolinaLight';
}

.Text20Solina {
   font-size: 20px;
   font-family: 'SolinaLight';
   color: #ffffff;
}

.text18OS {
   font-size: 18px;
   color: #95A6B7;
   font-family: 'ObjectSans-Regular';
}

.text14OS {
   font-size: 14px;
   color: #95A6B7;
   font-family: 'ObjectSans-Regular';
   line-height: 15px;
}

.text14OSW {
   font-size: 14px;
   color: #ffffff;
   font-family: 'ObjectSans-Regular';
   line-height: 15px;
}

.text14OSI {
   font-size: 14px;
   color: #ffffff;
   font-family: 'ObjectSans-Regular';
   line-height: 18px;
   text-transform: uppercase;
   font-style: italic;
   font-weight: 700;
}

.text12OS {
   font-size: 12px;
   color: #95A6B7;
   font-family: 'ObjectSans-Regular';
}

.text24SolinaItaBold {
   font-size: 24px;
   color: #95A6B7;
   font-family: 'SolinaExBI';
   line-height: 30px;
   font-style: italic;
}

.indicatorWrap {
   display: flex;
   flex-direction: column;
   justify-content: center;
   background-image: linear-gradient(0deg, #1A263980, #0B1C3580);
   border-left: 1px solid #FFFFFF0F;
   border-right: 1px solid #FFFFFF0F;
   border-bottom: 1px solid #FFFFFF0F;
}

.indicatorNum {
   display: flex;
   justify-content: center;
   font-family: "SolinaExtraBoldItalic";
   font-size: 20px;
   padding: 5px;
}

.indicatorDesc {
   display: flex;
   justify-content: center;
   color: #95A6B7;
   font-family: "OSRegular";
   font-size: 12px;
   padding: 5px;
}

.borderTop1 {
   border-top: 1px solid;
   border-image: linear-gradient(to right, #f3dd1b00, #F3DE1B, #f3dd1b00) 1 0 0 0;
}

.borderTop2 {
   border-top: 1px solid;
   border-image: linear-gradient(to right, #f3dd1b00, #2DFEE5, #f3dd1b00) 1 0 0 0;
}

.borderTop3 {
   border-top: 1px solid;
   border-image: linear-gradient(to right, #f3dd1b00, #C668FF, #f3dd1b00) 1 0 0 0;
}

.scoreBoard {
   border: 1px solid #1F2A36;
   box-shadow: 10px 22px 47px rgba(0, 0, 0, 0.13);
   backdrop-filter: blur(20px);
   border-radius: 2px;
   width: 100%;
   margin-bottom: 25px;
   background-image: url('../../assets/images/mgroup.webp'), linear-gradient(91.25deg, rgba(57, 45, 252, 0.5) 1.34%, rgba(255, 122, 0, 0.5) 100%);
   background-size: 100%;
}

.FavoUL {
   font-size: 16px;
   color: #ffffff;
   font-family: 'ObjectSans-Regular';
   margin-left: 10px;
   list-style-type: none;
   padding-left: 0px;
}

.FavoUL li {
   margin-top: 20px;
   margin-bottom: 20px;
}

.FavoUL li img {
   width: 42px;
   border-radius: 50%;
}

.FavoLi2 {
   margin-top: 40px;
   margin-bottom: 40px;
}

.FavoUL li:last-child {
   color: #ffffff;
}

.imageMargin {
   margin-left: -10px;
}

p.teamCardDescription {
   height: 90px;
   text-align: left !important;
}

p.teamCardDescriptionForTBAData {
   text-align: left !important;
}

.profilePicWrapper {
   width: 100%;
   margin-bottom: 25px;
   border: 1px solid rgba(255, 255, 255, 0.06);
   border-radius: 6px;
   text-align: center;
}

.profilePicWrapper img {
   width: 100%;
}

.matchIdWrapper {
   display: inline-block;
}

.matchIdLbl {
   font-family: 'OSHeavySlanted';
   display: inline-block;
   font-size: 12px;
   padding-right: 10px;
}

.MatchTournamentHeader {
   font-family: Montserrat;
   font-size: 32px;
   font-style: italic;
   font-weight: 800;
   line-height: 42px;
   letter-spacing: 0.02em;
   text-align: center;
   padding-top: 24px;
}

.matchIdVal {
   font-family: 'OSSlanted';
   display: inline-block;
   font-size: 12px;
}

.matchIdVal a {
   background: #111E33;
   border: 1px solid rgba(255, 255, 255, 0.06);
   border-radius: 2px;
   padding: 5px 10px;
   line-height: 20px;
   text-transform: uppercase;
   display: inline-block;
   margin-left: 10px;
}

.matchIdVal a:hover {
   background: #203455;
}

.matchIdVal svg {
   font-size: 16px;
}

.arrowBtn {
   display: none;
}

.clickclr {
   color: #17EA85 !important;
   cursor: pointer;
}

.calculatingTimeMsg,
.schedMidMsg {
   font-family: 'ObjectSans-Regular';
   text-align: center;
   text-transform: capitalize;
}

.teamInfoWrapper {
   .indicatorWrap {
      width: 100% !important;
   }

   .teamInfo {
      > a {
         display: block;
      }

      .moreInfo {
         > .score {
            display: block;
            clear: both;
         }

         > .overs {
            display: block;
            clear: both;
         }
      }

   }

}

.matchInfoWrapper {
   display: grid;
   grid-template-columns: 1fr 155px 1fr;
   gap: 10px;
   padding: 12px;
   border: 1px solid #1F2A36;
   box-shadow: 10px 22px 47px rgba(0, 0, 0, 0.13);
   backdrop-filter: blur(20px);
   border-radius: 2px;
   width: 100%;
   margin-bottom: 15px;
   background-image: url('../../assets/images/mgroup.webp'), linear-gradient(91.25deg, rgba(57, 45, 252, 0.5) 1.34%, rgba(255, 122, 0, 0.5) 100%);
   background-size: 100%;

   .matchIfo {
      width: 100%;
      // padding-top: 16px;
      .playerName {
         font-size: 16px;
         text-transform: uppercase;
         font-family: 'ObjectSans-Regular';
         line-height: 19px;
         color: #ffffff;
         font-style: italic;
         font-weight: 700;
      }

      .score {
         font-family: 'SolinaExBI';
         font-style: italic;
         font-size: 32px;
         color: rgba(255, 255, 255, 0.4);
         line-height: 40px;
         letter-spacing: 0.02em;
         text-transform: uppercase;
      }

      .overs {
         color: rgba(255, 255, 255, 0.4);
      }
   }

   .teamAcol {
      display: grid;
      grid-template-columns: 1fr 100px;
      gap: 10px;
      align-items: center;

      .profileImg {
         order: 2;

         > img {
            max-width: 75px;
            // border-radius: 50%;
            display: block;
            margin: 0 auto;
         }
      }

      .matchIfo {
         .playerName, .score, .overs {
            text-align: right;
         }
      }
   }

   .teamBcol {
      display: grid;
      grid-template-columns:100px 1fr;
      gap: 10px;
      align-items: center;

      .profileImg {
         order: 1;

         > img {
            max-width: 75px;
            // border-radius: 50%;
            display: block;
            margin: 0 auto;
         }
      }

      .matchIfo {
         order: 2;

         .playerName, .score, .overs {
            text-align: left;
         }
      }
   }

   .otherInfoCol {
      display: flex;
      align-items: center;

      > .TimerWrapper {
         position: relative;
         padding-top: 20px;

         background-color: #0000004d;
         border: solid 1px #ffffff40;
         padding: 4px;
         border-radius: 6px;

         padding: 7px;
         // position: absolute;
         // top: 50%;
         // transform: translateY(-50%);

         .timmerText {
            text-align: center;

            span {
               font-size: 12px;
               padding: 5px;
            }

            > div {
               display: flex;
               align-items: center;
               justify-content: center;
            }
         }

         label {
            width: 100%;
            display: block;
            text-align: center;
         }

         .calculatingTimeMsg {
            font-size: 14px;
         }
      }
   }
}

.likeBtnWrapper {
   width: 100%;
   position: relative;
   z-index: 10000;
   zoom: .8;

   > span {
      position: absolute;
      right: 17px;
      top: 20px;
      zoom: .8;
   }
}

.TeamMatchWrapper {
   .matchInfoWrapper {
      .profileImg {
         border-radius: 50%;
         position: relative;

         img {
            max-width: 70px;
         }
      }

      .profilePicWrapper {
         width: 120px;
         height: 120px;
         background-color: #010E14 !important;
      }


   }

   .ProfilePane {

   }

   .TeamInfoWrapper {
      display: grid;
      grid-template-columns: 1fr 1fr 1fr;
      margin-top: 20px;
      gap: 4px;

      > div {
         border: 1px solid #1d2636;
         border-top: none;
         border-radius: 4px;
      }

      .indicatorWrap {
         width: 100%;

         .indicatorNum {
            height: unset;
            margin-bottom: 0;
            margin-top: 10px;
         }
      }
   }

   .LikeBtnWrapper {
      position: relative;
      width: 1px;
      height: 1px;
      float: right;
   }

   .TeamInfoName {

   }

   .PlayerScrollWrapper {
      // display: grid;
      // grid-template-columns: repeat(5, 128px);
      display: flex;
      gap: 10px;
      overflow-x: auto;

      > div {
         // width: 128px;
         width: 200px;
         padding: 10px;
         float: left;
         background: linear-gradient(180deg, rgba(26, 38, 57, 0.5) 0%, rgba(11, 28, 53, 0.5) 100%);
      }

      .ProfileImg {
         width: 56px;
         height: 70px;
         margin: 0 auto;
         margin-top: 10px;
         margin-bottom: 10px;

         img {
            width: 56px;
            //height: 56px;
            //border-radius: 50%;
         }
      }

      ul {
         padding: 0;
         margin: 0;

         li {
            margin-bottom: 10px;
            list-style: none;

            label {
               font-size: 12px;
               color: #95A6B7;
               display: block;
               width: 100%;
               margin: 0;;
            }

            span {
               font-size: 12px;
               font-style: italic;
               text-transform: uppercase;
            }
         }
      }

      .indicatorWrap {
         justify-content: unset;
      }
   }

   .progressBarWrapper {
      padding: 0 !important;
      margin-bottom: 10px;

      > span {
         //width: 100%;
         width: 80px;
      }
   }

   .indicatorNum {
      padding: 0;
      font-size: 18px;
      line-height: normal;
      margin-bottom: 10px;
      margin-top: 10px;
      height: 43px;
      text-align: center;
   }

   .PlayerScrollWrapper::-webkit-scrollbar {
      width: 10px; /* Mostly for vertical scrollbars */
      height: 10px; /* Mostly for horizontal scrollbars */
   }

   .PlayerScrollWrapper::-webkit-scrollbar-track {
      box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
      height: 15px !important;
   }

   .PlayerScrollWrapper::-webkit-scrollbar-thumb {
      background: #95A6B7;
      outline: 1px solid #59646f;
      height: 15px !important;
      width: 10px;
      border-radius: 10px;
   }

   .PlayerScrollWrapper::-webkit-scrollbar-track-piece {
      background-color: #142537;
   }
}

// .teamCardDescriptionWrapper{
//     -webkit-box-orient: vertical;
//     display: -webkit-box;
//     -webkit-line-clamp: 4;
//     overflow: hidden;
//     .teamCardDescription{
//         overflow: hidden;
//         max-width: 100%;
//         text-overflow: ellipsis;
//         width: fit-content;
//         white-space: nowrap;
//     }
// }
.TeamMatchWrapper {
   .matchInfoWrapper {
      display: block !important;

      h3 {
         text-transform: uppercase;
         text-align: center;
         display: block !important;
         font-style: italic;
         font-size: 24px;
         margin-bottom: 0;
         width: 90%;
         white-space: nowrap;
         overflow: hidden;
         text-overflow: ellipsis;
         margin: 0 auto;
         padding-bottom: 10px;
      }

      > div {
         display: grid;
         grid-template-columns: 1fr 155px 1fr;

      }
   }
}

@media only screen and (max-width: 880px) {
   .teamCardDescription {
      min-height: 126px;
   }
}

@media only screen and (min-width: 768px) {
   .VinMobile {
      display: none;
   }
   .HinMobile {
      display: block;
   }
   .TeamMatchWrapper {
      .matchInfoWrapper {
         h3 {
            font-size: 32px;
            width: 90%;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
            margin: 0 auto;
         }
      }
   }
}

@media only screen and (max-width: 768px) {
   .scoreBoardDark {
      height: auto !important;
      display: inline-block;
   }

   .teamBall, .teamBat {
      float: right;
   }

   .team1Name, .team2Name {
      font-size: 14px;;
   }
   .timerContainer {
      padding: 5px 30px;
   }
   .Text24Solina {
      font-size: 18px;
   }
   .teamCardDescription {
      min-height: auto;
      display: grid;
   }
   .matchInfoWrapper {
      > div {
         grid-template-columns: 1fr 150px 1fr;
      }

      .teamAcol {
         grid-template-columns: 1fr 55px;

         .profileImg {
            width: 50px;
            height: 50px;
            background-color: unset;

            > img {
               max-width: fit-content;
               max-height: 48px;
               width: 50px;
               height: 50px;
            }
         }
      }

      .teamBcol {
         grid-template-columns:55px 1fr;

         .profileImg {
            width: 50px;
            height: 50px;
            background-color: unset;

            > img {
               max-width: fit-content;
               max-height: 48px;
               width: 50px;
               height: 50px;
            }
         }
      }

      .score {
         font-size: 22px !important;
      }

      .otherInfoCol {
         align-items: center;
         display: flex;
         justify-content: center;

         > div {
            // padding-top: 40px;
            label:nth-child(2) {
               display: none;
            }
         }
      }
   }
   .scoutingReport {
      .indicatorWrap {
         min-height: unset !important;
         padding-bottom: 0 !important;
      }
   }
   .teamDetail {

   }
   .VinMobile {
      display: block;
   }
   .HinMobile {
      display: none;
   }
   .TimerWrapper {
      display: flex;
      padding: 0;
      margin: 0;
      gap: 2px;
      justify-content: center;
      align-items: center;
   }
   .timeOutMsgWrapper {
      position: relative;
      display: flex;
      align-items: center;
      height: -webkit-fill-available;

      .Text24Solina {
         display: flex;
         margin: 0;
         width: 100%;
         justify-content: center;
         all: unset;
         margin: 0 auto;
      }
   }
}

@media only screen and (max-width: 576px) {
   .ProfilePane {
      .profilePicWrapper > img {
         width: 60% !important;
         max-width: 200px;
      }

      .heartLike {
         position: absolute;
         top: 25px;
         right: 25px;
         background-Color: "#ffffff10";
      }
   }

   .arrowBtn {
      display: flex;
      position: absolute;
      top: 0;
   }
   .arrowBtn svg {
      font-size: 20px;
   }
   .arrowBtnBack {
      left: 10px;
   }
   .arrowBtnNext {
      right: 10px;
   }
   .calculatingTimeMsg {
      font-size: 15px;
   }

   .matchInfoWrapper {
      grid-template-columns: 1fr 100px 1fr;
      padding: 8px;
      gap: 10px;

      .playerName {
         font-size: 11px !important;
      }

      .score {
         font-size: 16px !important;
         line-height: 25px !important;
      }

      .otherInfoCol {
         // padding-top:17%;
      }

      .matchIfo {
         padding-top: 2px !important;
         position: relative;
      }

      .teamAcol {
         grid-template-columns: 1fr 35px;

         .profileImg {
            margin-top: 8px;

            > img {
               max-width: fit-content;
               max-height: 45px;
               margin-top: 16px
            }
         }

         .matchIfo {
            .playerName {
               // position: absolute;
               // right: -52px;
            }

            .score {
               margin-top: 16px;
            }
         }
      }

      .teamBcol {
         grid-template-columns:35px 1fr;

         .profileImg {
            > img {
               max-width: fit-content;
               max-height: 45px;
               margin-top: 16px
            }
         }

         .matchIfo {
            .playerName {
               position: absolute;
               left: -35px;
            }

            .score {
               margin-top: 16px;
            }
         }
      }

      .score {
         font-size: 22px !important;
      }

      .otherInfoCol {
         > div {
            label:nth-child(1) {
               font-size: 14px;
            }

            label:nth-child(2) {
               display: none;
            }
         }
      }

   }
   .matchInfoWrapper {
      .playerName {
         width: 90px;
         white-space: nowrap;
         overflow: hidden;
         text-overflow: ellipsis;
      }

      .teamAcol {
         .playerName {
            text-align: right;
            float: right;
         }

         .overs, .score {
            clear: both;
         }
      }
   }
   .timeOutMsgWrapper {
      position: relative;
      display: flex;
      align-items: center;
      height: -webkit-fill-available;

      .Text24Solina {
         display: flex;
         margin: 0;
         width: 100%;
         justify-content: center;
         all: unset;
         margin: 0 auto;

         a.arrowBtnBack {
            margin: 0;
            display: block;
            position: absolute;
            top: 45% !important;
            -ms-transform: translateY(-50%);
            transform: translateY(-50%);
            left: -8px;
         }
      }
   }
   .TeamMatchWrapper .TeamInfoWrapper .indicatorWrap {
      width: 100% !important;
   }
   .TimerWrapper {
      margin-left: 7px;
      scale: .9;

      > * {
         font-size: 12px;
      }

   }
   .alculatingTimeMsg {
      font-size: 12px;
   }
   .matchInfoWrapper {
      .teamBcol, .teamAcol {
         display: unset;
      }

      .profileImg {
         display: block;
         margin: 0 auto;
      }

      .playerName {
         position: unset !important;
         left: unset !important;
         right: unset !important;
         margin: 0 auto;
         text-align: center;
         float: none !important;
      }

      .calculatingTimeMsg {
         font-size: 12px !important;
      }

   }

   .matchInfoWrapper {

   }
   .TeamMatchWrapper {
      margin: 0;
      padding: 0;

      .matchInfoWrapper {
         padding: 2px;
         gap: 5px;

         .TimerWrapper {
            margin: 0;
            scale: 1;

            .calculatingTimeMsg {
               line-height: normal;
               font-size: 13px !important;
            }
         }
      }

      .profileImg {
         img {
            margin-top: 10px !important;
         }
      }
   }
   .TeamMatchWrapper {
      .matchInfoWrapper {
         a {
            h3 {
               font-size: 16px !important;
               padding-bottom: 0;
               padding-top: 5px;
            }
         }
      }
   }
}

section.infoMessage * {
   color: #00FFC2;
}

@media only screen and (min-width: 576px) {
   .timmerText {
      font-size: 20px !important;
   }
}

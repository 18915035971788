@import '../../scss/globals/global';
@import '../../scss/utilities/mixins';

.UpcomingBlock {
   background-image: url('../../assets/images/Now_playing_block.webp');
   background-repeat: no-repeat;
   width: 526px;
   height: 300px;
   position: absolute;
   right: -26px;
   z-index: 1;
   top: -40px;

   .NowPlayingContainer {
      color: white;
      width: 260px;
      height: 180px;
      float: right;
      margin: 60px 45px;

      h2 {
         text-transform: uppercase;
         font-size: 22px;
         font-family: 'ObjectSans-Regular';
         font-style: italic;
         text-align: right;
      }

      .Row1 {
         display: flex;
         gap: 10px;
         align-content: end;

         h2 {
            padding: 0;
            margin: 0;
            text-align: right;
         }

         .liveTag {
            display: block;
            width: -moz-fit-content;
            width: fit-content;
            background: linear-gradient(96.32deg, #B10B8F 1.99%, #E01655 99.35%), linear-gradient(92.2deg, #E11653 2.13%, #AD0A93 106.58%);
            transform: skew(-10deg, 0deg);
            bottom: 2px;
            font-family: "ObjectSans-Regular";
            letter-spacing: 2px;
            font-size: 12px;
            padding: 2px 10px;
            height: 24px;
            color: rgb(255, 255, 255);
            -webkit-text-fill-color: #FFFFFF !important;
            text-transform: uppercase;

            span {
               width: 3px;
               height: 3px;
               background-color: #fff;
               display: inline-block;
               margin-right: 4px;
               margin-bottom: 2px;
               animation: OngoingTournaments_blink__Cysya 2s infinite;
            }
         }
      }

      .Row2 {
         display: flex;
         flex-direction: column;
         margin-top: 24px;

         .SubRow {
            display: flex;
            gap: 10px;
            margin-bottom: 10px;

            h4 {
               font-size: 14px;
               font-style: italic;
               font-family: "ObjectSans-Regular";
               text-transform: uppercase;
               font-weight: 700;
               margin-top: 7px;

               width: 116px;
               display: -webkit-box;
               max-width: 100px;
               -webkit-line-clamp: 2;
               -webkit-box-orient: vertical;
               overflow: hidden;
               height: 35px;
               max-height: 35px;
            }

            span {
               font-size: 24px;
               color: #95A6B7;
               font-weight: 800;
               font-style: italic;
            }

            label {
               margin-top: -0px;
               display: block;
            }
         }

         .Logo {
            width: 46px;
            height: 46px;
            background-color: #000;
            border-radius: 50%;
            padding: 2px;
            border: 2px solid #000;
         }

         .BattBallIcon {
            width: 16px;
            height: 16px;
            margin-top: 6px;

            img {
               width: 100%;
            }
         }
      }
   }

   .upcomingTag {
      display: block;
      width: -moz-fit-content;
      width: fit-content;
      font-family: "ObjectSans-Regular";
      letter-spacing: 2px;
      font-size: 12px;
      background: linear-gradient(91.36deg, #19A62F 1.34%, #DBB908 66.71%);
      border: 1px solid;
      border-image-source: linear-gradient(92.52deg, #D0B80B 0.84%, #1EA72F 41.93%);
      transform: skew(-10deg, 0deg);
      padding: 2px 14px;
      text-transform: uppercase;
      height: 22px;
   }

   .Row1 {
      display: block;
      gap: 10px;
      align-content: end;
   }

   .Row2 {
      h2 {
         font-family: "OdibeeSans-Regular";
         font-size: 21px;
         font-weight: 400;
         line-height: 27px;
         letter-spacing: 0.28em;
         text-align: left;
         text-transform: uppercase;
         background: -webkit-linear-gradient(45deg, #FAFF00, #00FFC2 80%);
         -webkit-background-clip: text;
         -webkit-text-fill-color: transparent;
         // margin-top: 20px;
         margin-top: -10px;
      }

      .countdown {
         width: fit-content;
         margin: 0 auto;
         margin-top: 10px;
         display: grid;
         grid-template-columns: repeat(3, 78px);
         gap: 0px;
         scale: 1.3;

         > div {
            .cercalBack {
               width: 56px;
               height: 56px;
               // background-image: url('../images/backgrounds/countdown_circle-01.svg');
               background-image: url('../../assets/images/backgrounds/countdown_circle-01.svg');
               background-position: top left;
               background-repeat: no-repeat;
               position: relative;
               margin-left: 13px;
            }

            .cercal {
               width: 56PX;
               height: 56px;
               border-radius: 50%;
               /* border: 1.69px solid; */
               border-color: linear-gradient(143.92deg, #FAFF01 13.27%, #39FF96 61.67%);

               > span {
                  position: absolute;
                  width: 50px;
                  top: 19%;
                  left: 5%;
                  margin-left: auto;
                  margin-right: auto;
                  font-family: $countdown-count-font;
                  // font-size: $countdown-count;
                  font-size: 21px;
                  letter-spacing: 2px;
                  background: -webkit-linear-gradient(45deg, #FAFF00, #00FFC2 80%);
                  -webkit-background-clip: text;
                  -webkit-text-fill-color: transparent;
                  text-align: center;
               }

            }

            > span {
               text-transform: uppercase;
               font-weight: 400;
               display: block;
               text-align: center;
               font-family: $countdown-bottom-font;
               font-size: $countdown-bottom-title;
               margin-top: 8px;
               background: -webkit-linear-gradient(45deg, #FAFF00, #00FFC2 80%);
               -webkit-background-clip: text;
               -webkit-text-fill-color: transparent;
            }

         }

         .timerWrapper {
            width: fit-content;
            position: absolute;
            top: -62px;
            margin-left: 1px;

            div {
               max-width: 54px;

               > svg {
                  max-width: 54px;
                  margin-left: 13px;

                  > :nth-child(1) {
                     stroke: unset !important;
                  }
               }
            }

         }
      }
   }

   .nextTournamentWrapper {
      position: relative;
      // left: 0;
      right: 10vw;
      margin-left: auto;
      margin-right: auto;

      bottom: 0;
      //width: 480px;
      // height: 182px;
      border-radius: 3px;
      //box-shadow: 0px 0px 55.573062896728516px 0px rgba(0, 255, 194, 0.7);
      //background: #00000042;
      //backdrop-filter: blur(5px);
      //-webkit-backdrop-filter: blur(5px);
      //border: solid 1px;
      //border-image: linear-gradient(45deg, #F9FF01, #2FFF9E) 1;
      //border-width: 2px;
      padding-bottom: 10px;

      // h2 {
      //    font-family: $countdown-bottom-font;
      //    font-size: 21px;
      //    font-weight: 400;
      //    line-height: 27px;
      //    letter-spacing: 0.28em;
      //    text-align: center;
      //    text-transform: uppercase;
      //    background: -webkit-linear-gradient(45deg, #FAFF00, #00FFC2 80%);
      //    -webkit-background-clip: text;
      //    -webkit-text-fill-color: transparent;
      //    margin-top: 20px;
      // }


   }
}

@media only screen and (max-width: 992px) {
   .UpcomingBlock {
      scale: 0.7 !important;
      right: -79px !important;
      top: 16px !important;
   }

}

@media only screen and (max-width: 768px) {
   .UpcomingBlock {
      scale: 0.7;
      right: -79px !important;
      top: 16px !important;
   }
}

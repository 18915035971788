.FantasyCardWrapper {
   padding: 10px;
   margin: 0 auto;
   justify-content: center;

   > .CardWrapper {
      display: flex;
      justify-content: center;
      margin-top: 5px;
      display: grid;
      grid-template-columns: 1fr 1fr;
      gap: 8px;
      width: fit-content;
   }

   @mixin FantacyCard {

      position: relative;
      background: linear-gradient(0deg, #224755, #068F7A, #224755);
      border-radius: 8px;

      width: 115px;
      max-width: 115px;
      padding: 2px;
      .CardBackground {
         background-color: #0A1627;
         border-radius: 8px;
         padding: 10px;
         position: relative;
         min-height: 135px;

         > div {
            justify-content: center;
            padding-top: 12px;
         }
      }
      .ProfilePic {
         position: relative;
         width: 60px;
         height: 60px;
         border-radius: 50%;
         background-size: cover;
         background-repeat: no-repeat;
         background-color: #010E14;
         background-image: url('../../assets/images/PlayerProfilePic.webp');
         background-repeat: no-repeat;
         border: solid 1px rgba(204, 204, 204, 0.3607843137);
      }

      .TrashIcon {
         position: absolute;
         top: 5px;
         right: 20px;
      }
      .BtnRow {
         display: flex;
         justify-content: center;

         button {

            font-family: 'ObjectSans-Regular';
            border: solid 1px #17EA85;
            background-color: #152D39;
            color: #17EA85;
            border-radius: 4px;
            margin-bottom: 4px;
            font-size: 14px;
         }

         button:disabled {
            filter: grayscale(1);
         }
      }

      .TitleRow {
         justify-content: center;

         span {
            color: #17EA85;
            font-size: 12px;
            text-align: center;
            margin-top: -4px;
            margin-bottom: 4px;
            display: flex;
            width: fit-content;
            padding: 0;
         }
      }
      h4 {
         font-family: 'ObjectSans-Regular';
         font-size: 11px;
         font-weight: 600;
         font-style: italic;
         text-transform: uppercase;
         text-align: center;
         padding: 0;
         margin: 0;
         white-space: nowrap;
         overflow: hidden;
         text-overflow: ellipsis;
         max-width: 65px;
      }

   }

   .BoostIconWrapper {
      .Circle {
         scale: .9;
         right: 6px;
         top: 46px;
         z-index: 2;
      }
   }

   h2 {
      text-transform: uppercase;
      font-family: Montserrat;
      font-size: 20px;
      font-style: italic;
      font-weight: 800;
      line-height: 32px;
      letter-spacing: 0.02em;
      text-align: center;
      text-transform: uppercase;
      background: linear-gradient(89.7deg, #17EA85 2.27%, #14D0E6 23.6%, #17EA85 44.41%, #14D0E6 64.17%, #17EA85 82.99%, #14D0E6 99.74%);
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
      background-clip: text;
      text-fill-color: transparent;
      text-shadow: 0px 0px 8px rgba(20, 208, 229, 0.5607843137);
      padding-top: 3px !important;
   }

   .Card {
      @include FantacyCard;
   }

   .TotalEarningWrapper {
      display: flex;
      background-image: url('../../assets/images/backgrounds/FantasyBotTotalEarnings.webp');
      background-position: top;
      background-repeat: no-repeat;
      width: 250px;
      height: 50px;
      margin: 0 auto;
      margin-top: 14px;
      padding: 0;
      align-items: center;
      justify-content: center;

      .TotalEarning {
         display: flex;
         width: 100%;
         height: 30px;
         justify-content: center;
         padding-bottom: 7px;

         .Title {
            text-transform: uppercase;
            font-size: 13px;
            font-style: italic;
            font-weight: 700;
            margin-right: 5px;
            color: #fff;
         }

         div {
            font-size: 14px;
            display: flex;
            align-items: center;
            font-weight: 500;
            color: #fff;

            img {
               margin: 0 4px;
               width: 24px;
               height: 24px;
            }
         }
      }

   }

   .ModalButtonRow {
      display: flex;
      width: fit-content;
      justify-content: center;
      margin-bottom: 30px;

      button text {
         color: #000 !important;
      }
   }

   .TotalEarningRow {
      > div {
         padding: 0;
         height: 110px;
      }

      .TotalEarningWrapper {
         background-image: url('../../assets/images/backgrounds/TotalEarningMobile.webp');
         width: 345px;
         height: 100px;
      }
   }

   .TournamentFantasySection .FantacyTeamContainer .SelectedPlayersCol .OtherPlayers .Card {
      width: 159px;
   }

   .TotalEarningRow {
      .TotalEarningWrapper {
         background-position: -22px 0;
         width: 302px;
         height: 100px;
      }
   }
}

.NoOneCaptain {
   grid-template-columns: 1fr !important;
   justify-content: space-evenly;
   justify-items: center;
   align-content: space-evenly;
   align-items: center;
}

.NoAnyCaptain {
   display: none;
}

.FantasyzBoardWrapper {
   .TitleWrapper {
      display: flex;
      justify-content: center;

      h2 {
         font-size: 20px;
      }

      .MoreInfoIconBtn {
         width: 20px;
         height: 20px;
         margin-top: -4px;
         margin-left: 3px;
      }

      .MoreInfoIconBtn:hover {
         cursor: pointer;
         opacity: .8;
      }
   }
}

@media (max-width: 1200px) {
   .FantasyCardWrapper {
      .CardWrapper {
         display: flex;

      }

      .FantasyWrapperContainer {
         max-height: 450px !important;
      }
   }
}

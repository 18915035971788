@import '../../../../scss/globals/global';
@import '../../../../scss/utilities/mixins';

.creditsWrapper {
   width: fit-content;
   display: inline-block;
   font-size: 14px;
   margin: 0 auto;

   img {
      transform: skew(10deg, 0deg) !important;
      margin: 0 8px;
   }

   span {
      color: #17EA85;
      font-size: 14px;
      transform: skew(10deg, 0deg) !important;
   }

   label {
      text-transform: capitalize;
   }

   .credits {
      padding: 6px 22px;
      border: none;
      outline: none;
      position: relative;
      z-index: 1;
      border-radius: 2px;
      background: linear-gradient(103.05deg, #F9FF01 7.22%, #2FFF9E 93.09%);
      //    cursor: pointer;
      transform: skew(-9deg, 0deg);
      text-transform: uppercase;
      margin-right: 10px;
   }

   .credits::before {
      content: "";
      position: absolute;
      left: 1px;
      right: 1px;
      top: 1px;
      bottom: 1px;
      border-radius: 4px;
      background-color: #0E2029;
      z-index: -1;
      transition: 200ms
   }

   .credits::after {
      content: attr(data);
      font-size: 16px;
      background: linear-gradient(to left, #00FFA3, #DC1FFF);
      -webkit-background-clip: text;
      color: #fff;
      transition: 200ms
   }
}

.modalContentContainer {
   box-shadow: 0px 0px 65px 0px #80ff5f6e;
}

.PaymentConfirmmodal {
   display: block;
   background: rgba(0, 0, 0, 0.2588235294);
   backdrop-filter: blur(5px);
   -webkit-backdrop-filter: blur(5px);

   .CreditPurchaseModal {
      width: 100%;
      max-width: 580px;

      .closeButton {
         position: absolute;
         z-index: 1;
         top: 10px;
         right: 15px;
      }

      .ModalHeader {
         background: rgba(17, 30, 51, 1);
         margin-bottom: 40px;

         h4 {
            font-size: 20px;
            font-family: Montserrat;
            padding: 20px;

            img {
               margin: -6px 10px 0 0;
            }
         }
      }
   }

   .CreditRate {
      width: fit-content;
      margin: 0 auto;

      img {
         width: 24px;
         height: 24px;
      }

      * {
         padding: 3px;
      }

      p {
         text-align: center;

         a {
            color: #17ea85;
            text-decoration: underline;
            display: unset;
         }
      }
   }

   .OmniCardWrapper {
      margin-top: 15px;

      .OmniCard {
         display: block;
         position: relative;
         width: calc(50% - 13px);
         float: left;
         padding: 5px 10px;
         background-color: #062024;
         border: solid 1px #102531;
         margin: 6px;
         border-radius: 4px;
         margin-top: 20px;

         * {
            padding: 4px;
         }

         img {
            width: 32px;
            height: 32px;
         }

         > div {
            display: flex;
            justify-content: center;
         }
      }

      .OmniCardClicked {
         border-color: rgba(23, 234, 133, 1);
         cursor: pointer;
      }

      label, span {
         font-size: 14px;
      }
   }

   fieldset {
      border-top: solid 1px #1b3341;
      margin: 30px 0px;

      legend {
         all: unset;
         margin: 0 auto;
         color: rgba(149, 166, 183, 1);
         font-size: 16px;
         padding-left: 10px;
         padding-right: 10px;
      }

      .OmniCal {
         margin-top: 30px;
         display: grid;
         grid-template-columns: 1fr 30px 1fr;
         gap: 5px;

         input {
            width: 100%;
            padding: 10px;
            border: solid 1px rgb(28 45 74);
            background-color: rgba(17, 30, 51, 1);
            border-radius: 4px;
         }

         input:focus-visible {
            border: solid 1px rgb(50 68 100) !important;
            outline: none;
         }

         span {
            display: block;
            text-align: center;
            color: rgba(149, 166, 183, 1);
            padding-top: 42px;
         }

         label {
            color: rgba(149, 166, 183, 1);
         }
      }
   }

   .photoModifyModelBtnRow {
      display: block !important;
      width: 90% !important;
      margin-left: auto;
      margin-right: auto;

      .BlackSkBtn {
         float: left;
         background: linear-gradient(103.05deg, #F9FF01 7.22%, #2FFF9E 93.09%);
         border: 0 !important;

         span {
            // background-image: linear-gradient(0deg, #FAFF01, #39FF96);
            // font-size: 16px;
            // line-height: 20px;
            // -webkit-background-clip: text;
            // background-clip: text;
            // color: transparent;
            // background-image: linear-gradient(90deg, #FAFF01, #39FF96);

            font-size: 16px;
            line-height: 20px;
            background-color: #000 !important;
            padding: 15px 27px;
            border-radius: 4px;
            // -webkit-background-clip: text;
            // background-clip: text;
            // color: transparent;
         }
      }

      .LoginButton {
         float: left;
      }

      .LoginButton.ProceedButton {
         float: right;
      }

   }
}

.Radial1 {
   @include gradientGeneralLeft(900px, 900px, -550px, 550px, $Blue03, 32.65%, 32.65%, $GradPosCenter);
   mix-blend-mode: normal;
   opacity: 0.14;
   z-index: -1;
}

.Radial2 {
   @include gradientGeneralRight(500px, 500px, 0, 1050px, $Blue03, 32.65%, 32.65%, $GradPosRight);
   mix-blend-mode: normal;
   opacity: 0.14;
   z-index: -1;
}


// p {
//    text-align: center;
//    font-size: 18px;
//    font-family: 'ObjectSans-Regular';
// }


.OverviewBox {
   @include overview-box;
   padding-left: 5px;

   img {
      width: auto;
   }
}

.leaderBoardTitle {
   position: relative;

   .shareBtnWrapper {
      position: absolute;
      top: -4px;
      right: 0;
   }
}

.borderTop1 {
   @include yellow-gradient;
}

.borderTop2 {
   @include green-gradient;
}

.borderTop3 {
   @include purple-gradient;
}


.SplashBox {
   height: 320px;
   background-image: url(https://storage.googleapis.com/meta11/serviceplatform/splash-bg.png);
   background-position: center 0%;
}

.marks {
   width: 56px;
   height: 28px;
   background: #172842;
   border: 1px solid #253342;
   border-radius: 4px;
   margin-left: auto;
   margin-right: auto;
}


.RadioBox {
   width: 20%;
   float: left;
   text-align: center;
   padding-top: 21px;
}

.radioFill:checked {
   background-color: #04151d;
   border-color: #17EA85;
   background-image: url(https://storage.googleapis.com/meta11/serviceplatform/radioCheckGreen.png) !important;
   background-repeat: no-repeat;
   background-size: 60%;
}

.radioFill {
   border: 1px solid #17EA85 !important;
   width: 1.3em;
   height: 1.3em;
   background-color: #04151d;
   cursor: pointer;
}


.BlackSkBtn text {

   background-image: linear-gradient(0deg, #FAFF01, #39FF96);
   background-clip: text;
   color: transparent;
   font-family: "ObjectSans-Heavy";
   font-size: 16px;
   line-height: 18px;
}

.GrayButton {
   @include GrayButtonAtributes($grayButtonColor1, $grayButtonColor2, $buttonColor1, $buttonColor2, $BtnBorderRadius, $Btntransform, $BtntextTransform, $BtnPaddingGray);
   @include css3Anim();
   border: 1px solid $buttonColor1;
   background-clip: text;
   color: transparent;
   padding: 12px 20px;
   border-radius: 5px;

   text {
      @include GrayButtonText($buttonColor1, $buttonColor2, $BtnfontSize, $BtnlineHeight);
      -webkit-background-clip: text;
      background-clip: text;
      color: transparent;
      font-family: $buttonTextFont;
   }
}

.GrayButton:hover {
   background: $grayButtonHover;
}

.BlackSkBtn {
   background-color: linear-gradient(0deg, #0E2029, #0E2029) padding-box2029;
   box-shadow: 0px 2px 0px rgba(0, 0, 0, 0.51);
   border-radius: 4px;
   transform: skew(-10deg, 0deg);
   border: 1px solid rgba(0, 255, 194, 0.09);
   /* identical to box height, or 129% */
   text-align: center;
   letter-spacing: -0.01em;
   text-transform: uppercase;
   padding: 1px;
   font-weight: 700;
   height: 54px;

   .BlackSkBtn text {
      color: linear-gradient(0deg, #FAFF01, #39FF96) 5A6B7;
   }

   :visited {
      box-shadow: 0px 2px 0px rgba(0, 0, 0, 0.51);
      cursor: not-allowed;
   }

   :disabled,
   .BlackSkBtn[disabled] {
      background: #0E2029;
      box-shadow: 0px 2px 0px rgba(0, 0, 0, 0.51);
      backdrop-filter: blur(45px);
   }
}

.BlackSkBtn:hover {
   background: #203455;
}

.LoginButton {
   background: linear-gradient(95.34deg, #FAFF00 6.28%, #00FFC2 111.13%);
   box-shadow: 0px 2px 0px rgba(0, 0, 0, 0.51);
   border-radius: 4px;
   transform: skew(-10deg, 0deg);
   border: 1px solid #2FFF9E;
   /*border-image-source: linear-gradient(103.05deg, #2FFF9E 7.22%, #F9FF01 93.09%);*/
   text-align: center;
   letter-spacing: -0.01em;
   padding: 10px 27px;
   /*font-weight: 700;
   width: 100%;*/
   height: 48px;
   margin-top: 1px;
   font-family: "ObjectSans-Heavy", Helvetica, Arial, sans-serif;
   font-size: 16px;
   line-height: 18px;
   text-transform: uppercase;
   margin-left: 10px;
   // :hover {
   //   background: linear-gradient(95.34deg, #00FFC2 6.28%, #FAFF00 111.13%);
   // }

   :visited {
      background: linear-gradient(95.34deg, #B0FFCA 6.28%, #F2FF7E 111.13%);
   }
}

.LoginButton:hover {
   background: linear-gradient(95.34deg, #00FFC2 6.28%, #FAFF00 111.13%);
}

.modalBlur {
   background-color: #00000063;
}

//  .closeButton {
//     border: 0px;
//     background-color: #00000000;
//  }

.CreditPurchaseModal {
   .modalContainer {
      .modalTitle {
         font-family: 'SolinaLight';
         font-size: 20px;
         color: #ffffff;
         text-transform: uppercase;
         line-height: 40px;
      }
   }

   .photoModifyModelBtnRow {
      display: flex;
      width: fit-content;
      margin: 0 auto;

      > a {
         float: right;
         margin-right: -25px;

         > button:hover {
            -webkit-text-fill-color: unset !important;
            cursor: pointer;
         }
      }

      > a:hover {
         -webkit-text-fill-color: unset !important;
         color: #000;

      }
   }
}


.DeleteAccountWrapper {
   margin: 40px 0;
   padding: 0;

   h3 {
      font-size: 32px;
      font-family: "SolinaLight", Helvetica, Arial, sans-serif;
      text-align: left;
      line-height: 35px;
      color: #ffffff;
      text-transform: uppercase;
   }

   .DeleteAccountBtnWrapper {
      .DeleteAccountBtn {
         padding: 14px 23px;
         border: none;
         outline: none;
         position: relative;
         z-index: 1;
         border-radius: 5px;
         background: linear-gradient(103.05deg, #F9FF01 7.22%, #2FFF9E 93.09%);
         cursor: pointer;
         transform: skew(-10deg, 0deg);
         text-transform: uppercase;
      }

      .DeleteAccountBtn::before {
         content: "";
         position: absolute;
         left: 1px;
         right: 1px;
         top: 1px;
         bottom: 1px;
         border-radius: 4px;
         background-color: #0E2029;
         z-index: -1;
         transition: 200ms
      }

      .DeleteAccountBtn::after {
         content: attr(data);
         font-size: 16px;
         background: linear-gradient(to left, #00FFA3, #DC1FFF);
         -webkit-background-clip: text;
         color: #fff;
         transition: 200ms
      }

      .DeleteAccountBtn:hover::before {
         opacity: 50%;
         top: 0px;
         right: 0px;
         bottom: 0px;
         left: 0px;
      }

      .DeleteAccountBtn:hover::after {
         color: white;
      }
   }

}

.UserProfileSplashBox {

   .SplashBox {
      min-height: 320px;
      padding-bottom: 40px !important;
      background-image: url(../../../../assets/images/leader-board-splash.webp);
      background-repeat: no-repeat;
      background-position: center 0%;
   }

   .upChevron {
      width: 24px;
      height: 24px;
      margin: 0 auto;
      margin-bottom: 10px;
      background-image: url(../../../../assets/images/up-chevron.webp);
      background-repeat: no-repeat;
   }

   .downChevron {
      width: 24px;
      height: 24px;
      margin: 0 auto;
      margin-bottom: 10px;
      background-image: url(../../../../assets/images/down-chevron.webp);
      background-repeat: no-repeat;
   }

   .outCurcleWrapper {
      width: 144px;
      height: 144px;
      position: relative;
      margin: 0 auto;
   }

   .trophyTextW {
      font-size: 18px;
      color: #ffffff;
      font-family: "ObjectSans-Regular";
      text-transform: uppercase;
      letter-spacing: -0.01em;
      line-height: 24px;
      font-style: italic;
      font-weight: 700;
      min-height: 60px;
   }

   .curcleOut1 {
      margin-top: 27px;
   }

}

.CreditPurchaseModal {
   position: relative;

   .closeButton {
      position: absolute;
      top: 15px;
      right: 16px;
      z-index: 2;

      img {
         display: block;
         width: 14px;
         height: 14px;
         position: absolute;
         z-index: 4;
      }
   }

   .circle1 {
      z-index: 0;
      width: 195px;
      height: 220px;
      position: absolute;
      background-image: url('../../../../assets/images/circle1.webp');
      background-size: 195px 220px;
      background-repeat: no-repeat;
      background-position: top right;
      top: -24px;
      right: -24px;
   }

   .circle2 {
      z-index: 0;
      width: 98px;
      height: 200px;
      position: absolute;
      background-image: url('../../../../assets/images/circle2.webp');
      background-size: 98px 200px;
      background-repeat: no-repeat;
      background-position: top right;
      top: 73px;
      left: -24px;
   }

   .circle3 {
      z-index: 0;
      width: 88px;
      height: 88px;
      position: absolute;
      background-image: url('../../../../assets/images/circle3.webp');
      background-size: 88px 88px;
      background-repeat: no-repeat;
      background-position: bottom left;
      bottom: 32px;
      left: 24px;
   }

   .UserProfileModal {
      img {
         width: fit-content;
         max-width: 240px;
         margin: 0 auto;
         display: block;
      }

      h4 {
         text-transform: uppercase;
         font-family: Montserrat;
         font-size: 28px;
         font-style: italic;
         font-weight: 800;
         line-height: 32px;
         letter-spacing: 0.02em;
         text-align: center;
         text-transform: uppercase;
         background: linear-gradient(89.7deg, #17EA85 2.27%, #14D0E6 23.6%, #17EA85 44.41%, #14D0E6 64.17%, #17EA85 82.99%, #14D0E6 99.74%);
         -webkit-background-clip: text;
         -webkit-text-fill-color: transparent;
         background-clip: text;
         text-fill-color: transparent;
         text-shadow: 0px 0px 8px rgba(20, 208, 229, 0.5607843137);
         padding-top: 30px !important;
      }

   }

   .SaveTag {
      color: rgba(222, 74, 74, 1);
      background-color: #291421;
      width: fit-content;
      padding-left: 10px !important;
      padding-right: 10px !important;
      position: absolute;
      top: -14px;
      left: 50%;
      transform: translate(-50%, 0);
      font-size: 12px;
   }
}

//Created Team ConfirmationModal

.CreatedTeamConfirmationModal {
   .modalContentContainer {
      padding: 40px;

      .ModalButtonRow {
         display: flex;

         width: fit-content;
         margin: 0 auto;
         margin-top: 30px;

         .grayButton {
            width: fit-content;
            padding: 10px 15px;
            height: 44px;
            line-height: unset;
            margin-top: 4px;
         }

         .ContinueBtn {
            float: right;
            padding: 0;
            background: linear-gradient(90deg, #F9FF01, #2FFF9E);
            padding: 2px;
            border-radius: 5px;
            transform: skew(-10deg, 0deg);
            margin: 2px;
            padding: 10px 15px;
            width: fit-content;

            span {
               color: #000;
               font-weight: 700;
               text-transform: uppercase;
               font-family: "ObjectSans-Heavy";
            }
         }
      }

      p.SelectCaptainVcaptain {
         font-size: 14px;
         text-align: center;

         span {
            color: #00FFA3;
         }

         span:hover {
            opacity: .8;
         }
      }
   }
}

.HowFantasyWorks {
   p {
      font-size: 16px;
   }

   ul {
      margin-top: 20px;
      margin-left: 20px;
      margin-right: 26px;

      li {
         list-style: none;

         div {
            font-size: 16px;
            color: #17EA85;
         }
      }
   }

   button {
      width: fit-content;
      padding: 10px 20px;
      font-size: 16px;
      margin: 0 auto;
      margin-top: 10px;
      margin-bottom: 30px;
   }

   @mixin eMulticolorFull {
      position: absolute;
      background-image: url("../../../../assets/images/backgrounds/MulticolorFullEllipse.webp");
      background-repeat: no-repeat;
      background-size: 100% 100%;
   }
   @mixin eMulticolorHalf {
      position: absolute;
      background-image: url("../../../../assets/images/Ellipse-half.webp");
      background-repeat: no-repeat;
      background-size: 100% 100%;
   }

   .Radial1 {
      @include eMulticolorFull;
      width: 125px;
      height: 125px;
      top: 138px;
      right: 78px;
      mix-blend-mode: normal;

   }

   .Radial2 {
      @include eMulticolorHalf;
      width: 70px;
      height: 180px;
      top: 110px;
      left: 0;
      mix-blend-mode: normal;
      transform: rotate(180deg);
      z-index: 0;
      opacity: .7 !important;
      filter: blur(7px);
   }

   .Radial3 {
      @include eMulticolorFull;
      width: 78px;
      height: 78px;
      right: 50px;
      bottom: 74px;
      z-index: 0;
      mix-blend-mode: normal;
      filter: blur(8px);
   }

   .EdgeRadial1 {
      position: absolute;
      background-image: url("../../../../assets/images/backgrounds/EllipsePinkBackground.webp");
      background-repeat: no-repeat;
      background-size: 100% 100%;

      width: 315px;
      height: 212px;
      top: 0;
      right: 0;
      z-index: 0;
   }

   .EdgeRadial2 {
      position: absolute;
      background-image: url("../../../../assets/images/backgrounds/EdgeBlueEllipse2.webp");
      background-repeat: no-repeat;
      background-size: 100% 100%;

      width: 198px;
      height: 198px;
      top: 0;
      right: 0;
      z-index: 0;
      transform: rotate(180deg);
   }

   .HowFantasyWorksCheckBox {
      input[type="checkbox"] {
         margin-right: 15px;
         accent-color: #00FFC2;
         scale: 1.2;
      }
   }

   .SeeFantasyPointsCalculation {
      width: 100%;
      text-align: center;
      color: #17EA85 !important;
      text-decoration: underline;
      text-underline-offset: 5px;
      margin-bottom: 0 !important;
   }

   .SeeFantasyPointsCalculation:hover {
      opacity: .8;
      cursor: pointer;
   }
}

.FantasyPointCalculation {
   z-index: 4;

   .CreditPurchaseModal {
      max-width: 620px !important;
   }

   table {
      all: unset;
      border-collapse: collapse;
      width: inherit;
      table-layout: fixed;
      font-size: 14px;
      font-weight: normal;
   }

   td, th {
      padding: 5px;
   }

   th {
      font-weight: bold;
   }

   th:nth-child(2), th:nth-child(3), th:nth-child(4), td:nth-child(2), td:nth-child(3), td:nth-child(4) {
      min-width: 113px;
   }

   th:nth-child(1),
   td:nth-child(1) {
      width: 250px;
      min-width: 230px;
      padding-left: 5px;
   }

   thead tr {
      color: #eee;
      display: block;
      position: relative;
   }

   tbody {
      display: block;
      height: 400px;
      width: 585px;
      overflow-y: auto;
      overflow-x: hidden;

      tbody::-webkit-scrollbar {
         // width: 10px;
         height: 10px;
      }

      /* Track */
      tbody::-webkit-scrollbar-track {
         background: #f1f1f1;
      }

      /* Handle */
      tbody::-webkit-scrollbar-thumb {
         background: linear-gradient(180deg, #224755 -19.6%, #878787 48.63%, #646464 110.8%);
         border-radius: 5px;
      }

      /* Handle on hover */
      tbody::-webkit-scrollbar-thumb:hover {
         background: #555;
      }
   }

   thead {
      width: 100%;
      overflow-x: auto;
   }

   tr:nth-child(even) {
      background-color: #131f30;
      line-height: 30px;
   }

   tr:nth-child(odd) {
      background-color: #0c1926;
      line-height: 30px;
   }


   .Radial1 {
      @include eMulticolorFull;
      width: 125px;
      height: 125px;
      top: 138px;
      right: 78px;
      mix-blend-mode: normal;

   }

   .Radial2 {
      @include eMulticolorHalf;
      width: 70px;
      height: 180px;
      top: 110px;
      left: 0;
      mix-blend-mode: normal;
      transform: rotate(180deg);
      z-index: 0;
      opacity: .7 !important;
      filter: blur(7px);
   }

   .Radial3 {
      @include eMulticolorFull;
      position: absolute;
      width: 78px;
      height: 78px;
      right: 50px;
      bottom: 74px;
      z-index: 0;
      mix-blend-mode: normal;
      filter: blur(8px);
   }

   .EdgeRadial1 {
      position: absolute;
      background-image: url("../../../../assets/images/backgrounds/EllipsePinkBackground.webp");
      background-repeat: no-repeat;
      background-size: 100% 100%;

      width: 315px;
      height: 212px;
      top: 0;
      right: 0;
      z-index: 0;
   }

   .EdgeRadial2 {
      position: absolute;
      background-image: url("../../../../assets/images/backgrounds/EdgeBlueEllipse2.webp");
      background-repeat: no-repeat;
      background-size: 100% 100%;

      width: 198px;
      height: 198px;
      top: 0;
      right: 0;
      z-index: 0;
      transform: rotate(180deg);
   }

   .ModalButtonRow {
      width: 100%;
      display: block;

      button {
         padding: 7px 17px;
         margin: 23px 20px;
         margin-right: 30px;
         float: right;
         display: block;
         width: fit-content;

         text {
            font-size: 14px;
         }
      }
   }

   .leaderboardRow {
      overflow: unset !important;
   }
}

.AreYouSureMsgModal {
   .CreditPurchaseModal {
      .ContinueWithSelectedTeamBtns {
         button {
            margin: 5px;
         }

         .ContinueBtn {
            margin-top: 2.5px;
            margin-bottom: 2.5px;
         }
      }
   }
}

@media (max-width: 992px) {
   .ProfilePaneWrapper {
      .subTitle {
         padding-top: 6px !important;
      }
   }
}

@media (max-width: 768px) {
   .photoModifyModelBtnRow {
      display: grid !important;
      grid-template-columns: 1fr !important;
      gap: 15px !important;

      .LoginButton {
         margin-left: -13px;
      }
   }
   .CreditPurchaseModal {
      > .modalContentContainer {
         width: 95vw;

         .OmniCardWrapper {
            position: relative;

            .OmniCard {
               position: relative;
               width: 98%;
               margin: 0 auto;
               margin-bottom: 15px;

               > div {
                  width: fit-content;
                  margin: 0 auto;
                  margin-top: 4px;
               }
            }
         }

         fieldset {
            .OmniCal {
               display: block;

               > span {
                  display: none;
               }

               > div {
                  display: block;
                  margin-bottom: 10px;
               }
            }
         }
      }
   }
   .FantasyPointCalculation {
      .UserProfileModal {
         min-width: 100%;
         margin: 0 auto;
         padding: 0;
         overflow-x: auto;
         overflow-y: hidden;

         h4 {
            padding-top: 34px !important;
         }

         tbody {
            height: 300px;
         }
      }

      .UserProfileModal::-webkit-scrollbar {
         // width: 10px;
         height: 10px;
      }

      /* Track */
      .UserProfileModal::-webkit-scrollbar-track {
         background: #f1f1f1;
      }

      /* Handle */
      .UserProfileModal::-webkit-scrollbar-thumb {
         background: linear-gradient(180deg, #224755 -19.6%, #878787 48.63%, #646464 110.8%);
         border-radius: 5px;
      }

      /* Handle on hover */
      .UserProfileModal::-webkit-scrollbar-thumb:hover {
         background: #555;
      }

      table {
         min-width: 584px;

         tbody {
            min-width: 585px !important;
         }
      }

      th:nth-child(4), td:nth-child(4) {
         min-width: 114px;
      }
   }

}

@media (max-width: 576px) {
   .FantasyPointCalculation {
      .UserProfileModal {
         min-width: 100%;

         h4 {
            padding-top: 34px !important;
         }

         tbody {
            height: 260px;
         }
      }
   }
   .CreditPackagesModal {
      .TitleWrapper {
         h4 {
            font-size: 18px !important;
         }
      }

      .ModalButtonRow {
         gap: 5px;
      }
   }
   .AreYouSureMsgModal {
      .TitleWrapper {
         h4 {
            font-size: 18px !important;
         }
      }
   }
}
